import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { createChangeGroupStack, createGroupUndoableChangesFunction, mergeGroupedPatchOp, UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { editorClient } from "../../../communication/EditorClient";
import { copyTilePosition } from "../../../../shared/definitions/other/TilePosition";
import { EditorToolType } from "../../MapEditorStore";
import { editorMapStore } from "../../EditorMapStore";
export let DynamicMapElementChangeGroup;

(function (DynamicMapElementChangeGroup) {
  DynamicMapElementChangeGroup[DynamicMapElementChangeGroup["None"] = 0] = "None";
  DynamicMapElementChangeGroup[DynamicMapElementChangeGroup["Create"] = 1] = "Create";
  DynamicMapElementChangeGroup[DynamicMapElementChangeGroup["Delete"] = 2] = "Delete";
  DynamicMapElementChangeGroup[DynamicMapElementChangeGroup["MoveViaPaste"] = 3] = "MoveViaPaste";
  DynamicMapElementChangeGroup[DynamicMapElementChangeGroup["MoveStartMarker"] = 4] = "MoveStartMarker";
})(DynamicMapElementChangeGroup || (DynamicMapElementChangeGroup = {}));

const autoMergableGroups = [DynamicMapElementChangeGroup.MoveViaPaste, DynamicMapElementChangeGroup.MoveStartMarker];
const changeGroupStack = createChangeGroupStack(DynamicMapElementChangeGroup.None);
/**
 * This method groups all changes made inside `executer` and merges them into one undo/redo entry, and labels
 * it appropriately (according to the selected `group`) and executes side effects if necessary.
 *
 * @see {@link createGroupUndoableChangesFunction} for more information.
 *
 * @param group A group denoting the purpose of the grouped changes in executer
 * @param executer The callback that contains all changes that should be grouped
 * @param sideEffects Side effects to be executed after the first patch (initial run) or after all patches (undo/redo)
 */

export const groupUndoableMapEditorDynamicMapElementChanges = createGroupUndoableChangesFunction(changeGroupStack, DynamicMapElementChangeGroup.None, () => ({
  mapId: null
}));
export function undoableMapEditorDynamicMapElementPaste(targetPosition, mapEditorStore) {
  const cutDynamicMapElement = mapEditorStore.cutDynamicMapElement;
  groupUndoableMapEditorDynamicMapElementChanges(DynamicMapElementChangeGroup.MoveViaPaste, () => {
    cutDynamicMapElement.position.setXYPlane(targetPosition.x, targetPosition.y, targetPosition.plane);
  }, [new PasteSideEffect(cutDynamicMapElement, mapEditorStore)]);
}
export function undoableMapEditorSubmitCurrentMapDynamicMapElementsChanges(mapId, patch, inversePatch) {
  const currentStack = changeGroupStack[changeGroupStack.length - 1];
  const {
    currentChangeGroup,
    currentGroupId,
    queuedSideEffects
  } = currentStack;
  currentStack.queuedSideEffects = null;

  if (currentStack.currentChangeGroup !== DynamicMapElementChangeGroup.None) {
    if (currentStack.extraData.mapId != null && currentStack.extraData.mapId !== mapId) throw new Error("Groups must have the same mapId");
    currentStack.extraData.mapId = mapId;
  }

  executeUndoableOperation(new MapEditorSubmitCurrentMapDynamicMapElementsChangesOp(mapId, currentChangeGroup, currentGroupId, queuedSideEffects, [patch], [inversePatch]));
}

class MapEditorSubmitCurrentMapDynamicMapElementsChangesOp extends UndoableOperation {
  constructor(mapId, group, groupId, sideEffects, patches, inversePatches) {
    super("mapEditorSubmitCurrentMapDynamicMapElementsChanges/" + DynamicMapElementChangeGroup[group]); //console.log(`[${groupId}:${DynamicMapElementChangeGroup[group]}]`, { patches, inversePatches });

    this.mapId = mapId;
    this.group = group;
    this.groupId = groupId;
    this.sideEffects = sideEffects;
    this.patches = patches;
    this.inversePatches = inversePatches;
  }

  async execute(isRedo) {
    var _this$sideEffects;

    await editorClient.submitDynamicMapElementsChanges(this.mapId, this.patches, this.inversePatches, isRedo);

    if (isRedo) {
      editorClient.patch(editorMapStore.getOrLoadMapWithMetaData(this.mapId).map.dynamicMapElements, this.patches);
    }

    (_this$sideEffects = this.sideEffects) === null || _this$sideEffects === void 0 ? void 0 : _this$sideEffects.forEach(sideEffect => sideEffect.afterExecute(isRedo));
  }

  async reverse() {
    var _this$sideEffects2;

    const reversedInversePatches = this.inversePatches.slice().reverse();
    await editorClient.submitDynamicMapElementsChanges(this.mapId, reversedInversePatches, this.patches.slice().reverse(), true);
    editorClient.patch(editorMapStore.getOrLoadMapWithMetaData(this.mapId).map.dynamicMapElements, reversedInversePatches);
    (_this$sideEffects2 = this.sideEffects) === null || _this$sideEffects2 === void 0 ? void 0 : _this$sideEffects2.forEach(sideEffect => sideEffect.afterReverse());
  }

  merge(previousOperation) {
    return mergeGroupedPatchOp(this, previousOperation, autoMergableGroups, DynamicMapElementChangeGroup.None);
  }

}

class PasteSideEffect {
  constructor(cutDynamicMapElement, mapEditorStore) {
    _defineProperty(this, "dynamicMapElementModelId", void 0);

    _defineProperty(this, "mapEditorStore", void 0);

    this.dynamicMapElementModelId = cutDynamicMapElement.$modelId;
    this.mapEditorStore = mapEditorStore;
  }

  async afterExecute(isRedo) {
    this.mapEditorStore.setCutDynamicMapElementModelId(null);
  }

  async afterReverse() {
    this.mapEditorStore.setCutDynamicMapElementModelId(this.dynamicMapElementModelId);
  }

}

export class SelectTileSideEffect {
  constructor(mapEditorStore, tilePosition) {
    _defineProperty(this, "mapEditorStore", void 0);

    _defineProperty(this, "previousTilePosition", void 0);

    _defineProperty(this, "newTilePosition", void 0);

    this.mapEditorStore = mapEditorStore;
    this.newTilePosition = copyTilePosition(tilePosition);
    this.previousTilePosition = mapEditorStore.selectedTilePosition;
  }

  async afterExecute() {
    this.mapEditorStore.setSelectedTilePosition(this.newTilePosition);
  }

  async afterReverse() {
    this.mapEditorStore.setSelectedTilePosition(this.previousTilePosition);
  }

}
export class ActionMapEditorPlaceAssetSideEffect {
  constructor(mapEditorStore) {
    _defineProperty(this, "mapEditorStore", void 0);

    _defineProperty(this, "previousToolType", void 0);

    this.mapEditorStore = mapEditorStore;
    this.previousToolType = this.mapEditorStore.selectedTool;
  }

  async afterExecute() {
    this.mapEditorStore.setTool(EditorToolType.SingleSelect);
  }

  async afterReverse() {
    this.mapEditorStore.setTool(this.previousToolType);
  }

}