import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { EditorToolType, mainMapEditorStore, MapEditorStore } from "../../MapEditorStore";
import { doesPlacementSelectionLooselyEqual, OtherPlacementElement } from "../../MapRelatedStore";
import { TranslatedError } from "../../../../shared/definitions/errors/TranslatedError";
import { runInAction } from "mobx";
import { sharedStore } from "../../SharedStore";
export function undoableSelectTileAsset(mapRelatedStore, tileAssetId) {
  if (tileAssetId == mapRelatedStore.placementSelection.selectedTileAssetId && mapRelatedStore.allowToggleSelection) {
    tileAssetId = tileAssetId == null ? tileAssetId : null;
  }

  undoableApply("changedTileAsset", mapRelatedStore, EditorToolType.PlaceAsset, {
    selectedTileAssetId: tileAssetId
  });
}
export function undoableMapEditorSelectOtherPlacementElement(selectedOtherElement, mapEditorStore) {
  undoableApply("changed" + OtherPlacementElement[selectedOtherElement], mapEditorStore, EditorToolType.PlaceAsset, {
    selectedOtherElement
  });
}
export function undoableMapEditorSelectAreaTriggerWithPrefilledId(id, mapEditorStore) {
  undoableApply("changed" + OtherPlacementElement[OtherPlacementElement.AreaTrigger], mapEditorStore, EditorToolType.PlaceAsset, {
    selectedOtherElement: OtherPlacementElement.AreaTrigger,
    areaTriggerId: id
  });
}
export function undoableMapEditorSelectCharacterPlacement(selectedCharacterId) {
  undoableApply("changedCharacter", mainMapEditorStore, EditorToolType.PlaceAsset, {
    selectedCharacterId
  });
}
export function undoableMapEditorSelectAnimationElementPlacement(selectedAnimationName) {
  undoableApply("changedAnimationElement", mainMapEditorStore, EditorToolType.PlaceAsset, {
    selectedAnimationName
  });
}
export function undoableSelectTool(mapRelatedStore, tool) {
  const operationName = "activated" + MapEditorStore.ToolTypeToName.get(tool);
  undoableApply(operationName, mapRelatedStore, tool, mapRelatedStore.placementSelection);
}

function undoableApply(operationNamePostfix, mapRelatedStore, currentTool, placementSelection) {
  if (mapRelatedStore.selectedTool === currentTool && doesPlacementSelectionLooselyEqual(mapRelatedStore.placementSelection, placementSelection)) return;
  executeUndoableOperation(new SetPlacementSelectionOp(operationNamePostfix, mapRelatedStore, currentTool, placementSelection));
}

export function throwIfAssetsDoNotExist(placementSelection) {
  const {
    selectedTileAssetId
  } = placementSelection;
  if (selectedTileAssetId && !sharedStore.getTileAsset(selectedTileAssetId)) throw new TranslatedError("editor.error_tile_asset_does_not_exist");
}

class SetPlacementSelectionOp extends UndoableOperation {
  constructor(operationNamePostfix, mapRelatedStore, toolType, placementSelection) {
    super("setAssetSelection/" + operationNamePostfix);
    this.mapRelatedStore = mapRelatedStore;
    this.toolType = toolType;
    this.placementSelection = placementSelection;

    _defineProperty(this, "previousTool", void 0);

    _defineProperty(this, "previousPlacementSelection", void 0);

    this.previousTool = mapRelatedStore.selectedTool;
    this.previousPlacementSelection = mapRelatedStore.placementSelection;
  }

  async execute() {
    throwIfAssetsDoNotExist(this.placementSelection);
    runInAction(() => {
      this.mapRelatedStore.setTool(this.toolType);
      this.mapRelatedStore.setPlacementSelection(this.placementSelection);
    });
  }

  async reverse() {
    throwIfAssetsDoNotExist(this.previousPlacementSelection);
    runInAction(() => {
      this.mapRelatedStore.setTool(this.previousTool);
      this.mapRelatedStore.setPlacementSelection(this.previousPlacementSelection);
    });
  }

}