import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ViewArea } from "../../game/character/ViewArea";
import EventEmitter from "eventemitter3";

/**
 * A controller that contains {@link ViewArea}s.
 * It can trigger the events {@see EventViewAreaTriggerEnter} and {@see EventViewAreaTriggerLeave}.
 * Call {@link checkViewIntersections} to check for intersections.
 */
export class ViewAreaController {
  constructor() {
    _defineProperty(this, "viewAreas", new Array());

    _defineProperty(this, "overlayContainer", void 0);

    _defineProperty(this, "visible", false);

    _defineProperty(this, "events", new EventEmitter());
  }

  /**
   * Initializes all containing {@link ViewArea}s.
   * Should be called if the assigned {@link ViewAreaTriggerModel}s changed.
   * @param viewAreaTriggers The triggers to initialize from.
   */
  init(viewAreaTriggers) {
    this.clearContainer();
    this.viewAreas = new Array();
    viewAreaTriggers.forEach(trigger => {
      const area = ViewArea.fromModel(trigger);
      this.viewAreas.push(area);
    });
    this.fillContainer();
  }
  /**
   * Updates the position and rotation of the containing {@link ViewArea}s.
   * @param worldX The x world position.
   * @param worldY The y world position.
   * @param rotation The rotation in radiant.
   */


  updateTransform(worldX, worldY, rotation) {
    this.viewAreas.forEach(area => {
      area.update(worldX, worldY, rotation);
      if (this.visible) area.draw();
    });
  }
  /**
   * Checks intersections of the assigned world coordinates to all containing {@link ViewArea}s.
   * Emits the corresponding events: {@see ViewAreaController.EventViewAreaTriggerEnter} and {@see ViewAreaController.EventViewAreaTriggerLeave}
   * @param worldX The world x coordinate to check for intersection.
   * @param worldY The world y coordinate to check for intersection.
   */


  checkViewIntersections(worldX, worldY) {
    for (let i = 0; i < this.viewAreas.length; i++) {
      const area = this.viewAreas[i];
      const indexBefore = area.getIntersectingPolygonIndex();
      const wasIntersecting = area.isIntersecting();
      const isIntersectionNow = area.checkIntersection(worldX, worldY);
      const indexAfter = area.getIntersectingPolygonIndex();

      if (!wasIntersecting && isIntersectionNow) {
        this.events.emit("viewAreaTriggerEnter", area.triggerName);
        this.updateGraphics();
      }

      if (wasIntersecting && !isIntersectionNow) {
        this.events.emit("viewAreaTriggerLeave", area.triggerName);
        this.updateGraphics();
      }

      if (indexBefore != indexAfter) {
        this.updateGraphics(); // just for correct visualization
      }
    }
  }
  /**
   * Shows the containing {@link ViewArea}s and updates them.
   */


  showGraphics() {
    if (this.visible) return;
    this.visible = true;
    this.updateGraphics();
  }
  /**
   * Hides the containing {@link ViewArea}s and updates them.
   */


  hideGraphics() {
    if (!this.visible) return;
    this.visible = false;
    this.viewAreas.forEach(area => {
      area.clear();
    });
  }
  /**
   * Updates the containing {@link ViewArea}s.
   */


  updateGraphics() {
    if (!this.visible) return;
    this.viewAreas.forEach(area => {
      area.draw();
    });
  }
  /**
   * Removes the containing {@link ViewArea}s from the {@link Container} and destroys them.
   */


  destroy() {
    // Destroy all viewAreas. They will automatically be removed from their parent container.
    for (const viewArea of this.viewAreas) {
      viewArea.destroy({
        children: true
      });
    }

    this.viewAreas = [];
  }
  /**
   * Sets the {@link Container} to add the {@link ViewArea}s to.
   * @param container The container.
   */


  setOverlayContainer(container) {
    if (!container) return;
    this.clearContainer();
    this.overlayContainer = container;
    this.fillContainer();
  }

  clearContainer() {
    if (!this.overlayContainer) return;
    this.viewAreas.forEach(area => this.overlayContainer.removeChild(area));
  }

  fillContainer() {
    if (!this.overlayContainer) return;
    this.viewAreas.forEach(area => this.overlayContainer.addChild(area));
  }

}

_defineProperty(ViewAreaController, "EventViewAreaTriggerEnter", "EventViewAreaTriggerEnter");

_defineProperty(ViewAreaController, "EventViewAreaTriggerLeave", "EventViewAreaTriggerLeave");