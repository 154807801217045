import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2, _dec2, _class4, _class5, _dec3, _class7, _class8, _dec4, _class10, _class11, _dec5, _class13, _class14, _dec6, _class16, _class17, _dec7, _class19, _class20, _dec8, _class22, _class23, _dec9, _class25, _class26, _dec10, _class28, _class29, _dec11, _class31, _class32, _dec12, _class34, _class35, _dec13, _class37, _class38, _dec14, _class40, _class41, _dec15, _class43, _class44, _dec16, _class46, _class47, _dec17, _class49, _class50, _dec18, _class52, _class53, _dec19, _class55, _class56, _dec20, _class58, _class59, _dec21, _class61, _class62, _dec22, _class64, _class65, _dec23, _class67, _class68, _dec24, _class70, _class71, _dec25, _class73, _class74, _dec26, _class76, _class77, _dec27, _class79, _class80, _dec28, _class82, _class83, _dec29, _class85, _class86, _dec30, _class88, _class89, _dec31, _class91, _class92, _dec32, _class94, _class95, _dec33, _class97, _class98, _dec34, _class100, _class101, _dec35, _class103, _class104, _dec36, _class106, _class107, _dec37, _class109, _class110, _dec38, _class112, _class113, _dec39, _class115, _class116, _dec40, _class118, _class119, _dec41, _class121, _class122, _dec42, _class124, _class125, _dec43, _class127, _class128, _dec44, _class130, _class131, _dec45, _class133, _class134, _class135, _temp, _dec46, _class136, _class137, _dec47, _class139, _class140, _dec48, _class142, _class143, _dec49, _class145, _class146;

import { model, Model, modelAction, prop } from "mobx-keystone";
import { TranslatedString } from "../game/TranslatedString";
import { ActionPositionModel } from "./ActionPositionModel";
import { ConditionModel, ConditionType } from "./ConditionModel";
import { AnimationElementReferenceModel, MapElementReferenceModel } from "./MapElementReferenceModel";
import { SelectableExitModel } from "./SelectableExitModel";
import { TranslatedStringValueModel } from "./ValueModel";
import { ActionTreeColors } from "../data/ActionTreeColors";
import { NPCReferenceModel } from "./NPCReferenceModel";
import { InteractionTriggerIconType } from "../game/InteractionTriggerIconType";
import { isValidActionNumberValue } from "../helper/actionTreeHelper";
import { isBlank } from "../helper/generalHelpers";
import { EditorComplexity } from "../definitions/other/EditorComplexity";
import { CutSceneTextModel } from "./CutSceneTextModel";
import { computed } from "mobx";
export let ActionScope;

(function (ActionScope) {
  ActionScope[ActionScope["Tree"] = 0] = "Tree";
  ActionScope[ActionScope["Global"] = 1] = "Global";
})(ActionScope || (ActionScope = {}));

export const playStyles = ["Undecided", "Spy", "Fighter"];
export const factions = ["LandSeeker", "WaterStayer"];
export const reputationAmounts = ["Reasonable", "Small", "Large"];
export let LocationTriggerActionModel = (_dec = model("actions/LocationTriggerActionModel"), _dec(_class = (_class2 = class LocationTriggerActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  mapElement: prop(() => new MapElementReferenceModel({})).withSetter(),
  checkOnActivation: prop(false),
  stopPlayerPath: prop(false),
  triggerOnEnter: prop(true).withSetter(),
  enterArea: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop2);
  }

  get exitTrigger() {
    return this.enterArea;
  }

  toggleCheckOnActivation() {
    this.checkOnActivation = !this.checkOnActivation;
  }

  toggleStopPlayerPath() {
    this.stopPlayerPath = !this.stopPlayerPath;
  }

  shortDescription(_, t) {
    return t(this.triggerOnEnter ? "content.answer_enter" : "content.answer_leave") + ": " + this.mapElement.elementId + " (" + this.mapElement.mapId + ")";
  }

  exits() {
    return [this.exitTrigger];
  }

  exitLabels() {
    return [this.triggerOnEnter ? "content.answer_enter" : "content.answer_leave"];
  }

  title() {
    return "action_editor.node_trigger_location";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.LOCATION_TRIGGER;
  }

  get isDataComplete() {
    return this.mapElement.isComplete();
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "toggleCheckOnActivation", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleCheckOnActivation"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleStopPlayerPath", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleStopPlayerPath"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "isDataComplete"), _class2.prototype)), _class2)) || _class);
export let InteractionTriggerActionModel = (_dec2 = model("actions/InteractionTriggerActionModel"), _dec2(_class4 = (_class5 = class InteractionTriggerActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  iconType: prop(InteractionTriggerIconType.Interact).withSetter(),
  triggerElement: prop(() => new MapElementReferenceModel({})).withSetter(),
  triggeredActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop2);
  }

  shortDescription() {
    return this.triggerElement.mapId ? " (" + this.triggerElement.mapId + ")" : "";
  }

  exits() {
    return [this.triggeredActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_trigger_interaction";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.INTERACTION_TRIGGER;
  }

  get isDataComplete() {
    return this.triggerElement.isComplete();
  }

}, (_applyDecoratedDescriptor(_class5.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class5.prototype, "isDataComplete"), _class5.prototype)), _class5)) || _class4);
export let ConditionTriggerActionModel = (_dec3 = model("actions/ConditionTriggerActionModel"), _dec3(_class7 = (_class8 = class ConditionTriggerActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  condition: prop(() => new ConditionModel({})),
  triggeredActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription(_, t) {
    return t(this.condition.displayString());
  }

  exits() {
    return [this.triggeredActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_trigger_condition";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.CONDITION_TRIGGER;
  }

  get isDataComplete() {
    return this.condition.isDataComplete;
  }

}, (_applyDecoratedDescriptor(_class8.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class8.prototype, "isDataComplete"), _class8.prototype)), _class8)) || _class7);
export let TreeEnterActionModel = (_dec4 = model("actions/TreeEnterActionModel"), _dec4(_class10 = (_class11 = class TreeEnterActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  name: prop(() => new TranslatedString({})),
  enterActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription(rootActionTrees, t, languageKey) {
    return this.name.get(languageKey);
  }

  exits() {
    return [this.enterActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_entry";
  }

  translatableEntityData() {
    return {
      entity: this,
      label: "Tree Entry",
      translateableStrings: [{
        label: "Label",
        translatedString: this.name
      }]
    };
  }

  nodeColor() {
    return ActionTreeColors.TREE_ENTER;
  }

  get isDataComplete() {
    return true;
  }

}, (_applyDecoratedDescriptor(_class11.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class11.prototype, "isDataComplete"), _class11.prototype)), _class11)) || _class10);
export let TreeExitActionModel = (_dec5 = model("actions/TreeExitActionModel"), _dec5(_class13 = (_class14 = class TreeExitActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  name: prop(() => new TranslatedString({})),
  subTreeExit: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription(rootActionTrees, t, languageKey) {
    return this.name.get(languageKey);
  }

  exits() {
    return new Array();
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_exit";
  }

  translatableEntityData() {
    return {
      entity: this,
      label: "Tree Exit",
      translateableStrings: [{
        label: "Label",
        translatedString: this.name
      }]
    };
  }

  nodeColor() {
    return ActionTreeColors.TREE_EXIT;
  }

  get isDataComplete() {
    return true;
  }

}, (_applyDecoratedDescriptor(_class14.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class14.prototype, "isDataComplete"), _class14.prototype)), _class14)) || _class13);
export let StartDialogueActionModel = (_dec6 = model("actions/StartDialogueActionModel"), _dec6(_class16 = (_class17 = class StartDialogueActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  text: prop(() => new TranslatedString({})),
  speaker: prop(() => new NPCReferenceModel({})).withSetter(),
  defaultExit: prop(() => new SelectableExitModel({})),
  answers: prop(() => []).withSetter()
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop4);
  }

  addAnswer() {
    const newAnswer = new SelectableExitModel(this.answers.length === 0 ? {
      nextActions: [...this.defaultExit.nextActions]
    } : {});
    this.answers.push(newAnswer);
    return newAnswer;
  }

  removeAnswer(index) {
    if (this.answers.length === 1) {
      this.defaultExit.nextActions = [...this.answers[0].nextActions];
    }

    this.answers.splice(index, 1);
  }

  shortDescription(_, t, languageKey, getCharacter) {
    if (!this.speaker.npcId || this.speaker.npcId === "" || this.speaker.npcId === "-1") {
      return this.text.get(languageKey);
    }

    const character = getCharacter(+this.speaker.npcId);
    const speaker = character ? character.localizedName.get(languageKey) : this.speaker.npcId;
    return speaker + ": " + this.text.get(languageKey);
  }

  exits() {
    if (this.answers.length === 0) {
      return [this.defaultExit];
    }

    return this.answers;
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_dialogue";
  }

  translatableEntityData() {
    return {
      entity: this,
      label: "Dialogue",
      translateableStrings: [{
        label: "Text",
        translatedString: this.text
      }, ...this.answers.map((answer, index) => ({
        label: `Answer ${index + 1}`,
        translatedString: answer.value
      }))]
    };
  }

  nodeColor() {
    return ActionTreeColors.START_DIALOGUE;
  }

  get isDataComplete() {
    var _this$text;

    return !((_this$text = this.text) !== null && _this$text !== void 0 && _this$text.isCurrentLanguageEmpty());
  }

}, (_applyDecoratedDescriptor(_class17.prototype, "addAnswer", [modelAction], Object.getOwnPropertyDescriptor(_class17.prototype, "addAnswer"), _class17.prototype), _applyDecoratedDescriptor(_class17.prototype, "removeAnswer", [modelAction], Object.getOwnPropertyDescriptor(_class17.prototype, "removeAnswer"), _class17.prototype), _applyDecoratedDescriptor(_class17.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class17.prototype, "isDataComplete"), _class17.prototype)), _class17)) || _class16);
export let SetVariableActionModel = (_dec7 = model("actions/SetVariableActionModel"), _dec7(_class19 = (_class20 = class SetVariableActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  name: prop("").withSetter(),
  scope: prop(ActionScope.Tree).withSetter(),
  value: prop("").withSetter(),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop4);
  }

  shortDescription() {
    return this.name + " = " + this.value;
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_set_variable";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.SET_VARIABLE;
  }

  get isDataComplete() {
    return !isBlank(this.name) && !isBlank(this.value);
  }

}, (_applyDecoratedDescriptor(_class20.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class20.prototype, "isDataComplete"), _class20.prototype)), _class20)) || _class19);
export let CopyAwarenessIntoVariableActionModel = (_dec8 = model("actions/CopyAwarenessIntoVariableActionModel"), _dec8(_class22 = (_class23 = class CopyAwarenessIntoVariableActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  name: prop("").withSetter(),
  scope: prop(ActionScope.Global),
  value: prop("").withSetter(),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop4);
  }

  shortDescription() {
    return this.name + " = " + this.value;
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_copy_awareness_into_variable";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.SET_VARIABLE;
  }

  get isDataComplete() {
    return this.name && this.name.length > 0;
  }

}, (_applyDecoratedDescriptor(_class23.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class23.prototype, "isDataComplete"), _class23.prototype)), _class23)) || _class22);
export let SetTagActionModel = (_dec9 = model("actions/SetTagActionModel"), _dec9(_class25 = (_class26 = class SetTagActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  tag: prop("").withSetter(),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription() {
    return this.tag;
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_set_tag";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.SET_TAG;
  }

  get isDataComplete() {
    return !isBlank(this.tag);
  }

}, (_applyDecoratedDescriptor(_class26.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class26.prototype, "isDataComplete"), _class26.prototype)), _class26)) || _class25);
export let SetPlayStyleActionModel = (_dec10 = model("actions/SetPlayStyleActionModel"), _dec10(_class28 = (_class29 = class SetPlayStyleActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  playStyle: prop("").withSetter(),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription(_, t) {
    return playStyles.includes(this.playStyle) ? t("content.play_style_" + this.playStyle) : this.playStyle;
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_set_play_style";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.SET_PLAYSTYLE;
  }

  get isDataComplete() {
    return !isBlank(this.playStyle);
  }

}, (_applyDecoratedDescriptor(_class29.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class29.prototype, "isDataComplete"), _class29.prototype)), _class29)) || _class28);
export let ReceiveReputationActionModel = (_dec11 = model("actions/ReceiveReputationActionModel"), _dec11(_class31 = (_class32 = class ReceiveReputationActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  fraction: prop(factions[0]).withSetter(),
  amount: prop(reputationAmounts[0]).withSetter(),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription(_, t) {
    return (factions.includes(this.fraction) ? t("content.faction_" + this.fraction) : this.fraction) + " (" + t("content.reputation_amount_" + this.amount) + ")";
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels(t, languageKey) {
    return [t("content.answer_ok")];
  }

  title() {
    return "action_editor.node_reputation_receive";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.RECEIVE_REPUTATION;
  }

  get isDataComplete() {
    return !isBlank(this.fraction);
  }

}, (_applyDecoratedDescriptor(_class32.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class32.prototype, "isDataComplete"), _class32.prototype)), _class32)) || _class31);
export let ReceiveAwarenessActionModel = (_dec12 = model("actions/ReceiveAwarenessActionModel"), _dec12(_class34 = (_class35 = class ReceiveAwarenessActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  amount: prop("0").withSetter(),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription() {
    return "+" + this.amount;
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels(t, languageKey) {
    return [t("content.answer_ok")];
  }

  title() {
    return "action_editor.node_awareness_receive";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.RECEIVE_AWARENESS;
  }

  get isDataComplete() {
    return isValidActionNumberValue(this.amount);
  }

}, (_applyDecoratedDescriptor(_class35.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class35.prototype, "isDataComplete"), _class35.prototype)), _class35)) || _class34);
export let ReceiveItemActionModel = (_dec13 = model("actions/ReceiveItemActionModel"), _dec13(_class37 = (_class38 = class ReceiveItemActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  itemId: prop("").withSetter(),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop2);
  }

  shortDescription() {
    return this.itemId;
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels(t, languageKey) {
    return [t("content.answer_ok")];
  }

  title() {
    return "action_editor.node_item_receive";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.RECEIVE_ITEM;
  }

  get isDataComplete() {
    return !isBlank(this.itemId);
  }

}, (_applyDecoratedDescriptor(_class38.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class38.prototype, "isDataComplete"), _class38.prototype)), _class38)) || _class37);
export let ItemSelectionMode;

(function (ItemSelectionMode) {
  ItemSelectionMode[ItemSelectionMode["Item"] = 0] = "Item";
  ItemSelectionMode[ItemSelectionMode["ItemWithOneTag"] = 1] = "ItemWithOneTag";
  ItemSelectionMode[ItemSelectionMode["ItemWithMultipleTags"] = 2] = "ItemWithMultipleTags";
  ItemSelectionMode[ItemSelectionMode["ItemWithOneOfMultipleTags"] = 3] = "ItemWithOneOfMultipleTags";
})(ItemSelectionMode || (ItemSelectionMode = {}));

export let LooseItemActionModel = (_dec14 = model("actions/LooseItemActionModel"), _dec14(_class40 = (_class41 = class LooseItemActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  selectionMode: prop(ItemSelectionMode.Item).withSetter(),
  itemId: prop("").withSetter(),
  // kept the name 'itemId', but this can also be a 'tag' or a 'list of tags'
  allItems: prop(false).withSetter(),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop2);
  }

  shortDescription() {
    return this.itemId;
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels(t, languageKey) {
    return [t("content.answer_ok")];
  }

  title() {
    return "action_editor.node_item_lose";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.LOOSE_ITEM;
  }
  /**
   * 'itemId' property is interpreted as a list of item tags in a 'tag' selection mode.
   */


  itemTags() {
    return this.selectionMode !== ItemSelectionMode.Item ? this.itemId.split(" ") : [];
  }
  /**
   * Toggle if the player looses all the items that are selected if multipe items are selected by Tag(s)?
   */


  toggleAllItems() {
    this.allItems = !this.allItems;
  }

  get isDataComplete() {
    return !isBlank(this.itemId);
  }

}, (_applyDecoratedDescriptor(_class41.prototype, "toggleAllItems", [modelAction], Object.getOwnPropertyDescriptor(_class41.prototype, "toggleAllItems"), _class41.prototype), _applyDecoratedDescriptor(_class41.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class41.prototype, "isDataComplete"), _class41.prototype)), _class41)) || _class40);
export let UseItemTriggerActionModel = (_dec15 = model("actions/UseItemTriggerActionModel"), _dec15(_class43 = (_class44 = class UseItemTriggerActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  itemId: prop("").withSetter(),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  exitLabels() {
    return [];
  }

  exits() {
    return [this.nextActions];
  }

  get isDataComplete() {
    return !isBlank(this.itemId);
  }

  shortDescription() {
    return this.itemId;
  }

  title() {
    return "action_editor.node_item_use";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.USE_ITEM_TRIGGER;
  }
  /**
   * Searches in the assigned array of nodes for {@link UseItemTriggerActionModel} nodes with the assigned item id.
   * @param nodes The nodes to search in.
   * @param itemId The item id to search for.
   */


  static findByItemId(nodes, itemId) {
    if (!nodes) return [];
    return nodes.filter(node => {
      const triggerNode = node;
      return triggerNode && triggerNode.itemId == itemId;
    });
  }

}, (_applyDecoratedDescriptor(_class44.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class44.prototype, "isDataComplete"), _class44.prototype)), _class44)) || _class43);
export let ReceiveQuestActionModel = (_dec16 = model("actions/ReceiveQuestTaskActionModel"), _dec16(_class46 = (_class47 = class ReceiveQuestActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  questId: prop("").withSetter(),
  // Short 'id' for quest selection lists (to uniquily identify a quest, the $modelId is used)
  description: prop(() => new TranslatedString({})),
  global: prop(false),
  // This is used for visibility in quest selection lists in the editor. In the game, each Quest is unique (identified by the modelId of the ReceiveQuestTaskActionModel).
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop2);
  }

  shortDescription(_, t) {
    return this.questId + (this.global ? " (" + t("action_editor.property_scope_global") + ")" : "");
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels(t, languageKey) {
    return [t("content.answer_ok")];
  }

  title() {
    return "action_editor.node_quest_start";
  }

  translatableEntityData() {
    return {
      entity: this,
      label: "Receive Quest",
      translateableStrings: [{
        label: "Quest Title",
        translatedString: this.description
      }]
    };
  }

  nodeColor() {
    return ActionTreeColors.RECEIVE_QUEST_TASK;
  }

  toggleGlobal() {
    this.global = !this.global;
  }

  get isDataComplete() {
    return !isBlank(this.questId) && !this.description.isCurrentLanguageEmpty();
  }

}, (_applyDecoratedDescriptor(_class47.prototype, "toggleGlobal", [modelAction], Object.getOwnPropertyDescriptor(_class47.prototype, "toggleGlobal"), _class47.prototype), _applyDecoratedDescriptor(_class47.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class47.prototype, "isDataComplete"), _class47.prototype)), _class47)) || _class46);
export let FinishQuestActionModel = (_dec17 = model("actions/QuestTaskFinishedActionModel"), _dec17(_class49 = (_class50 = class FinishQuestActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  questId: prop("").withSetter(),
  // Set to modelId of a ReceiveQuestActionModel
  text: prop(() => new TranslatedString({})),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop2);
  }

  shortDescription(rootActionTrees) {
    for (const actionTree of rootActionTrees) {
      const quest = actionTree.questForId(this.questId);
      if (quest) return quest.questId;
    }

    return "";
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_quest_finish";
  }

  translatableEntityData() {
    return {
      entity: this,
      label: "Finish Quest",
      translateableStrings: [{
        label: "Message",
        translatedString: this.text
      }]
    };
  }

  nodeColor() {
    return ActionTreeColors.QUEST_TASK_FINISHED;
  }

  get isDataComplete() {
    return !isBlank(this.questId) && !this.text.isCurrentLanguageEmpty();
  }

}, (_applyDecoratedDescriptor(_class50.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class50.prototype, "isDataComplete"), _class50.prototype)), _class50)) || _class49);
export let AbortQuestActionModel = (_dec18 = model("actions/AbortQuestActionModel"), _dec18(_class52 = (_class53 = class AbortQuestActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  questId: prop("").withSetter(),
  // Set to modelId of a ReceiveQuestActionModel
  text: prop(() => new TranslatedString({})),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription(rootActionTrees) {
    for (const actionTree of rootActionTrees) {
      const quest = actionTree.questForId(this.questId);
      if (quest) return quest.questId;
    }

    return "";
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_quest_abort";
  }

  translatableEntityData() {
    return {
      entity: this,
      label: "Abort Quest",
      translateableStrings: [{
        label: "Message",
        translatedString: this.text
      }]
    };
  }

  nodeColor() {
    return ActionTreeColors.ABORT_QUEST;
  }

  get isDataComplete() {
    return !isBlank(this.questId) && !this.text.isCurrentLanguageEmpty();
  }

}, (_applyDecoratedDescriptor(_class53.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class53.prototype, "isDataComplete"), _class53.prototype)), _class53)) || _class52);
export let ReceiveTaskActionModel = (_dec19 = model("actions/ReceiveTaskActionModel"), _dec19(_class55 = (_class56 = class ReceiveTaskActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  questId: prop("").withSetter(),
  taskName: prop("").withSetter(),
  // Short 'id' for task selection lists (to uniquily identify a task the $modelId is used)
  description: prop(() => new TranslatedString({})),
  location: prop().withSetter(),
  // By default, there is no location
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop4);
  }

  isTaskWithLocation() {
    return !!this.location;
  }

  shortDescription() {
    return this.taskName;
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels(t, languageKey) {
    return [t("content.answer_ok")];
  }

  title() {
    return this.isTaskWithLocation() ? "action_editor.node_task_start_location" : "action_editor.node_task_start_default";
  }

  translatableEntityData() {
    return {
      entity: this,
      label: "Receive Task",
      translateableStrings: [{
        label: "Task Title",
        translatedString: this.description
      }]
    };
  }

  nodeColor() {
    return this.isTaskWithLocation() ? ActionTreeColors.RECEIVE_TASK_LOCATION : ActionTreeColors.RECEIVE_TASK_DEFAULT;
  }

  get isDataComplete() {
    return !isBlank(this.questId) && !isBlank(this.taskName) && !this.description.isCurrentLanguageEmpty() && (!this.isTaskWithLocation() || this.location.isComplete());
  }

}, (_applyDecoratedDescriptor(_class56.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class56.prototype, "isDataComplete"), _class56.prototype)), _class56)) || _class55);
export let FinishTaskActionModel = (_dec20 = model("actions/FinishTaskActionModel"), _dec20(_class58 = (_class59 = class FinishTaskActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  questId: prop("").withSetter(),
  // Set to modelId of a ReceiveQuestActionModel
  taskId: prop("").withSetter(),
  // Set to modelId of a ReceiveTaskActionModel
  text: prop(() => new TranslatedString({})),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription(rootActionTrees) {
    for (const actionTree of rootActionTrees) {
      const task = actionTree.taskForId(this.taskId);
      if (task) return task.taskName;
    }

    return "";
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels(t, languageKey) {
    return [t("content.answer_ok")];
  }

  title() {
    return "action_editor.node_task_finish";
  }

  translatableEntityData() {
    return {
      entity: this,
      label: "Finish Task",
      translateableStrings: [{
        label: "Message",
        translatedString: this.text
      }]
    };
  }

  nodeColor() {
    return ActionTreeColors.FINISH_TASK;
  }

  get isDataComplete() {
    return !isBlank(this.questId) && !isBlank(this.taskId) && !this.text.isCurrentLanguageEmpty();
  }

}, (_applyDecoratedDescriptor(_class59.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class59.prototype, "isDataComplete"), _class59.prototype)), _class59)) || _class58);
export let AbortTaskActionModel = (_dec21 = model("actions/AbortTaskActionModel"), _dec21(_class61 = (_class62 = class AbortTaskActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  questId: prop("").withSetter(),
  // Set to modelId of a ReceiveQuestActionModel
  taskId: prop("").withSetter(),
  // Set to modelId of a ReceiveTaskActionModel
  text: prop(() => new TranslatedString({})),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription(rootActionTrees) {
    for (const actionTree of rootActionTrees) {
      const task = actionTree.taskForId(this.taskId);
      if (task) return task.taskName;
    }

    return "";
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_task_abort";
  }

  translatableEntityData() {
    return {
      entity: this,
      label: "Abort Task",
      translateableStrings: [{
        label: "Message",
        translatedString: this.text
      }]
    };
  }

  nodeColor() {
    return ActionTreeColors.ABORT_TASK;
  }

  get isDataComplete() {
    return !isBlank(this.questId) && !isBlank(this.taskId) && !this.text.isCurrentLanguageEmpty();
  }

}, (_applyDecoratedDescriptor(_class62.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class62.prototype, "isDataComplete"), _class62.prototype)), _class62)) || _class61);
export let StartFightActionModel = (_dec22 = model("actions/StartFightActionModel"), _dec22(_class64 = (_class65 = class StartFightActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  enemies: prop(() => []),
  win: prop(() => new SelectableExitModel({})),
  loose: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop4);
  }

  addEnemy(index, newEnemy) {
    this.enemies.splice(index, 0, newEnemy);
    return newEnemy;
  }

  removeEnemy(index) {
    this.enemies.splice(index, 1);
  }

  shortDescription() {
    return "";
  }

  exits() {
    return [this.win, this.loose];
  }

  exitLabels(t, languageKey) {
    return [t("content.answer_win"), t("content.answer_lose")];
  }

  title() {
    return "action_editor.node_fight";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.START_FIGHT;
  }

  get isDataComplete() {
    return this.enemies.some(e => e.isComplete());
  }

}, (_applyDecoratedDescriptor(_class65.prototype, "addEnemy", [modelAction], Object.getOwnPropertyDescriptor(_class65.prototype, "addEnemy"), _class65.prototype), _applyDecoratedDescriptor(_class65.prototype, "removeEnemy", [modelAction], Object.getOwnPropertyDescriptor(_class65.prototype, "removeEnemy"), _class65.prototype), _applyDecoratedDescriptor(_class65.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class65.prototype, "isDataComplete"), _class65.prototype)), _class65)) || _class64);
export let TriggerDamageInAreaActionModel = (_dec23 = model("actions/TriggerDamageInAreaActionModel"), _dec23(_class67 = (_class68 = class TriggerDamageInAreaActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  mapElement: prop(() => new MapElementReferenceModel({})).withSetter(),
  damage: prop("1").withSetter(),
  delay: prop("1").withSetter(),
  duration: prop("1").withSetter(),
  exitNodeActivated: prop(() => new SelectableExitModel({})),
  exitStartedTriggering: prop(() => new SelectableExitModel({})),
  exitDamagedPlayer: prop(() => new SelectableExitModel({})),
  exitFinishedTriggering: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription() {
    return this.mapElement.elementId + " (" + this.mapElement.mapId + ")";
  }

  exits() {
    return [this.exitNodeActivated, this.exitStartedTriggering, this.exitDamagedPlayer, this.exitFinishedTriggering];
  }

  exitLabels(t, languageKey) {
    return [t("content.node_activated"), t("content.started_triggering"), t("content.damaged_player"), t("content.finished_triggering")];
  }

  title() {
    return "action_editor.node_trigger_damage_in_area";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.TRIGGER_DAMAGE_ON_TILE;
  }

  get isDataComplete() {
    return this.mapElement.isComplete();
  }

}, (_applyDecoratedDescriptor(_class68.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class68.prototype, "isDataComplete"), _class68.prototype)), _class68)) || _class67);
export let PlayAnimationActionModel = (_dec24 = model("actions/PlayAnimationModel"), _dec24(_class70 = (_class71 = class PlayAnimationActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  animationElement: prop(() => new AnimationElementReferenceModel({})).withSetter(),
  nextActions: prop(() => new SelectableExitModel({})),
  exitAnimationFinished: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop4);
  }

  shortDescription() {
    const {
      elementLabel,
      animationName,
      loop
    } = this.animationElement;
    return `${elementLabel} ${animationName + loop ? " (Loop)" : ""}`;
  }

  exits() {
    var _this$animationElemen;

    if ((_this$animationElemen = this.animationElement) !== null && _this$animationElemen !== void 0 && _this$animationElemen.loop) return [this.nextActions];
    return [this.nextActions, this.exitAnimationFinished];
  }

  exitLabels(t, languageKey) {
    var _this$animationElemen2;

    if ((_this$animationElemen2 = this.animationElement) !== null && _this$animationElemen2 !== void 0 && _this$animationElemen2.loop) return [t("content.answer_started")];
    return [t("content.answer_started"), t("content.answer_finished")];
  }

  title() {
    return "action_editor.node_play_animation";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.PLAY_ANIMATION;
  }

  get isDataComplete() {
    return this.animationElement.isComplete();
  }

}, (_applyDecoratedDescriptor(_class71.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class71.prototype, "isDataComplete"), _class71.prototype)), _class71)) || _class70);
export let MoveMapElementActionModel = (_dec25 = model("actions/MoveMapElementActionModel"), _dec25(_class73 = (_class74 = class MoveMapElementActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  mapElement: prop(() => new MapElementReferenceModel({})).withSetter(),
  targetMapMarker: prop(() => new MapElementReferenceModel({})).withSetter(),
  teleport: prop(false).withSetter(),
  directNextActions: prop(() => new SelectableExitModel({})),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription() {
    return "";
  }

  exits() {
    return [this.directNextActions, this.nextActions];
  }

  exitLabels(t, languageKey) {
    return [t("content.answer_started"), t("content.answer_target_reached")];
  }

  title() {
    return "action_editor.node_move_map_element";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.MOVE_MAP_ELEMENT;
  }

  get isDataComplete() {
    return this.mapElement.isComplete() && this.targetMapMarker.isComplete() && (this.mapElement.isTreeParameter() || this.targetMapMarker.isTreeParameter() || this.mapElement.mapId === this.targetMapMarker.mapId);
  }

}, (_applyDecoratedDescriptor(_class74.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class74.prototype, "isDataComplete"), _class74.prototype)), _class74)) || _class73);
export let StopMapElementActionModel = (_dec26 = model("actions/StopMapElementActionModel"), _dec26(_class76 = (_class77 = class StopMapElementActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  mapElement: prop(() => new MapElementReferenceModel({})).withSetter(),
  defaultExit: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription() {
    return "";
  }

  exits() {
    return [this.defaultExit];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_stop_map_element";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.STOP_MAP_ELEMENT;
  }

  get isDataComplete() {
    return this.mapElement.isComplete();
  }

}, (_applyDecoratedDescriptor(_class77.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class77.prototype, "isDataComplete"), _class77.prototype)), _class77)) || _class76);
export let MovePlayerActionModel = (_dec27 = model("actions/MovePlayerActionModel"), _dec27(_class79 = (_class80 = class MovePlayerActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  targetMapMarker: prop(() => new MapElementReferenceModel({})).withSetter(),
  transitionTime: prop("1").withSetter(),
  // time in seconds
  teleport: prop(false),
  // should we teleport with transition screen? (instead of actually moving or transporting as fast as possible when switching maps)
  directNextActions: prop(() => new SelectableExitModel({})),
  // Executed instantly after this node
  nextActions: prop(() => new SelectableExitModel({})) // Executed when the player reached the target

}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop4);
  }

  shortDescription() {
    return "Map ID: " + this.targetMapMarker.mapId.toString();
  }

  exits() {
    return [this.directNextActions, this.nextActions];
  }

  exitLabels(t, languageKey) {
    return [t("content.answer_started"), t("content.answer_target_reached")];
  }

  title() {
    return "action_editor.node_load_map";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.LOAD_MAP;
  }

  toggleTeleport() {
    this.teleport = !this.teleport;
  }

  get isDataComplete() {
    return this.targetMapMarker.isComplete();
  }

}, (_applyDecoratedDescriptor(_class80.prototype, "toggleTeleport", [modelAction], Object.getOwnPropertyDescriptor(_class80.prototype, "toggleTeleport"), _class80.prototype), _applyDecoratedDescriptor(_class80.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class80.prototype, "isDataComplete"), _class80.prototype)), _class80)) || _class79);
export let ConditionActionModel = (_dec28 = model("actions/ConditionActionModel"), _dec28(_class82 = (_class83 = class ConditionActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  condition: prop(() => new ConditionModel({})),
  conditionTrue: prop(() => new SelectableExitModel({})),
  conditionFalse: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop3);
  }

  isPlayStyleCondition() {
    return this.condition.conditionType === ConditionType.PlayStyle && this.condition.variableName === playStyles[1] && this.condition.value === "1";
  }

  shortDescription(_, t) {
    return this.isPlayStyleCondition() ? "" : t(this.condition.displayString());
  }

  exits() {
    return [this.conditionTrue, this.conditionFalse];
  }

  exitLabels(t, languageKey) {
    return this.isPlayStyleCondition() ? [t("content.play_style_Spy"), t("content.play_style_Fighter")] : [t("content.answer_true"), t("content.answer_false")];
  }

  title() {
    return this.isPlayStyleCondition() ? "action_editor.node_condition_play_style" : "action_editor.node_condition";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return this.isPlayStyleCondition() ? ActionTreeColors.CONDITION_PLAYSTYLE : ActionTreeColors.CONDITION;
  }

  get isDataComplete() {
    return this.isPlayStyleCondition() || this.condition.isDataComplete;
  }

}, (_applyDecoratedDescriptor(_class83.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class83.prototype, "isDataComplete"), _class83.prototype)), _class83)) || _class82);
export let ModifyPlayerHealthModel = (_dec29 = model("actions/ModifyPlayerHealthModel"), _dec29(_class85 = (_class86 = class ModifyPlayerHealthModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  amount: prop("0").withSetter(),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription() {
    return "" + this.amount;
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_modify_player_health";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.MODIFY_PLAYER_HEALTH;
  }

  get isDataComplete() {
    return isValidActionNumberValue(this.amount) && +this.amount != 0;
  }

}, (_applyDecoratedDescriptor(_class86.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class86.prototype, "isDataComplete"), _class86.prototype)), _class86)) || _class85);
export let ResetAreaActionModel = (_dec30 = model("actions/ResetAreaActionModel"), _dec30(_class88 = (_class89 = class ResetAreaActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription() {
    return "";
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_reset_area";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.RESET_AREA;
  }

  get isDataComplete() {
    return true;
  }

}, (_applyDecoratedDescriptor(_class89.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class89.prototype, "isDataComplete"), _class89.prototype)), _class89)) || _class88);
export let TossCoinActionModel = (_dec31 = model("actions/TossCoinActionModel"), _dec31(_class91 = (_class92 = class TossCoinActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  heads: prop(() => new SelectableExitModel({})),
  tails: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription() {
    return "";
  }

  exits() {
    return [this.heads, this.tails];
  }

  exitLabels(t, languageKey) {
    return [t("content.answer_heads"), t("content.answer_tails")];
  }

  title() {
    return "action_editor.node_toss_coin";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.TOSS_COIN;
  }

  get isDataComplete() {
    return true;
  }

}, (_applyDecoratedDescriptor(_class92.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class92.prototype, "isDataComplete"), _class92.prototype)), _class92)) || _class91);
export let CalculateVariableOperator;

(function (CalculateVariableOperator) {
  CalculateVariableOperator[CalculateVariableOperator["Plus"] = 0] = "Plus";
  CalculateVariableOperator[CalculateVariableOperator["Minus"] = 1] = "Minus";
  CalculateVariableOperator[CalculateVariableOperator["Multiply"] = 2] = "Multiply";
  CalculateVariableOperator[CalculateVariableOperator["Divide"] = 3] = "Divide";
})(CalculateVariableOperator || (CalculateVariableOperator = {}));

export const calculateVariableOperatorLiterals = [" + ", " - ", " * ", " / "];
export let CalculateVariableActionModel = (_dec32 = model("actions/CalculateVariableActionModel"), _dec32(_class94 = (_class95 = class CalculateVariableActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  variable1: prop("").withSetter(),
  operator: prop(CalculateVariableOperator.Plus).withSetter(),
  variable2: prop("").withSetter(),
  variableResult: prop("").withSetter(),
  scope: prop(ActionScope.Tree).withSetter(),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop4);
  }

  shortDescription() {
    return this.variable1 + calculateVariableOperatorLiterals[this.operator] + this.variable2 + " = " + this.variableResult;
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_calculate_variable";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.CALCULATE_VARIABLE;
  }

  get isDataComplete() {
    return !isBlank(this.variable1) && !isBlank(this.variable2) && !isBlank(this.variableResult);
  }

}, (_applyDecoratedDescriptor(_class95.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class95.prototype, "isDataComplete"), _class95.prototype)), _class95)) || _class94);
export let ShowTextActionModel = (_dec33 = model("actions/ShowTextActionModel"), _dec33(_class97 = (_class98 = class ShowTextActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  text: prop(() => new TranslatedString({})),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription(_rootActionTrees, _t, languageKey) {
    return this.text.get(languageKey);
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_show_text";
  }

  translatableEntityData() {
    return {
      entity: this,
      label: "Show Text",
      translateableStrings: [{
        label: "Text",
        translatedString: this.text
      }]
    };
  }

  nodeColor() {
    return ActionTreeColors.SHOW_TEXT;
  }

  get isDataComplete() {
    var _this$text2;

    return !((_this$text2 = this.text) !== null && _this$text2 !== void 0 && _this$text2.isCurrentLanguageEmpty());
  }

}, (_applyDecoratedDescriptor(_class98.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class98.prototype, "isDataComplete"), _class98.prototype)), _class98)) || _class97);
export let ShowImageActionModel = (_dec34 = model("actions/ShowImageActionModel"), _dec34(_class100 = (_class101 = class ShowImageActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  imageId: prop(0).withSetter(),
  imageShown: prop(() => new SelectableExitModel({})),
  imageClosed: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop4);
  }

  shortDescription() {
    return "" + this.imageId;
  }

  exits() {
    return [this.imageShown, this.imageClosed];
  }

  exitLabels(t, languageKey) {
    return [t("content.image_shown"), t("content.image_closed")];
  }

  title() {
    return "action_editor.node_show_image";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.SHOW_IMAGE;
  }

  get isDataComplete() {
    return this.imageId > 0;
  }

}, (_applyDecoratedDescriptor(_class101.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class101.prototype, "isDataComplete"), _class101.prototype)), _class101)) || _class100);
export let StartTimerActionModel = (_dec35 = model("actions/StartTimerActionModel"), _dec35(_class103 = (_class104 = class StartTimerActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  time: prop("0").withSetter(),
  // time in seconds
  countDown: prop(true),
  // Is the timer a count down or should it count up?
  visible: prop(true),
  // is the timer shown in the ui?
  text: prop(() => new TranslatedString({})),
  // text to show above the timer
  started: prop(() => new SelectableExitModel({})),
  finished: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop4);
  }

  toggleCountDown() {
    this.countDown = !this.countDown;
  }

  toggleVisible() {
    this.visible = !this.visible;
  }

  shortDescription() {
    return "" + this.time;
  }

  exits() {
    return [this.started, this.finished];
  }

  exitLabels(t, languageKey) {
    return [t("content.answer_started"), t("content.answer_finished")];
  }

  title() {
    return "action_editor.node_start_timer";
  }

  translatableEntityData() {
    return {
      entity: this,
      label: "Start Timer",
      translateableStrings: [{
        label: "Label",
        translatedString: this.text
      }]
    };
  }

  nodeColor() {
    return ActionTreeColors.START_TIMER;
  }

  get isDataComplete() {
    return isValidActionNumberValue(this.time);
  }

}, (_applyDecoratedDescriptor(_class104.prototype, "toggleCountDown", [modelAction], Object.getOwnPropertyDescriptor(_class104.prototype, "toggleCountDown"), _class104.prototype), _applyDecoratedDescriptor(_class104.prototype, "toggleVisible", [modelAction], Object.getOwnPropertyDescriptor(_class104.prototype, "toggleVisible"), _class104.prototype), _applyDecoratedDescriptor(_class104.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class104.prototype, "isDataComplete"), _class104.prototype)), _class104)) || _class103);
export let StartActActionModel = (_dec36 = model("actions/StartActActionModel"), _dec36(_class106 = (_class107 = class StartActActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  act: prop("2").withSetter(),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription() {
    return "" + this.act;
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return [];
  }

  title() {
    return "action_editor.node_start_act";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.START_ACT;
  }

  get isDataComplete() {
    return true;
  }

}, (_applyDecoratedDescriptor(_class107.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class107.prototype, "isDataComplete"), _class107.prototype)), _class107)) || _class106);
export let SetReputationStatusActionModel = (_dec37 = model("actions/SetReputationStatusActionModel"), _dec37(_class109 = (_class110 = class SetReputationStatusActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  status: prop(() => new TranslatedString({})),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription(_rootActionTrees, _t, languageKey) {
    return this.status.get(languageKey);
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return [];
  }

  title() {
    return "action_editor.node_set_reputation_status";
  }

  translatableEntityData() {
    return {
      entity: this,
      label: "Set Reputation Status",
      translateableStrings: [{
        label: "Message",
        translatedString: this.status
      }]
    };
  }

  nodeColor() {
    return ActionTreeColors.SET_REPUTATION_STATUS;
  }

  get isDataComplete() {
    return !this.status.isCurrentLanguageEmpty();
  }

}, (_applyDecoratedDescriptor(_class110.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class110.prototype, "isDataComplete"), _class110.prototype)), _class110)) || _class109);
export let TreeParamterActionModel = (_dec38 = model("actions/TreeParamterActionModel"), _dec38(_class112 = (_class113 = class TreeParamterActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  name: prop("").withSetter(),
  description: prop(() => new TranslatedString({})),
  tutorial: prop(() => new TranslatedString({})),
  dividingLine: prop(false),
  showOnNode: prop(false),
  allowBlankValue: prop(false),
  value: prop(() => new TranslatedStringValueModel({})).withSetter()
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription(_, t) {
    return this.name + " (" + t(this.value.title()) + ")";
  }

  exits() {
    return new Array();
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_tree_parameter";
  }

  translatableEntityData() {
    const additionalTranslatedStrings = new Array();

    if (this.value instanceof TranslatedStringValueModel) {
      additionalTranslatedStrings.push({
        label: "Text Value",
        translatedString: this.value.value
      });
    }

    return {
      entity: this,
      label: "Tree Parameter",
      translateableStrings: [{
        label: "Label",
        translatedString: this.description
      }, {
        label: "Tutorial",
        translatedString: this.tutorial
      }, ...additionalTranslatedStrings]
    };
  }

  toggleDividingLine() {
    this.dividingLine = !this.dividingLine;
  }

  toggleShowOnNode() {
    this.showOnNode = !this.showOnNode;
  }

  toggleAllowBlankValue() {
    this.allowBlankValue = !this.allowBlankValue;
  }

  nodeColor() {
    return ActionTreeColors.TREE_PARAMETER;
  }

  get isDataComplete() {
    return !isBlank(this.name) && !this.description.isCurrentLanguageEmpty();
  }

  isValueSet() {
    return this.value.isSet();
  }

}, (_applyDecoratedDescriptor(_class113.prototype, "toggleDividingLine", [modelAction], Object.getOwnPropertyDescriptor(_class113.prototype, "toggleDividingLine"), _class113.prototype), _applyDecoratedDescriptor(_class113.prototype, "toggleShowOnNode", [modelAction], Object.getOwnPropertyDescriptor(_class113.prototype, "toggleShowOnNode"), _class113.prototype), _applyDecoratedDescriptor(_class113.prototype, "toggleAllowBlankValue", [modelAction], Object.getOwnPropertyDescriptor(_class113.prototype, "toggleAllowBlankValue"), _class113.prototype), _applyDecoratedDescriptor(_class113.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class113.prototype, "isDataComplete"), _class113.prototype)), _class113)) || _class112);
export let TreePropertiesActionModel = (_dec39 = model("actions/TreePropertiesActionModel"), _dec39(_class115 = (_class116 = class TreePropertiesActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  minimumComplexity: prop(EditorComplexity.Production).withSetter(),
  localizedName: prop(() => new TranslatedString({})),
  tags: prop(() => []).withSetter(),
  description: prop(() => new TranslatedString({})),
  tutorial: prop(() => new TranslatedString({})),
  complexityGate: prop(EditorComplexity.Production).withSetter(),
  color: prop("").withSetter(),
  icon: prop("").withSetter(),
  excludeFromTranslations: prop(false)
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);
  }

  shortDescription(_rootActionTrees, _t, languageKey) {
    return this.description.get(languageKey);
  }

  exits() {
    return new Array();
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_tree_properties";
  }

  translatableEntityData() {
    return {
      entity: this,
      label: "Tree Properties",
      translateableStrings: [{
        label: "Description",
        translatedString: this.description
      }, {
        label: "Tutorial",
        translatedString: this.tutorial
      }, {
        label: "Label",
        translatedString: this.localizedName
      }]
    };
  }

  nodeColor() {
    return ActionTreeColors.TREE_PROPERTIES;
  }

  toggleExcludeFromTranslations() {
    this.excludeFromTranslations = !this.excludeFromTranslations;
  }

  get isDataComplete() {
    return !this.localizedName.isCurrentLanguageEmpty();
  }

}, (_applyDecoratedDescriptor(_class116.prototype, "toggleExcludeFromTranslations", [modelAction], Object.getOwnPropertyDescriptor(_class116.prototype, "toggleExcludeFromTranslations"), _class116.prototype), _applyDecoratedDescriptor(_class116.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class116.prototype, "isDataComplete"), _class116.prototype)), _class116)) || _class115);
export let CommentActionModel = (_dec40 = model("actions/CommentActionModel"), _dec40(_class118 = (_class119 = class CommentActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  color: prop("").withSetter(),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop2);
  }

  shortDescription() {
    return this.comment;
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_comment";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return this.color ? this.color : ActionTreeColors.COMMENT;
  }

  get isDataComplete() {
    return true;
  }

}, (_applyDecoratedDescriptor(_class119.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class119.prototype, "isDataComplete"), _class119.prototype)), _class119)) || _class118);
export let DebugStartActionModel = (_dec41 = model("actions/DebugStartActionModel"), _dec41(_class121 = (_class122 = class DebugStartActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  initialize: prop(false),
  nextActions: prop(() => new SelectableExitModel({}))
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription() {
    return "";
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_debug_start";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.DEBUG_START;
  }

  toggleInitialize() {
    this.initialize = !this.initialize;
  }

  get isDataComplete() {
    return true;
  }

}, (_applyDecoratedDescriptor(_class122.prototype, "toggleInitialize", [modelAction], Object.getOwnPropertyDescriptor(_class122.prototype, "toggleInitialize"), _class122.prototype), _applyDecoratedDescriptor(_class122.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class122.prototype, "isDataComplete"), _class122.prototype)), _class122)) || _class121);
export let SetPlayerInputActionModel = (_dec42 = model("actions/SetPlayerInputActionModel"), _dec42(_class124 = (_class125 = class SetPlayerInputActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  nextActions: prop(() => new SelectableExitModel({})),
  uiVisible: prop(true).withSetter(),
  movementEnabled: prop(true).withSetter()
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription() {
    return "";
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_set_input";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.SET_PLAYER_INPUT_ACTION;
  }

  get isDataComplete() {
    return true;
  }

}, (_applyDecoratedDescriptor(_class125.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class125.prototype, "isDataComplete"), _class125.prototype)), _class125)) || _class124);
export let SetCameraActionModel = (_dec43 = model("actions/SetCameraActionModel"), _dec43(_class127 = (_class128 = class SetCameraActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  onStartExit: prop(() => new SelectableExitModel({})),
  onEndExit: prop(() => new SelectableExitModel({})),
  targetZoomFactor: prop(-1).withSetter(),
  returnToMainCamera: prop(true).withSetter(),
  targetLocation: prop().withSetter(),
  cameraMovementSpeedFactor: prop(0.5).withSetter()
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop4);
  }

  shortDescription() {
    return "";
  }

  exits() {
    return [this.onStartExit, this.onEndExit];
  }

  exitLabels(t, languageKey) {
    return [t("content.answer_started"), t("content.answer_finished")];
  }

  title() {
    return "action_editor.node_set_camera";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.CAMERA_NODES;
  }

  get isDataComplete() {
    return !this.targetLocation || this.targetLocation.isComplete();
  }

  isTargetSet() {
    return this.targetLocation && this.targetLocation.isComplete();
  }

}, (_applyDecoratedDescriptor(_class128.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class128.prototype, "isDataComplete"), _class128.prototype)), _class128)) || _class127);
export let ShakeCameraActionModel = (_dec44 = model("actions/ShakeCameraActionModel"), _dec44(_class130 = (_class131 = class ShakeCameraActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  onStartExit: prop(() => new SelectableExitModel({})),
  onEndExit: prop(() => new SelectableExitModel({})),
  intensity: prop(0.2).withSetter(),
  durationSeconds: prop(0.5).withSetter(),
  fadeOut: prop(true).withSetter()
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop4);
  }

  shortDescription() {
    return "";
  }

  exits() {
    return [this.onStartExit, this.onEndExit];
  }

  exitLabels(t, languageKey) {
    return [t("content.answer_started"), t("content.answer_finished")];
  }

  title() {
    return "action_editor.node_shake_camera";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.CAMERA_NODES;
  }

  get isDataComplete() {
    return true;
  }

}, (_applyDecoratedDescriptor(_class131.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class131.prototype, "isDataComplete"), _class131.prototype)), _class131)) || _class130);
export let FadeCameraActionModel = (_dec45 = model("actions/FadeCameraActionModel"), _dec45(_class133 = (_class134 = (_temp = _class135 = class FadeCameraActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  onStartExit: prop(() => new SelectableExitModel({})),
  onEndExit: prop(() => new SelectableExitModel({})),
  fadeIn: prop(true).withSetter(),
  withDuration: prop(true).withSetter()
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop4);
  }

  shortDescription() {
    return "";
  }

  exits() {
    return [this.onStartExit, this.onEndExit];
  }

  exitLabels(t, languageKey) {
    return [t("content.answer_started"), t("content.answer_finished")];
  }

  title() {
    return "action_editor.node_fade_overlay";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.CAMERA_NODES;
  }

  get isDataComplete() {
    return true;
  }

}, _defineProperty(_class135, "FADE_STEP", 0.005), _defineProperty(_class135, "FADE_INTERVAL_MILLIS", 10), _temp), (_applyDecoratedDescriptor(_class134.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class134.prototype, "isDataComplete"), _class134.prototype)), _class134)) || _class133);
export let SimpleCutSceneActionModel = (_dec46 = model("actions/SimpleCutSceneActionModel"), _dec46(_class136 = (_class137 = class SimpleCutSceneActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  onEndExit: prop(() => new SelectableExitModel({})),
  onStartExit: prop(() => new SelectableExitModel({})),
  textItems: prop(() => null).withSetter(),
  animation: prop(null).withSetter()
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription() {
    return "";
  }

  exits() {
    return [this.onStartExit, this.onEndExit];
  }

  exitLabels(t, languageKey) {
    return [t("content.answer_started"), t("content.answer_finished")];
  }

  title() {
    return "action_editor.node_cut_scene";
  }

  translatableEntityData() {
    if (!this.textItems || this.textItems.length === 0) return null;
    return {
      entity: this,
      label: "Cutscene",
      translateableStrings: this.textItems.map((item, index) => ({
        label: `Cutscene Text ${index + 1}`,
        translatedString: item.text
      }))
    };
  }

  nodeColor() {
    return ActionTreeColors.CUTSCENE;
  }

  get isDataComplete() {
    if ((!this.textItems || this.textItems.length === 0) && !this.animation) return false;
    return (!this.textItems || this.textItems.every(item => !item.text.isCurrentLanguageEmpty())) && (!this.animation || this.animation.isSet());
  }

  addTextItem() {
    this.textItems.push(new CutSceneTextModel({}));
  }

  removeTextItem(index) {
    this.textItems.splice(index, 1);
  }

}, (_applyDecoratedDescriptor(_class137.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class137.prototype, "isDataComplete"), _class137.prototype), _applyDecoratedDescriptor(_class137.prototype, "addTextItem", [modelAction], Object.getOwnPropertyDescriptor(_class137.prototype, "addTextItem"), _class137.prototype), _applyDecoratedDescriptor(_class137.prototype, "removeTextItem", [modelAction], Object.getOwnPropertyDescriptor(_class137.prototype, "removeTextItem"), _class137.prototype)), _class137)) || _class136);
export let SetEmergencyLightingActionModel = (_dec47 = model("actions/SetEmergencyLightingActionModel"), _dec47(_class139 = (_class140 = class SetEmergencyLightingActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  nextActions: prop(() => new SelectableExitModel({})),
  activate: prop(true).withSetter()
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription() {
    return "";
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return "action_editor.node_start_emergency_lighting";
  }

  translatableEntityData() {
    return null;
  }

  nodeColor() {
    return ActionTreeColors.START_EMERGENCY_LIGHTING;
  }

  get isDataComplete() {
    return true;
  }

  toggleActivate() {
    this.activate = !this.activate;
  }

}, (_applyDecoratedDescriptor(_class140.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class140.prototype, "isDataComplete"), _class140.prototype), _applyDecoratedDescriptor(_class140.prototype, "toggleActivate", [modelAction], Object.getOwnPropertyDescriptor(_class140.prototype, "toggleActivate"), _class140.prototype)), _class140)) || _class139);
export let PlaySoundActionModel = (_dec48 = model("actions/PlaySoundActionModel"), _dec48(_class142 = (_class143 = class PlaySoundActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  nextActions: prop(() => new SelectableExitModel({})),
  exitFinished: prop(() => new SelectableExitModel({})),
  filePath: prop(null).withSetter(),
  treeParameter: prop(null).withSetter(),
  sourcePosition: prop().withSetter(),
  rangeInTiles: prop(3).withSetter(),
  loopWhileInRange: prop(false).withSetter()
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Workshop4);
  }

  shortDescription() {
    return "";
  }

  exits() {
    if (!this.hasExitFinish()) return [this.nextActions];
    return [this.nextActions, this.exitFinished];
  }

  exitLabels(t, languageKey) {
    if (!this.hasExitFinish()) return [t("content.answer_started")];
    return [t("content.answer_started"), t("content.answer_finished")];
  }

  hasExitFinish() {
    return !this.loopWhileInRange || !this.sourcePosition;
  }

  title() {
    return "action_editor.node_play_sound";
  }

  nodeColor() {
    return ActionTreeColors.PLAY_ANIMATION;
  }

  translatableEntityData() {
    return null;
  }

  get isDataComplete() {
    return this.filePath && (!this.sourcePosition || this.sourcePosition.isComplete());
  }

}, (_applyDecoratedDescriptor(_class143.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class143.prototype, "isDataComplete"), _class143.prototype)), _class143)) || _class142);
export let DeactivateNodeGroupActionModel = (_dec49 = model("actions/DeactivateNodeGroupActionModel"), _dec49(_class145 = (_class146 = class DeactivateNodeGroupActionModel extends Model({
  position: prop(() => new ActionPositionModel({})),
  comment: prop("").withSetter(),
  deactivationGroupId: prop(() => "").withSetter(),
  nextActions: prop(() => new SelectableExitModel({})),
  targetDeactivationGroupId: prop(() => "").withSetter()
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "isActionModel", true);

    _defineProperty(this, "minimumComplexity", EditorComplexity.Production);
  }

  shortDescription() {
    return this.targetDeactivationGroupId;
  }

  exits() {
    return [this.nextActions];
  }

  exitLabels() {
    return null;
  }

  title() {
    return (
      /*t*/
      "action_editor.node_deactivate_node_group"
    );
  }

  nodeColor() {
    return ActionTreeColors.DEACTIVATE_NODE_GROUP;
  }

  translatableEntityData() {
    return null;
  }

  get isDataComplete() {
    return this.targetDeactivationGroupId.length > 0;
  }

}, (_applyDecoratedDescriptor(_class146.prototype, "isDataComplete", [computed], Object.getOwnPropertyDescriptor(_class146.prototype, "isDataComplete"), _class146.prototype)), _class146)) || _class145);