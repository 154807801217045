import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable, observable } from "mobx";
export class ItemStore {
  constructor() {
    _defineProperty(this, "items", void 0);

    _defineProperty(this, "editorSelectedItem", void 0);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
    this.items = observable.map(new Map());
  }

  setAllItems(newItems) {
    this.items = observable.map(newItems);
  }

  setItem(newItem) {
    this.items.set(newItem.id, newItem);
  }

  setSelectedItem(item) {
    this.editorSelectedItem = item;
  }

  deleteItem(itemToDelete) {
    var _this$items;

    (_this$items = this.items) === null || _this$items === void 0 ? void 0 : _this$items.delete(itemToDelete.id);
  }

  deleteItemById(itemId) {
    var _this$items2;

    (_this$items2 = this.items) === null || _this$items2 === void 0 ? void 0 : _this$items2.delete(itemId);
  }

  getItem(id) {
    var _this$items3;

    return (_this$items3 = this.items) === null || _this$items3 === void 0 ? void 0 : _this$items3.get(id);
  }

  getItemsForTag(tag) {
    return this.getAllItems.filter(item => !tag || item.tags.indexOf(tag) >= 0);
  }
  /**
   * All items that have *one* of the given tags.
   */


  getItemsForSomeTag(tags) {
    return this.getAllItems.filter(item => tags.some(t => item.tags.includes(t)));
  }
  /**
   * All items that have *all* of the given tags.
   */


  getItemsForEveryTag(tags) {
    return this.getAllItems.filter(item => tags.every(t => item.tags.includes(t)));
  }

  get getAllItems() {
    if (!this.items || this.items.size === 0) return [];
    return Array.from(this.items.values());
  }

  get selectedItem() {
    return this.editorSelectedItem;
  }

  getAllItemTags() {
    return [...new Set(this.getAllItems.map(item => item.tags).flat())].filter(t => t !== '').sort();
  }

}
export const itemStore = new ItemStore();