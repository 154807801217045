import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _dec, _class;

import { model, Model, prop } from "mobx-keystone";
import { ReadonlyPosition } from "../PositionModel";
export let DynamicMapElementAnimationElementModel = (_dec = model("game/DynamicMapElementAnimationElementModel"), _dec(_class = class DynamicMapElementAnimationElementModel extends Model({
  position: prop(),
  animationName: prop(),
  startAnimationName: prop("").withSetter(),
  startAnimationLoops: prop(true).withSetter(),
  label: prop("").withSetter(),
  isInteractionTrigger: prop(false).withSetter(),
  isModuleGate: prop(false).withSetter()
}) {
  createReadOnlyVersion() {
    return new ReadonlyDynamicMapElementAnimationElement(this);
  }

}) || _class);
export class ReadonlyDynamicMapElementAnimationElement {
  constructor(data) {
    _defineProperty(this, "$modelId", void 0);

    _defineProperty(this, "position", void 0);

    _defineProperty(this, "animationName", void 0);

    _defineProperty(this, "startAnimationName", void 0);

    _defineProperty(this, "startAnimationLoops", void 0);

    _defineProperty(this, "label", void 0);

    _defineProperty(this, "isInteractionTrigger", void 0);

    _defineProperty(this, "isModuleGate", void 0);

    this.$modelId = data.$modelId;
    this.position = new ReadonlyPosition(data.position);
    this.animationName = data.animationName;
    this.startAnimationName = data.startAnimationName;
    this.startAnimationLoops = data.startAnimationLoops;
    this.label = data.label;
    this.isInteractionTrigger = data.isInteractionTrigger;
    this.isModuleGate = data.isModuleGate;
  }

  createReadOnlyVersion() {
    return this;
  }

}