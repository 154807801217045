import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _dec, _class;

import { model, Model, prop } from "mobx-keystone";
import { ReadonlyPosition } from "../PositionModel";
export let DynamicMapElementMapMarkerModel = (_dec = model("game/DynamicMapElementMapMarkerModel"), _dec(_class = class DynamicMapElementMapMarkerModel extends Model({
  position: prop(),
  label: prop("").withSetter()
}) {
  get isInteractionTrigger() {
    return false;
  }

  get isModuleGate() {
    return false;
  }

  createReadOnlyVersion() {
    return new ReadonlyDynamicMapElementMapMarker(this);
  }

}) || _class);
export class ReadonlyDynamicMapElementMapMarker {
  constructor(data) {
    _defineProperty(this, "position", void 0);

    _defineProperty(this, "label", void 0);

    _defineProperty(this, "$modelId", void 0);

    this.position = new ReadonlyPosition(data.position);
    this.label = data.label;
    this.$modelId = data.$modelId;
  }

  createReadOnlyVersion() {
    return this;
  }

}