import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { editorClient } from "../../../communication/EditorClient";
import { executeUndoableOperation } from "../UndoStore";
import { ActionTreeType } from "../../../../shared/action/ActionTreeModel";
import { actionTreeSetSelection, getCurrentlySelectedHierarchyIds, getCurrentReactFlowInstanceTransform, selectHierarchyFromIds } from "./actionEditorSupport";
import { actionEditorStore, allTemplatesCategory } from "../../ActionEditorStore";
import { sharedStore } from "../../SharedStore";
import { getSnapshot } from "mobx-keystone";
export function undoableActionEditorCreateTemplate(source) {
  const {
    copy,
    allNewTrees
  } = source.cloneWithNewIds(ActionTreeType.TemplateRoot);
  copy.resetPositionAndCleanParameters();

  for (const newTree of allNewTrees) {
    sharedStore.addActionTree(newTree);
  }

  executeUndoableOperation(new ActionEditorCreateTemplateOp(allNewTrees.map(newTree => getSnapshot(newTree))));
}

class ActionEditorCreateTemplateOp extends UndoableOperation {
  constructor(templateAndSubtrees) {
    var _actionEditorStore$cu;

    super("actionEditorCreateTemplate");
    this.templateAndSubtrees = templateAndSubtrees;

    _defineProperty(this, "previousActionTreeHierarchy", void 0);

    _defineProperty(this, "previousCategory", void 0);

    _defineProperty(this, "previousActionModelId", void 0);

    _defineProperty(this, "previousTransform", void 0);

    this.previousCategory = actionEditorStore.currentCategory;
    this.previousActionModelId = (_actionEditorStore$cu = actionEditorStore.currentAction) === null || _actionEditorStore$cu === void 0 ? void 0 : _actionEditorStore$cu.$modelId;
    this.previousActionTreeHierarchy = actionEditorStore.currentActionSelectionHierarchyIdsForUndo || getCurrentlySelectedHierarchyIds();
    this.previousTransform = actionEditorStore.currentActionSelectionTransformForUndo || getCurrentReactFlowInstanceTransform();
  }

  async execute(isRedo) {
    actionEditorStore.clearClickActions();

    if (isRedo) {
      await editorClient.unDeleteActionTrees(this.templateAndSubtrees.map(tree => tree.$modelId));
    } else {
      await editorClient.createActionTrees(this.templateAndSubtrees);
    }

    actionEditorStore.deselectSelectedAction();
    selectHierarchyFromIds([this.templateAndSubtrees[0].$modelId]);
    actionEditorStore.setSelectedCategory(allTemplatesCategory);
  }

  async reverse() {
    actionEditorStore.clearClickActions();
    await editorClient.deleteActionTrees(this.templateAndSubtrees);
    actionTreeSetSelection(this.previousActionModelId, this.previousActionTreeHierarchy, this.previousTransform);
    actionEditorStore.setSelectedCategory(this.previousCategory);
  }

}