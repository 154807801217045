import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { MapState } from "./MapState";
import { LocalStorageObjectBoolean } from "../integration/localStorage";
import { GameEngine } from "../gameengine/GameEngine";
import { GameStateModel } from "../gameengine/GameStateModel";
import { CharacterEditorStore } from "./CharacterEditorStore";
import { CharacterDefaultName } from "../canvas/game/character/characterAnimationHelper";
import { Gender } from "../../shared/definitions/other/Gender";
import { GameDesignVariablesModel } from "../../shared/game/GameDesignVariablesModel";
import { editorClient } from "../communication/EditorClient";
import { LogEntry } from "./LogEntry";
import i18n from "../integration/i18n";
import { sharedStore } from "./SharedStore";
import { errorStore } from "./ErrorStore";
import { setSharedCurrentLanguageKeyCallback } from "../../shared/data/dataAccess";
import { addSentryGameLogBreadcrumb } from "../helper/sentryHelpers";
const localStorageAccessibilityOptions = new LocalStorageObjectBoolean("accessibilityOptions", false);
export let MapLoadingState;

(function (MapLoadingState) {
  MapLoadingState[MapLoadingState["NotLoading"] = 0] = "NotLoading";
  MapLoadingState[MapLoadingState["LoadingCachedMap"] = 1] = "LoadingCachedMap";
  MapLoadingState[MapLoadingState["LoadingMapFromServer"] = 2] = "LoadingMapFromServer";
})(MapLoadingState || (MapLoadingState = {}));

export class GameStore {
  // For the player, the name is saved here and not in character
  // because it should not be localized
  constructor() {
    _defineProperty(this, "blockSharedActionTrees", void 0);

    _defineProperty(this, "languageKey", void 0);

    _defineProperty(this, "accessibilityOptions", localStorageAccessibilityOptions.get());

    _defineProperty(this, "mapState", new MapState());

    _defineProperty(this, "gameEngine", void 0);

    _defineProperty(this, "characterEditorStore", new CharacterEditorStore(false));

    _defineProperty(this, "character", void 0);

    _defineProperty(this, "playerName", CharacterDefaultName);

    _defineProperty(this, "playerGender", Gender.Neutral);

    _defineProperty(this, "gameDesignVariables", new GameDesignVariablesModel({}));

    _defineProperty(this, "debugStartNodeModelId", null);

    _defineProperty(this, "debugStartMarker", void 0);

    _defineProperty(this, "debugStartMarkerMapId", void 0);

    _defineProperty(this, "gameLog", new Array());

    _defineProperty(this, "gameLogChangeCounter", 0);

    _defineProperty(this, "gameLogLimit", 100);

    _defineProperty(this, "debugLogAndTriggerAutoRefresh", true);

    _defineProperty(this, "selectedStartMap", null);

    _defineProperty(this, "currentCamera", void 0);

    _defineProperty(this, "playerCamera", void 0);

    _defineProperty(this, "cameraIsAnimating", false);

    _defineProperty(this, "cutSceneAnimationStore", void 0);

    _defineProperty(this, "mapLoadingState", void 0);

    _defineProperty(this, "activeSoundSources", new Array());

    _defineProperty(this, "mapOffsetX", void 0);

    _defineProperty(this, "mapOffsetY", void 0);

    _defineProperty(this, "mapScale", void 0);

    _defineProperty(this, "taskMarkers", observable.array());

    makeAutoObservable(this, {}, {
      autoBind: true
    });
  }

  get gameInProgress() {
    return Boolean(this.gameEngine);
  }

  get hasStartMap() {
    return !!this.loadStartMapId;
  }

  get loadStartMapId() {
    return this.selectedStartMap;
  }

  setSelectedStartMap(mapId) {
    this.selectedStartMap = mapId;
  }

  setCutSceneAnimationStore(store) {
    this.cutSceneAnimationStore = store;
  }

  clearSelectedStartMap() {
    this.setSelectedStartMap(0);
  }

  setLanguageKey(languageKey) {
    this.languageKey = languageKey;
  }

  setPlayerCharacter(character) {
    this.character = character;
  }

  setPlayerName(playerName) {
    this.playerName = playerName;
  }

  setPlayerGender(gender) {
    this.playerGender = gender;
  }

  setGameDesignVariables(variables) {
    if (this.gameDesignVariables === variables) return;
    if (this.gameDesignVariables) editorClient.stopTrackingGameDesignVariables();
    this.gameDesignVariables = variables;
    if (this.gameDesignVariables) editorClient.startTrackingGameDesignVariables();
  }

  setDebugStartNodeModelId(debugStartNodeModelId) {
    if (this.debugStartNodeModelId === debugStartNodeModelId) {
      this.debugStartNodeModelId = null;
      return;
    }

    this.debugStartNodeModelId = debugStartNodeModelId;
  }

  setDebugStartMarker(newStartMarker, mapId) {
    this.debugStartMarker = newStartMarker;
    this.debugStartMarkerMapId = mapId;
  }

  setCameraIsAnimating(isAnimating) {
    this.cameraIsAnimating = isAnimating;
  }

  toggleAccessibilityOptions() {
    this.accessibilityOptions = !this.accessibilityOptions;
    localStorageAccessibilityOptions.set(this.accessibilityOptions);
  }

  startGame(startMapId, loadMapCallback, movePlayerCallback, onPlayerDamaged, damageInAreaVisualManager, player) {
    runInAction(() => {
      this.blockSharedActionTrees = true;
      const gameState = new GameStateModel({
        currentMap: startMapId
      });
      const {
        mainGameRootActionTree,
        subTrees
      } = sharedStore;
      this.gameEngine = new GameEngine(mainGameRootActionTree, subTrees, gameState, loadMapCallback, movePlayerCallback, this.addErrorFromErrorObject.bind(this), onPlayerDamaged, damageInAreaVisualManager, player);
      this.addLog(LogEntry.byStartedGame());
      this.gameEngine.start();
    });
  }

  addErrorFromErrorObject(error) {
    // This might later be separated from errorStore, but for now, let's just use our regular errorStore mechanism to display errors
    errorStore.addErrorFromErrorObject(error);
  }

  get isLoadingMap() {
    return this.mapLoadingState !== MapLoadingState.NotLoading;
  }

  setMapLoadingState(value) {
    this.mapLoadingState = value;
  }

  disposeCurrentData() {
    this.cameraIsAnimating = false;
    this.setMapLoadingState(MapLoadingState.NotLoading);
    this.setCutSceneAnimationStore(null);
    this.disposeGameEngine();
    this.blockSharedActionTrees = false;
  }

  disposeGameEngine() {
    var _this$gameEngine;

    (_this$gameEngine = this.gameEngine) === null || _this$gameEngine === void 0 ? void 0 : _this$gameEngine.dispose();
    this.gameEngine = null;
  }

  addLog(entry) {
    addSentryGameLogBreadcrumb(entry);
    if (this.gameLogLimit > -1 && this.gameLog.length >= this.gameLogLimit) this.gameLog.splice(0, 1);
    this.gameLog.push(entry);
    this.gameLogChangeCounter++;
  }

  setGameLogLimit(limit) {
    this.gameLogLimit = limit;

    if (this.gameLogLimit > -1 && this.gameLog.length > limit) {
      this.gameLog.splice(0, this.gameLog.length - limit);
      this.gameLogChangeCounter++;
    }
  }

  clearLog() {
    if (this.gameLog.length === 0) return;
    this.gameLog.length = 0;
    this.gameLogChangeCounter++;
  }

  get gameLogLength() {
    return this.gameLog.length;
  }

  toggleDebugLogAndTriggerAutoRefresh() {
    this.debugLogAndTriggerAutoRefresh = !this.debugLogAndTriggerAutoRefresh;
  }

  initPlayerCamera(camera) {
    this.playerCamera = camera;
    this.enablePlayerCamera();
  }

  enablePlayerCamera() {
    this.currentCamera = this.playerCamera;
  }

  setCurrentCamera(camera) {
    this.currentCamera = camera;
  }

  addSoundSource(soundSource) {
    this.activeSoundSources.push(soundSource);
  }

  getSoundSourceByIndex(index) {
    return this.activeSoundSources[index];
  }

  removeSoundSourceByIndex(index) {
    this.activeSoundSources.splice(index, 1);
  }

  removeSoundSourceByActionId(actionId) {
    this.activeSoundSources = this.activeSoundSources.filter(a => a.actionId != actionId);
  }

  getSoundSourceCount() {
    return this.activeSoundSources.length;
  }

  updateFromCurrentCamera() {
    this.mapOffsetX = this.currentCamera.getX();
    this.mapOffsetY = this.currentCamera.getY();
    this.mapScale = this.currentCamera.getZoom();
  }

}
export class TaskMarkerColorPosition {
  constructor() {
    _defineProperty(this, "x", void 0);

    _defineProperty(this, "y", void 0);

    _defineProperty(this, "color", void 0);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
  }

}
export const gameStore = new GameStore();
i18n.on("initialized", () => gameStore.setLanguageKey(i18n.language));
i18n.on("languageChanged", () => gameStore.setLanguageKey(i18n.language));
setSharedCurrentLanguageKeyCallback(() => gameStore.languageKey);