import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { editorClient } from "../../../communication/EditorClient";
export function undoableMapEditorCloseCurrentMap(currentMapStore) {
  if (!currentMapStore.hasCurrentMap) return;
  executeUndoableOperation(new MapEditorCloseCurrentMapOp(currentMapStore, false));
}
export function undoableMapEditorCloseCurrentMapBecauseOfDeletion(currentMapStore) {
  executeUndoableOperation(new MapEditorCloseCurrentMapOp(currentMapStore, true));
}

class MapEditorCloseCurrentMapOp extends UndoableOperation {
  constructor(currentMapStore, willBeAutomaticallyClosedBecauseOfDeletion) {
    super("mapEditorCloseCurrentMap");
    this.currentMapStore = currentMapStore;
    this.willBeAutomaticallyClosedBecauseOfDeletion = willBeAutomaticallyClosedBecauseOfDeletion;

    _defineProperty(this, "previousMapId", void 0);

    this.previousMapId = currentMapStore.currentMapId;
  }

  async execute(isRedo) {
    if (this.willBeAutomaticallyClosedBecauseOfDeletion && !isRedo) return;
    this.currentMapStore.clearCurrentMap();
  }

  async reverse() {
    await editorClient.openMapInMapEditor(this.currentMapStore, this.previousMapId);
  }

}