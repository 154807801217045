import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { mainMapEditorStore } from "../../MapEditorStore";
import { doesPlacementSelectionLooselyEqual } from "../../MapRelatedStore";
import { runInAction } from "mobx";
export function undoableSetMapEditorComplexity(value) {
  executeUndoableOperation(new MapEditorSetMapEditorComplexity(value));
}

class MapEditorSetMapEditorComplexity extends UndoableOperation {
  constructor(value) {
    super("mapEditorSetMapEditorComplexity");
    this.value = value;

    _defineProperty(this, "previousValue", void 0);

    _defineProperty(this, "previousPlane", void 0);

    _defineProperty(this, "previousTileLayer", void 0);

    _defineProperty(this, "previousPlacementSelection", void 0);

    _defineProperty(this, "previousTileAssetTagFilter", void 0);

    this.previousValue = mainMapEditorStore.mapEditorComplexity;
    this.previousPlane = mainMapEditorStore.selectedPlane;
    this.previousTileLayer = mainMapEditorStore.selectedLayer;
    this.previousPlacementSelection = mainMapEditorStore.placementSelection;
    this.previousTileAssetTagFilter = mainMapEditorStore.tileAssetTagFilter;
  }

  async execute() {
    mainMapEditorStore.setMapEditorComplexity(this.value);
  }

  async reverse() {
    mainMapEditorStore.setMapEditorComplexity(this.previousValue);
    runInAction(() => {
      mainMapEditorStore.setPlane(this.previousPlane);
      mainMapEditorStore.setSelectedLayer(this.previousTileLayer);
      mainMapEditorStore.setPlacementSelection(this.previousPlacementSelection);
      mainMapEditorStore.setTileAssetTagFilter(this.previousTileAssetTagFilter);
    });

    if (!doesPlacementSelectionLooselyEqual(this.previousPlacementSelection, mainMapEditorStore.placementSelection)) {
      throw new Error("MapEditorSetMapEditorComplexity: Couldn't properly reset previous selection.");
    }
  }

}