import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Rectangle } from "pixi.js";
export class SpatialGridCell extends Array {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "mapElementSorterVisitedVisibleElements", 0);
  }

}
export class SpatialGrid {
  constructor() {
    _defineProperty(this, "grid", new Array());

    _defineProperty(this, "gridSizeX", 0);

    _defineProperty(this, "gridSizeY", 0);

    _defineProperty(this, "cellSize", 500);

    _defineProperty(this, "xOffset", 0);

    _defineProperty(this, "yOffset", 0);
  }

  insertOrUpdate(object, bounds, info) {
    if (!info.isDirty) return false;
    info.isDirty = false;
    this.remove(object, info);

    if (!info.currentBounds) {
      info.currentBounds = new Rectangle();
    }

    info.currentBounds.copyFrom(bounds);
    let fromX = this.xOffset + this.worldCoordinateToCellCoordinate(info.currentBounds.left);
    let toX = this.xOffset + this.worldCoordinateToCellCoordinate(info.currentBounds.right);
    let fromY = this.yOffset + this.worldCoordinateToCellCoordinate(info.currentBounds.top);
    let toY = this.yOffset + this.worldCoordinateToCellCoordinate(info.currentBounds.bottom);

    while (fromX < 0) {
      this.grid.unshift(createArrayOfEmptySpatialGridCells(this.gridSizeY));
      this.gridSizeX++;
      this.xOffset++;
      fromX++;
      toX++;
    }

    while (toX >= this.gridSizeX) {
      this.grid.push(createArrayOfEmptySpatialGridCells(this.gridSizeY));
      this.gridSizeX++;
    }

    if (fromY < 0) {
      const missing = -fromY;

      for (let x = 0; x < this.gridSizeX; x++) {
        this.grid[x].unshift(...createArrayOfEmptySpatialGridCells(missing));
      }

      this.gridSizeY += missing;
      this.yOffset += missing;
      fromY += missing;
      toY += missing;
    }

    if (toY >= this.gridSizeY) {
      const missing = toY - this.gridSizeY + 1;

      for (let x = 0; x < this.gridSizeX; x++) {
        this.grid[x].push(...createArrayOfEmptySpatialGridCells(missing));
      }

      this.gridSizeY += missing;
    }

    for (let x = fromX; x <= toX; x++) {
      const column = this.grid[x];

      for (let y = fromY; y <= toY; y++) {
        const cell = column[y];
        cell.push(object);
      }
    }

    return true;
  }

  remove(object, info) {
    if (!info.currentBounds) return;
    const fromX = this.xOffset + this.worldCoordinateToCellCoordinate(info.currentBounds.left);
    const toX = this.xOffset + this.worldCoordinateToCellCoordinate(info.currentBounds.right);
    const fromY = this.yOffset + this.worldCoordinateToCellCoordinate(info.currentBounds.top);
    const toY = this.yOffset + this.worldCoordinateToCellCoordinate(info.currentBounds.bottom);

    for (let x = fromX; x <= toX; x++) {
      const column = this.grid[x];

      for (let y = fromY; y <= toY; y++) {
        const cell = column[y];

        if (info.wasCountedAsVisibleElementDuringComparing) {
          cell.mapElementSorterVisitedVisibleElements--;
        }

        cell.splice(cell.indexOf(object), 1);
      }
    }

    info.wasCountedAsVisibleElementDuringComparing = false;
  }

  worldCoordinateToCellCoordinate(value) {
    return Math.floor(value / this.cellSize);
  }

}

function createArrayOfEmptySpatialGridCells(length) {
  const array = new Array();

  for (let i = 0; i < length; i++) {
    array.push(new SpatialGridCell());
  }

  return array;
}