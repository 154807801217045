import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2;

import { model, Model, modelAction, prop } from "mobx-keystone";
export let ActionPositionModel = (_dec = model("actions/ActionPositionModel"), _dec(_class = (_class2 = class ActionPositionModel extends Model({
  x: prop(0).withSetter(),
  y: prop(0).withSetter()
}) {
  set(x, y) {
    this.x = x;
    this.y = y;
  }

  move(deltaX, deltaY) {
    this.x += deltaX;
    this.y += deltaY;
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "set", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "set"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "move", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "move"), _class2.prototype)), _class2)) || _class);