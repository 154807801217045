import _defineProperty from "@babel/runtime/helpers/defineProperty";
export class MathE {
  /**
   * Calculates the distance between the assigned coordinates.
   */
  static distance(x1, y1, x2, y2) {
    const y = x2 - x1;
    const x = y2 - y1;
    return Math.sqrt(x * x + y * y);
  }
  /**
   * Calculates the angle (radiant) between two points.
   */


  static angleBetween(x1, y1, x2, y2) {
    return MathE.modulo(Math.atan2(y2 - y1, x2 - x1), MathE.PI_DOUBLE);
  }
  /**
   * Returns true if the assigned midAngleRad is between the startAngleRad and endAngleRad.
   * @param startAngleRad The start.
   * @param endAngleRad The end.
   * @param midAngleRad The value to check.
   */


  static isAngleBetween(startAngleRad, endAngleRad, midAngleRad) {
    const a = endAngleRad - startAngleRad;
    const b = midAngleRad - startAngleRad;
    return (b < 0.0 ? b + MathE.PI_DOUBLE : b) < (a < 0.0 ? a + MathE.PI_DOUBLE : a);
  }
  /**
   * Returns the modulo of the assigned value. (Unlike javascripts % 'remainder operator')
   * @param n The value.
   * @param m The modulo value.
   */


  static modulo(n, m) {
    return (n % m + m) % m;
  }
  /**
   * Returns true if two lines are intersecting.
   */


  static linesIntersect(p0x, p0y, p1x, p1y, p2x, p2y, p3x, p3y) {
    const a1x = p1x - p0x;
    const a1y = p1y - p0y;
    const a2x = p3x - p2x;
    const a2y = p3y - p2y;
    const a = (-a1y * (p0x - p2x) + a1x * (p0y - p2y)) / (-a2x * a1y + a1x * a2y);
    const t = (a2x * (p0y - p2y) - a2y * (p0x - p2x)) / (-a2x * a1y + a1x * a2y);
    return a >= 0 && a <= 1 && t >= 0 && t <= 1;
  }
  /**
   * Returns true if
   * - a and b are both finite numbers and
   * - Math.abs(a - b) <= errorMarginInclusive.
   */


  static equalsApproximately(a, b, errorMarginInclusive) {
    if (!Number.isFinite(a) || !Number.isFinite(b)) return false;
    if (a === b) return true;
    return Math.abs(a - b) <= errorMarginInclusive;
  }
  /**
   * Returns true if the assigned string contains a number.
   * @param value the string to check.
   */


  static containsNumber(value) {
    return !isNaN(Number(value));
  }
  /**
   * Returns the middle point of the assigned line.
   */


  static midpoint(x1, y1, x2, y2) {
    return [(x1 + x2) / 2, (y1 + y2) / 2];
  }

  static inverseLerp(n1, n2, value) {
    return (value - n1) / (n2 - n1);
  }

  static clamp(value, min, max) {
    return Math.max(min, Math.min(max, value));
  }

  static adjustPrecision(value, precision) {
    return Math.round(value * precision) / precision;
  }
  /*
   * Interpolates between the assigned numbers n1 and n2 by the assigned progress (0 - 1).
   */


  static lerp(n1, n2, progress) {
    return n1 * (1 - progress) + n2 * progress;
  }
  /**
   * Limits the assigned value to the assigned minimum and maximum.
   * @param min The minimum to limit.
   * @param max The maximum to limit.
   * @param value The valiue to limit.
   */


  static limit(min, max, value) {
    return Math.max(min, Math.min(max, value));
  }

}

_defineProperty(MathE, "degToRad", 0.01745329252);

_defineProperty(MathE, "radToDeg", 57.2958);

_defineProperty(MathE, "PI_DOUBLE", Math.PI * 2);

_defineProperty(MathE, "PI_HALF", Math.PI * 0.5);