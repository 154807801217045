import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Container } from "pixi.js";
import { CombatPhaseLength } from "../../../../shared/combat/CombatPhaseLength";
import { staticAssetLoader } from "../../loader/StaticAssetLoader";
import { CombatPhase } from "../../../stores/CombatStore";
export class TimeBarView extends Container {
  constructor() {
    super();

    _defineProperty(this, "attackBar", void 0);

    _defineProperty(this, "defenseBar", void 0);

    _defineProperty(this, "longAttackSymbol", void 0);

    _defineProperty(this, "shortAttackSymbol", void 0);

    _defineProperty(this, "longDefenseSymbol", void 0);

    _defineProperty(this, "shortDefenseSymbol", void 0);

    const background = staticAssetLoader.createStaticAssetView("TopBar_Background");
    this.attackBar = staticAssetLoader.createStaticAssetView("TopBar_Bar_Attack");
    this.defenseBar = staticAssetLoader.createStaticAssetView("TopBar_Bar_Defense");
    const border = staticAssetLoader.createStaticAssetView("TopBar");
    this.longAttackSymbol = staticAssetLoader.createStaticAssetView("Phase_LongAttack");
    this.shortAttackSymbol = staticAssetLoader.createStaticAssetView("Phase_ShortAttack");
    this.longDefenseSymbol = staticAssetLoader.createStaticAssetView("Phase_LongDefense");
    this.shortDefenseSymbol = staticAssetLoader.createStaticAssetView("Phase_ShortDefense");
    this.reset(null, null);
    this.addChild(border, background, this.attackBar, this.defenseBar, border, this.longAttackSymbol, this.shortAttackSymbol, this.longDefenseSymbol, this.shortDefenseSymbol);
  }

  reset(phase, phaseLength) {
    this.adjustTexture(this.attackBar, 1);
    this.adjustTexture(this.defenseBar, 1);
    const attackPhase = phase === CombatPhase.Attack || phase === CombatPhase.FirstAttack;
    this.attackBar.visible = attackPhase;
    this.defenseBar.visible = phase === CombatPhase.Defense;
    this.longAttackSymbol.visible = attackPhase && phaseLength === CombatPhaseLength.Long;
    this.shortAttackSymbol.visible = attackPhase && phaseLength === CombatPhaseLength.Short;
    this.longDefenseSymbol.visible = phase === CombatPhase.Defense && phaseLength === CombatPhaseLength.Long;
    this.shortDefenseSymbol.visible = phase === CombatPhase.Defense && phaseLength === CombatPhaseLength.Short;
  }

  reduce(amount) {
    this.adjustTexture(this.attackBar, amount);
    this.adjustTexture(this.defenseBar, amount);
  }

  adjustTexture(sprite, amount) {
    const {
      width
    } = sprite.texture.baseTexture;
    const middle = 88;
    sprite.texture.frame.width = (width - middle) * amount + middle;
    sprite.width = sprite.texture.frame.width;
    sprite.texture.frame.x = (width - middle - (width - middle) * amount) * 0.5;
    sprite.position.x = sprite.texture.frame.x;
    sprite.texture.updateUvs();
  }

}