import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable } from "mobx";
export class CurrentMapUserListStore {
  constructor() {
    _defineProperty(this, "currentMapUserList", void 0);

    _defineProperty(this, "currentMapUserMap", new Map());

    _defineProperty(this, "currentMapId", null);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
  }

  setCurrentMapUserList(mapId, userList) {
    this.currentMapId = mapId;
    this.updateCurrentMapUserList(userList);
  }

  updateCurrentMapUserList(userList) {
    this.currentMapUserList = userList;
    this.currentMapUserMap.clear();

    for (const user of userList) {
      this.currentMapUserMap.set(user.userId, user);
    }
  }

  getUsernameForId(userId) {
    const user = this.currentMapUserMap.get(userId);
    return user ? user.username : "";
  }

  clearCurrentMapUserList() {
    this.setCurrentMapUserList(null, []);
  }

}
export const currentMapUserListStore = new CurrentMapUserListStore();