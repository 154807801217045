var _dec, _class;

import { Model, model, prop } from "mobx-keystone";
import { TranslatedString } from "./TranslatedString";
export let ItemModel = (_dec = model('game/ItemModel'), _dec(_class = class ItemModel extends Model({
  id: prop("").withSetter(),
  name: prop(() => new TranslatedString({})),
  description: prop(() => new TranslatedString({})),
  tags: prop(() => []).withSetter(),
  itemImageId: prop(0).withSetter(),
  moduleOwner: prop("").withSetter()
}) {
  translatableEntityData() {
    return {
      entity: this,
      label: "Item",
      translateableStrings: [{
        label: "Name",
        translatedString: this.name
      }, {
        label: "Description",
        translatedString: this.description
      }]
    };
  }

}) || _class);