import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { editorClient } from "../../../communication/EditorClient";
import { fromSnapshot } from "mobx-keystone";
import { animationEditorStore } from "../../AnimationSelectionStore";
import { sharedStore } from "../../SharedStore";
export function undoableAnimationEditorDeleteAnimation(animationId) {
  executeUndoableOperation(new AnimationEditorDeletionOp(animationId));
}

class AnimationEditorDeletionOp extends UndoableOperation {
  constructor(animationId) {
    super("animationEditorDeleteAnimation");
    this.animationId = animationId;

    _defineProperty(this, "selectedAnimationSate", void 0);

    this.selectedAnimationSate = animationEditorStore.selectedAnimationState;
  }

  async execute() {
    await editorClient.deleteAnimationAsset(this.animationId);
    await animationEditorStore.setSelectedAnimation(null);
    animationEditorStore.setSelectedState(null);
  }

  async reverse() {
    const snapshot = await editorClient.unDeleteAnimationAsset(this.animationId);
    const animationAssetModel = fromSnapshot(snapshot); // will be added by the 'animationAssetUnDeleted' server event for others.

    sharedStore.setAnimation(animationAssetModel);
    await animationEditorStore.setSelectedAnimation(animationAssetModel);
    animationEditorStore.setSelectedState(this.selectedAnimationSate);
  }

}