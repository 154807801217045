import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { gameStore } from "../../GameStore";
export function undoableSetDebugStartMarkerOp(debugStartMarker, mapId) {
  executeUndoableOperation(new ActionEditorDebugStartMarkerChangeOp(debugStartMarker, mapId));
}

class ActionEditorDebugStartMarkerChangeOp extends UndoableOperation {
  constructor(debugStartMarker, mapId) {
    super("changeDebugStartMarker");
    this.debugStartMarker = debugStartMarker;
    this.mapId = mapId;

    _defineProperty(this, "previousDebugStartMarker", void 0);

    _defineProperty(this, "previousMapId", void 0);

    this.previousDebugStartMarker = gameStore.debugStartMarker;
    this.previousMapId = gameStore.debugStartMarkerMapId;
  }

  async execute() {
    gameStore.setDebugStartMarker(this.debugStartMarker, this.mapId);
  }

  async reverse() {
    gameStore.setDebugStartMarker(this.previousDebugStartMarker, this.previousMapId);
  }

}