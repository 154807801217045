import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

///<reference types="webpack-env" />
import { Container, Point, settings } from "pixi.js";
import { combatStore, Enemy } from "../../stores/CombatStore";
import { gameCanvasSize, gameConstants } from "../../data/gameConstants";
import { calcCanvasCenteredPosition, calcDefaultZoom, getEventMouseButton, getInteractionManagerFromApplication, isTouchEvent, MouseButton, tileToWorldPositionX, tileToWorldPositionY } from "../../helper/pixiHelpers";
import { MapZoomController } from "../shared/controller/MapZoomController";
import { AppContext, PixiApp } from "../shared/PixiApp";
import { CombatOverlayView } from "./combat/CombatOverlayView";
import { GameMapView } from "./map/GameMapView";
import { Player } from "./character/Player";
import { Group, Layer } from "@pixi/layers";
import { HealthBarView } from "./combat/HealthBarView";
import { autorun, runInAction } from "mobx";
import { KeyInputController } from "./controller/KeyInputController";
import { ReadonlyMapData } from "../../../shared/game/MapDataModel";
import { LoadedMap } from "../../gameengine/LoadedMap";
import { delay, shuffle, wait } from "../../../shared/helper/generalHelpers";
import { GameNpcView } from "./map/GameNpcView";
import { GameInteractionTriggerIcon } from "./map/GameInteractionTriggerIcon";
import { doesTilePositionEqual } from "../../../shared/definitions/other/TilePosition";
import { ReadonlyPosition } from "../../../shared/game/PositionModel";
import { wrapArraySet, wrapIterator } from "../../../shared/helper/IterableIteratorWrapper";
import { resolvePotentialMapElementTreeParameter } from "../../helper/treeParameterHelpers";
import { gameGetMap } from "../../communication/api";
import { DamageInAreaVisualManager } from "./map/DamageInAreaVisualManager";
import { getCharacterNameForCurrentLanguage } from "../../helper/displayHelpers";
import { LogEntry } from "../../stores/LogEntry";
import { MapStateCamera } from "./camera/MapStateCamera";
import { AnimatedMoveCamera } from "./camera/AnimatedMoveCamera";
import { CutSceneController } from "./controller/CutSceneController";
import { FadeGraphics } from "./camera/FadeGraphics";
import { gameStore, MapLoadingState, TaskMarkerColorPosition } from "../../stores/GameStore";
import { sharedStore } from "../../stores/SharedStore";
import { errorStore } from "../../stores/ErrorStore";
import { SoundActionHelper } from "./controller/SoundActionHelper";
import { notificationController } from "../../components/game/ui components/NotificationController";
import { repeatCallUntilSuccess } from "../../helper/asyncHelpers";
import { addSentryDebugBreadcrumb } from "../../helper/sentryHelpers";
import { soundCache } from "../../stores/SoundCache";
import { UiSounds } from "./sound/UiSounds";
const directionalOffsets = [{
  dx: 1,
  dy: 0
}, {
  dx: -1,
  dy: 0
}, {
  dx: 0,
  dy: 1
}, {
  dx: 0,
  dy: -1
}];

class Game extends PixiApp {
  constructor() {
    super("Game", AppContext.Main, _objectSpread(_objectSpread({}, gameCanvasSize), {}, {
      manualTextureGarbageCollectionMode: true
    }));

    _defineProperty(this, "mapViewContainer", void 0);

    _defineProperty(this, "interactionTriggerOverlay", void 0);

    _defineProperty(this, "characterOverlayContainer", void 0);

    _defineProperty(this, "mapView", void 0);

    _defineProperty(this, "combatView", void 0);

    _defineProperty(this, "overlayUI", void 0);

    _defineProperty(this, "fadeGraphics", new FadeGraphics());

    _defineProperty(this, "emergencyLightGraphics", new FadeGraphics(gameConstants.emergencyLightColor));

    _defineProperty(this, "damageInAreaVisualManager", void 0);

    _defineProperty(this, "player", void 0);

    _defineProperty(this, "playerHealthUI", void 0);

    _defineProperty(this, "gameMapZoomController", void 0);

    _defineProperty(this, "keyInputController", void 0);

    _defineProperty(this, "didPinch", void 0);

    _defineProperty(this, "textGroup", void 0);

    _defineProperty(this, "walkingToInteractionTrigger", void 0);

    _defineProperty(this, "loadedMaps", new Map());

    _defineProperty(this, "mapRelatedReactionDisposers", new Array());

    _defineProperty(this, "previousMovePlayerPromise", Promise.resolve());

    _defineProperty(this, "cutSceneController", void 0);

    const {
      app
    } = this;
    const {
      stage
    } = app;
    this.textGroup = new Group(1, false);
    stage.addChild(new Layer(this.textGroup));
    this.mapViewContainer = new Container();
    stage.addChild(this.mapViewContainer);
    stage.addChild(this.emergencyLightGraphics);
    this.characterOverlayContainer = new Container();
    this.mapViewContainer.addChild(this.characterOverlayContainer);
    this.interactionTriggerOverlay = new Container();
    this.mapViewContainer.addChild(this.interactionTriggerOverlay);
    this.player = new Player(this.characterOverlayContainer);
    this.player.characterMovementControllerEvents.on("enterTile", this.onPlayerEnterTile, this);
    this.player.characterMovementControllerEvents.on("leaveTile", this.onPlayerLeaveTile, this);
    this.player.characterMovementControllerEvents.on("startPath", this.onPlayerStartPath, this);
    this.player.characterMovementControllerEvents.on("enterTriggerObject", this.onPlayerEnterTrigger, this);
    this.player.characterMovementControllerEvents.on("leaveTriggerObject", this.onPlayerLeaveTrigger, this);
    this.player.characterMovementControllerEvents.on("endPath", this.onPlayerEndPath, this);
    this.player.characterMovementControllerEvents.on("pathAnimationUpdate", this.onPlayerMoved, this);
    this.player.characterMovementControllerEvents.on("positionSetDirectly", this.onPlayerMoved, this);
    gameStore.initPlayerCamera(new MapStateCamera(gameStore.mapState));
    this.centerPlayerCameraOverPlayer();
    this.playerHealthUI = new HealthBarView(0.8, true);
    this.playerHealthUI.position.set(16, gameCanvasSize.height - 69);
    stage.addChild(this.playerHealthUI);
    this.keyInputController = new KeyInputController();

    this.keyInputController.onMove = (deltaX, deltaY) => {
      if (!gameStore.gameEngine.playerCanMove()) return;
      const targetPosition = this.mapView.pathfinder.pickTarget(this.player.baseTileX + deltaX, this.player.baseTileY + deltaY);
      this.player.move(targetPosition);
    };

    this.combatView = new CombatOverlayView(this.player);
    this.combatView.visible = false;
    this.app.stage.addChild(this.combatView);
    this.damageInAreaVisualManager = new DamageInAreaVisualManager(visual => this.mapView.addChildToContentContainer(visual), triggerId => this.mapView.mapData.getAllAreaTriggersById(triggerId).map(trigger => trigger.position));
    stage.addChild(this.fadeGraphics);
    this.overlayUI = new Container();
    this.overlayUI.addChild(this.fadeGraphics);
    stage.addChild(this.overlayUI);
    this.cutSceneController = new CutSceneController(gameCanvasSize.width * 0.5, gameCanvasSize.height * 0.4, gameCanvasSize.width * 0.6, gameCanvasSize.height * 0.90, this.overlayUI);
    this.gameMapZoomController = new MapZoomController(gameConstants.map.minZoomGame, gameStore.mapState, () => gameStore.currentCamera === gameStore.playerCamera);
    this.reactionDisposers.push(autorun(this.observeIfFightShouldStart.bind(this)));
    this.reactionDisposers.push(autorun(this.observePlayerHealth.bind(this)));
    this.reactionDisposers.push(autorun(this.playerAnimationRefresher.bind(this)));
    this.reactionDisposers.push(autorun(this.cutSceneController.observeCutSceneAnimation.bind(this.cutSceneController)));
    this.reactionDisposers.push(autorun(this.observeUi.bind(this)));
    const interactionManager = getInteractionManagerFromApplication(this.app);
    interactionManager.on('pointerdown', this.onPointerDown, this);
    interactionManager.on('pointerup', this.onPointerUp, this);
    interactionManager.on('pointermove', this.onPointerMove, this);
    this.app.view.onwheel = this.onWheel.bind(this);
    app.ticker.add(this.update, this);
    this.defaultZoom();
    gameStore.startGame(gameStore.loadStartMapId, this.loadAndStartMap.bind(this), this.movePlayer.bind(this), () => this.player.playSlashEffect(true), this.damageInAreaVisualManager, this.player);
  }

  dispose() {
    runInAction(() => {
      this.disposeMap();
      this.keyInputController.dispose();
      this.app.view.onwheel = undefined;
      this.app.ticker.remove(this.update, this);
      combatStore.clear();
      this.damageInAreaVisualManager.destroy();
      this.mapRelatedReactionDisposers.forEach(disposer => disposer());
      this.mapRelatedReactionDisposers.length = 0;
      gameStore.disposeCurrentData();
      notificationController.disposeCurrentData();
      super.dispose();
    });
  }
  /**
   * Loads the map using the {@link GameClient} and starts it afterwards.
   * @param id The id of the map the player should be moved to.
   * @param targetMapMarkerModelId The $modelId of the target map marker for the player spawn position.
   * @param transitionTime The time for the transition during which transition screen is shown.
   */


  movePlayer(id, targetMapMarkerModelId, transitionTime, teleport, actionModelId) {
    // Execute this call to movePlayerInternal after the previous call to movePlayerInternal finished.
    this.previousMovePlayerPromise = this.previousMovePlayerPromise.then(() => this.movePlayerInternal(id, targetMapMarkerModelId, transitionTime, teleport, actionModelId)).catch(gameStore.addErrorFromErrorObject);
  }

  async movePlayerInternal(id, targetMapMarkerModelId, transitionTimeS, teleport, actionModelId) {
    var _gameStore$gameEngine, _gameStore$gameEngine2;

    if (this.wasDisposed) return; // check if player should be moved to another map. if yes, load that map first.

    if (id && (!this.mapView || id !== ((_gameStore$gameEngine = gameStore.gameEngine) === null || _gameStore$gameEngine === void 0 ? void 0 : (_gameStore$gameEngine2 = _gameStore$gameEngine.gameState) === null || _gameStore$gameEngine2 === void 0 ? void 0 : _gameStore$gameEngine2.currentMap))) {
      const startedLoadingAt = Date.now();
      await this.loadAndStartMap(id, targetMapMarkerModelId);
      if (this.wasDisposed) return;
      const elapsedTimeWhileLoadingS = (Date.now() - startedLoadingAt) / 1000;
      transitionTimeS -= elapsedTimeWhileLoadingS;
      gameStore.gameEngine.gameState.setCurrentMap(id);
    } // we stay on the current map and do not need to load another one


    gameStore.gameEngine.gameState.waitingForCharacterToReachTileActions.add(actionModelId);
    const targetMapMarker = targetMapMarkerModelId ? this.mapView.mapData.findMapMarkerByModelId(targetMapMarkerModelId) : null;
    const targetPosition = targetMapMarker === null || targetMapMarker === void 0 ? void 0 : targetMapMarker.position;

    if (teleport) {
      // teleport with a black screen (will show transition picture in the future)
      this.mapView.visible = false;

      if (transitionTimeS > 0) {
        await delay(transitionTimeS * 1000);
        if (this.wasDisposed) return;
      }

      gameStore.gameEngine.clearPlayerIsInsideTriggersList();

      if (targetPosition) {
        this.player.spawnAt(targetPosition);
        this.mapView.visible = true;
        this.informGameEngine(this.player);
      } else {
        this.player.resetToSpawnPosition();
        this.mapView.visible = true;
        this.endMovePlayerWithoutTargetPosition(id, targetMapMarkerModelId, actionModelId);
      }
    } else if (targetPosition) {
      if (doesTilePositionEqual(this.player.baseTilePosition, targetPosition)) {
        // player already at the target position
        this.informGameEngine(this.player);
      } else {
        // let the player actually move
        const playerIsMoving = this.player.move(targetPosition, true); // the transition time should maybe control the player speed

        if (!playerIsMoving) {
          // Target not reachable - pretend that the player reached is to unblock the game
          const markersAtTargetPosition = this.mapView.mapData.getMapMarkersAtPositionXYPlane(targetPosition.x, targetPosition.y, targetPosition.plane);
          gameStore.gameEngine.progressWhenCharacterReachedTile("Player", markersAtTargetPosition);
        }
      }
    } else {
      this.endMovePlayerWithoutTargetPosition(id, targetMapMarkerModelId, actionModelId);
    }
  }

  endMovePlayerWithoutTargetPosition(mapId, targetMapMarkerModelId, actionModelId) {
    if (targetMapMarkerModelId) {
      console.warn(`movePlayer didn't find the target map marker with the id '${targetMapMarkerModelId}' on map #${mapId}.`);
    } else {
      console.warn(`movePlayer to map #${mapId} had no target map marker. That's not valid and should be fixed.`);
    } // If we don't have a target position, we assume we have already arrived.


    gameStore.gameEngine.gameState.waitingForCharacterToReachTileActions.delete(actionModelId);
    const action = gameStore.gameEngine.getCachedActionNode(actionModelId);
    gameStore.gameEngine.executeActions(action.nextActions, action);
  }
  /**
   * Loads the map using the {@link GameClient} and starts it afterwards.
   * @param id The id of the map to load.
   * @param targetMapMarkerModelId The $modelId of the target map marker for the player spawn position.
   */


  async loadAndStartMap(id, targetMapMarkerModelId, startPositionOverride) {
    addSentryDebugBreadcrumb(`loadAndStartMap(${id})`);

    try {
      var _this$mapView;

      if (!id) return;
      if ((_this$mapView = this.mapView) !== null && _this$mapView !== void 0 && _this$mapView.mapData) this.loadedMaps.set(gameStore.gameEngine.gameState.currentMap, this.mapView.mapData);
      this.walkingToInteractionTrigger = null;
      let mapData = this.loadedMaps.get(id);
      gameStore.setMapLoadingState(mapData ? MapLoadingState.LoadingCachedMap : MapLoadingState.LoadingMapFromServer);
      this.disposeMap();

      if (!mapData) {
        const serverMapData = await repeatCallUntilSuccess(() => gameGetMap(id), () => this.wasDisposed, errorStore.addErrorFromErrorObject);
        if (this.wasDisposed) return;
        mapData = new ReadonlyMapData(serverMapData, sharedStore.getTileAsset);
      } else {
        // NOTE(Lena): Needed so that changing to map from loadedMaps gives other objects a chance to update correctly
        await wait(0);
        if (this.wasDisposed) return;
      }

      await this.startMap(mapData, targetMapMarkerModelId, startPositionOverride);
      if (this.wasDisposed) return;
      gameStore.setMapLoadingState(MapLoadingState.NotLoading);
      this.mapView.visible = true;
      this.overlayUI.visible = true;
      this.informGameEngine(this.player);
    } catch (e) {
      addSentryDebugBreadcrumb(`error during loadAndStartMap(${id})`); // Should be fed back to the user if we have a solution for it.

      console.log("Error while loading map with the id " + id, e);
      throw e;
    }

    addSentryDebugBreadcrumb(`finished loadAndStartMap(${id})`);
  }
  /**
   * Starts a map. Disposes old map data. Tries to find a start {@link PositionModel}
   * for the player.
   * @param map The map to start.
   * @param mapMarkerModelId The modelId of a map marker the player should start at, or null.
   */


  async startMap(map, mapMarkerModelId, startPositionOverride) {
    this.triggerManualTextureGarbageCollection();
    this.mapView = new GameMapView(this.appReference, map, this.characterOverlayContainer, this.interactionTriggerOverlay, () => this.wasDisposed);
    this.mapView.visible = false;
    this.overlayUI.visible = false;
    this.mapViewContainer.addChildAt(this.mapView, 0);
    this.player.onMapLoaded(this.mapView);
    this.mapView.addChildToContentContainer(this.player);
    gameStore.gameEngine.onMapWasLoaded();
    this.combatView.map = this.mapView;
    const startPosition = startPositionOverride ? startPositionOverride : map.findStartPosition(mapMarkerModelId, gameConstants.mapStartMarker);
    this.player.spawnAt(startPosition); // TODO tw: Trigger area triggers on map load. It would be better to do this in this.player.spawnAt (so it'll also happen on e.g. teleport)

    const triggers = this.mapView.mapData.getAllAreaTriggersAtPositionXYPlane(this.player.baseTileX, this.player.baseTileY, this.player.baseTilePlane);
    triggers.map(trigger => gameStore.gameEngine.handleAreaTrigger(trigger.id, true));
    gameStore.gameEngine.loadedMap = new LoadedMap(this.mapView.mapData, this.mapView.npcViewsArray, this.mapView.mapData.mapMarkers, this.mapView.animationElementViewsArray, this.mapView.interactionTriggersArray, this.mapView.mapData.areaTriggers);

    for (const npc of gameStore.gameEngine.loadedMap.npcs) {
      npc.onMapLoaded(this.mapView);
      npc.characterMovementControllerEvents.on("endPath", this.onNpcEndPath, this);
      npc.characterMovementControllerEvents.on("pathAnimationUpdate", this.onNpcMoved, this);
      npc.characterMovementControllerEvents.on("positionSetDirectly", this.onNpcMoved, this);
      npc.viewAreaControllerEvents.on("viewAreaTriggerEnter", this.onViewAreaTriggerEnter, this);
      npc.viewAreaControllerEvents.on("viewAreaTriggerLeave", this.onViewAreaTriggerLeave, this);

      if (gameStore.gameEngine.gameState.defeatedEnemies.has(npc.$modelId)) {
        npc.hide();
      }
    }

    const {
      backgroundSoundFilePath
    } = map.properties;

    if (backgroundSoundFilePath) {
      await SoundActionHelper.startSoundSource(backgroundSoundFilePath);
      if (this.wasDisposed) return;
    }

    const animationElementsLoading = gameStore.gameEngine.loadedMap.animationElements.map(ae => ae.loadingPromise);
    await Promise.all(animationElementsLoading);
    if (this.wasDisposed) return;
    const npcsLoading = gameStore.gameEngine.loadedMap.npcs.map(npc => npc.loadingPromise);
    await Promise.all(npcsLoading);
    if (this.wasDisposed) return;

    if (this.mapRelatedReactionDisposers.length > 0) {
      console.error("this.mapRelatedReactionDisposers.length should be 0");
    }

    this.mapRelatedReactionDisposers.push(autorun(this.taskMarkerRefresher.bind(this)));
    gameStore.addLog(LogEntry.byLoadedMap(map));
  }

  disposeMap() {
    var _this$player$parent;

    if (!this.mapView) return;
    SoundActionHelper.endAllActiveSounds();
    gameStore.gameEngine.clearPlayerIsInsideTriggersList();
    this.damageInAreaVisualManager.finishAll();
    this.mapViewContainer.removeChild(this.mapView);
    this.player.onMapUnloaded();

    if (gameStore.gameEngine.loadedMap) {
      for (const npc of gameStore.gameEngine.loadedMap.npcs) {
        npc.onMapUnloaded();
      }
    } // Detach the player from the mapView so it doesn't get destroyed


    (_this$player$parent = this.player.parent) === null || _this$player$parent === void 0 ? void 0 : _this$player$parent.removeChild(this.player);
    this.player.detachFromSpatialGrid();
    this.player.stop(false); // Destroy the mapView and all its references and contents

    this.mapView.destroy({
      children: true
    });
    this.mapRelatedReactionDisposers.forEach(disposer => disposer());
    this.mapRelatedReactionDisposers.length = 0;
    this.mapView = null;
  }

  defaultZoom() {
    gameStore.currentCamera.setX(this.mapViewContainer.position.x);
    gameStore.currentCamera.setY(this.mapViewContainer.position.y);
    gameStore.currentCamera.setZoom(calcDefaultZoom());
  }

  update(deltaTimeTicks) {
    if (!this.mapView || gameStore.isLoadingMap) return;
    const deltaTimeS = deltaTimeTicks / settings.TARGET_FPMS / 1000;
    if (this.keyInputController) this.keyInputController.moveByKey();

    if (combatStore.active) {
      combatStore.reduceTimer(deltaTimeTicks);
    }

    for (const npc of gameStore.gameEngine.loadedMap.npcs) {
      npc.onTick(deltaTimeTicks);
    }

    this.player.onTick(deltaTimeTicks);
    gameStore.currentCamera.onTick();
    this.updateCamera();
    gameStore.gameEngine.update(deltaTimeS);
    this.damageInAreaVisualManager.update(deltaTimeS);
  }

  updateCamera() {
    gameStore.currentCamera.applyTo(this.mapViewContainer);
    gameStore.updateFromCurrentCamera();
  }

  playerAnimationRefresher() {
    this.player.applyConfiguration(gameStore.character).catch(errorStore.addErrorFromErrorObject);
  }

  observeUi() {
    if (this.playerHealthUI) {
      var _gameStore$gameEngine3;

      this.playerHealthUI.visible = (_gameStore$gameEngine3 = gameStore.gameEngine) === null || _gameStore$gameEngine3 === void 0 ? void 0 : _gameStore$gameEngine3.gameState.actionPropertyUIVisible;
    }

    if (this.fadeGraphics) {
      var _gameStore$gameEngine4;

      this.fadeGraphics.setOpacity((_gameStore$gameEngine4 = gameStore.gameEngine) === null || _gameStore$gameEngine4 === void 0 ? void 0 : _gameStore$gameEngine4.gameState.actionPropertyOverlayOpacity);
    }

    if (this.emergencyLightGraphics) {
      var _gameStore$gameEngine5;

      this.emergencyLightGraphics.setOpacity((_gameStore$gameEngine5 = gameStore.gameEngine) === null || _gameStore$gameEngine5 === void 0 ? void 0 : _gameStore$gameEngine5.gameState.actionPropertyEmergencyLightOverlayOpacity);
    }
  }

  centerPlayerCameraOverPlayer() {
    const cameraPosition = calcCanvasCenteredPosition(this.player.position, gameStore.currentCamera.getZoom());
    gameStore.playerCamera.setPosition(cameraPosition);

    if (gameStore.currentCamera === gameStore.playerCamera) {
      this.updateCamera();
    }
  }

  fireTriggersOrMovePlayer(e) {
    const {
      target,
      data
    } = e;
    const screenPosition = data.global;
    if (!gameStore.gameEngine.playerCanMove() || !this.mapView) return;
    const {
      x,
      y
    } = this.mapView.getTilePosition(screenPosition);
    const highestTilePlane = this.mapView.mapData.getHighestMainGroundTilePlaneForPosition(x, y);
    const tileExists = highestTilePlane !== undefined;
    if (this.handleClickedInteractionTrigger(target, tileExists, x, y, highestTilePlane)) return; // We are not using an interaction trigger. Pick the actual target. This might be a virtual transition (like on stairs).

    const targetPosition = this.mapView.pathfinder.pickTarget(x, y);

    if (this.player.move(targetPosition, false, false, this.mapView.toLocal(screenPosition))) {
      soundCache.playOneOf(UiSounds.SET_WAYPOINT);
    }
  }

  handleClickedInteractionTrigger(target, validTileWasClicked, clickedTileX, clickedTileY, clickedTilePlane) {
    if (!(target instanceof GameInteractionTriggerIcon)) return false;
    const {
      interactionTrigger
    } = target;
    const interactionTriggerTilePosition = interactionTrigger.tilePosition;
    /*
    // Ignore the interaction trigger if the clicked tile exists and is in front of the interactionTrigger
    if (validTileWasClicked && (
        (clickedTilePlane > interactionTriggerTilePosition.plane) ||
        (clickedTileX > interactionTriggerTilePosition.x) ||
        (clickedTileY > interactionTriggerTilePosition.y)))
        return false;
    */
    // Handle interaction if player is on interaction trigger tile already

    if (this.handleInteractionTriggerIfPlayerIsOnTile(interactionTrigger, 0)) return true; // Try to walk directly on the interaction trigger tile position

    if (this.player.move(interactionTriggerTilePosition, false)) {
      soundCache.playOneOf(UiSounds.SET_WAYPOINT);
      this.walkingToInteractionTrigger = interactionTrigger;
      return true;
    } // Can't walk directly onto tile position? Handle interaction if player next to the interaction trigger tile already.


    if (this.handleInteractionTriggerIfPlayerIsOnTile(interactionTrigger, 1)) return true; // Try to walk to the closest spot next to the target

    const closestPositionNextToTarget = this.findClosestReachableTargetNextToInteractionTrigger(interactionTriggerTilePosition);

    if (closestPositionNextToTarget && this.player.move(closestPositionNextToTarget, false)) {
      soundCache.playOneOf(UiSounds.SET_WAYPOINT);
      this.walkingToInteractionTrigger = interactionTrigger;
      return true;
    }

    return false;
  }
  /**
   * Finds the closest tile in the 4 spaces directly next to interactionTriggerPosition that is reachable
   * via pathfinding from the player's current position.
   */


  findClosestReachableTargetNextToInteractionTrigger(interactionTriggerPosition) {
    const {
      x,
      y,
      plane
    } = interactionTriggerPosition;
    let closestPosition;
    let distanceToClosestPosition;
    const playerBasePosition = this.player.copyBasePosition();

    for (const {
      dx,
      dy
    } of directionalOffsets) {
      const targetPositionWithOffset = new ReadonlyPosition({
        x: x + dx,
        y: y + dy,
        plane
      });
      const path = this.mapView.pathfinder.findPath(playerBasePosition, targetPositionWithOffset, false);

      if (path) {
        if (!closestPosition || distanceToClosestPosition > path.length) {
          closestPosition = targetPositionWithOffset;
          distanceToClosestPosition = path.length;
        }
      }
    }

    return closestPosition;
  }

  taskMarkerRefresher() {
    if (!gameStore.gameEngine) return;
    const activeLocationTaskMarkers = this.mapView.getTaskMarkerOnCurrentMap();
    runInAction(() => {
      const count = activeLocationTaskMarkers.length;

      while (gameStore.taskMarkers.length < count) {
        gameStore.taskMarkers.push(new TaskMarkerColorPosition());
      }

      if (gameStore.taskMarkers.length > count) {
        gameStore.taskMarkers.splice(count, gameStore.taskMarkers.length - count);
      }

      let i = 0;

      for (const taskMarkerInformation of activeLocationTaskMarkers) {
        const taskMarker = gameStore.taskMarkers[i++];
        const {
          x,
          y
        } = taskMarkerInformation.taskMarker.position;
        taskMarker.x = tileToWorldPositionX(x, y, true);
        taskMarker.y = tileToWorldPositionY(x, y, true);
        taskMarker.color = "#" + taskMarkerInformation.color.toString(16).padStart(6, "0");
      }
    });
  }

  onPointerDown(e) {
    this.gameMapZoomController.reset();
    this.didPinch = false;
    if (getEventMouseButton(e) !== MouseButton.LeftOrTouch || e.stopped) return;

    if (document.activeElement && document.activeElement.blur) {
      document.activeElement.blur();
    }

    e.data.originalEvent.preventDefault();
    e.stopPropagation();

    if (combatStore.active) {
      combatStore.gestureStart();
      const p = this.combatView.patternView.toLocal(e.data.global);
      combatStore.gestureInput(p);
      this.combatView.patternView.startTouchTrail(p);
      return;
    }

    if (!isTouchEvent(e)) {
      this.fireTriggersOrMovePlayer(e);
    }
  }

  onPointerUp(e) {
    if (combatStore.active) {
      combatStore.gestureEnd();
      this.combatView.patternView.endTouchTrail();
    } else {
      if (isTouchEvent(e) && !this.didPinch) {
        this.fireTriggersOrMovePlayer(e);
      }
    }
  }

  onPointerMove(e) {
    if (combatStore.active) {
      const p = this.combatView.patternView.toLocal(e.data.global);
      combatStore.gestureInput(p);
      this.combatView.patternView.extendTouchTrail(p);
      return;
    }

    if (this.gameMapZoomController.pinch(e, null)) {
      this.didPinch = true;
    }
  }

  onWheel(e) {
    e.preventDefault();
    e.stopPropagation();
    if (combatStore.active) return;
    this.gameMapZoomController.wheelZoom(e, null);
  }

  onNpcEndPath(character) {
    // Inform the game engine that the NPC has ended its path on this tile
    this.informGameEngine(character);

    if (character.baseTileX == this.player.baseTileX && character.baseTileY == this.player.baseTileY) {
      if (!this.player.pushAway()) {
        gameStore.gameEngine.gameState.setPlayerHealth(0);
        this.observePlayerHealth();
      }
    }
  }
  /**
   * Gets called if the assigned {@link Character} enters a tile position.
   * @param character The character that enters the tile position.
   * @param tileX The tile x position.
   * @param tileY The tile y position.
   * @param tilePlane The tile plane.
   * @param isBaseTile True if it is the 'base tile' of the character.
   */


  onPlayerEnterTile(character, tileX, tileY, tilePlane, isBaseTile) {
    //console.log("[EVENT] On enter " + (isBaseTile ? "BASE" : "SECONDARY") + " tile.", "x: " + tileX, "y: " + tileY, "plane: " + tilePlane);
    this.informGameEngine(this.player);
  }
  /**
   * Gets called if the assigned {@link Character} leaves a tile position.
   * @param character The character that enters the tile position.
   * @param tileX The tile x position.
   * @param tileY The tile y position.
   * @param tilePlane The tile plane.
   * @param isBaseTile True if it is the 'base tile' of the character.
   */


  onPlayerLeaveTile(character, tileX, tileY, tilePlane, isBaseTile) {//console.log("[EVENT] On leave " + (isBaseTile ? "BASE" : "SECONDARY") + " tile.", "x: " + tileX, "y: " + tileY, "plane: " + tilePlane);
  }

  onPlayerStartPath() {
    this.walkingToInteractionTrigger = null;
  }

  onPlayerEnterTrigger(character, trigger) {
    const stopPlayerPath = gameStore.gameEngine.handleAreaTrigger(trigger.id, true);

    if (stopPlayerPath) {
      this.player.move(trigger.position);
    }
  }

  onPlayerLeaveTrigger(character, trigger) {
    gameStore.gameEngine.handleAreaTrigger(trigger.id, false);
  }

  onPlayerEndPath() {
    if (this.walkingToInteractionTrigger) {
      this.handleInteractionTriggerIfPlayerIsOnTile(this.walkingToInteractionTrigger, 1);
      this.walkingToInteractionTrigger = null;
    }
  }

  onPlayerMoved() {
    // If the player has left the map during moving, jump out here
    if (!this.mapView) return;
    this.centerPlayerCameraOverPlayer();
    SoundActionHelper.handlePlayerMovement(this.player);
    this.mapView.npcViewsArray.forEach(npc => {
      npc.checkViewIntersections(this.player.x, this.player.y);
    });
  }

  onNpcMoved(char) {
    const npc = char;

    if (npc) {
      npc.checkViewIntersections(this.player.x, this.player.y);
    }
  }

  onViewAreaTriggerEnter(triggerName) {
    gameStore.gameEngine.handleAreaTrigger(triggerName, true);
  }

  onViewAreaTriggerLeave(triggerName) {
    gameStore.gameEngine.handleAreaTrigger(triggerName, false);
  }

  handleInteractionTriggerIfPlayerIsOnTile(interactionTrigger, maxManhattanDistance) {
    const playerPosition = this.player.baseTilePosition;
    const interactionTriggerPosition = interactionTrigger.tilePosition;
    if (playerPosition.plane !== interactionTriggerPosition.plane) return false;
    const manhattanDistance = Math.abs(playerPosition.x - interactionTriggerPosition.x) + Math.abs(playerPosition.y - interactionTriggerPosition.y);
    if (manhattanDistance > maxManhattanDistance) return false;
    gameStore.gameEngine.handleInteractionTrigger(interactionTrigger.$modelId);
    return true;
  }

  informGameEngine(character) {
    const markersAtPosition = this.mapView.mapData.getMapMarkersAtPositionXYPlane(character.baseTileX, character.baseTileY, character.baseTilePlane);

    if (markersAtPosition) {
      gameStore.gameEngine.progressWhenCharacterReachedTile(character instanceof GameNpcView ? character.$modelId : "Player", markersAtPosition);
    }
  }

  observeIfFightShouldStart() {
    var _gameStore$gameEngine6;

    const currentCombat = (_gameStore$gameEngine6 = gameStore.gameEngine) === null || _gameStore$gameEngine6 === void 0 ? void 0 : _gameStore$gameEngine6.currentCombat();
    if (!currentCombat) return;
    runInAction(() => {
      this.startArenaFight();
    });
  }

  observePlayerHealth() {
    if (!gameStore.gameEngine || !combatStore.hasConfig) return;
    const {
      playerHealth
    } = gameStore.gameEngine.gameState;

    if (playerHealth === 0) {
      this.resetPlayerAfterKnockOut();
    }

    this.playerHealthUI.setHealth(gameStore.gameEngine.gameState.playerHealth / combatStore.config.playerHealth);
  }

  startArenaFight() {
    const {
      npcs
    } = gameStore.gameEngine.loadedMap;
    const {
      defeatedEnemies
    } = gameStore.gameEngine.gameState;
    const combat = gameStore.gameEngine.currentCombat();
    const uniqueEnemyModelIds = new Set(combat.enemies.map(enemyOnMapRef => resolvePotentialMapElementTreeParameter(enemyOnMapRef, "actions/EnemyOnMapValueModel", combat)).filter(enemyOnMapRef => !!enemyOnMapRef && // resolved as a reference
    npcs.some(npcView => npcView.$modelId === enemyOnMapRef.elementId) && // only enemies that actually exist on map
    !wrapArraySet(defeatedEnemies).some(defeatedEnemyId => defeatedEnemyId === enemyOnMapRef.elementId) // only enemies that are not yet defeated
    ).map(enemyOnMapRef => enemyOnMapRef.elementId));
    const enemyData = shuffle(wrapIterator(uniqueEnemyModelIds.values()).map(enemyModelId => {
      const enemyOnMap = this.mapView.mapData.npcs.find(npc => npc.$modelId === enemyModelId);
      const enemyCharacter = sharedStore.characterConfigurations.get(enemyOnMap.characterId);

      if (!enemyCharacter) {
        console.warn("Enemy character not found: " + getCharacterNameForCurrentLanguage(enemyOnMap.characterId));
        return null;
      }

      if (!enemyCharacter.isEnemy) {
        console.warn("Enemy character is not marked as 'isEnemy': " + getCharacterNameForCurrentLanguage(enemyOnMap.characterId));
        return null;
      }

      if (!combatStore.config.enemyCombatPresets.find(preset => preset.$modelId === enemyCharacter.enemyCombatPresetModelId)) {
        console.warn("Enemy character has no enemy combat preset: " + getCharacterNameForCurrentLanguage(enemyOnMap.characterId));
        return null;
      }

      return new Enemy(enemyModelId, enemyOnMap.position.x, enemyOnMap.position.y, enemyCharacter.enemyHealth, enemyCharacter.enemyCombatPresetModelId, enemyCharacter.enemyDamage);
    }).filter(enemy => !!enemy) // only return not-null enemies
    );

    if (enemyData.length === 0) {
      gameStore.gameEngine.progressAfterCombat();
    } else {
      const enemyViews = enemyData.map(enemyData => npcs.find(npcView => npcView.$modelId === enemyData.elementId));
      const {
        minPosition,
        maxPosition
      } = this.player.calculateCurrentAnimationExtents(false);

      for (const enemyView of enemyViews) {
        const enemyExtents = enemyView.calculateCurrentAnimationExtents(true);
        minPosition.x = Math.min(minPosition.x, enemyExtents.minPosition.x);
        minPosition.y = Math.min(minPosition.y, enemyExtents.minPosition.y);
        maxPosition.x = Math.max(maxPosition.x, enemyExtents.maxPosition.x);
        maxPosition.y = Math.max(maxPosition.y, enemyExtents.maxPosition.y);
      }

      const uiBorderTop = 100;
      const uiBorderBottom = 150;
      const borderSide = 75;
      const width = maxPosition.x - minPosition.x;
      const height = maxPosition.y - minPosition.y;
      const exactZoom = Math.min((gameCanvasSize.width - borderSide * 2) / width, (gameCanvasSize.height - uiBorderTop - uiBorderBottom) / height);
      const zoom = Math.min(exactZoom, gameConstants.map.maxZoom);
      const centerOffsetY = (uiBorderTop - uiBorderBottom) / 2 / zoom;
      const center = calcCanvasCenteredPosition(new Point((minPosition.x + maxPosition.x) / 2, (minPosition.y + maxPosition.y) / 2 - centerOffsetY), zoom);
      const interpolationCamera = new AnimatedMoveCamera(gameStore.currentCamera.getX(), gameStore.currentCamera.getY(), gameStore.currentCamera.getZoom());
      interpolationCamera.setAnimationTarget(center.x, center.y, zoom, gameConstants.arenaFightZoomAnimationDurationMillis);
      interpolationCamera.startAnimation();
      gameStore.setCurrentCamera(interpolationCamera);
      this.combatView.addEnemies(enemyViews);
      this.combatView.activate();
      combatStore.start(enemyData, this.finishArenaFight.bind(this));
    }
  }

  finishArenaFight() {
    if (gameStore.gameEngine.progressAfterCombat()) {
      this.combatView.deactivate();
      const animationCamera = new AnimatedMoveCamera(gameStore.currentCamera.getX(), gameStore.currentCamera.getY(), gameStore.currentCamera.getZoom());
      const target = calcCanvasCenteredPosition(this.player.position, gameStore.playerCamera.getZoom());
      animationCamera.setAnimationTarget(target.x, target.y, gameStore.playerCamera.getZoom(), gameConstants.arenaFightZoomAnimationDurationMillis);
      animationCamera.startAnimation(() => {
        gameStore.enablePlayerCamera();
        this.centerPlayerCameraOverPlayer();
      });
      gameStore.setCurrentCamera(animationCamera);
    }
  }

  resetPlayerAfterKnockOut() {
    if (!this.mapView) return;
    this.mapView.visible = false;
    delay(1000).then(() => {
      if (this.wasDisposed) return;
      gameStore.gameEngine.clearPlayerIsInsideTriggersList();
      gameStore.gameEngine.gameState.resetPlayerHealth();
      this.player.resetToSpawnPosition();
      this.mapView.visible = true;
    }).catch(gameStore.addErrorFromErrorObject);
  }

}

let game;
export function createGame() {
  if (game) return;
  game = new Game();
}
export function getGame() {
  return game;
}
export function disposeGame() {
  if (game) {
    game.dispose();
    game = null;
  }
}

if (module.hot) {
  const {
    data
  } = module.hot;

  if (data && data.parent) {
    createGame();
    game.attach(data.parent);
  }

  module.hot.dispose(data => {
    if (game) {
      data.parent = game.parentElement;
      disposeGame();
    }
  });
}