import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Graphics } from "pixi.js";
/**
 * This class can be used as an animated {@link Graphics} to mark a path target.
 * Call the {@see update} method for every animation frame.
 */

export class PathTargetGraphic extends Graphics {
  /**
   * Creates a new instance.
   */
  constructor() {
    super();

    _defineProperty(this, "color1", 0xA18D41);

    _defineProperty(this, "color2", 0xA1AD41);

    _defineProperty(this, "hidden", true);

    _defineProperty(this, "worldX", void 0);

    _defineProperty(this, "worldY", void 0);

    _defineProperty(this, "currentRadius", PathTargetGraphic.InitialRadius);
  }
  /**
   * Sets the position of this marker and shows it.
   * @param worldX The x position.
   * @param worldY The y position.
   */


  setPosition(worldX, worldY) {
    this.worldX = worldX;
    this.worldY = worldY;
    this.hidden = false;
    this.currentRadius = PathTargetGraphic.InitialRadius;
  }
  /**
   * Updates the marker animation.
   * Needs to be called every animation frame.
   */


  update() {
    if (this.hidden) return;
    this.clear();

    if (this.currentRadius > PathTargetGraphic.LoopRadius) {
      // fade in animation
      this.currentRadius = Math.max(this.currentRadius - 5, PathTargetGraphic.LoopRadius);
    }

    this.lineStyle(6, this.color1);
    this.drawCircle(this.worldX, this.worldY, this.currentRadius + 7 * Math.sin(Date.now() / 150));
    this.lineStyle(3, this.color2);
    this.drawCircle(this.worldX, this.worldY, this.currentRadius - 15 + 6 * Math.cos(Date.now() / 150));
  }
  /**
   * Hides this marker.
   */


  hide() {
    this.clear();
    this.hidden = true;
  }

}

_defineProperty(PathTargetGraphic, "InitialRadius", 80);

_defineProperty(PathTargetGraphic, "LoopRadius", 25);