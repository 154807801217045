import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _dec, _class;

import { Model, model, prop } from "mobx-keystone";
import { EditorComplexity } from "../definitions/other/EditorComplexity";
export let ModuleModel = (_dec = model("workshop/ModuleModel"), _dec(_class = class ModuleModel extends Model({
  accesscode: prop().withSetter(),
  workshopId: prop(),
  actiontreeId: prop(),
  name: prop("").withSetter(),
  description: prop("").withSetter(),
  editorComplexity: prop(EditorComplexity.Workshop1).withSetter(),
  startDate: prop(0).withSetter(),
  endDate: prop(0).withSetter(),
  startAtAct: prop(1).withSetter(),
  isStandalone: prop(false).withSetter(),
  standaloneMapId: prop(0).withSetter(),
  standalonePlayCode: prop("").withSetter(),
  highlighted: prop(false).withSetter(),
  readyToPlay: prop(false).withSetter(),
  visibleInPublicMenu: prop(false).withSetter(),
  tags: prop(() => []).withSetter()
}) {}) || _class);
export class ReadonlyEditorModule {
  constructor(module, workshopName) {
    _defineProperty(this, "id", void 0);

    _defineProperty(this, "actiontreeId", void 0);

    _defineProperty(this, "editorComplexity", void 0);

    _defineProperty(this, "moduleName", void 0);

    _defineProperty(this, "workshopName", void 0);

    this.id = module.$modelId;
    this.actiontreeId = module.actiontreeId;
    this.editorComplexity = module.editorComplexity;
    this.moduleName = module.name;
    this.workshopName = workshopName;
  }

}