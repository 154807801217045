import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { AnimatedSprite, Container, Texture } from "pixi.js";
import { TileImageUsage } from "../../../../shared/resources/TileAssetModel";
import { TileHighlight } from "./TileHighlight";
import { UiConstants } from "../../../data/UiConstants";
import { animationLoader } from "../../../helper/AnimationLoader";
import { gameConstants } from "../../../data/gameConstants";
import { AreaTriggerVisual } from "./AreaTriggerVisual";
import { MapMarkerVisual } from "./MapMarkerVisual";
import { DebugStartMarkerVisual } from "./DebugStartMarkerVisual";
import { sharedStore } from "../../../stores/SharedStore";
import { tileImageStore } from "../../../stores/TileImageStore";
import { errorStore } from "../../../stores/ErrorStore";
import { EditorOnlyElementViewText } from "./EditorOnlyElementViewText";
import { adjustMapMarkerViewText } from "./MapMarkerView";
import { applyIdleAnimation } from "../../game/character/characterAnimationHelper";
export let TemporaryObjectViewMode;

(function (TemporaryObjectViewMode) {
  TemporaryObjectViewMode[TemporaryObjectViewMode["FullyVisible"] = 0] = "FullyVisible";
  TemporaryObjectViewMode[TemporaryObjectViewMode["TargetValid"] = 1] = "TargetValid";
  TemporaryObjectViewMode[TemporaryObjectViewMode["TargetInvalid"] = 2] = "TargetInvalid";
})(TemporaryObjectViewMode || (TemporaryObjectViewMode = {}));

export class TemporaryObjectView extends Container {
  constructor(mode) {
    super();

    _defineProperty(this, "_mode", void 0);

    _defineProperty(this, "highlightNormal", void 0);

    _defineProperty(this, "highlightInvalid", void 0);

    _defineProperty(this, "tileSpriteBg", void 0);

    _defineProperty(this, "tileSpriteFg", void 0);

    _defineProperty(this, "areaTriggerGroup", void 0);

    _defineProperty(this, "areaTriggerText", void 0);

    _defineProperty(this, "mapMarkerGroup", void 0);

    _defineProperty(this, "mapMarkerText", void 0);

    _defineProperty(this, "debugStartMarkerVisual", void 0);

    _defineProperty(this, "spine", void 0);

    _defineProperty(this, "currentLoadingAttempt", 0);

    this._mode = mode;
    this.tileSpriteBg = new AnimatedSprite([Texture.EMPTY], false);
    this.tileSpriteFg = new AnimatedSprite([Texture.EMPTY], false);
    this.addChild(this.tileSpriteBg, this.tileSpriteFg);
    this.areaTriggerGroup = new Container();
    this.areaTriggerGroup.addChild(new AreaTriggerVisual());
    this.areaTriggerText = new EditorOnlyElementViewText(null);
    this.areaTriggerGroup.addChild(this.areaTriggerText);
    this.addChild(this.areaTriggerGroup);
    this.mapMarkerGroup = new Container();
    this.mapMarkerGroup.addChild(new MapMarkerVisual());
    this.mapMarkerText = new EditorOnlyElementViewText(null);
    adjustMapMarkerViewText(this.mapMarkerText);
    this.mapMarkerGroup.addChild(this.mapMarkerText);
    this.addChild(this.mapMarkerGroup);
    this.debugStartMarkerVisual = new DebugStartMarkerVisual();
    this.addChild(this.debugStartMarkerVisual);
    this.highlightNormal = new TileHighlight(4, UiConstants.COLOR_SELECTION_HIGHLIGHT_0x);
    this.addChild(this.highlightNormal);
    this.highlightInvalid = new TileHighlight(4, 0xFF0000, 0xFFAAAA);
    this.addChild(this.highlightInvalid);
    this.refreshMode();
    this.clear();
  }

  get mode() {
    return this._mode;
  }

  set mode(value) {
    if (this._mode === value) return;
    this._mode = value;
    this.refreshMode();
  }

  refreshMode() {
    const isInvalid = this._mode === TemporaryObjectViewMode.TargetInvalid;
    this.alpha = 0.7;
    this.highlightNormal.visible = !isInvalid;
    this.highlightInvalid.visible = isInvalid;
  }

  clear() {
    this.clearTilePreview();
    this.removeSpineAnimation();
    this.currentLoadingAttempt++;
    this.areaTriggerGroup.visible = false;
    this.mapMarkerGroup.visible = false;
    this.debugStartMarkerVisual.visible = false;
  }

  clearTilePreview() {
    this.tileSpriteBg.visible = false;
    this.tileSpriteFg.visible = false;
  }

  removeSpineAnimation() {
    if (!this.spine) return;
    this.spine.destroy({
      children: true,
      texture: false,
      baseTexture: false
    });
    this.spine = null;
  }

  setHeightPlane(heightPlane) {
    this.highlightNormal.heightPlane = heightPlane;
    this.highlightInvalid.heightPlane = heightPlane;
  }

  setTilePreview(id, bgAsset, fgAsset) {
    this.clear();
    if (!bgAsset && !fgAsset) return;
    tileImageStore.adjustTileView(this.tileSpriteBg, bgAsset, id, TileImageUsage.Background);
    tileImageStore.adjustTileView(this.tileSpriteFg, fgAsset, id, TileImageUsage.Foreground);
    this.tileSpriteBg.visible = bgAsset != null;
    this.tileSpriteFg.visible = fgAsset != null;
  }

  setAreaTrigger(id) {
    this.clear();
    this.areaTriggerText.visible = Boolean(id);
    this.areaTriggerText.text = id;
    this.areaTriggerGroup.visible = true;
  }

  setMapMarker(name) {
    this.clear();
    this.mapMarkerText.visible = Boolean(name);
    this.mapMarkerText.text = name;
    this.mapMarkerGroup.visible = true;
  }

  setDebugStartMarker() {
    this.clear();
    this.debugStartMarkerVisual.visible = true;
  }

  setCharacterPreview(selectedCharacterId) {
    this.clear();
    const characterConfiguration = sharedStore.characterConfigurations.get(selectedCharacterId);

    if (characterConfiguration) {
      this.setSpineFromPromise(animationLoader.loadCharacterAnimation(characterConfiguration), characterConfiguration.animationAssetName);
    }
  }

  setAnimationPreview(selectedAnimationName) {
    this.clear();
    this.setSpineFromPromise(animationLoader.getSpineFromAnimationName(selectedAnimationName), selectedAnimationName);
  }

  setSpineFromPromise(spinePromise, animationName) {
    // Track loading attempts
    this.currentLoadingAttempt++;
    const loadingAttempt = this.currentLoadingAttempt;
    spinePromise.then(spine => {
      // Only use if we were still expecting this...
      if (this.currentLoadingAttempt === loadingAttempt) {
        spine.position.set(gameConstants.tileWidth / 2, gameConstants.tileHeight / 2); // to tile center

        this.addChild(spine);
        this.spine = spine;
        const animationData = sharedStore.getAnimationByName(animationName);
        const {
          scale
        } = animationData;
        this.spine.scale.set(scale, scale);
        applyIdleAnimation(this.spine);
      } else {
        // ...else discard.
        spine.destroy({
          children: true,
          texture: false,
          baseTexture: false
        });
      }
    }).catch(e => errorStore.addErrorFromErrorObject(e));
  }

}