import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { onPatchesImproved } from "../../../shared/helper/mobXHelpers";
export class PatchTracker {
  constructor(isTemporarilyDeactivated) {
    this.isTemporarilyDeactivated = isTemporarilyDeactivated;

    _defineProperty(this, "trackerDisposer", void 0);
  }

  startTracking(targetNode, callbackSingle, callbackAll) {
    if (this.trackerDisposer) {
      console.error("Can only use each PatchTracker once at a time.");
      return;
    }

    this.trackerDisposer = onPatchesImproved(targetNode, (patches, inversePatches) => {
      if (callbackSingle) {
        for (let i = 0; i < patches.length; i++) {
          callbackSingle(patches[i], inversePatches[i]);
        }
      }

      if (callbackAll) callbackAll(patches, inversePatches);
    }, this.isTemporarilyDeactivated); // Ignore if this is triggered e.g. by applyPatches() in EditorClient
  }

  stopTracking() {
    if (this.trackerDisposer) {
      this.trackerDisposer();
      this.trackerDisposer = null;
    }
  }

}