import _defineProperty from "@babel/runtime/helpers/defineProperty";
export class Pool {
  constructor(createObject, destroyObject, initializeObject = null, freeObject = null) {
    this.createObject = createObject;
    this.destroyObject = destroyObject;
    this.initializeObject = initializeObject;
    this.freeObject = freeObject;

    _defineProperty(this, "inUse", new Array());

    _defineProperty(this, "unused", new Array());
  }

  getOrCreate() {
    let object = null;

    if (this.unused.length > 0) {
      object = this.unused.pop();
    } else {
      object = this.createObject();
    }

    this.inUse.push(object);
    if (this.initializeObject) this.initializeObject(object);
    return object;
  }

  free(object) {
    const index = this.inUse.indexOf(object);

    if (index >= 0) {
      this.inUse.splice(index, 1);
    } else {
      console.error("Tried to free an object that was not in use", object);
    }

    this.freeObject(object);
    this.unused.push(object);
  }

  destroyCurrentlyInUseObjects() {
    this.inUse.forEach(this.destroyObject);
    this.inUse.length = 0;
  }

  destroyFreedObjects() {
    this.unused.forEach(this.destroyObject);
    this.unused.length = 0;
  }

}