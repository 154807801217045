import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { mainMapEditorStore } from "../../MapEditorStore";
export function undoableMapEditorSetPlacementSelectorCategory(category) {
  if (mainMapEditorStore.selectedCategory === category) return;
  executeUndoableOperation(new MapEditorSetPlacementSelectorCategoryOp(category));
}

class MapEditorSetPlacementSelectorCategoryOp extends UndoableOperation {
  constructor(newCategory) {
    super("mapEditorSetPlacementSelectorCategory");
    this.newCategory = newCategory;

    _defineProperty(this, "previousCategory", void 0);

    this.previousCategory = mainMapEditorStore.selectedCategory;
  }

  async execute() {
    mainMapEditorStore.setPlacementSelectorCategory(this.newCategory);
  }

  async reverse() {
    mainMapEditorStore.setPlacementSelectorCategory(this.previousCategory);
  }

}