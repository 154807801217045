import _defineProperty from "@babel/runtime/helpers/defineProperty";
export class ActionTreeZoomAndPosition {
  constructor() {
    _defineProperty(this, "zoom", 0);

    _defineProperty(this, "x", 0);

    _defineProperty(this, "y", 0);

    _defineProperty(this, "canvasWidth", 0);

    _defineProperty(this, "canvasHeight", 0);
  }

}