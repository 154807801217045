import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Container, Graphics, filters } from "pixi.js";
import { gameCanvasSize } from "../../../data/gameConstants";
export class FullScreenVignetteView extends Container {
  constructor() {
    super();

    _defineProperty(this, "screen", void 0);

    this.screen = new Graphics();
    this.screen.filters = [new filters.NoiseFilter()];
    this.addChild(this.screen);
  }

  reset() {
    this.screen.clear();
  }

  fill(timer, color) {
    if (timer > 200) return;
    this.screen.clear();
    this.screen.lineStyle(200, color, 0.6).drawCircle(gameCanvasSize.width / 2, gameCanvasSize.height / 2, gameCanvasSize.width / 2 + timer);
  }

}