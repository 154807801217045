import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Camera } from "./Camera";
import { MathE } from "../../../../shared/helper/MathExtension";
/**
 * A camera that can animate a shake effect.
 * The {@link onTick} method needs to be called every animation frame.
 */

export class AnimatedShakeCamera extends Camera {
  /**
   * Creates a new instance.
   * @param x The camera x position in world coordinates.
   * @param y The camera y position in world coordinates.
   * @param zoom The camera zoom.
   */
  constructor(x = 0, y = 0, zoom = 0) {
    super(x, y, zoom);

    _defineProperty(this, "animationDurationMillis", -1);

    _defineProperty(this, "animationStartMillis", -1);

    _defineProperty(this, "fadeOut", void 0);

    _defineProperty(this, "startX", void 0);

    _defineProperty(this, "startY", void 0);

    _defineProperty(this, "startZoom", void 0);

    _defineProperty(this, "intensityFactor", void 0);

    _defineProperty(this, "endCallback", void 0);

    this.startX = x;
    this.startY = y;
    this.startZoom = zoom;
  }
  /**
   * Should be called every animation frame. Applies the shake animation.
   */


  onTick() {
    const millisSinceAnimationStart = Date.now() - this.animationStartMillis;
    if (this.animationStartMillis < 0) return;
    const progress = Math.min(1, millisSinceAnimationStart / this.animationDurationMillis);
    let processedIntensity = this.intensityFactor;
    if (this.fadeOut) processedIntensity = processedIntensity * (1 - progress);
    this.setX(this.startX + Math.random() * processedIntensity * AnimatedShakeCamera.MAX_SHAKE_OFFSET_POSITION);
    this.setY(this.startY + Math.random() * processedIntensity * AnimatedShakeCamera.MAX_SHAKE_OFFSET_POSITION);
    this.setZoom(this.startZoom + Math.random() * processedIntensity * AnimatedShakeCamera.MAX_SHAKE_OFFSET_ZOOM);

    if (progress >= 1) {
      this.animationStartMillis = -1;

      if (this.endCallback) {
        const currentCallback = this.endCallback;
        this.endCallback = null;
        currentCallback();
      }
    }
  }
  /**
   * Starts the current initialized animation.
   * @param durationSeconds The duration of the share animation in seconds.
   * @param intensityFactor Factor for the intensity of the share (range [0:1] higher is more intense)
   * @param fadeOut Fades out the intensity if ture is assigned.
   * @param animationEndCallback Callback that will be called if the animation ended.
   */


  startAnimation(durationSeconds, intensityFactor, fadeOut, animationEndCallback) {
    this.intensityFactor = MathE.limit(0, 1, intensityFactor);
    this.animationDurationMillis = Math.max(0, durationSeconds * 1000);
    this.fadeOut = fadeOut;
    this.endCallback = animationEndCallback;
    this.animationStartMillis = Date.now();
  }

}

_defineProperty(AnimatedShakeCamera, "MAX_SHAKE_OFFSET_POSITION", 60);

_defineProperty(AnimatedShakeCamera, "MAX_SHAKE_OFFSET_ZOOM", 0.05);