import { AreaTriggerVisual, AreaTriggerVisualColorBorder } from "./AreaTriggerVisual";
import { EditorOnlyElementView } from "./EditorOnlyElementView";
export class AreaTriggerView extends EditorOnlyElementView {
  constructor(mapRelatedStore, areaTriggerData, textGroup) {
    super(mapRelatedStore, textGroup, true);
    this.areaTriggerData = areaTriggerData;
    this.text.style.stroke = AreaTriggerVisualColorBorder;
    this.text.style.fill = "white";
    this.addChild(new AreaTriggerVisual());
  }

  getPosition() {
    return this.areaTriggerData.position;
  }

  getLabelString() {
    return this.areaTriggerData.id;
  }

  refreshVisibility() {
    if (!this.mapRelatedStore.highlightedElements) return super.refreshVisibility();
    this.isHidden = !this.mapRelatedStore.highlightedElements.has(this.areaTriggerData) && !this.mapRelatedStore.showEmptyAreaTriggersAndNPCsEvenIfNotHighlighted;
  }

}