import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable } from "mobx";
import { defaultComplexitySetting, EditorComplexity } from "../../shared/definitions/other/EditorComplexity";
import { getAllNumberEnumValues } from "../../shared/helper/generalHelpers";
import { getURLParameterNumber } from "../helper/generalHelpers";
import { LocalStorageObjectBoolean, LocalStorageObjectNumber } from "../integration/localStorage";
import { userStore } from "./UserStore";
export const allEditorComplexities = getAllNumberEnumValues(EditorComplexity);
const localStorageEditorComplexity = new LocalStorageObjectNumber("editorComplexity", defaultComplexitySetting);
const localStorageShowDebugInfo = new LocalStorageObjectBoolean("showDebugInfo", false);
const localStorageShowPerformanceInfo = new LocalStorageObjectBoolean("showPerformanceInfo", false);
const localStorageShowUndoHistoryDebug = new LocalStorageObjectBoolean("showUndoHistoryDebug", false);
export class LocalSettingsStore {
  constructor() {
    _defineProperty(this, "fullscreen", false);

    _defineProperty(this, "editorComplexity", void 0);

    _defineProperty(this, "settingShowDebugInfo", localStorageShowDebugInfo.get());

    _defineProperty(this, "settingShowPerformanceInfo", localStorageShowPerformanceInfo.get());

    _defineProperty(this, "settingShowUndoHistoryDebug", localStorageShowUndoHistoryDebug.get());

    _defineProperty(this, "actionTreeValidationEnabled", void 0);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
    this.editorComplexity = getURLParameterNumber("complexity", localStorageEditorComplexity.get());

    if (!allEditorComplexities.includes(this.editorComplexity)) {
      this.editorComplexity = defaultComplexitySetting;
    }

    localStorageEditorComplexity.set(this.editorComplexity);
  }

  get isProductionEditor() {
    return this.editorComplexity === EditorComplexity.Production;
  }

  setEditorComplexity(value) {
    this.editorComplexity = value;
    localStorageEditorComplexity.set(value);
  }

  get showDebugInfo() {
    if (!userStore.shouldShowAdvancedOptions) return false;
    return this.settingShowDebugInfo;
  }

  get showPerformanceInfo() {
    if (!userStore.shouldShowAdvancedOptions) return false;
    return this.settingShowPerformanceInfo;
  }

  get showUndoHistoryDebug() {
    if (!userStore.shouldShowAdvancedOptions) return false;
    return this.settingShowUndoHistoryDebug;
  }

  toggleActionTreeValidationEnabled() {
    this.actionTreeValidationEnabled = !this.actionTreeValidationEnabled;
  }

  setActionTreeValidationEnabled(enabled) {
    this.actionTreeValidationEnabled = enabled;
  }

  toggleShowDebugInfoSetting() {
    this.settingShowDebugInfo = !this.settingShowDebugInfo;
    localStorageShowDebugInfo.set(this.settingShowDebugInfo);
  }

  toggleShowPerformanceInfoSetting() {
    this.settingShowPerformanceInfo = !this.settingShowPerformanceInfo;
    localStorageShowPerformanceInfo.set(this.settingShowPerformanceInfo);
  }

  toggleShowUndoHistoryDebug() {
    this.settingShowUndoHistoryDebug = !this.settingShowUndoHistoryDebug;
    localStorageShowUndoHistoryDebug.set(this.settingShowUndoHistoryDebug);
  }

  toggleFullscreen() {
    this.fullscreen = !this.fullscreen;

    if (this.fullscreen) {
      document.body.requestFullscreen().catch(e => console.error(e));
    } else {
      document.exitFullscreen().catch(e => console.error(e));
    }
  }

}
export const localSettingsStore = new LocalSettingsStore();