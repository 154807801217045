import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { executeUndoableOperation } from "../UndoStore";
import { UndoableOperation } from "../UndoableOperation";
export function undoableCharacterEditorSelectSkinClass(skinClassName, store) {
  executeUndoableOperation(new CharacterEditorStateChangeOp(skinClassName, store, "charEditorSkinClassSelected"));
}

class CharacterEditorStateChangeOp extends UndoableOperation {
  constructor(targetClass, store, operationNameI18nKey) {
    super(operationNameI18nKey);
    this.targetClass = targetClass;
    this.store = store;

    _defineProperty(this, "previousClass", void 0);

    this.previousClass = store.animationSelectionStore.selectedSkinClass;
  }

  async execute() {
    this.store.animationSelectionStore.setSelectedSkinClass(this.targetClass);
  }

  async reverse() {
    this.store.animationSelectionStore.setSelectedSkinClass(this.previousClass);
  }

}