import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { editorClient } from "../../../communication/EditorClient";
import { mainMapEditorStore } from "../../MapEditorStore";
export function undoableMapEditorCreateMap(mapName) {
  executeUndoableOperation(new MapEditorCreateMapOp(mapName));
}

class MapEditorCreateMapOp extends UndoableOperation {
  constructor(mapName) {
    super("mapEditorCreateMap");
    this.mapName = mapName;

    _defineProperty(this, "createdMapId", void 0);
  }

  async execute(redo) {
    const {
      currentMapStore
    } = mainMapEditorStore;

    if (redo) {
      await editorClient.undeleteMap(currentMapStore, this.createdMapId);
      await editorClient.openMapInMapEditor(currentMapStore, this.createdMapId);
    } else {
      this.createdMapId = await editorClient.createNewMap(currentMapStore, this.mapName);
    }
  }

  async reverse() {
    const {
      currentMapStore
    } = mainMapEditorStore;
    await editorClient.deleteMap(currentMapStore, this.createdMapId);
  }

}