import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableSetTileAssetTagFilter(mapRelatedStore, tag) {
  executeUndoableOperation(new SetTileAssetTagFilterOp(mapRelatedStore.setTileAssetTagFilter.bind(mapRelatedStore), tag, mapRelatedStore.tileAssetTagFilter));
}

class SetTileAssetTagFilterOp extends UndoableOperation {
  constructor(setter, newTileAssetTagFilter, previousTileAssetTagFilter) {
    super("setTileAssetTagFilter");
    this.setter = setter;
    this.newTileAssetTagFilter = newTileAssetTagFilter;
    this.previousTileAssetTagFilter = previousTileAssetTagFilter;
  }

  async execute() {
    this.setter(this.newTileAssetTagFilter);
  }

  async reverse() {
    this.setter(this.previousTileAssetTagFilter);
  }

}