import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2;

import { computed } from "mobx";
import { model, Model, modelAction, prop } from "mobx-keystone";
import { PositionModel, ReadonlyPosition } from "./PositionModel";
import { ReadonlyTileDataInteractionTrigger, TileDataInteractionTriggerModel } from "./TileDataInteractionTriggerModel";
export let TileDataModel = (_dec = model("game/TileModel"), _dec(_class = (_class2 = class TileDataModel extends Model({
  position: prop(),
  tileAssetId: prop(""),
  interactionTriggerData: prop(),
  conflictResolutionOriginOverride: prop().withSetter(),
  conflictResolutionFlatZIndex: prop(0).withSetter(),
  additionalOffsetX: prop(0).withSetter(),
  additionalOffsetY: prop(0).withSetter(),
  additionalOffsetZ: prop(0).withSetter()
}) {
  get isInteractionTrigger() {
    var _this$interactionTrig;

    return !!((_this$interactionTrig = this.interactionTriggerData) !== null && _this$interactionTrig !== void 0 && _this$interactionTrig.isInteractionTrigger);
  }

  get isModuleGate() {
    var _this$interactionTrig2;

    return !!((_this$interactionTrig2 = this.interactionTriggerData) !== null && _this$interactionTrig2 !== void 0 && _this$interactionTrig2.isModuleGate);
  }

  get hasConflictResolutionOriginOverride() {
    return this.conflictResolutionOriginOverride !== null && this.conflictResolutionOriginOverride !== undefined;
  }

  applyChangeableTileDataSnapshot(snapshot) {
    var _this$interactionTrig3, _this$interactionTrig4, _this$interactionTrig5, _this$interactionTrig6, _this$interactionTrig7, _this$interactionTrig8;

    this.tileAssetId = snapshot.tileAssetId;

    if (((_this$interactionTrig3 = this.interactionTriggerData) === null || _this$interactionTrig3 === void 0 ? void 0 : _this$interactionTrig3.$modelId) != snapshot.interactionTriggerModelId) {
      if (!snapshot.interactionTriggerModelId) {
        this.interactionTriggerData = undefined;
      } else {
        this.interactionTriggerData = new TileDataInteractionTriggerModel({
          $modelId: snapshot.interactionTriggerModelId
        });
      }
    }

    (_this$interactionTrig4 = this.interactionTriggerData) === null || _this$interactionTrig4 === void 0 ? void 0 : _this$interactionTrig4.setIsInteractionTrigger(snapshot.isInteractionTrigger);
    (_this$interactionTrig5 = this.interactionTriggerData) === null || _this$interactionTrig5 === void 0 ? void 0 : _this$interactionTrig5.setIsModuleGate(snapshot.isModuleGate);
    (_this$interactionTrig6 = this.interactionTriggerData) === null || _this$interactionTrig6 === void 0 ? void 0 : _this$interactionTrig6.setLabel(snapshot.interactionTriggerLabel);
    (_this$interactionTrig7 = this.interactionTriggerData) === null || _this$interactionTrig7 === void 0 ? void 0 : _this$interactionTrig7.setTileOffsetX(snapshot.interactionTriggerTileOffsetX);
    (_this$interactionTrig8 = this.interactionTriggerData) === null || _this$interactionTrig8 === void 0 ? void 0 : _this$interactionTrig8.setTileOffsetY(snapshot.interactionTriggerTileOffsetY);
    this.conflictResolutionOriginOverride = snapshot.conflictResolutionOriginOverride;
    this.conflictResolutionFlatZIndex = snapshot.conflictResolutionFlatZIndex;
    this.additionalOffsetX = snapshot.additionalOffsetX;
    this.additionalOffsetY = snapshot.additionalOffsetY;
    this.additionalOffsetZ = snapshot.additionalOffsetZ;
  }

  isOnPlaneAndOverlappingXY(x, y, plane, getTileAsset) {
    if (this.position.plane !== plane) return false;
    return this.isOverlappingXY(x, y, getTileAsset);
  }

  isOverlappingXY(x, y, getTileAsset) {
    if (this.position.x > x || this.position.y > y) return false;
    const tileAsset = getTileAsset(this.tileAssetId);
    const tilesX = tileAsset ? tileAsset.tilesX : 1;
    const tilesY = tileAsset ? tileAsset.tilesY : 1;
    return this.position.x + tilesX > x && this.position.y + tilesY > y;
  }

  isOnSamePlaneAndIntersectingXY(x, y, tilesX, tilesY, plane, getTileAsset) {
    if (this.position.plane !== plane) return false;
    return this.isIntersectingXY(x, y, tilesX, tilesY, getTileAsset);
  }

  isIntersectingXY(otherX, otherY, otherTilesX, otherTilesY, getTileAsset) {
    if (this.position.x > otherX + otherTilesX || this.position.y > otherY + otherTilesY) return false;
    const tileAsset = getTileAsset(this.tileAssetId);
    const tilesX = tileAsset ? tileAsset.tilesX : 1;
    const tilesY = tileAsset ? tileAsset.tilesY : 1;
    const thisLeft = this.position.x;
    const thisRight = thisLeft + tilesX - 1;
    const thisTop = this.position.y;
    const thisBottom = thisTop + tilesY - 1;
    const otherLeft = otherX;
    const otherRight = otherLeft + otherTilesX - 1;
    const otherTop = otherY;
    const otherBottom = otherTop + otherTilesY - 1;
    return thisLeft <= otherRight && otherLeft <= thisRight && thisTop <= otherBottom && otherTop <= thisBottom;
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "isInteractionTrigger", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "isInteractionTrigger"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isModuleGate", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "isModuleGate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "hasConflictResolutionOriginOverride", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "hasConflictResolutionOriginOverride"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "applyChangeableTileDataSnapshot", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "applyChangeableTileDataSnapshot"), _class2.prototype)), _class2)) || _class);
export function getChangeableTileDataSnapshot(tileData) {
  var _tileData$interaction, _tileData$interaction2, _tileData$interaction3, _tileData$interaction4;

  if (!tileData) return getEmptyChangeableTileDataSnapshot();
  return {
    tileAssetId: tileData.tileAssetId,
    isInteractionTrigger: tileData.isInteractionTrigger,
    isModuleGate: tileData.isModuleGate,
    interactionTriggerModelId: (_tileData$interaction = tileData.interactionTriggerData) === null || _tileData$interaction === void 0 ? void 0 : _tileData$interaction.$modelId,
    interactionTriggerLabel: (_tileData$interaction2 = tileData.interactionTriggerData) === null || _tileData$interaction2 === void 0 ? void 0 : _tileData$interaction2.label,
    interactionTriggerTileOffsetX: (_tileData$interaction3 = tileData.interactionTriggerData) === null || _tileData$interaction3 === void 0 ? void 0 : _tileData$interaction3.tileOffsetX,
    interactionTriggerTileOffsetY: (_tileData$interaction4 = tileData.interactionTriggerData) === null || _tileData$interaction4 === void 0 ? void 0 : _tileData$interaction4.tileOffsetY,
    conflictResolutionOriginOverride: tileData.conflictResolutionOriginOverride,
    conflictResolutionFlatZIndex: tileData.conflictResolutionFlatZIndex,
    additionalOffsetX: tileData.additionalOffsetX,
    additionalOffsetY: tileData.additionalOffsetY,
    additionalOffsetZ: tileData.additionalOffsetZ
  };
}
export function getEmptyChangeableTileDataSnapshot() {
  return {
    tileAssetId: undefined,
    isInteractionTrigger: false,
    isModuleGate: false,
    conflictResolutionFlatZIndex: 0,
    additionalOffsetX: 0,
    additionalOffsetY: 0,
    additionalOffsetZ: 0
  };
}
export function tileDataEqualsChangeableTileDataSnapshot(tileData, snapshot) {
  var _tileData$interaction5, _tileData$interaction6, _tileData$interaction7, _tileData$interaction8;

  if (!tileData) {
    return snapshot.tileAssetId === undefined;
  }

  return tileData.tileAssetId == snapshot.tileAssetId && tileData.isInteractionTrigger == snapshot.isInteractionTrigger && tileData.isModuleGate == snapshot.isModuleGate && ((_tileData$interaction5 = tileData.interactionTriggerData) === null || _tileData$interaction5 === void 0 ? void 0 : _tileData$interaction5.$modelId) == snapshot.interactionTriggerModelId && ((_tileData$interaction6 = tileData.interactionTriggerData) === null || _tileData$interaction6 === void 0 ? void 0 : _tileData$interaction6.label) == snapshot.interactionTriggerLabel && ((_tileData$interaction7 = tileData.interactionTriggerData) === null || _tileData$interaction7 === void 0 ? void 0 : _tileData$interaction7.tileOffsetX) == snapshot.interactionTriggerTileOffsetX && ((_tileData$interaction8 = tileData.interactionTriggerData) === null || _tileData$interaction8 === void 0 ? void 0 : _tileData$interaction8.tileOffsetY) == snapshot.interactionTriggerTileOffsetY && tileData.conflictResolutionOriginOverride == snapshot.conflictResolutionOriginOverride && tileData.conflictResolutionFlatZIndex == snapshot.conflictResolutionFlatZIndex && tileData.additionalOffsetX == snapshot.additionalOffsetX && tileData.additionalOffsetY == snapshot.additionalOffsetY && tileData.additionalOffsetZ == snapshot.additionalOffsetZ;
}
export class ReadonlyTileData {
  constructor(data) {
    _defineProperty(this, "position", void 0);

    _defineProperty(this, "tileAssetId", void 0);

    _defineProperty(this, "label", void 0);

    _defineProperty(this, "interactionTriggerData", void 0);

    _defineProperty(this, "isInteractionTrigger", void 0);

    _defineProperty(this, "hasConflictResolutionOriginOverride", void 0);

    _defineProperty(this, "conflictResolutionOriginOverride", void 0);

    _defineProperty(this, "conflictResolutionFlatZIndex", void 0);

    _defineProperty(this, "additionalOffsetX", void 0);

    _defineProperty(this, "additionalOffsetY", void 0);

    _defineProperty(this, "additionalOffsetZ", void 0);

    this.position = new ReadonlyPosition(data.position);
    this.tileAssetId = data.tileAssetId;
    this.interactionTriggerData = data.interactionTriggerData && new ReadonlyTileDataInteractionTrigger(data.interactionTriggerData);
    this.isInteractionTrigger = data.isInteractionTrigger;
    this.hasConflictResolutionOriginOverride = data.hasConflictResolutionOriginOverride;
    this.conflictResolutionOriginOverride = data.conflictResolutionOriginOverride;
    this.conflictResolutionFlatZIndex = data.conflictResolutionFlatZIndex;
    this.additionalOffsetX = data.additionalOffsetX;
    this.additionalOffsetY = data.additionalOffsetY;
    this.additionalOffsetZ = data.additionalOffsetZ;
  }

}
export const EMPTY_TILE_DATA_MODEL = new TileDataModel({
  position: new PositionModel({})
});