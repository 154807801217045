import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2;

import { computed } from "mobx";
import { model, Model, modelAction, prop } from "mobx-keystone";
export let Size3DModel = (_dec = model("resources/Size3DModel"), _dec(_class = (_class2 = class Size3DModel extends Model({
  x: prop(1),
  y: prop(1),
  z: prop(1)
}) {
  get isFlatX() {
    return this.x <= 0;
  }

  get isFlatY() {
    return this.y <= 0;
  }

  get isFlatZ() {
    return this.z <= 0;
  }

  get isFlat() {
    return this.isFlatX || this.isFlatY || this.isFlatZ;
  }

  applyCeil() {
    this.x = Math.ceil(this.x);
    this.y = Math.ceil(this.y);
    this.z = Math.ceil(this.z);
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "isFlatX", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "isFlatX"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isFlatY", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "isFlatY"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isFlatZ", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "isFlatZ"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isFlat", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "isFlat"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "applyCeil", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "applyCeil"), _class2.prototype)), _class2)) || _class);
export const unitSize3D = {
  x: 1,
  y: 1,
  z: 1
};
export const flatUnitSize3D = {
  x: 1,
  y: 1,
  z: 0
};