import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { editorClient } from "../../../communication/EditorClient";
import { executeUndoableOperation } from "../UndoStore";
import { actionTreeSetSelection, getCurrentlySelectedHierarchyIds, getCurrentReactFlowInstanceTransform } from "./actionEditorSupport";
import { actionEditorStore } from "../../ActionEditorStore";
import { getSnapshot } from "mobx-keystone";
export function undoableActionEditorDeleteSubtree(subtree) {
  const deletedSnapshots = new Array();
  collectAllSubtreeSnapshots(subtree, deletedSnapshots);
  executeUndoableOperation(new ActionEditorDeleteSubtreeOp(deletedSnapshots));
}
export function collectAllSubtreeSnapshots(currentTree, results) {
  results.push(getSnapshot(currentTree));
  currentTree.subtreeActions.forEach(subtree => collectAllSubtreeSnapshots(subtree, results));
}

class ActionEditorDeleteSubtreeOp extends UndoableOperation {
  constructor(subtrees) {
    var _actionEditorStore$cu;

    super("actionEditorDeleteSubtree");
    this.subtrees = subtrees;

    _defineProperty(this, "currentTransform", void 0);

    _defineProperty(this, "hierachyIds", void 0);

    _defineProperty(this, "selectedActionModelId", void 0);

    this.currentTransform = getCurrentReactFlowInstanceTransform();
    this.hierachyIds = getCurrentlySelectedHierarchyIds();
    this.selectedActionModelId = (_actionEditorStore$cu = actionEditorStore.currentAction) === null || _actionEditorStore$cu === void 0 ? void 0 : _actionEditorStore$cu.$modelId;
  }

  async execute() {
    actionEditorStore.clearClickActions();
    actionEditorStore.deselectSelectedAction();
    await editorClient.deleteActionTrees(this.subtrees);
  }

  async reverse() {
    actionEditorStore.clearClickActions();
    await editorClient.unDeleteActionTrees(this.subtrees.map(snapshot => snapshot.$modelId));
    actionTreeSetSelection(this.selectedActionModelId, this.hierachyIds, this.currentTransform);
  }

}