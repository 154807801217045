import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { combatEditorStore } from "../../CombatEditorStore";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableSetShowEnemyCombatPresetGesturePatternSelection(round) {
  executeUndoableOperation(new CombatSetShowEnemyCombatPresetGesturePatternSelectionOp(round.$modelId));
}
export function undoableClearShowEnemyCombatPresetGesturePatternSelection() {
  executeUndoableOperation(new CombatSetShowEnemyCombatPresetGesturePatternSelectionOp(null));
}

class CombatSetShowEnemyCombatPresetGesturePatternSelectionOp extends UndoableOperation {
  constructor(roundModelId) {
    super("combatSetShowEnemyCombatPresetGesturePatternSelection/" + (roundModelId ? "open" : "close"));
    this.roundModelId = roundModelId;

    _defineProperty(this, "previousRoundModelId", void 0);

    this.previousRoundModelId = combatEditorStore.showEnemyCombatPresetGesturePatternSelectionRoundModelId;
  }

  async execute() {
    combatEditorStore.setShowEnemyCombatPresetGesturePatternSelectionModelId(this.roundModelId);
  }

  async reverse() {
    combatEditorStore.setShowEnemyCombatPresetGesturePatternSelectionModelId(this.previousRoundModelId);
  }

}