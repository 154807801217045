import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2;

import { model, Model, modelAction, prop } from "mobx-keystone";
import { PositionModel, ReadonlyPosition } from "./PositionModel";
import { ReadonlyTileData, TileDataModel } from "./TileDataModel";
import { sharedLogger } from "../helper/logger";
import { ReadonlyMapDataProperties, MapDataPropertiesModel } from "./MapDataPropertiesModel";
import { DynamicMapElementNPCModel } from "./dynamicMapElements/DynamicMapElementNPCModel";
import { DynamicMapElementMapMarkerModel } from "./dynamicMapElements/DynamicMapElementMapMarkerModel";
import { DynamicMapElementAreaTriggerModel } from "./dynamicMapElements/DynamicMapElementAreaTriggerModel";
import { DynamicMapElementAnimationElementModel } from "./dynamicMapElements/DynamicMapElementAnimationElementModel";
import { computed } from "mobx";
export let MapDataModel = (_dec = model("game/MapModel"), _dec(_class = (_class2 = class MapDataModel extends Model({
  tiles: prop(() => []),
  dynamicMapElements: prop(() => []),
  properties: prop(() => new MapDataPropertiesModel({})),
  moduleOwner: prop("")
}) {
  addDynamicMapElement(dynamicMapElement) {
    this.dynamicMapElements.push(dynamicMapElement);
  }

  removeDynamicMapElement(dynamicMapElement) {
    const index = this.dynamicMapElements.indexOf(dynamicMapElement);
    if (index === -1) return;
    this.dynamicMapElements.splice(index, 1);
  }

  getDynamicMapElementByModelId($modelId) {
    return this.dynamicMapElements.find(element => element.$modelId === $modelId);
  }

  setTile(x, y, layer, plane, newData) {
    const tile = this.getTile(x, y, layer, plane);

    if (newData.tileAssetId == undefined) {
      if (tile) {
        const index = this.tiles.indexOf(tile);
        this.tiles.splice(index, 1);
      }

      return;
    }

    if (tile) {
      tile.applyChangeableTileDataSnapshot(newData);
    } else {
      const newTile = new TileDataModel({
        position: new PositionModel({
          x,
          y,
          layer,
          plane
        })
      });
      newTile.applyChangeableTileDataSnapshot(newData);
      this.tiles.push(newTile);
    }
  }

  getTile(x, y, layer, plane) {
    return this.tiles.find(e => e.position.equals(x, y, layer, plane));
  }

  getFirstEmptyLayer(x, y, plane, startLayerInclusive) {
    const tilesOnPosition = this.tiles.filter(tile => tile.position.equals(x, y, tile.position.layer, plane));
    let layer = startLayerInclusive;

    while (tilesOnPosition.some(tile => tile.position.layer === layer)) {
      layer++;
    }

    return layer;
  }

  getTilesOnPlaneWithXYOverlap(x, y, plane, getTileAsset) {
    return this.tiles.filter(tile => tile.isOnPlaneAndOverlappingXY(x, y, plane, getTileAsset));
  }

  getTilesWithXYOverlap(x, y, getTileAsset) {
    return this.tiles.filter(tile => tile.isOverlappingXY(x, y, getTileAsset));
  }

  getHighestMainGroundTilePlaneForPosition(x, y, getTileAsset) {
    let highestPlane = undefined;

    for (const tile of this.tiles) {
      if (tile.position.layer !== 0) continue;
      if (highestPlane !== undefined && tile.position.plane <= highestPlane) continue;

      if (tile.isOverlappingXY(x, y, getTileAsset)) {
        highestPlane = tile.position.plane;
      }
    }

    return highestPlane;
  }

  get npcs() {
    return this.dynamicMapElements.filter(element => element instanceof DynamicMapElementNPCModel).map(element => element);
  }

  getNPCAtPositionXYPlane(x, y, plane) {
    return this.npcs.filter(npc => npc.position.x === x && npc.position.y === y && npc.position.plane === plane)[0];
  }

  get animationElements() {
    return this.dynamicMapElements.filter(element => element instanceof DynamicMapElementAnimationElementModel).map(element => element);
  }

  get areaTriggers() {
    return this.dynamicMapElements.filter(element => element instanceof DynamicMapElementAreaTriggerModel).map(element => element);
  }

  get mapMarkers() {
    return this.dynamicMapElements.filter(element => element instanceof DynamicMapElementMapMarkerModel).map(element => element);
  }

  getAllDynamicMapElementsAtPositionXYPlane(x, y, plane) {
    return this.dynamicMapElements.filter(element => element.position.x === x && element.position.y === y && element.position.plane === plane);
  }

  getAllDynamicMapElementsAtPositionXY(x, y) {
    return this.dynamicMapElements.filter(element => element.position.x === x && element.position.y === y);
  }

  get interactionTriggerTiles() {
    return this.tiles.filter(tile => tile.isInteractionTrigger);
  }

  get interactionTriggerDataArray() {
    const result = this.dynamicMapElements.filter(element => element.isInteractionTrigger);
    result.push(...this.interactionTriggerTiles.map(tile => tile.interactionTriggerData));
    return result;
  }

  get interactionGateDataArray() {
    const result = this.dynamicMapElements.filter(element => element.isInteractionTrigger && element.isModuleGate);
    result.push(...this.tiles.filter(tile => tile.isInteractionTrigger && tile.isModuleGate).map(tile => tile.interactionTriggerData));
    return result;
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "addDynamicMapElement", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "addDynamicMapElement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeDynamicMapElement", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "removeDynamicMapElement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setTile", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "setTile"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "npcs", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "npcs"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "animationElements", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "animationElements"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "areaTriggers", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "areaTriggers"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "mapMarkers", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "mapMarkers"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "interactionTriggerTiles", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "interactionTriggerTiles"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "interactionTriggerDataArray", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "interactionTriggerDataArray"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "interactionGateDataArray", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "interactionGateDataArray"), _class2.prototype)), _class2)) || _class);
export function mapSnapshotHasInteractionGates(mapSnapshot) {
  return mapSnapshot.dynamicMapElements.some(element => element.isInteractionTrigger && element.isModuleGate) || mapSnapshot.tiles.some(tile => {
    var _tile$interactionTrig, _tile$interactionTrig2;

    return !!((_tile$interactionTrig = tile.interactionTriggerData) !== null && _tile$interactionTrig !== void 0 && _tile$interactionTrig.isInteractionTrigger) && !!((_tile$interactionTrig2 = tile.interactionTriggerData) !== null && _tile$interactionTrig2 !== void 0 && _tile$interactionTrig2.isModuleGate);
  });
}

function getKeyXYLP(x, y, layer, plane) {
  return x + " " + y + " " + layer + " " + plane;
}

function getKeyXYP(x, y, plane) {
  return x + " " + y + " " + plane;
}

function getKeyXY(x, y) {
  return x + " " + y;
}

export class ReadonlyMapData {
  constructor(data, getTileAsset) {
    _defineProperty(this, "tiles", void 0);

    _defineProperty(this, "dynamicMapElements", void 0);

    _defineProperty(this, "npcs", void 0);

    _defineProperty(this, "animationElements", void 0);

    _defineProperty(this, "areaTriggers", void 0);

    _defineProperty(this, "mapMarkers", void 0);

    _defineProperty(this, "properties", void 0);

    _defineProperty(this, "interactionTriggerTiles", void 0);

    _defineProperty(this, "exactTilesByXYLP", new Map());

    _defineProperty(this, "allTilesByXYP", new Map());

    _defineProperty(this, "highestMainGroundPlaneByXY", new Map());

    this.tiles = data.tiles.map(tile => new ReadonlyTileData(tile));
    this.dynamicMapElements = data.dynamicMapElements.map(element => element.createReadOnlyVersion());
    this.npcs = data.npcs.map(npc => npc.createReadOnlyVersion());
    this.animationElements = data.animationElements.map(animationElements => animationElements.createReadOnlyVersion());
    this.areaTriggers = data.areaTriggers.map(triggers => triggers.createReadOnlyVersion());
    this.mapMarkers = data.mapMarkers.map(markers => markers.createReadOnlyVersion());
    this.properties = new ReadonlyMapDataProperties(data.properties);
    this.interactionTriggerTiles = data.interactionTriggerTiles.map(tile => new ReadonlyTileData(tile));

    for (const tile of this.tiles) {
      const {
        x,
        y,
        layer,
        plane
      } = tile.position;
      this.exactTilesByXYLP.set(getKeyXYLP(x, y, layer, plane), tile);
      const tileAsset = getTileAsset(tile.tileAssetId);
      const tilesX = tileAsset ? tileAsset.tilesX : 1;
      const tilesY = tileAsset ? tileAsset.tilesY : 1;

      for (let offsetX = 0; offsetX < tilesX; offsetX++) {
        for (let offsetY = 0; offsetY < tilesY; offsetY++) {
          const keyXYP = getKeyXYP(x + offsetX, y + offsetY, plane);

          if (this.allTilesByXYP.has(keyXYP)) {
            this.allTilesByXYP.get(keyXYP).push(tile);
          } else {
            this.allTilesByXYP.set(keyXYP, [tile]);
          }

          if (layer === 0) {
            const keyXY = getKeyXY(x + offsetX, y + offsetY);
            const currentHighestPlane = this.highestMainGroundPlaneByXY.get(keyXY);

            if (currentHighestPlane === undefined || currentHighestPlane < plane) {
              this.highestMainGroundPlaneByXY.set(keyXY, plane);
            }
          }
        }
      }
    }
  }
  /**
   * Searches for a {@link DynamicMapElementMapMarkerInterface} with the exact $modelId.
   * 
   * @param mapMarkerModelId The $modelId of the map marker to search for
   * @returns 
   */


  findMapMarkerByModelId(mapMarkerModelId) {
    return this.mapMarkers.find(m => m.$modelId === mapMarkerModelId);
  }
  /**
   * Since several Map Markers can have the same label,
   * this only returns the first {@link DynamicMapElementMapMarkerInterface} in the list.
   * 
   * @param mapMarkerLabel The label of the map marker to search for
   */


  findFirstMapMarkerByLabel(mapMarkerLabel) {
    return this.mapMarkers.find(m => m.label === mapMarkerLabel);
  }
  /**
   * Tries to find a start {@link ReadonlyPosition} for on this map.
   *
   * @param startMapMarkerModelId The $modelId of the start map marker.
   */


  findStartPosition(startMapMarkerModelId, defaultStartLabel) {
    const startMarker = this.findMapMarkerByModelId(startMapMarkerModelId);

    if (startMarker) {
      return startMarker.position;
    } else {
      const defaultStart = this.findFirstMapMarkerByLabel(defaultStartLabel);
      if (defaultStart) return defaultStart.position;
    }

    sharedLogger.error("Starting position invalid.", startMapMarkerModelId, defaultStartLabel);
    return new ReadonlyPosition({
      x: 0,
      y: 0
    });
  }

  getTile(x, y, layer, plane) {
    return this.exactTilesByXYLP.get(getKeyXYLP(x, y, layer, plane));
  }

  getTilesOnPlaneWithXYOverlap(tileX, tileY, plane) {
    return this.allTilesByXYP.get(getKeyXYP(tileX, tileY, plane)) || [];
  }

  getHighestMainGroundTilePlaneForPosition(x, y) {
    return this.highestMainGroundPlaneByXY.get(getKeyXY(x, y));
  }

  getNPCAtPositionXYPlane(x, y, plane) {
    return this.npcs.filter(npc => npc.position.x === x && npc.position.y === y && npc.position.plane === plane)[0];
  }

  getAllAreaTriggersAtPositionXYPlane(x, y, plane) {
    return this.areaTriggers.filter(trigger => trigger.position.x === x && trigger.position.y === y && trigger.position.plane === plane);
  }

  getAllAreaTriggersById(triggerId) {
    return this.areaTriggers.filter(trigger => trigger.id === triggerId);
  }

  getMapMarkersAtPositionXYPlane(x, y, plane) {
    return this.mapMarkers.filter(marker => marker.position.x === x && marker.position.y === y && marker.position.plane === plane);
  }

}