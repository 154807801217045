import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2;

import { model, Model, modelAction, prop } from "mobx-keystone";
import { CircleGestureModel } from "./CircleGestureModel";
import { LineGestureModel } from "./LineGestureModel";
export let GesturePatternModel = (_dec = model("combat/GesturePatternModel"), _dec(_class = (_class2 = class GesturePatternModel extends Model({
  precision: prop(5).withSetter(),
  missTolerance: prop(20).withSetter(),
  keySequence: prop("WASD").withSetter(),
  gestures: prop(() => [])
}) {
  removeGesture(index) {
    this.gestures.splice(index, 1);
  }

  addLineGesture() {
    this.gestures.push(new LineGestureModel({}));
  }

  addCircleGesture() {
    this.gestures.push(new CircleGestureModel({}));
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "removeGesture", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "removeGesture"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addLineGesture", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "addLineGesture"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addCircleGesture", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "addCircleGesture"), _class2.prototype)), _class2)) || _class);