import localforage from "localforage";
import { keyToTileImageIdentification } from "../../shared/resources/TileAssetModel";
import { featureSwitchConstants } from "../data/featureSwitchConstants";
import { tileImageCacheKeyPrefix } from "./sharedCacheData";
const {
  useCache
} = featureSwitchConstants;
/* ---------------------- */

/* -- Tile Image Cache -- */

/* ---------------------- */

export function tileImageCacheKey(id, tileImageUsage) {
  return `${tileImageCacheKeyPrefix}${id}_${tileImageUsage}`;
}
export function saveInTileImageCache(id, tileImageUsage, version, blob) {
  if (!useCache) return Promise.resolve();
  return localforage.setItem(tileImageCacheKey(id, tileImageUsage), {
    blob,
    version
  });
}
export async function loadFromTileImageCacheIfVersionMatchesElsePrune(id, tileImageUsage, expectedVersion) {
  if (!useCache) return null;
  const cachedImageData = await localforage.getItem(tileImageCacheKey(id, tileImageUsage));

  if (cachedImageData && cachedImageData.version !== expectedVersion) {
    //console.log(`[Cache] Removed: ${id}:${tileImageUsage} (expected version ${expectedVersion}, but cached was ${cachedImageData.version})`);
    await removeFromTileImageCache(id, tileImageUsage);
    return null;
  }

  return cachedImageData;
}
export function removeFromTileImageCache(id, tileImageUsage) {
  if (!useCache) return Promise.resolve();
  return localforage.removeItem(tileImageCacheKey(id, tileImageUsage));
}
/* ---------------------------- */

/* -- Tile Atlas Image Cache -- */

/* ---------------------------- */

export function tileAtlasImageCacheKey(atlasImageFilename) {
  return tileAtlasImageCacheKey + atlasImageFilename;
}
export function saveInTileAtlasImageCache(atlasImageFilename, blob) {
  if (!useCache) return Promise.resolve();
  return localforage.setItem(tileAtlasImageCacheKey(atlasImageFilename), blob);
}
export function getFromTileAtlasImageCache(atlasImageFilename) {
  if (!useCache) return null;
  return localforage.getItem(tileAtlasImageCacheKey(atlasImageFilename));
}
/* ------------ */

/* -- Shared -- */

/* ------------ */

export async function pruneEverythingFromAllCachesExcept(neededTileImagesNotInAtlasses, tileAtlasDataArray, otherNeededKeys, abortSignal) {
  let removedCount = 0;
  if (!useCache) return Promise.resolve(removedCount);
  const tileImagesInAtlasses = new Set(tileAtlasDataArray.map(atlas => Object.keys(atlas.frames)).flat().map(frameId => keyToTileImageIdentification(frameId)).map(tileImageIdentification => tileImageCacheKey(tileImageIdentification.id, tileImageIdentification.tileImageUsage)));
  const currentTileAtlasNames = tileAtlasDataArray.map(atlas => atlas.meta.image);
  const neededCacheKeys = new Set(otherNeededKeys);

  for (const neededTileImage of neededTileImagesNotInAtlasses) {
    const cacheKey = tileImageCacheKey(neededTileImage.id, neededTileImage.tileImageUsage);
    if (tileImagesInAtlasses.has(cacheKey)) continue;
    neededCacheKeys.add(cacheKey);
  }

  for (const neededAtlas of currentTileAtlasNames) {
    neededCacheKeys.add(tileAtlasImageCacheKey(neededAtlas));
  }

  const allCachedKeys = await localforage.keys();
  if (abortSignal.aborted) return Promise.resolve(removedCount);

  for (const cachedKey of allCachedKeys) {
    if (neededCacheKeys.has(cachedKey)) continue;
    removedCount++;
  }

  const clearThreshold = 2000;

  if (removedCount > clearThreshold) {
    console.log(`Completely clearing cache because over ${clearThreshold} entries are obsolete.`);
    removedCount = allCachedKeys.length;
    await localforage.clear();
  } else {
    const promises = Array();

    for (const cachedKey of allCachedKeys) {
      if (neededCacheKeys.has(cachedKey)) continue; //console.log(`[Cache] Removed: ${cachedKey} because it is not needed anymore.`);

      promises.push(localforage.removeItem(cachedKey));
    }

    await Promise.all(promises);
  }

  return removedCount;
}