import _defineProperty from "@babel/runtime/helpers/defineProperty";
export class CombatSounds {}

_defineProperty(CombatSounds, "DEFENSE_ATTEMPT", ["ui_kampf_verteidigung 01", "ui_kampf_verteidigung 02", "ui_kampf_verteidigung 03", "ui_kampf_verteidigung 04", "ui_kampf_verteidigung 05"]);

_defineProperty(CombatSounds, "DEFENSE_ATTEMPT_FAILED", ["ui_kampf_verteidigung_fehlgeschlagen 01", "ui_kampf_verteidigung_fehlgeschlagen 02"]);

_defineProperty(CombatSounds, "DEFENSE_SUCCESS", ["ui_kampf_verteidigung_erfolgreich 01", "ui_kampf_verteidigung_erfolgreich 02"]);

_defineProperty(CombatSounds, "KNIFE_ATTACK_ATTEMPT", ["ui_kampf_angriff_messer 01", "ui_kampf_angriff_messer 02"]);

_defineProperty(CombatSounds, "KNIFE_ATTACK_FAILED_ATTEMPT", ["ui_kampf_angriff_messer_fehlgeschlagen 01", "ui_kampf_angriff_messer_fehlgeschlagen 02"]);

_defineProperty(CombatSounds, "KNIFE_ATTACK_SUCCESS", ["ui_kampf_angriff_messer_treffer 01", "ui_kampf_angriff_messer_treffer 02"]);

_defineProperty(CombatSounds, "BOMB_ATTACK_ATTEMPT", ["ui_kampf_angriff_bombe 01", "ui_kampf_angriff_bombe 02", "ui_kampf_angriff_bombe 03", "ui_kampf_angriff_bombe 04", "ui_kampf_angriff_bombe 05"]);

_defineProperty(CombatSounds, "BOMB_ATTACK_FAILED_ATTEMPT", ["ui_kampf_angriff_bombe_fehlgeschlagen 01", "ui_kampf_angriff_bombe_fehlgeschlagen 02"]);

_defineProperty(CombatSounds, "BOMB_ATTACK_SUCCESS", ["ui_kampf_angriff_bombe_treffer 01", "ui_kampf_angriff_bombe_treffer 02"]);

_defineProperty(CombatSounds, "ROUND_DEFENSE_SHORT", ["ui_kampf_runde_verteidigung_kurz"]);

_defineProperty(CombatSounds, "ROUND_DEFENSE_LONG", ["ui_kampf_runde_verteidigung_lang"]);

_defineProperty(CombatSounds, "ROUND_ATTACK_SHORT", ["ui_kampf_runde_angriff_kurz"]);

_defineProperty(CombatSounds, "ROUND_ATTACK_LONG", ["ui_kampf_runde_angriff_lang"]);

_defineProperty(CombatSounds, "PLAYER_LOST_HEALTH", ["sc_kampf_schaden", "sc_kampf_schaden 01", "sc_kampf_schaden 02"]);

_defineProperty(CombatSounds, "SUCCESS", ["ui_kampf_gewonnen"]);

_defineProperty(CombatSounds, "START", ["ui_kampf_start"]);