import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable, ObservableMap, ObservableSet, runInAction } from "mobx";
import { registerRootStore, unregisterRootStore } from "mobx-keystone";
import { editorClient } from "../communication/EditorClient";
import { ErrorType } from "./editor/ErrorNotification";
import { editorStore } from "./EditorStore";
import { errorStore } from "./ErrorStore";
import { gameStore } from "./GameStore";
import { mapEditorStores } from "./MapEditorStore";
import { undoableMapEditorCloseCurrentMapBecauseOfDeletion } from "./undo/operation/MapEditorCloseCurrentMapOp";
export let MapStatus;

(function (MapStatus) {
  MapStatus[MapStatus["NotRequested"] = 0] = "NotRequested";
  MapStatus[MapStatus["Loading"] = 1] = "Loading";
  MapStatus[MapStatus["Loaded"] = 2] = "Loaded";
  MapStatus[MapStatus["DoesNotExist"] = 3] = "DoesNotExist";
})(MapStatus || (MapStatus = {}));

export class EditorMapStore {
  constructor() {
    _defineProperty(this, "mapList", []);

    _defineProperty(this, "existingMaps", new ObservableSet());

    _defineProperty(this, "maps", new ObservableMap());

    _defineProperty(this, "mapLoadingPromises", new ObservableMap());

    makeAutoObservable(this, {}, {
      autoBind: true
    });
  }

  setMapList(mapList) {
    this.mapList = mapList;
    this.existingMaps.clear();

    for (const map of mapList) {
      this.existingMaps.add(map.id);
    }
  }

  getMapStatus(mapId) {
    if (!this.existingMaps.has(mapId)) return MapStatus.DoesNotExist;
    if (this.maps.has(mapId)) return MapStatus.Loaded;
    if (this.mapLoadingPromises.has(mapId)) return MapStatus.Loading;
    return MapStatus.NotRequested;
  }

  addMap(mapId, mapData) {
    if (this.maps.has(mapId)) throw new Error("Tried to add a map which is already added");
    registerRootStore(mapData);
    this.maps.set(mapId, mapData);
    editorClient.startTrackingMap(mapData, mapId);
  }

  removeMap(mapId) {
    if (!this.maps.has(mapId)) throw new Error("Tried to remove a map which is currently not added");
    const mapData = this.maps.get(mapId);
    unregisterRootStore(mapData);
    this.maps.delete(mapId);
    this.mapLoadingPromises.delete(mapId);
    editorClient.stopTrackingMap(mapId);
  }

  clearMaps() {
    for (const map of this.maps.values()) {
      unregisterRootStore(map);
    }

    this.maps.clear();
    this.mapLoadingPromises.clear();
    this.setMapList([]);
    editorClient.stopTrackingAllMaps();
  }

  setMapLoading(mapId, loadingPromise) {
    if (this.getMapStatus(mapId) !== MapStatus.NotRequested) throw new Error("Can only set MapStatus.Loading if currently MapStatus.NotRequested");
    this.mapLoadingPromises.set(mapId, loadingPromise);
  }

  setMapFailed(mapId) {
    if (this.getMapStatus(mapId) !== MapStatus.Loading) throw new Error("Can only call setMapFailed if currently MapStatus.Loading");
    this.mapLoadingPromises.delete(mapId);
  }

  removeDeletedMap(deletedId, deletedByThisUser) {
    for (const mapEditorStore of mapEditorStores) {
      const {
        currentMapStore
      } = mapEditorStore;

      if (currentMapStore.currentMapId === deletedId) {
        if (!deletedByThisUser) {
          errorStore.addError(ErrorType.General, "editor.error_current_map_deleted");
          undoableMapEditorCloseCurrentMapBecauseOfDeletion(currentMapStore);
        }

        currentMapStore.clearCurrentMap();
      }
    }

    if (gameStore.selectedStartMap === deletedId) {
      gameStore.clearSelectedStartMap();
    }

    this.setMapList(this.mapList.filter(entry => entry.id !== deletedId));

    if (this.maps.has(deletedId)) {
      this.removeMap(deletedId);
    }
  }

  getOrLoadMapWithMetaData(mapId, loadIfNotLoaded = true) {
    const mapStatus = this.getMapStatus(mapId);

    if (loadIfNotLoaded && mapStatus === MapStatus.NotRequested) {
      // HACK tw: If we are calling getOrLoadMapWithMetaData in an observable, we're getting
      // "Since strict-mode is enabled, changing (observed) observable values without
      // using an action is not allowed." (because of the actions that loadMapIfNotLoaded
      // calls). runInAction() removes that problem, although I am not completely sure why.
      runInAction(() => editorClient.loadMapIfNotLoaded(mapId));
    }

    const map = this.maps.get(mapId);
    return {
      map,
      mapStatus,
      mapDoesNotExist: mapStatus === MapStatus.DoesNotExist
    };
  }

  isUserAllowedToEditMapWithId(mapId) {
    return editorStore.isMainGameEditor || this.isMapPartOfTheModule(mapId);
  }

  isMapPartOfTheModule(mapId) {
    var _this$getOrLoadMapWit;

    return ((_this$getOrLoadMapWit = this.getOrLoadMapWithMetaData(mapId).map) === null || _this$getOrLoadMapWit === void 0 ? void 0 : _this$getOrLoadMapWit.moduleOwner) === editorStore.sessionModuleId;
  }

  get mapIdsOfSessionModule() {
    return this.mapList.filter(m => !!m.isOwnedByAModule).map(m => m.id);
  }

  hasMapId(id) {
    return Boolean(this.mapList.find(map => map.id === id));
  }

}
export const editorMapStore = new EditorMapStore();