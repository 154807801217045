import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { TranslatedError } from "../../../../shared/definitions/errors/TranslatedError";
export function undoableMapEditorDynamicMapElementCut(dynamicMapElement, mapEditorStore) {
  executeUndoableOperation(new MapEditorDynamicMapElementCut(dynamicMapElement.$modelId, mapEditorStore));
}

class MapEditorDynamicMapElementCut extends UndoableOperation {
  constructor(dynamicMapElementModelId, mapEditorStore) {
    super("mapEditorDynamicMapElementCut");
    this.dynamicMapElementModelId = dynamicMapElementModelId;
    this.mapEditorStore = mapEditorStore;

    _defineProperty(this, "previousCutDynamicMapElementModelId", void 0);

    this.previousCutDynamicMapElementModelId = this.mapEditorStore.cutDynamicMapElementModelId;
  }

  async execute() {
    this.setCut(this.dynamicMapElementModelId);
  }

  async reverse() {
    this.setCut(this.previousCutDynamicMapElementModelId);
  }

  setCut(dynamicMapElementModelId) {
    var _this$mapEditorStore$;

    const dynamicMapElement = (_this$mapEditorStore$ = this.mapEditorStore.currentMapStore.currentMap) === null || _this$mapEditorStore$ === void 0 ? void 0 : _this$mapEditorStore$.getDynamicMapElementByModelId(dynamicMapElementModelId);
    if (!dynamicMapElement) throw new TranslatedError("editor.error_dynamic_map_element_does_not_exist");
    this.mapEditorStore.setCutDynamicMapElementModelId(dynamicMapElementModelId);
  }

}