import { UndoableOperation } from "../UndoableOperation";
import { editorClient } from "../../../communication/EditorClient";
import { fromSnapshot } from "mobx-keystone";
import { executeUndoableOperation } from "../UndoStore";
import { sharedStore } from "../../SharedStore";
export function undoableCharacterEditorDeleteCharacter(characterConfigId, store) {
  executeUndoableOperation(new CharacterEditorDeletionOp(characterConfigId, store));
}

class CharacterEditorDeletionOp extends UndoableOperation {
  constructor(characterConfigId, store) {
    super("characterEditorDeleteCharacter");
    this.characterConfigId = characterConfigId;
    this.store = store;
  }

  async execute() {
    await editorClient.deleteCharacterConfiguration(this.characterConfigId);
    this.store.setSelectedCharacter(null);
  }

  async reverse() {
    const snapshot = await editorClient.unDeleteCharacterConfiguration(this.characterConfigId);
    const characterConfiguration = fromSnapshot(snapshot);
    sharedStore.putCharacter(characterConfiguration);
    this.store.setSelectedCharacter(characterConfiguration);
  }

}