var _dec, _class;

import { model, Model, prop } from "mobx-keystone";
import { PixelPositionModel } from "./PixelPositionModel";
export let ImagePropertiesModel = (_dec = model("resources/AssetModel"), _dec(_class = class ImagePropertiesModel extends Model({
  size: prop(),
  positionOnTile: prop(() => new PixelPositionModel({})),
  frames: prop(1).withSetter(),
  animationDuration: prop(1).withSetter()
}) {
  frameWidth() {
    return this.frames > 1 ? Math.floor(this.size.width / this.frames) : this.size.width;
  }

}) || _class);