import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { navigateTo } from "../../../helper/navigationHelpers";
export function undoableSwitchMode(to, history) {
  if (history.location.pathname === to) return;
  executeUndoableOperation(new SwitchModeOp(to, history));
}

class SwitchModeOp extends UndoableOperation {
  constructor(to, history) {
    super("switchMode" + to);
    this.to = to;
    this.history = history;

    _defineProperty(this, "previousUrl", void 0);

    this.previousUrl = this.history.location.pathname;
  }

  async execute() {
    navigateTo(this.history, this.to);
  }

  async reverse() {
    navigateTo(this.history, this.previousUrl);
  }

}