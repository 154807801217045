import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Skin } from '@pixi-spine/all-4.1';
export class AnimationSkinCombinator {
  constructor(skinCacheParameter) {
    _defineProperty(this, "skins", Array());

    _defineProperty(this, "skinCache", void 0);

    this.skinCache = skinCacheParameter ? skinCacheParameter : new Array();
  }

  findInCache(skinName) {
    return this.skinCache.find(skin => skin.name == skinName);
  }

  add(skinName) {
    if (this.contains(skinName)) return;
    const skin = this.findInCache(skinName);
    if (skin) this.skins.push(skin);
  }

  addAll(skinNames) {
    if (skinNames) skinNames.forEach(name => this.add(name));
  }

  remove(skinName) {
    this.skins = this.skins.filter(skin => skin.name != skinName);
  }

  contains(skinName) {
    return this.skins.some(skin => skin.name == skinName);
  }

  create(name) {
    const combinedSkin = new Skin(name);
    this.skins.forEach(skin => combinedSkin.addSkin(skin));
    return combinedSkin;
  }

  applyTo(skeleton, name = "combined-skin") {
    if (!skeleton) return;
    skeleton.setSkin(this.create(name));
    skeleton.setSlotsToSetupPose();
  }

}