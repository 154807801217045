import localforage from "localforage";
import { animationCacheKeyPrefix } from "./sharedCacheData";
export function animationCacheKey(id) {
  return animationCacheKeyPrefix + id;
}
export function saveInAnimationCache(id, data) {
  return localforage.setItem(animationCacheKey(id), data);
}
export async function loadFromAnimationCache(id) {
  return await localforage.getItem(animationCacheKey(id));
}
export function removeFromAnimationCache(id) {
  return localforage.removeItem(animationCacheKey(id));
}