import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { destroyDisplayObject } from "../../../helper/pixiHelpers";
import { Pool } from "../../../helper/Pool";
import { resolvePotentialMapElementTreeParameter } from "../../../helper/treeParameterHelpers";
import { gameStore } from "../../../stores/GameStore";
import { DamageOnTileVisual } from "./DamageOnTileVisual";
export class DamageInAreaVisualManager {
  constructor(addToContainer, getAreaTriggerPositions) {
    this.getAreaTriggerPositions = getAreaTriggerPositions;

    _defineProperty(this, "inUse", new Map());

    _defineProperty(this, "pool", void 0);

    this.pool = new Pool(() => new DamageOnTileVisual(), destroyDisplayObject, addToContainer, object => {
      var _object$parent;

      return (_object$parent = object.parent) === null || _object$parent === void 0 ? void 0 : _object$parent.removeChild(object);
    });
  }

  update(elapsedS) {
    for (const visuals of this.inUse.values()) {
      for (const visual of visuals) {
        visual.update(elapsedS);
      }
    }
  }

  startCountdown(action, delay, delayLeft) {
    const visuals = this.getVisualsForAction(action, true);

    for (const visual of visuals) {
      visual.showCountdownIndicator(delay, delayLeft);
    }
  }

  startTriggering(action) {
    const visuals = this.getVisualsForAction(action, true);

    for (const visual of visuals) {
      visual.showTriggeringIndicator();
    }
  }

  finish(action, zeroDuration) {
    const visuals = this.getVisualsForAction(action, false);

    if (visuals) {
      this.inUse.delete(action);

      for (const visual of visuals) {
        this.pool.free(visual);
      }
    }
  }

  finishAll() {
    for (const visuals of this.inUse.values()) {
      for (const visual of visuals) {
        this.pool.free(visual);
      }
    }

    this.inUse.clear();
  }

  destroy() {
    this.inUse = null;
    this.pool.destroyCurrentlyInUseObjects();
    this.pool.destroyFreedObjects();
    this.pool = null;
  }

  getVisualsForAction(action, createIfEmpty) {
    let visuals = this.inUse.get(action);

    if (!visuals && createIfEmpty) {
      visuals = [];
      this.inUse.set(action, visuals);
      const {
        rootActionTree,
        gameState
      } = gameStore.gameEngine;
      const mapElement = resolvePotentialMapElementTreeParameter(action.mapElement, "actions/AreaTriggerValueModel", action);

      if (mapElement.mapId === gameState.currentMap) {
        const triggerId = mapElement.elementId;
        const positions = this.getAreaTriggerPositions(triggerId);

        for (const position of positions) {
          const visual = this.pool.getOrCreate();
          visual.tilePosition = position;
          visuals.push(visual);
        }
      }
    }

    return visuals;
  }

}