import { Gender, genders } from "../../shared/definitions/other/Gender";
import { arrayToCSVLine, collectTranslatableEntityData } from "../../shared/translation/translationHelpers";
import { combatStore } from "../stores/CombatStore";
import { itemStore } from "../stores/ItemStore";
import { sharedStore } from "../stores/SharedStore";
import Papa from "papaparse";
import { editorStore } from "../stores/EditorStore";
import { translationStore } from "../stores/TranslationStore";
export function clientCollectTranslatableEntityData(sourceLanguage) {
  if (editorStore.sessionModule) {
    const moduleActionSubtree = sharedStore.modulesRootActionTrees.find(t => t.$modelId === editorStore.sessionModule.actiontreeId);
    return collectTranslatableEntityData([moduleActionSubtree], Array.from(sharedStore.characterConfigurations.values()).filter(characterConfiguration => characterConfiguration.moduleOwner), itemStore.getAllItems.filter(item => item.moduleOwner), [], [], [], null, sourceLanguage);
  } else {
    return collectTranslatableEntityData([sharedStore.mainGameRootActionTree, ...sharedStore.actionTreeTemplates], Array.from(sharedStore.characterConfigurations.values()), itemStore.getAllItems, combatStore.config.enemyCombatPresets, Array.from(sharedStore.tileAssets.values()), Array.from(sharedStore.animationAssets.values()), translationStore.makeshiftTranslationSystemData, sourceLanguage);
  }
}
export function translatableEntityDataArrayToCSV(entities, sourceLanguage, targetLanguage, csvSeparator) {
  const lines = new Array();
  lines.push(`Element${csvSeparator}Text Type${csvSeparator}${genders.map(gender => `${sourceLanguage}${gender}`).join(csvSeparator)}${csvSeparator}${genders.map(gender => `${targetLanguage}${gender}`).join(csvSeparator)}${csvSeparator}Comment;ID`);
  const contentMap = new Map();

  for (const entity of entities) {
    for (const translatableString of entity.translateableStrings) {
      const resultParts = new Array();
      const translateableStringModelId = translatableString.translatedString.$modelId;
      resultParts.push(entity.label);
      resultParts.push(translatableString.label);

      for (const gender of genders) {
        resultParts.push(translatableString.translatedString.getForGender(sourceLanguage, gender, false));
      }

      for (const gender of genders) {
        resultParts.push(translatableString.translatedString.getForGender(targetLanguage, gender, false));
      }

      resultParts.push(translatableString.translatedString.comment);
      resultParts.push(translateableStringModelId);
      const contentForComparison = arrayToCSVLine(resultParts.slice(0, -1), csvSeparator);

      if (contentMap.has(contentForComparison)) {
        const duplicateResultParts = contentMap.get(contentForComparison);
        duplicateResultParts[duplicateResultParts.length - 1] += "," + translateableStringModelId;
      } else {
        contentMap.set(contentForComparison, resultParts);
      }
    }
  }

  for (const resultParts of contentMap.values()) {
    lines.push(arrayToCSVLine(resultParts, csvSeparator));
  }

  return lines.join('\r\n');
}
export let TranslationAction;

(function (TranslationAction) {
  TranslationAction["Discard"] = "Discard";
  TranslationAction["NotSet"] = "NotSet";
  TranslationAction["Commit"] = "Commit";
})(TranslationAction || (TranslationAction = {}));

export const translationActions = Object.values(TranslationAction);
export function populateTranslationData(filename, importCSV) {
  const {
    data: lines,
    errors
  } = Papa.parse(importCSV);
  if (errors.length > 0) throw new Error("Couldn't parse file:\n" + errors.map(error => `${error.row}: ${error.message}`).join("\n"));
  let nextLine = 0;

  while (nextLine < lines.length && lines[nextLine][0] !== "Element") {
    nextLine++;
  }

  if (nextLine === lines.length) throw Error("Couldn't find title line");
  const titleLine = lines[nextLine++];
  const sourceLanguageStart = 2;
  const targetLanguageStart = sourceLanguageStart + genders.length;
  const sourceLanguageKey = titleLine[sourceLanguageStart];
  const targetLanguageKey = titleLine[targetLanguageStart];
  const commentIndex = targetLanguageStart + genders.length;
  const translatableStringIdsIndex = commentIndex + 1;
  const translations = new Array();
  const translationsForRemovedElements = new Array();
  const currentTranslatableEntities = clientCollectTranslatableEntityData(sourceLanguageKey);
  const translatedStringsById = new Map();
  const entitiesByTranslatedStringId = new Map();

  for (const entity of currentTranslatableEntities) {
    for (const {
      translatedString
    } of entity.translateableStrings) {
      translatedStringsById.set(translatedString.$modelId, translatedString);
      entitiesByTranslatedStringId.set(translatedString.$modelId, entity.entity);
    }
  }

  const linesByTranslatedStringModelId = new Map();

  while (nextLine < lines.length) {
    const currentLine = lines[nextLine++];
    if (currentLine.length <= 1) continue;

    if (currentLine.length <= translatableStringIdsIndex) {
      console.log("Cannot read line; skipping", {
        currentLine
      });
      continue;
    }

    const translatedStringModelIds = currentLine[translatableStringIdsIndex].split(",");

    for (const modelId of translatedStringModelIds) {
      linesByTranslatedStringModelId.set(modelId, currentLine);
    }
  }

  for (const [translatedStringModelId, currentLine] of linesByTranslatedStringModelId) {
    let elementIndex = 0;
    const entityLabel = currentLine[elementIndex++];
    const translatableStringLabel = currentLine[elementIndex++];
    const entity = entitiesByTranslatedStringId.get(translatedStringModelId);
    const translatedString = translatedStringsById.get(translatedStringModelId);
    const stillExists = Boolean(translatedString);
    const translationsByGender = new Map();
    const importStringsSourceLanguage = new Map(genders.map(gender => [gender, currentLine[elementIndex++]]));
    const importStringsTargetLanguage = new Map(genders.map(gender => [gender, currentLine[elementIndex++]]));

    for (const gender of genders) {
      let currentSourceLanguageString = (translatedString === null || translatedString === void 0 ? void 0 : translatedString.getForGender(sourceLanguageKey, gender, false)) || "";
      const currentTargetLanguageString = (translatedString === null || translatedString === void 0 ? void 0 : translatedString.getForGender(targetLanguageKey, gender, false)) || "";
      let importSourceLanguageString = importStringsSourceLanguage.get(gender);
      const importTargetLanguageString = importStringsTargetLanguage.get(gender);
      let sourceReplacedFromNeutral = false;

      if (gender !== Gender.Neutral && !currentSourceLanguageString && !importSourceLanguageString) {
        const translationDataForNeutral = translationsByGender.get(Gender.Neutral);
        currentSourceLanguageString = translationDataForNeutral.currentSourceLanguageString;
        importSourceLanguageString = translationDataForNeutral.importSourceLanguageString;
        sourceReplacedFromNeutral = true;
      }

      const sourceLanguageStringChanged = currentSourceLanguageString !== importSourceLanguageString;
      const targetLanguageStringChanged = currentTargetLanguageString !== importTargetLanguageString;
      const targetLanguageConflict = targetLanguageStringChanged && Boolean(currentTargetLanguageString);
      let action;

      if (!targetLanguageStringChanged) {
        action = TranslationAction.Discard;
      } else if (sourceLanguageStringChanged || targetLanguageConflict) {
        action = TranslationAction.NotSet;
      } else {
        action = TranslationAction.Commit;
      }

      translationsByGender.set(gender, {
        currentSourceLanguageString,
        currentTargetLanguageString,
        importSourceLanguageString,
        importTargetLanguageString,
        sourceLanguageStringChanged,
        targetLanguageStringChanged,
        action,
        sourceReplacedFromNeutral
      });
    }

    const translation = {
      translatedStringModelId,
      entityLabel,
      translatableStringLabel,
      stillExists,
      translationsByGender,
      translatedString,
      entity
    };

    if (stillExists) {
      translations.push(translation);
    } else {
      translationsForRemovedElements.push(translation);
    }
  }

  return {
    filename,
    importCSV,
    sourceLanguageKey,
    targetLanguageKey,
    translations,
    translationsForRemovedElements
  };
}