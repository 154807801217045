import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { actionEditorStore } from "../../ActionEditorStore";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableActionEditorSelectCategory(category) {
  actionEditorStore.clearClickActions();
  if (actionEditorStore.currentCategory === category) return;
  executeUndoableOperation(new ActionEditorSelectCategoryOp(category));
}

class ActionEditorSelectCategoryOp extends UndoableOperation {
  constructor(category) {
    super("actionEditorSelectCategory");
    this.category = category;

    _defineProperty(this, "previousCategory", void 0);

    this.previousCategory = actionEditorStore.currentCategory;
  }

  async execute() {
    actionEditorStore.clearClickActions();
    actionEditorStore.setSelectedCategory(this.category);
  }

  async reverse() {
    actionEditorStore.clearClickActions();
    actionEditorStore.setSelectedCategory(this.previousCategory);
  }

}