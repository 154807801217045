import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { actionEditorStore } from "../../ActionEditorStore";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { actionTreeSetSelection, getCurrentlySelectedHierarchyIds, getCurrentReactFlowInstanceTransform, selectHierarchyFromIds } from "./actionEditorSupport";
export function undoableActionEditorSelectActionTreeHierachy(actionTreeHierarchy) {
  actionEditorStore.clearClickActions();
  if (getCurrentlySelectedHierarchyIds() == actionTreeHierarchy) return;
  executeUndoableOperation(new ActionEditorSelectActionTreeHierachyOp(actionTreeHierarchy));
}

class ActionEditorSelectActionTreeHierachyOp extends UndoableOperation {
  constructor(actionTreeHierarchy) {
    var _actionEditorStore$cu;

    super("actionTreeHierarchySelectAction");
    this.actionTreeHierarchy = actionTreeHierarchy;

    _defineProperty(this, "previousActionTreeHierarchy", void 0);

    _defineProperty(this, "previousActionModelId", void 0);

    _defineProperty(this, "previousTransform", void 0);

    this.previousActionTreeHierarchy = getCurrentlySelectedHierarchyIds();
    this.previousActionModelId = (_actionEditorStore$cu = actionEditorStore.currentAction) === null || _actionEditorStore$cu === void 0 ? void 0 : _actionEditorStore$cu.$modelId;
    this.previousTransform = getCurrentReactFlowInstanceTransform();
  }

  async execute() {
    actionEditorStore.clearClickActions();
    actionEditorStore.deselectSelectedAction();
    selectHierarchyFromIds(this.actionTreeHierarchy);
  }

  async reverse() {
    actionEditorStore.clearClickActions();
    actionTreeSetSelection(this.previousActionModelId, this.previousActionTreeHierarchy, this.previousTransform);
  }

}