import { Gender } from "../definitions/other/Gender";
import { isBlank, replaceAll } from "../helper/generalHelpers";
// https://stackoverflow.com/a/68146412
export function arrayToCSVLine(rowData, csvSeparator) {
  return rowData.map(v => replaceAll(v, '"', '""')) // escape double colons
  .map(v => `"${v}"`) // quote it
  .join(csvSeparator);
}
export function collectTranslatableEntityData(actionTrees, characters, items, enemyCombatPresets, tileAssets, animationAssets, makeshiftTranslationSystemData, languageKeyForEmptyCheck) {
  let result = new Array();

  if (actionTrees) {
    for (const actionTree of actionTrees) {
      addActionTree(actionTree, result);
    }
  }

  characters.forEach(character => result.push(character.translatableEntityData()));
  items.forEach(item => result.push(item.translatableEntityData()));
  enemyCombatPresets.forEach(enemyCombatPreset => result.push(enemyCombatPreset.translatableEntityData()));
  tileAssets.forEach(tileAsset => result.push(tileAsset.translatableEntityData()));
  animationAssets.forEach(animationAsset => result.push(animationAsset.translatableEntityData()));
  makeshiftTranslationSystemData === null || makeshiftTranslationSystemData === void 0 ? void 0 : makeshiftTranslationSystemData.translatableEntityDataArray().forEach(translateableEntityData => result.push(translateableEntityData));
  result.forEach(entityData => removeEmptyTranslatableStrings(entityData, languageKeyForEmptyCheck));
  result = result.filter(isNotEmptyTranslatableEntity);
  return result;
}

function addActionTree(root, result) {
  var _root$treePropertiesA;

  if ((_root$treePropertiesA = root.treePropertiesAction) !== null && _root$treePropertiesA !== void 0 && _root$treePropertiesA.excludeFromTranslations) return;

  for (const action of root.nonSubTreeActions) {
    result.push(action.translatableEntityData());
  }

  for (const subTree of root.subtreeActions) {
    addActionTree(subTree, result);
  }
}

function removeEmptyTranslatableStrings(entityData, languageKeyForEmptyCheck) {
  if (!entityData) return;
  entityData.translateableStrings = entityData.translateableStrings.filter(translateableString => !isBlank(translateableString.translatedString.getForGender(languageKeyForEmptyCheck, Gender.Neutral, false)) || !isBlank(translateableString.translatedString.getForGender(languageKeyForEmptyCheck, Gender.Female, false)) || !isBlank(translateableString.translatedString.getForGender(languageKeyForEmptyCheck, Gender.Male, false)));
}

function isNotEmptyTranslatableEntity(translatableText) {
  return translatableText && translatableText.translateableStrings.length > 0;
}