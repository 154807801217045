import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { wait } from "../../../shared/helper/generalHelpers";
import { animationLoader } from "../../helper/AnimationLoader";
import { TokenGate } from "../../helper/asyncHelpers";
import { makeCharacterConfigurationThumbnailVersion } from "../../helper/characterConfigurationHelpers";
import { editorStore } from "../../stores/EditorStore";
import { sharedStore } from "../../stores/SharedStore";
import { createBackgroundRenderer, getOrCreateBackgroundRenderer } from "./BackgroundRenderer";
const maxSimultaneousRendering = 1;
export class BackgroundRendererManager {
  constructor() {
    _defineProperty(this, "tokenGate", new TokenGate(maxSimultaneousRendering));
  }

  async renderAnimation(animationName, abortSignal) {
    return this.render(async () => ({
      displayObject: await animationLoader.getSpineFromAnimationName(animationName),
      version: null
    }), abortSignal);
  }

  async renderCharacter(id, abortSignal) {
    return this.render(async () => {
      const characterConfiguration = sharedStore.characterConfigurations.get(id);
      const spine = await animationLoader.loadCharacterAnimation(characterConfiguration);
      const animationName = "idleEast";

      if (spine.state.hasAnimation(animationName)) {
        spine.state.setAnimation(0, animationName, true);
      }

      spine.update(1);
      return {
        displayObject: spine,
        version: makeCharacterConfigurationThumbnailVersion(characterConfiguration)
      };
    }, abortSignal);
  }

  async render(generateDisplayObject, abortSignal) {
    // If not connected, silently fail (by returning an empty result)
    if (!editorStore.isConnected || abortSignal.aborted) return null;
    return this.tokenGate.executeWhenTokenIsFree(async () => {
      // If not connected, silently fail (by returning an empty result)
      if (!editorStore.isConnected || abortSignal.aborted) return null; // Wait a very short time to make sure that this queue isn't triggering again and again in case something in
      // generateDisplayObject errors.

      await wait(1);
      const {
        displayObject,
        version
      } = await generateDisplayObject();

      try {
        if (abortSignal.aborted) return null;
        createBackgroundRenderer();
        const imageBlob = await getOrCreateBackgroundRenderer().render(displayObject);
        if (abortSignal.aborted) return null;
        return {
          imageBlob,
          version
        };
      } finally {
        displayObject.destroy();
      }
    });
  }

}
export const backgroundRendererManager = new BackgroundRendererManager();