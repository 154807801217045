import { getTreeParent } from "../../shared/action/ActionTreeModel";
import { AnimationElementReferenceModel, MapElementReferenceModel } from "../../shared/action/MapElementReferenceModel";
import { SoundValueModel } from "../../shared/action/ValueModel";
import { parseFormattedTreeParameter } from "../../shared/helper/actionTreeHelper";

/**
 * @param value The value given from the action tree node
 * @param actionsValueModel The value model of the given map element, ex. "actions/MapMarkerValueModel"
 * @param treeScopeContext ActionModel in tree we want to look for the actual value in
 * @returns the "real" MapElementReferenceModel that the tree parameter was referencing
 */
export function resolvePotentialMapElementTreeParameter(value, actionsValueModel, treeScopeContext) {
  var _tree$treeParameterAc;

  const parameterName = parseFormattedTreeParameter(value.elementId);

  if (!parameterName) {
    // not a parameter
    return value;
  }

  const tree = getTreeParent(treeScopeContext);
  const parameter = tree === null || tree === void 0 ? void 0 : (_tree$treeParameterAc = tree.treeParameterActions(actionsValueModel)) === null || _tree$treeParameterAc === void 0 ? void 0 : _tree$treeParameterAc.find(p => p.name === parameterName);

  if (!parameter) {
    // parameter not found
    if (value instanceof MapElementReferenceModel) {
      return new MapElementReferenceModel({});
    } else if (value instanceof AnimationElementReferenceModel) {
      return new AnimationElementReferenceModel({});
    } else {
      var _constructor;

      throw new Error("Not implemented: " + ((_constructor = value.constructor) === null || _constructor === void 0 ? void 0 : _constructor.name));
    }
  }

  return resolvePotentialMapElementTreeParameter(parameter.value.value, actionsValueModel, tree); // parameters can be set to parameters of the parent tree
}
export function findTreeParameterFor(action, parameterValueModelType) {
  if (!action) return [];
  const parentTree = getTreeParent(action);
  return parentTree.treeParameterActions(parameterValueModelType);
}
export function mapSoundValuesToTreeParameterNames(treeParameters) {
  return treeParameters.map(param => {
    if (param && param.value && param.value instanceof SoundValueModel) {
      return param.name;
    }

    return null;
  }).filter(element => element != null);
}
export function findSoundPathByTreeParameterName(searchStart, treeParameterName) {
  if (searchStart == null || treeParameterName == null) return null;
  const localParameters = findTreeParameterFor(searchStart, "actions/SoundValueModel");

  for (let i = 0; i < localParameters.length; i++) {
    const param = localParameters[i];

    if (param && param.value && param.value instanceof SoundValueModel) {
      const soundValue = param.value;

      if (param.name == treeParameterName) {
        if (soundValue.value) {
          return soundValue.value;
        } else {
          const nextStart = getTreeParent(searchStart);
          return findSoundPathByTreeParameterName(nextStart, soundValue.treeParameter);
        }
      }
    }
  }

  return null;
}
/**
 * @param value The value given from the action tree node
 * @param treeScopeContext ActionModel in tree we want to look for the actual value in
 * @returns the "real" MapElementReferenceModel that the tree parameter was referencing
 */

export function resolvePotentialNPCTreeParameter(value, treeScopeContext, rootActionTree) {
  var _tree$treeParameterAc2;

  const parameterName = parseFormattedTreeParameter(value.npcId);

  if (!parameterName) {
    return value; // not a parameter
  }

  const tree = getTreeParent(treeScopeContext);
  const parameter = tree === null || tree === void 0 ? void 0 : (_tree$treeParameterAc2 = tree.treeParameterActions("actions/NPCValueModel")) === null || _tree$treeParameterAc2 === void 0 ? void 0 : _tree$treeParameterAc2.find(p => p.name === parameterName);
  if (!parameter) return null; // parameter not found

  return resolvePotentialNPCTreeParameter(parameter.value.value, tree, rootActionTree); // parameters can be set to parameters of the parent tree
}