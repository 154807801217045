import _defineProperty from "@babel/runtime/helpers/defineProperty";
let runningId = 0;
export class LogEntry {
  constructor() {
    _defineProperty(this, "id", runningId++);

    _defineProperty(this, "time", void 0);

    _defineProperty(this, "useSeparator", void 0);

    _defineProperty(this, "executionType", void 0);

    _defineProperty(this, "executionEntity", void 0);

    _defineProperty(this, "executionEntityId", void 0);

    _defineProperty(this, "executionSourceEntity", void 0);

    _defineProperty(this, "executionSourceId", void 0);

    _defineProperty(this, "isWarning", false);

    this.time = Intl.DateTimeFormat("en-US", {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(new Date());
  }

  static byExecutedAction(target, source, targetActivated) {
    const logEntry = new LogEntry();
    logEntry.executionType = targetActivated ?
    /*t*/
    "game.debug_log_executed_action" :
    /*t*/
    "game.debug_log_deactivated_action_not_executed";
    logEntry.executionEntity = target.title();
    logEntry.executionEntityId = target.$modelId;

    if (source) {
      logEntry.executionSourceEntity = source.title();
      logEntry.executionSourceId = source.$modelId;
    }

    return logEntry;
  }

  static byDeactivatedSourceNode(source) {
    const logEntry = new LogEntry();
    logEntry.executionType =
    /*t*/
    "game.debug_log_action_after_deactivated_action_not_executed";
    logEntry.executionEntity = source.title();
    logEntry.executionEntityId = source.$modelId;
    return logEntry;
  }

  static byStartedGame() {
    const logEntry = new LogEntry();
    logEntry.executionType = "game.debug_log_game_started";
    logEntry.useSeparator = true;
    return logEntry;
  }

  static byLoadedMap(map) {
    const logEntry = new LogEntry();
    logEntry.executionType = "game.debug_log_map_loaded";
    logEntry.executionEntity = map.properties.name;
    return logEntry;
  }

  static byDialogAnswer(index) {
    const logEntry = new LogEntry();
    logEntry.executionType = "game.debug_log_dialog_option_chosen";
    logEntry.executionEntity = index + "";
    return logEntry;
  }

  static byCameraTargetReached(action) {
    return LogEntry.byExecutionType("game.debug_log_camera_target_reached", action);
  }

  static byCameraReturned(action) {
    return LogEntry.byExecutionType("game.debug_log_camera_returned", action);
  }

  static byCameraShaked(action) {
    return LogEntry.byExecutionType("game.debug_log_camera_shaked", action);
  }

  static byCameraOverlayFaded(action, fadedIn) {
    return LogEntry.byExecutionType(fadedIn ? "game.debug_log_camera_overlay_faded_in" : "game.debug_log_camera_overlay_faded_out", action);
  }

  static byExecutionType(executionType, action, isWarning = false) {
    const logEntry = new LogEntry();
    logEntry.executionType = executionType;
    logEntry.executionEntity = action.title();
    logEntry.executionEntityId = action.$modelId;
    logEntry.isWarning = isWarning;
    return logEntry;
  }

  static warnSameActionAlreadyRunning(action) {
    return LogEntry.byExecutionType("game.debug_log_already_running_action", action, true);
  }

  static warnSoundNotFound(action) {
    return LogEntry.byExecutionType("game.debug_log_sound_not_found_action", action, true);
  }

  static warnCameraIsInUse(action) {
    return LogEntry.byExecutionType("game.debug_log_camera_is_in_use", action, true);
  }

  toString(t) {
    let result = this.time + " | " + t(this.executionType);
    if (this.executionEntity) result += " | " + t(this.executionEntity);
    if (this.executionEntityId) result += " | " + this.executionEntityId;
    if (this.executionSourceEntity) result += " | triggered by " + t(this.executionSourceEntity) + " (" + this.executionSourceId + ")";
    return result;
  }

}