export function getURLParameterString(name, defaultValue) {
  const query = new URLSearchParams(location.search);
  return query.has(name) ? query.get(name) : defaultValue;
}
export function getURLParameterNumber(name, defaultValue) {
  const query = new URLSearchParams(location.search);
  return query.has(name) ? +query.get(name) : defaultValue;
}
export function hasURLParameter(name) {
  const query = new URLSearchParams(location.search);
  return query.has(name);
}
export function stringifyMapReplacer(_key, value) {
  if (value instanceof Map) {
    return Object.fromEntries(value);
  } else {
    return value;
  }
}
export function throttledDelayedCall(executor, callDelay) {
  let previousTimeout;

  const call = () => {
    if (previousTimeout) {
      clearTimeout(previousTimeout);
    }

    previousTimeout = setTimeout(() => {
      previousTimeout = null;
      executor();
    }, callDelay);
  };

  return call;
}