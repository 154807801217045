import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Application, Container } from "pixi.js";
import { dataConstants } from "../../../shared/data/dataConstants";
import { calcScaleToFit } from "../../helper/pixiHelpers";
import { ReactionDisposerGroup } from "../../helper/ReactionDisposerGroup";
const debugLifecycle = false;
const {
  thumbnailSize
} = dataConstants; //const thumbnailRectangle = new Rectangle(0, 0, thumbnailSize.width, thumbnailSize.height);

export class BackgroundRenderer {
  //private appReference: ApplicationReference;
  //private renderViaStageGate = new TokenGate(1);
  //private wasDisposed = false;
  constructor() {
    _defineProperty(this, "debugName", void 0);

    _defineProperty(this, "creationTime", void 0);

    _defineProperty(this, "app", void 0);

    _defineProperty(this, "isAttached", void 0);

    _defineProperty(this, "reactionDisposers", new ReactionDisposerGroup());

    this.debugName = "BackgroundRenderer";
    this.creationTime = Date.now().toString();
    this.lifecycleDebugOutput("created");
    this.app = new Application(_objectSpread({
      backgroundAlpha: 0
    }, thumbnailSize));
    this.app.stop(); //this.appReference = new ApplicationReference(this.app);
  }

  attach(parent) {
    if (this.app.view.parentElement === parent) return;
    this.isAttached = true;
    this.lifecycleDebugOutput(`attaching to ${parent}`);
    parent.appendChild(this.app.view);
  }

  detach() {
    if (!this.isAttached) return;
    this.isAttached = false;
    this.lifecycleDebugOutput("detaching");
    this.app.view.remove();
  }

  dispose() {
    this.lifecycleDebugOutput("disposing...");
    this.detach();
    this.app.destroy(true, {
      children: true,
      texture: false,
      baseTexture: false
    });
    this.reactionDisposers.disposeAll(); //this.wasDisposed = true;
    //this.appReference.invalidate();

    this.app = null;
    this.lifecycleDebugOutput("disposed");
  }

  get parentElement() {
    return this.app.view.parentElement;
  }

  lifecycleDebugOutput(message) {
    if (debugLifecycle) console.log("[" + this.debugName + " #" + this.creationTime + "] " + message);
  }
  /**
   * Renders a display object to a blob:
   * - Only renders the displayObject
   * - Ignores transform (position, rotating, scale etc.) of displayObject
   * - Output size is always the bounds of the displayObject rendered
   * - Always creates a separate canvas
   * - Seems to have the same performance/memory usage as renderStageToBlob()
   */


  renderDisplayObjectToBlob(displayObject) {
    return new Promise((resolve, reject) => {
      try {
        const extract = this.app.renderer.plugins.extract;
        extract.canvas(displayObject).toBlob(resolve);
      } catch (e) {
        reject(e);
      }
    });
  }
  /**
   * Renders the stage to a blob:
   * - Uses the already created canvas
   * - Output size is always the size of the renderer
   * - Seems to have the same performance/memory usage as renderDisplayObjectToBlob()
   */


  renderStageToBlob() {
    return new Promise((resolve, reject) => {
      try {
        this.app.renderer.once("postrender", () => {
          try {
            this.app.view.toBlob(resolve);
          } catch (e) {
            reject(e);
          }
        });
        this.app.render();
      } catch (e) {
        reject(e);
      }
    });
  }

  async render(displayObject) {
    const container = new Container();
    container.addChild(displayObject);
    const bounds = displayObject.getLocalBounds();
    const scaleFactor = calcScaleToFit(bounds, thumbnailSize.width, thumbnailSize.height);
    container.scale.set(scaleFactor, scaleFactor);
    container.position.x = (-bounds.x - bounds.width / 2) * scaleFactor + thumbnailSize.width / 2;
    container.position.y = (-bounds.y - bounds.height / 2) * scaleFactor + thumbnailSize.height / 2;
    const outerContainer = new Container();
    outerContainer.addChild(container);
    const blob = await this.renderDisplayObjectToBlob(outerContainer); // If renderStageToBlob() is used, ensure that the stage doesn't change before it is finished.

    /*
    const blob = await this.renderViaStageGate.executeWhenTokenIsFree(async () => {
        this.app.stage.addChild(outerContainer);
        const blob = await this.renderStageToBlob();
        this.app.stage.removeChild(outerContainer);
        return blob;
    });
    */

    displayObject.parent = null;
    return blob;
  }

}
let backgroundRenderer;
export function createBackgroundRenderer() {
  if (backgroundRenderer) return;
  backgroundRenderer = new BackgroundRenderer();
  /*
  const div = document.createElement("div");
  div.style.position = "fixed";
  div.style.zIndex = "100000";
  backgroundRenderer.attach(div);
  document.body.appendChild(div);
  */
}
export function getOrCreateBackgroundRenderer() {
  createBackgroundRenderer();
  return backgroundRenderer;
}
export function disposeBackgroundRenderer() {
  if (backgroundRenderer) {
    backgroundRenderer.dispose();
    backgroundRenderer = null;
  }
}

if (module.hot) {
  const {
    data
  } = module.hot;

  if (data && data.parent) {
    createBackgroundRenderer();
    backgroundRenderer.attach(data.parent);
  }

  module.hot.dispose(data => {
    if (backgroundRenderer) {
      data.parent = backgroundRenderer.parentElement;
      disposeBackgroundRenderer();
    }
  });
}