import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Container, Text, TextStyle } from "pixi.js";
import { CombatPhaseLength } from "../../../../shared/combat/CombatPhaseLength";
import { staticAssetLoader } from "../../loader/StaticAssetLoader";
import { CombatPhase } from "../../../stores/CombatStore";
import i18n from "../../../integration/i18n";
export class PhaseTransitionView extends Container {
  constructor() {
    super();

    _defineProperty(this, "start", void 0);

    _defineProperty(this, "won", void 0);

    _defineProperty(this, "lost", void 0);

    _defineProperty(this, "longAttackText", void 0);

    _defineProperty(this, "shortAttackText", void 0);

    _defineProperty(this, "longDefenseText", void 0);

    _defineProperty(this, "shortDefenseText", void 0);

    _defineProperty(this, "refreshTextFunction", new Array());

    this.start = this.loadElement("combatState",
    /*t*/
    "game.combat_start");
    this.won = this.loadElement("combatState",
    /*t*/
    "game.combat_won");
    this.lost = this.loadElement("combatState",
    /*t*/
    "game.combat_lost");
    const phaseTransitionOffsetY = 30;
    const colorAttack = "0x001EB9";
    const colorDefense = "0x9E0000";
    this.longAttackText = this.loadElement("PhaseTransition_LongAttack",
    /*t*/
    "game.combat_phase_transition_long_attack", phaseTransitionOffsetY, colorAttack);
    this.shortAttackText = this.loadElement("PhaseTransition_ShortAttack",
    /*t*/
    "game.combat_phase_transition_short_attack", phaseTransitionOffsetY, colorAttack);
    this.longDefenseText = this.loadElement("PhaseTransition_LongDefense",
    /*t*/
    "game.combat_phase_transition_long_defense", phaseTransitionOffsetY, colorDefense);
    this.shortDefenseText = this.loadElement("PhaseTransition_ShortDefense",
    /*t*/
    "game.combat_phase_transition_short_defense", phaseTransitionOffsetY, colorDefense);
    this.endTransition();
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    i18n.on("languageChanged", this.onLanguageChanged);
    this.onLanguageChanged();
  }

  destroy(options) {
    super.destroy(options);
    i18n.off("languageChanged", this.onLanguageChanged);
    this.refreshTextFunction = [];
  }

  loadElement(name, textKey, offsetY = 0, color = "black") {
    const element = staticAssetLoader.createStaticAssetView(name);
    this.addChild(element);
    const textField = new Text("", new TextStyle({
      fontSize: 72,
      fill: color,
      fontFamily: "Caveat"
    }));
    textField.position.set(element.width / 2, element.height / 2 + offsetY);
    textField.anchor.set(0.5, 0.5);
    element.addChild(textField);
    this.refreshTextFunction.push(() => {
      const newText = i18n.t(textKey); // HACK tw: The empty space behind the text is necessary because otherwise the text is cut off.
      // I don't know if that's a problem of the font file we're using (Caveat), if Pixi cannot properly
      // load/render it, or if something else is going wrong, but if I add an extra space, everything
      // seems fine.

      textField.text = newText + " ";
    });
    return element;
  }

  onLanguageChanged() {
    this.refreshTextFunction.forEach(f => f());
  }

  endTransition() {
    this.visible = false;
  }

  startTransition(phase, phaseLength) {
    this.visible = true;
    this.start.visible = phase === CombatPhase.FirstAttack;
    this.won.visible = phase === CombatPhase.WinCombat;
    this.lost.visible = phase === CombatPhase.LooseCombat;
    this.shortAttackText.visible = phase === CombatPhase.Attack && phaseLength === CombatPhaseLength.Short;
    this.longAttackText.visible = phase === CombatPhase.Attack && phaseLength === CombatPhaseLength.Long;
    this.shortDefenseText.visible = phase === CombatPhase.Defense && phaseLength === CombatPhaseLength.Short;
    this.longDefenseText.visible = phase === CombatPhase.Defense && phaseLength === CombatPhaseLength.Long;
  }

}