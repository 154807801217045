import { DynamicMapElementAnimationElementModel } from "../../shared/game/dynamicMapElements/DynamicMapElementAnimationElementModel";
import { DynamicMapElementAreaTriggerModel } from "../../shared/game/dynamicMapElements/DynamicMapElementAreaTriggerModel";
import { DynamicMapElementMapMarkerModel } from "../../shared/game/dynamicMapElements/DynamicMapElementMapMarkerModel";
import { DynamicMapElementNPCModel } from "../../shared/game/dynamicMapElements/DynamicMapElementNPCModel";
import { TileDataInteractionTriggerModel } from "../../shared/game/TileDataInteractionTriggerModel";
import { gameStore } from "../stores/GameStore";
import { sharedStore } from "../stores/SharedStore";
export function getCharacterNameForCurrentLanguage(id) {
  const npc = sharedStore.characterConfigurations.get(id);
  if (!npc) return `[Deleted Character #${id}]`;
  const npcName = npc.localizedName.get(gameStore.languageKey);
  return npcName;
}
export function getDynamicMapElementName(element, useLabelIfAvailable) {
  const label = element.label;
  if (useLabelIfAvailable && label) return label;

  if (element instanceof DynamicMapElementNPCModel) {
    return getCharacterNameForCurrentLanguage(element.characterId);
  }

  if (element instanceof DynamicMapElementAnimationElementModel) {
    const animation = sharedStore.getAnimationByName(element.animationName);
    return animation ? animation.localizedName.get(gameStore.languageKey) : `[Deleted Animation ${element.animationName}]`;
  }

  if (element instanceof DynamicMapElementAreaTriggerModel) return element.id;
  if (element instanceof DynamicMapElementMapMarkerModel) return element.label;
  return `${element.$modelType} ${getPositionString(element.position)}`;
}
export function getLocalizedDynamicMapElementDisplayNameWithPosition(t, element, useLabelIfAvailable) {
  const name = getDynamicMapElementName(element, useLabelIfAvailable);
  return t("editor.overview_display_name_with_position", {
    name,
    position: getPositionString(element.position)
  });
}
export function getInteractionTriggerDataName(interactionTriggerData) {
  if (interactionTriggerData instanceof TileDataInteractionTriggerModel) {
    const {
      label
    } = interactionTriggerData;
    if (label) return label;
    const {
      tileAssetId,
      position
    } = interactionTriggerData.tileData;
    const tileAsset = sharedStore.getTileAsset(tileAssetId);

    if (!tileAsset) {
      return `[Deleted TileAsset #${tileAssetId}]`;
    }

    return `${tileAsset.localizedName.get(gameStore.languageKey)} (${getPositionString(position)})`;
  }

  return getDynamicMapElementName(interactionTriggerData, true);
}

function getPositionString(position) {
  const {
    x,
    y,
    plane
  } = position;
  return `${x}|${y}|${plane}`;
}

export function timeDurationToString(totalSeconds, withTotalSeconds) {
  totalSeconds = Math.floor(totalSeconds);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  let result = `${minutes}:${seconds.toString().padStart(2, "0")} min`;

  if (withTotalSeconds) {
    result += ` (${totalSeconds}s)`;
  }

  return result;
}