import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { runInAction } from "mobx";
import { throwIfAssetsDoNotExist } from "./SetPlacementSelectionOp";
import { getTileLayerType } from "../../../../shared/data/layerConstants";
export function undoableSetTileLayer(mapRelatedStore, layer) {
  executeUndoableOperation(new SetTileLayerOp(mapRelatedStore, layer));
}

class SetTileLayerOp extends UndoableOperation {
  constructor(mapRelatedStore, layer) {
    super("setTileLayer");
    this.mapRelatedStore = mapRelatedStore;
    this.layer = layer;

    _defineProperty(this, "previousTileLayer", void 0);

    _defineProperty(this, "previousPlacementSelection", void 0);

    _defineProperty(this, "previousTileAssetTagFilter", void 0);

    this.previousTileLayer = mapRelatedStore.selectedLayer;
    this.previousPlacementSelection = mapRelatedStore.placementSelection;
    this.previousTileAssetTagFilter = mapRelatedStore.tileAssetTagFilter;
  }

  async execute() {
    runInAction(() => {
      // If we switch the layer, deselect the selectedTileAssetId if the layer type is changing
      // (but keep everything else, if there is anything)
      if (getTileLayerType(this.mapRelatedStore.selectedLayer) != getTileLayerType(this.layer)) {
        this.mapRelatedStore.setPlacementSelection(_objectSpread(_objectSpread({}, this.mapRelatedStore.placementSelection), {}, {
          selectedTileAssetId: undefined
        }));
      }

      this.mapRelatedStore.setSelectedLayer(this.layer);

      if (this.mapRelatedStore.filteredTileAssetsWithoutSearch.length === 0) {
        this.mapRelatedStore.setTileAssetTagFilter(null);
      }
    });
  }

  async reverse() {
    throwIfAssetsDoNotExist(this.previousPlacementSelection);
    runInAction(() => {
      this.mapRelatedStore.setSelectedLayer(this.previousTileLayer);
      this.mapRelatedStore.setPlacementSelection(this.previousPlacementSelection);
      this.mapRelatedStore.setTileAssetTagFilter(this.previousTileAssetTagFilter);
    });
  }

}