import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { runInAction } from "mobx";
import { fromSnapshot } from "mobx-keystone";
import { io } from "socket.io-client";
import { autoResolveRejectCallback, throwIfErrorSet } from "../../shared/definitions/socket.io/socketIODefinitions";
import { editorStore } from "../stores/EditorStore";
import { PatchTracker } from "./editorClient/PatchTracker";
import { managementStore } from "../stores/ManagementStore";
import { undoableWorkshopSubmitChanges } from "../stores/undo/operation/WorkshopSubmitChangesOp";
import { undoableModuleSubmitChanges } from "../stores/undo/operation/ModuleSubmitChangesOp";
import { addErrorIfSet, ClientBase, DisconnectReason } from "./ClientBase";
import { undoStore } from "../stores/undo/UndoStore";

class ManagementClient extends ClientBase {
  constructor() {
    super();

    _defineProperty(this, "workshopPatchTracker", void 0);

    _defineProperty(this, "modulePatchTracker", void 0);

    this.workshopPatchTracker = new Map();
    this.modulePatchTracker = new Map();
  }

  connectManagement() {
    if (this.socket) return;
    undoStore.clear();
    this.socket = io({
      transports: ['websocket'],
      query: {
        username: editorStore.username
      }
    });

    const afterConnect = () => {
      this.socket.emit("startManagementInitialization", addErrorIfSet);
    };

    const afterDisconnect = reason => {
      this.stopTrackingAllWorkshopsAndModules();
      runInAction(() => {
        managementStore.clear();
      });
      console.log("Completed disconnection cleanup.");

      if (reason === DisconnectReason.IOServerDisconnect) {
        // Manually reconnect
        this.socket.connect();
      }
    };

    this.registerBasicCallbacks(afterConnect, afterDisconnect, false);
    this.socket.on("initializeManagement", (serverGitCommitSHA, sessionworkshopId) => {
      if (this.reactToServerGitCommitSHA(serverGitCommitSHA)) return;
      console.log("Initializing Management.");
      console.log("Session Workshop Id: ", sessionworkshopId);
      managementStore.setSessionWorkshop(sessionworkshopId);
    });
    this.socket.on("initializeManagementFinished", () => {
      console.log("Management Init Done!");
      managementStore.setInitialized(true);
    });
    this.socket.on("allWorkshopsUpdated", async workshopSnapshots => {
      const serverWorkshops = new Map(workshopSnapshots.map(w => [w.$modelId, fromSnapshot(w)]));
      managementStore.setAllWorkshops(serverWorkshops);
      managementStore.getAllWorkshops.map(workshop => this.startTrackingWorkshop(workshop));
    });
    this.socket.on("workshopUpdated", async (workshopSnapshot, moduleSnapshots) => {
      managementStore.setWorkshop(fromSnapshot(workshopSnapshot));
      this.startTrackingWorkshop(managementStore.getWorkshop(workshopSnapshot.$modelId));
      moduleSnapshots.map(moduleSnapshot => {
        managementStore.setModule(fromSnapshot(moduleSnapshot));
        this.startTrackingModule(managementStore.getModule(moduleSnapshot.$modelId));
      });
    });
    this.socket.on("workshopDeleted", async workshopId => {
      const workshop = managementStore.getWorkshop(workshopId);

      if (workshop) {
        managementStore.deleteWorkshop(workshopId);
        this.stopTrackingWorkshop(workshopId);
        workshop.modules.map(moduleId => {
          managementStore.deleteModule(moduleId);
          this.stopTrackingModule(moduleId);
        });
      }
    });
    this.socket.on("workshopChanged", async (workshopId, patch) => {
      const workshop = managementStore.getWorkshop(workshopId);

      if (workshop) {
        this.patch(workshop, patch);
      }
    });
    this.socket.on("allModulesUpdated", async moduleSnapshots => {
      const serverModules = new Map(moduleSnapshots.map(m => [m.$modelId, fromSnapshot(m)]));
      managementStore.setAllModules(serverModules);
      managementStore.getAllModules.map(module => this.startTrackingModule(module));
    });
    this.socket.on("moduleMapListUpdated", async moduleMapList => {
      managementStore.setModuleMapList(moduleMapList);
    });
    this.socket.on("moduleUpdated", async (moduleSnapshot, workshopModuleList) => {
      managementStore.setModule(fromSnapshot(moduleSnapshot));
      this.startTrackingModule(managementStore.getModule(moduleSnapshot.$modelId)); // Update Workshop to have the new module list

      this.changeWithoutTriggeringPatchTrackers(() => {
        managementStore.getWorkshop(moduleSnapshot.workshopId).setModules(workshopModuleList);
      });
    });
    this.socket.on("moduleDeleted", async (moduleId, workshopModuleList) => {
      const module = managementStore.getModule(moduleId);

      if (module) {
        const workshopId = module.workshopId;
        managementStore.deleteModule(moduleId);
        this.stopTrackingModule(moduleId); // Update Workshop to have the new module list

        this.changeWithoutTriggeringPatchTrackers(() => {
          managementStore.getWorkshop(workshopId).setModules(workshopModuleList);
        });
      }
    });
    this.socket.on("moduleChanged", async (moduleId, patch) => {
      const module = managementStore.getModule(moduleId);

      if (module) {
        this.patch(module, patch);
      }
    });
    this.socket.on("allPlayableModulesUpdated", async playableModules => {
      const playableModulesMap = new Map(playableModules.map(m => [m.$modelId, m]));
      managementStore.setAllPlayableModules(playableModulesMap);
    });
    this.socket.on("playableModuleUpdated", async playableModule => {
      managementStore.setPlayableModule(playableModule);
    });
    this.socket.on("playableModuleDeleted", async moduleId => {
      managementStore.deletePlayableModule(moduleId);
    });
  }

  createWorkshop() {
    return this.actionPromise((resolve, reject) => {
      this.socket.emit("createWorkshop", (error, workshopSnapshot) => {
        try {
          const workshop = fromSnapshot(workshopSnapshot);
          throwIfErrorSet(error);
          this.startTrackingWorkshop(workshop);
          resolve(workshop);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  deleteWorkshop(workshopId) {
    return this.actionPromise((resolve, reject) => {
      this.socket.emit("deleteWorkshop", workshopId, autoResolveRejectCallback(resolve, reject));
    });
  }

  unDeleteWorkshop(workshopId) {
    return this.actionPromise((resolve, reject) => {
      this.socket.emit("unDeleteWorkshop", workshopId, autoResolveRejectCallback(resolve, reject));
    });
  }

  patchWorkshop(workshopId, patch) {
    const workshop = managementStore.getWorkshop(workshopId);
    this.patch(workshop, patch);
  }

  submitWorkshopChanges(workshopId, patch, inversePatch) {
    return this.actionPromise((resolve, reject) => {
      this.socket.emit("submitWorkshopChanges", workshopId, patch, inversePatch, autoResolveRejectCallback(resolve, reject));
    });
  }

  startTrackingWorkshop(workshop) {
    if (this.workshopPatchTracker.get(workshop.$modelId)) return;
    const newWorkshopPatchTracker = new PatchTracker(this.applyingPatchesCallback);
    newWorkshopPatchTracker.startTracking(workshop, (patch, inversePatch) => {
      undoableWorkshopSubmitChanges(workshop.$modelId, patch, inversePatch);
    });
    this.workshopPatchTracker.set(workshop.$modelId, newWorkshopPatchTracker);
  }

  stopTrackingWorkshop(workshopId) {
    const workshopTracker = this.workshopPatchTracker.get(workshopId);
    if (!workshopTracker) return;
    workshopTracker.stopTracking();
    this.workshopPatchTracker.delete(workshopId);
  }

  createModule(workshopId) {
    return this.actionPromise((resolve, reject) => {
      this.socket.emit("createModule", workshopId, (error, moduleSnapshot) => {
        try {
          const module = fromSnapshot(moduleSnapshot);
          throwIfErrorSet(error);
          this.startTrackingModule(module);
          resolve(module);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  deleteModule(moduleId) {
    return this.actionPromise((resolve, reject) => {
      this.socket.emit("deleteModule", moduleId, autoResolveRejectCallback(resolve, reject));
    });
  }

  unDeleteModule(moduleId) {
    return this.actionPromise((resolve, reject) => {
      this.socket.emit("unDeleteModule", moduleId, autoResolveRejectCallback(resolve, reject));
    });
  }

  patchModule(moduleId, patch) {
    const module = managementStore.getModule(moduleId);
    this.patch(module, patch);
  }

  submitModuleChanges(moduleId, patch, inversePatch) {
    return this.actionPromise((resolve, reject) => {
      this.socket.emit("submitModuleChanges", moduleId, patch, inversePatch, autoResolveRejectCallback(resolve, reject));
    });
  }

  requestModuleMapListUpdate() {
    return this.actionPromise((resolve, reject) => {
      this.socket.emit("requestModuleMapListsUpdate", autoResolveRejectCallback(resolve, reject));
    });
  }

  startTrackingModule(module) {
    if (this.modulePatchTracker.get(module.$modelId)) return;
    const newWorkshopPatchTracker = new PatchTracker(this.applyingPatchesCallback);
    newWorkshopPatchTracker.startTracking(module, (patch, inversePatch) => {
      undoableModuleSubmitChanges(module.$modelId, patch, inversePatch);
    });
    this.modulePatchTracker.set(module.$modelId, newWorkshopPatchTracker);
  }

  stopTrackingModule(moduleId) {
    const moduleTracker = this.modulePatchTracker.get(moduleId);
    if (!moduleTracker) return;
    moduleTracker.stopTracking();
    this.modulePatchTracker.delete(moduleId);
  }

  stopTrackingAllWorkshopsAndModules() {
    this.modulePatchTracker.forEach(moduleTracker => moduleTracker.stopTracking());
    this.modulePatchTracker.clear();
    this.workshopPatchTracker.forEach(workshopTracker => workshopTracker.stopTracking());
    this.workshopPatchTracker.clear();
  }

}

export const managementClient = new ManagementClient();

if (module.hot) {
  module.hot.dispose(data => {
    managementClient.disconnect();
  });

  if (module.hot.data) {
    managementClient.connectManagement();
  }
}