import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _dec, _class, _class2;

import { arraySet, Model, model, modelAction, objectMap, prop } from "mobx-keystone";
import { ActionScope, factions, ItemSelectionMode, LocationTriggerActionModel, MovePlayerActionModel, playStyles } from "../../shared/action/ActionModel";
import Graph from "node-dijkstra";
import { combatStore } from "../stores/CombatStore";
import { gameStore } from "../stores/GameStore";
import { itemStore } from "../stores/ItemStore";
import { resolvePotentialMapElementTreeParameter } from "../helper/treeParameterHelpers";
const taskColors = [0xe6194b, 0x3cb44b, 0xffe119, 0x4363d8, 0xf58231, 0x911eb4, 0x46f0f0, 0xf032e6, 0xbcf60c, 0xfabebe, 0x008080, 0xe6beff, 0x9a6324, 0xfffac8, 0x800000, 0xaaffc3, 0x808000, 0xffd8b1, 0x000075, 0x808080];

class NotObservableGameState {
  constructor() {
    _defineProperty(this, "activePlayAnimationActionNodeTimers", new Map());

    _defineProperty(this, "activeTriggerDamageInAreaDelays", new Map());

    _defineProperty(this, "activeTriggerDamageInAreaDurations", new Map());
  }

}

export let GameStateModel = (_dec = model("game/GameStateModel"), _dec(_class = (_class2 = class GameStateModel extends Model({
  currentMap: prop(0).withSetter(),
  // loadedMaps: prop(() => objectMap<ReadonlyMapData>()),
  variables: prop(() => objectMap()),
  playerHealth: prop(100).withSetter(),
  playerPlayStyle: prop(playStyles[0]).withSetter(),
  playerTags: prop(() => arraySet()),
  playerInventory: prop(() => arraySet()),
  playerQuestLog: prop(() => arraySet()),
  playerQuestLogTasks: prop(() => objectMap()),
  // value is the color of the task
  playerReputationWindChasers: prop(0).withSetter(),
  playerReputationSilverAnchors: prop(0).withSetter(),
  playerReputationStatus: prop("").withSetter(),
  playerAwareness: prop(0).withSetter(),
  defeatedEnemies: prop(() => arraySet()),
  currentAct: prop(1).withSetter(),
  activeTriggerActions: prop(() => arraySet()),
  waitingForDialogueAnswerSelection: prop(() => arraySet()),
  waitingForCharacterToReachTileActions: prop(() => arraySet()),
  waitingForCombatToFinishAction: prop("").withSetter(),
  currentDialogueSelection: prop(0).withSetter(),
  // Which dialogue answer is currently highlighted (accessibility mode)
  activeNonBlockingDialogues: prop(() => arraySet()),
  // These will eventually be shown as speech bubbles on characters
  activeText: prop("").withSetter(),
  // constantly shown in a specific place in the UI
  activeImage: prop("").withSetter(),
  activeTimers: prop(() => objectMap()),
  visibleRunningTimer: prop("").withSetter(),
  playerIsInsideTriggers: prop(() => arraySet()),
  actionPropertyUIVisible: prop(true).withSetter(),
  actionPropertyMovementPossible: prop(true).withSetter(),
  actionPropertyOverlayOpacity: prop(0).withSetter(),
  actionPropertyCurrentCutScene: prop(null).withSetter(),
  actionPropertyCurrentCutSceneTextIndex: prop(0).withSetter(),
  actionPropertyEmergencyLightOverlayOpacity: prop(0).withSetter(),
  deactivatedDeactivationGroupIds: prop(() => arraySet())
}) {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "notObservable", new NotObservableGameState());
  }

  setVariable(name, scope, value, rootActionTree, treeScopeContext) {
    this.variables.set(rootActionTree.scopedName(name, scope, treeScopeContext), value);
  }
  /**
   * Finds the variable with the given name and scope
   */


  getVariable(name, scope, rootActionTree, treeScopeContext) {
    return this.variables.get(rootActionTree.scopedName(name, scope, treeScopeContext));
  }
  /**
   * Finds the variable with the given name looking first in the tree scope, and if not found, in the global scope.
   */


  getVariableAllScopes(name, rootActionTree, treeScopeContext) {
    const inTreeScope = this.getVariable(name, ActionScope.Tree, rootActionTree, treeScopeContext);
    if (inTreeScope) return inTreeScope;
    return this.getVariable(name, ActionScope.Global, rootActionTree, treeScopeContext);
  }

  addQuest(quest) {
    this.playerQuestLog.add(quest.$modelId);
  }

  deleteQuest(questModelId, getCachedNode) {
    [...this.playerQuestLogTasks.keys()].forEach(taskActionId => {
      var _getCachedNode;

      const taskQuestId = (_getCachedNode = getCachedNode(taskActionId)) === null || _getCachedNode === void 0 ? void 0 : _getCachedNode.questId;

      if (taskQuestId == questModelId) {
        this.playerQuestLogTasks.delete(taskActionId);
      }
    });
    return this.playerQuestLog.delete(questModelId);
  }

  hasQuest(questModelId) {
    return this.playerQuestLog.has(questModelId);
  }

  addTask(task) {
    this.playerQuestLogTasks.set(task.$modelId, this.nextTaskColor(task));
  }

  deleteTask(taskModelId) {
    return this.playerQuestLogTasks.delete(taskModelId);
  }

  hasTask(taskModelId) {
    return this.playerQuestLogTasks.has(taskModelId);
  }

  nextTaskColor(task) {
    if (!task.isTaskWithLocation()) return 0; // no location - color is not used

    for (const color of taskColors) {
      // find the first unused color
      if (![...this.playerQuestLogTasks.values()].includes(color)) {
        return color;
      }
    }

    return taskColors[0]; // all colors used
  }

  addNonBlockingDialog(modelId) {
    this.activeNonBlockingDialogues.add(modelId);
  }

  looseItem(action, itemTags, item) {
    const {
      selectionMode
    } = action;
    let items;

    if (itemTags.length > 0) {
      // Item(s) addressed by Item Tag(s)
      items = (selectionMode === ItemSelectionMode.ItemWithOneOfMultipleTags ? itemStore.getItemsForSomeTag(itemTags) : itemStore.getItemsForEveryTag(itemTags)).map(i => i.id).filter(id => this.playerInventory.has(id));
    } else {
      // Item addressed directly by Item ID
      if (this.playerInventory.has(item)) {
        items = [item];
      } else {
        return [];
      }
    }

    if (action.allItems) {
      // Delete all items
      items.forEach(itemId => this.playerInventory.delete(itemId));
      return items;
    }

    if (items.length > 0) {
      // Randomly select one item to delete
      const lostItem = items[Math.floor(Math.random() * items.length)];
      this.playerInventory.delete(lostItem);
      return [lostItem];
    }

    return [];
  }

  receiveReputation(action, findTreeParameterValue) {
    if (this.currentAct < 1 || this.currentAct > gameStore.gameDesignVariables.reputationActBalance.length) {
      throw new Error("Trying to fetch reputationActBalance value for act that does not exist.");
    }

    const forWindChasers = findTreeParameterValue(action.fraction, action) === factions[0];
    const reputationValue = gameStore.gameDesignVariables.reputationAmountBalance[action.amount];
    const relevantFactionCurrentReputation = forWindChasers ? this.playerReputationWindChasers : this.playerReputationSilverAnchors;
    const amountDelta = reputationValue * gameStore.gameDesignVariables.reputationActBalance[this.currentAct - 1] + reputationValue * (relevantFactionCurrentReputation / 100 / gameStore.gameDesignVariables.reputationBalanceFactor);
    if (!amountDelta) return; // cap between 0% and 100%

    const newSilverAnchorsAmount = Math.max(0, Math.min(100, this.playerReputationSilverAnchors + (forWindChasers ? -amountDelta : amountDelta)));
    const newWindChasersAmount = Math.max(0, Math.min(100, this.playerReputationWindChasers + (forWindChasers ? amountDelta : -amountDelta)));
    this.setPlayerReputationSilverAnchors(newSilverAnchorsAmount);
    this.setPlayerReputationWindChasers(newWindChasersAmount);
  }
  /**
   * Change health by the given amount, but not below 'min'
   */


  changePlayerHealth(amount, min = 0) {
    if (!amount) return; // if this goes to 0, reset/respawn in Game.ts is triggered

    this.playerHealth = Math.max(min, Math.min(combatStore.config.playerHealth, this.playerHealth + amount));
  }

  resetPlayerHealth() {
    this.playerHealth = combatStore.config.playerHealth;
  }
  /**
   * Searches breadth-first all exits of fromNode for a MovePlayerActionModel node, ignoring loops.
   * Returns the target mapId if it finds a MovePlayerActionModel in the first maxNodesToInspect nodes
   * it inspects (excluding fromNode), else null.
   */


  findMovePlayerAction(fromNode, rootActionTree, maxNodesToInspect) {
    const queuedNodeIds = [fromNode];
    const seenNodeIds = new Set([fromNode.$modelId]);
    let nodesLeft = maxNodesToInspect;

    while (queuedNodeIds.length > 0 && nodesLeft >= 0) {
      nodesLeft--;
      const node = queuedNodeIds.shift();

      for (const exit of node.exits()) {
        for (const nextId of exit.nextActions) {
          if (seenNodeIds.has(nextId)) continue;
          const nextNode = gameStore.gameEngine.getCachedActionNode(nextId);
          if (!nextNode) continue;

          if (nextNode instanceof MovePlayerActionModel) {
            const targetMapMarker = resolvePotentialMapElementTreeParameter(nextNode.targetMapMarker, "actions/MapMarkerValueModel", nextNode);
            return targetMapMarker.mapId;
          }

          seenNodeIds.add(nextId);
          queuedNodeIds.push(nextNode);
        }
      }
    }

    return null;
  }
  /**
   * Finds a transition place to another map if it exists.
   * Transitions are all 'move player' actions which are 'next actions' of an active 'area trigger'.
   * If the player needs to move across multiple maps to reach the target, this method returns
   * the first transition place the player needs to reach.
   */


  findTransitionToMap(toMap, rootActionTree) {
    // Discover all active map transitions on all maps
    const allTransitions = new Map();
    const currentMapTransitions = new Map();

    for (const triggerActionId of this.activeTriggerActions) {
      const trigger = gameStore.gameEngine.getCachedActionNode(triggerActionId);
      if (!(trigger instanceof LocationTriggerActionModel)) continue;
      const triggerMapElement = resolvePotentialMapElementTreeParameter(trigger.mapElement, "actions/AreaTriggerValueModel", trigger);
      const toMapId = this.findMovePlayerAction(trigger, rootActionTree, 25);
      if (!toMapId) continue;
      const fromMapId = triggerMapElement.mapId;
      if (fromMapId === toMapId) continue;
      const from = "" + fromMapId;
      const to = "" + toMapId;

      if (!allTransitions.has(from)) {
        allTransitions.set(from, new Map());
      }

      if (!allTransitions.has(to)) {
        allTransitions.set(to, new Map());
      }

      allTransitions.get(from).set(to, 1);

      if (fromMapId === this.currentMap) {
        currentMapTransitions.set(toMapId, triggerMapElement);
      }
    } // Find the shortest path to the target map to determine the next map to move to


    const pathToTarget = new Graph(allTransitions).path("" + this.currentMap, "" + toMap);

    if ((pathToTarget === null || pathToTarget === void 0 ? void 0 : pathToTarget.length) >= 2) {
      // This is the next map, which is the place where we are going to show the marker
      const nextMapId = parseInt(pathToTarget[1]);
      return currentMapTransitions.get(nextMapId);
    }

    return null;
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "setVariable", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "setVariable"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addQuest", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "addQuest"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "deleteQuest", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteQuest"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addTask", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "addTask"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "deleteTask", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteTask"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addNonBlockingDialog", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "addNonBlockingDialog"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "looseItem", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "looseItem"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changePlayerHealth", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "changePlayerHealth"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "resetPlayerHealth", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "resetPlayerHealth"), _class2.prototype)), _class2)) || _class);