import { StartDialogueActionModel, ShowTextActionModel, ReceiveQuestActionModel, FinishQuestActionModel, AbortQuestActionModel, ReceiveTaskActionModel, FinishTaskActionModel, AbortTaskActionModel, ReceiveItemActionModel, LooseItemActionModel } from "../../shared/action/ActionModel";
import { NPCReferenceModel } from "../../shared/action/NPCReferenceModel";
import { resolvePotentialNPCTreeParameter } from "./treeParameterHelpers";
import { getCharacterNameForCurrentLanguage } from "./displayHelpers";
import { gameStore } from "../stores/GameStore";
import { itemStore } from "../stores/ItemStore";

/**
 * Legacy implementation to get text from the assigned action model.
 * @param action The action model.
 * @param t Translation method.
 */
export function getTextOfAction(action, extraInformation, t) {
  let translatedString;

  if (action instanceof StartDialogueActionModel) {
    translatedString = action.text;
  } else if (action instanceof ShowTextActionModel) {
    translatedString = action.text;
  } else if (action instanceof ReceiveQuestActionModel) {
    translatedString = action.description;
  } else if (action instanceof FinishQuestActionModel) {
    translatedString = action.text;
  } else if (action instanceof AbortQuestActionModel) {
    translatedString = action.text;
  } else if (action instanceof ReceiveTaskActionModel) {
    translatedString = action.description;
  } else if (action instanceof FinishTaskActionModel) {
    translatedString = action.text;
  } else if (action instanceof AbortTaskActionModel) {
    translatedString = action.text;
  } else if (action instanceof ReceiveItemActionModel) {
    var _itemStore$getItem;

    if (!extraInformation.itemId) throw new Error("getTextOfAction for ReceiveItemActionModel needs extraInformation.itemId to be set.");
    translatedString = (_itemStore$getItem = itemStore.getItem(extraInformation.itemId)) === null || _itemStore$getItem === void 0 ? void 0 : _itemStore$getItem.name;
    if (!translatedString) return t("action_editor.item_not_found");
  } else if (action instanceof LooseItemActionModel) {
    if (!extraInformation.itemsIds) throw new Error("getTextOfAction for LooseItemActionModel needs extraInformation.itemIds to be set.");
    return extraInformation.itemsIds.map(itemId => {
      var _itemStore$getItem2, _itemStore$getItem2$n;

      return (_itemStore$getItem2 = itemStore.getItem(itemId)) === null || _itemStore$getItem2 === void 0 ? void 0 : (_itemStore$getItem2$n = _itemStore$getItem2.name) === null || _itemStore$getItem2$n === void 0 ? void 0 : _itemStore$getItem2$n.getForGender(gameStore.languageKey, gameStore.playerGender, true);
    }).join(", ");
  } else {
    // TODO: This is prototype UI, eventually the description text will only be used in the editor.
    return action.shortDescription([gameStore.gameEngine.rootActionTree], t, gameStore.languageKey, null);
  }

  return translatedString.getForGender(gameStore.languageKey, gameStore.playerGender, true);
}
/**
 * Legacy implementation to get the NPC name of the assigned Action...
 * @param dialog The Action.
 * @param rootActionTree The root tree.
 * @param t Translation method.
 */

export function findDialogSpeaker(dialog, rootActionTree, t) {
  if (dialog instanceof StartDialogueActionModel) {
    if (dialog.speaker instanceof NPCReferenceModel) {
      const npcReference = resolvePotentialNPCTreeParameter(dialog.speaker, dialog, rootActionTree);
      if (npcReference.npcId === "-1") return "";
      return getCharacterNameForCurrentLanguage(+npcReference.npcId);
    } else if (typeof dialog.speaker === "string") {
      return `[Noch nicht als Character gesetzt] ${dialog.speaker}: `;
    } else {
      console.log(dialog, dialog.speaker);
      throw new Error("Not implemented");
    }
  } else {
    // Prototype UI - eventually title() should only be used for nodes in the action editor
    return t(dialog.title());
  }
}