import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { runInAction } from "mobx";
import { doesPlacementSelectionLooselyEqual } from "../../MapRelatedStore";
import { throwIfAssetsDoNotExist } from "./SetPlacementSelectionOp";
import { gameConstants } from "../../../data/gameConstants";
import { MathE } from "../../../../shared/helper/MathExtension";
export function undoableSetPlane(mapRelatedStore, plane) {
  const {
    minPlane,
    maxPlane
  } = gameConstants;
  plane = MathE.clamp(plane, minPlane, maxPlane);
  if (mapRelatedStore.selectedPlane === plane) return;
  executeUndoableOperation(new SetPlaneOp(mapRelatedStore, plane));
}

class SetPlaneOp extends UndoableOperation {
  constructor(mapRelatedStore, plane) {
    super("setPlane");
    this.mapRelatedStore = mapRelatedStore;
    this.plane = plane;

    _defineProperty(this, "previousPlane", void 0);

    _defineProperty(this, "previousPlacementSelection", void 0);

    this.previousPlane = mapRelatedStore.selectedPlane;
    this.previousPlacementSelection = mapRelatedStore.placementSelection;
  }

  async execute() {
    this.mapRelatedStore.setPlane(this.plane);
  }

  async reverse() {
    throwIfAssetsDoNotExist(this.previousPlacementSelection);
    runInAction(() => {
      this.mapRelatedStore.setPlacementSelection(this.previousPlacementSelection);
      this.mapRelatedStore.setPlane(this.previousPlane);
    });

    if (!doesPlacementSelectionLooselyEqual(this.previousPlacementSelection, this.mapRelatedStore.placementSelection)) {
      throw new Error("SetPlaneOp: Couldn't properly reset previous selection.");
    }
  }

}