import { sharedStore } from '../stores/SharedStore';
/**
 * Navigates to a route while preserving the search query.
 */

export function navigateTo(history, route) {
  sharedStore.setPreviousHistoryLocationPathname(history.location.pathname);
  history.replace(getFullRoute(route, history.location));
}
export function getFullRoute(route, location) {
  if (location.search) {
    const query = new URLSearchParams(location.search);
    query.delete("map");
    const newSearchString = query.toString();

    if (newSearchString.length > 0) {
      return route + "?" + newSearchString;
    }
  }

  return route;
}