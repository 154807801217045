import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { ReadonlyPosition } from "../../../../shared/game/PositionModel";
import { TileImageUsage } from "../../../../shared/resources/TileAssetModel";
import { gameConstants } from "../../../data/gameConstants";
import { tileToWorldPositionX, tileToWorldPositionY } from "../../../helper/pixiHelpers";
import { TileViewBase } from "../../shared/map/TileViewBase";
import { GameInteractionTrigger } from "./GameInteractionTrigger";
export class GameTileView extends TileViewBase {
  constructor(tileData, tileAssetData, tileImageUsage, water, interactionTriggerOverlay) {
    super(tileData, tileAssetData, tileImageUsage, water);
    this.interactionTriggerOverlay = interactionTriggerOverlay;

    _defineProperty(this, "_interactionTrigger", void 0);

    this.refreshTileVisuals();
    this.createInteractionTrigger();
    this.events.on("tileVisualsUpdated", () => {
      var _this$interactionTrig;

      return (_this$interactionTrig = this.interactionTrigger) === null || _this$interactionTrig === void 0 ? void 0 : _this$interactionTrig.updatePosition();
    });
  }

  createInteractionTrigger() {
    if (!this.tileData.isInteractionTrigger) return; // Only continue if this is either foreground, or background if there is no foreground

    if (this.tileImageUsage === TileImageUsage.Foreground || this.tileImageUsage === TileImageUsage.Background && !this.tileAssetData.imageProperties(TileImageUsage.Foreground)) {
      if (this.interactionTrigger) throw new Error("setTileData was called twice for GameTileView and tried to create a second interactionTrigger");
      const {
        tileOffsetX,
        tileOffsetY
      } = this.tileData.interactionTriggerData;
      const {
        x,
        y,
        layer,
        plane
      } = this.tileData.position;
      const interactionTriggerTilePosition = new ReadonlyPosition({
        x: x + tileOffsetX,
        y: y + tileOffsetY,
        layer,
        plane
      });
      this._interactionTrigger = new GameInteractionTrigger(this.tileData.interactionTriggerData, () => interactionTriggerTilePosition, this.interactionTriggerOverlay, this, gameConstants.tileWidth / 2 + tileToWorldPositionX(tileOffsetX, tileOffsetY, false), gameConstants.tileHeight / 2 + tileToWorldPositionY(tileOffsetX, tileOffsetY, false));

      this._interactionTrigger.on("show", this.emitTileVisualsUpdated);

      this._interactionTrigger.on("hide", this.emitTileVisualsUpdated);
    }
  }

  get interactionTrigger() {
    return this._interactionTrigger;
  }

  destroy(options) {
    var _this$_interactionTri;

    super.destroy(options);
    (_this$_interactionTri = this._interactionTrigger) === null || _this$_interactionTri === void 0 ? void 0 : _this$_interactionTri.destroy(options);
  }

}