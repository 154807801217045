export class LocalStorageObject {
  constructor(key, defaultValue, stringToValue, valueToString) {
    this.key = key;
    this.defaultValue = defaultValue;
    this.stringToValue = stringToValue;
    this.valueToString = valueToString;

    if (localStorage.getItem(this.key) === null) {
      this.set(defaultValue);
    }
  }

  get() {
    const str = localStorage.getItem(this.key);
    if (str === null) return this.defaultValue;
    return this.stringToValue(str);
  }

  set(value) {
    const str = this.valueToString(value);
    localStorage.setItem(this.key, str);
  }

  clear() {
    localStorage.removeItem(this.key);
  }

}
export function localStorageGetNumber(key, defaultValue) {
  const str = localStorage.getItem(key);
  if (str === null) return defaultValue;
  return +str;
}
export function localStorageSetNumber(key, value) {
  localStorage.setItem(key, value.toString());
}
export class LocalStorageObjectString extends LocalStorageObject {
  constructor(key, defaultValue) {
    super(key, defaultValue, str => str, value => value);
  }

}
export class LocalStorageObjectNumber extends LocalStorageObject {
  constructor(key, defaultValue) {
    super(key, defaultValue, str => +str, value => value.toString());
  }

}
export class LocalStorageObjectBoolean extends LocalStorageObject {
  constructor(key, defaultValue) {
    super(key, defaultValue, str => str === "true", value => value ? "true" : "false");
  }

}