var _dec, _class;

import { Model, model, prop } from "mobx-keystone";
import { TranslatedString } from "../game/TranslatedString";
export let CutSceneTextModel = (_dec = model("actions/CutSceneTextModel"), _dec(_class = class CutSceneTextModel extends Model({
  text: prop(() => new TranslatedString({})).withSetter(),
  textContainerAlignmentDirection: prop(null).withSetter(),
  textContainerWidthPercent: prop(20).withSetter(),
  enabledTypeAnimation: prop(true).withSetter(),
  textStyle: prop(0).withSetter()
}) {
  textToString(languageKey, gender) {
    if (!this.text) return "";
    return this.text.getForGender(languageKey, gender, true);
  }

}) || _class);