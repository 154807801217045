import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2;

import { model, Model, modelAction, prop } from "mobx-keystone";
import { ReadonlyPosition } from "../PositionModel";
import { Direction } from "../../resources/DirectionHelper";
export let DynamicMapElementNPCModel = (_dec = model("game/DynamicMapElementNPCModel"), _dec(_class = (_class2 = class DynamicMapElementNPCModel extends Model({
  position: prop(),
  characterId: prop(),
  label: prop("").withSetter(),
  isInteractionTrigger: prop(false).withSetter(),
  isModuleGate: prop(false).withSetter(),
  viewAreaTriggers: prop(() => []),
  initialFacingDirection: prop(Direction.East).withSetter()
}) {
  createReadOnlyVersion() {
    return new ReadonlyDynamicMapElementNPC(this);
  }

  addViewAreaTrigger(trigger) {
    this.viewAreaTriggers.push(trigger);
  }

  removeViewAreaTrigger(trigger) {
    const index = this.viewAreaTriggers.indexOf(trigger);
    if (index > -1) this.viewAreaTriggers.splice(index, 1);
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "addViewAreaTrigger", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "addViewAreaTrigger"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeViewAreaTrigger", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "removeViewAreaTrigger"), _class2.prototype)), _class2)) || _class);
export class ReadonlyDynamicMapElementNPC {
  constructor(data) {
    _defineProperty(this, "$modelId", void 0);

    _defineProperty(this, "position", void 0);

    _defineProperty(this, "characterId", void 0);

    _defineProperty(this, "label", void 0);

    _defineProperty(this, "isInteractionTrigger", void 0);

    _defineProperty(this, "isModuleGate", void 0);

    _defineProperty(this, "viewAreaTriggers", void 0);

    _defineProperty(this, "initialFacingDirection", void 0);

    this.$modelId = data.$modelId;
    this.position = new ReadonlyPosition(data.position);
    this.characterId = data.characterId;
    this.label = data.label;
    this.isInteractionTrigger = data.isInteractionTrigger;
    this.isModuleGate = data.isModuleGate;
    this.viewAreaTriggers = data.viewAreaTriggers;
    this.initialFacingDirection = data.initialFacingDirection;
  }

  createReadOnlyVersion() {
    return this;
  }

}