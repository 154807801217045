import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { mainMapEditorStore } from "../../MapEditorStore";
export function undoableToggleOnlyShowEnemyCharacters() {
  executeUndoableOperation(new MapEditorSetNPCEnemyFilter());
}

class MapEditorSetNPCEnemyFilter extends UndoableOperation {
  constructor() {
    super("mapEditorSetNPCEnemyFilter");
  }

  async execute() {
    mainMapEditorStore.toggleOnlyShowEnemyCharacters();
  }

  async reverse() {
    mainMapEditorStore.toggleOnlyShowEnemyCharacters();
  }

}