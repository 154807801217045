import _defineProperty from "@babel/runtime/helpers/defineProperty";
export class SpatialGridInfo {
  constructor() {
    _defineProperty(this, "currentBounds", void 0);

    _defineProperty(this, "isDirty", void 0);

    _defineProperty(this, "wasCountedAsVisibleElementDuringComparing", void 0);

    this.reset();
  }

  reset() {
    this.currentBounds = undefined;
    this.isDirty = true;
    this.wasCountedAsVisibleElementDuringComparing = false;
  }

}