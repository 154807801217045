import _defineProperty from "@babel/runtime/helpers/defineProperty";
export class ApplicationReference {
  constructor(app) {
    _defineProperty(this, "_app", void 0);

    this._app = app;
  }

  get required() {
    if (!this._app) throw new Error("Application reference was invalidated");
    return this._app;
  }

  get optional() {
    return this._app;
  }

  invalidate() {
    this._app = null;
  }

}