var _dec, _class;

import { model, Model, prop } from "mobx-keystone";
import { isBlank } from "../helper/generalHelpers";
export let ConditionType;

(function (ConditionType) {
  ConditionType[ConditionType["TreeVariable"] = 0] = "TreeVariable";
  ConditionType[ConditionType["GlobalVariable"] = 1] = "GlobalVariable";
  ConditionType[ConditionType["Reputation"] = 2] = "Reputation";
  ConditionType[ConditionType["Item"] = 3] = "Item";
  ConditionType[ConditionType["Quest"] = 4] = "Quest";
  ConditionType[ConditionType["Tag"] = 5] = "Tag";
  ConditionType[ConditionType["Awareness"] = 6] = "Awareness";
  ConditionType[ConditionType["PlayerHealth"] = 7] = "PlayerHealth";
  ConditionType[ConditionType["PlayStyle"] = 8] = "PlayStyle";
  ConditionType[ConditionType["ItemWithOneTag"] = 9] = "ItemWithOneTag";
  ConditionType[ConditionType["ItemWithOneOfMultipleTags"] = 10] = "ItemWithOneOfMultipleTags";
  ConditionType[ConditionType["ItemWithMultipleTags"] = 11] = "ItemWithMultipleTags";
})(ConditionType || (ConditionType = {}));

export const conditionTypesTranslationKeys = ["action_editor.property_variable", "action_editor.property_variable_global", "action_editor.property_reputation", "action_editor.property_item", "action_editor.property_quest", "action_editor.property_tag", "action_editor.property_awareness", "action_editor.property_health", "action_editor.property_play_style", "content.item_selection_mode_ItemWithOneTag", "content.item_selection_mode_ItemWithOneOfMultipleTags", "content.item_selection_mode_ItemWithMultipleTags"];
export let ConditionOperator;

(function (ConditionOperator) {
  ConditionOperator[ConditionOperator["Equals"] = 0] = "Equals";
  ConditionOperator[ConditionOperator["NotEquals"] = 1] = "NotEquals";
  ConditionOperator[ConditionOperator["GreaterThan"] = 2] = "GreaterThan";
  ConditionOperator[ConditionOperator["LessThan"] = 3] = "LessThan";
})(ConditionOperator || (ConditionOperator = {}));

export const conditionOperatorLiterals = ["==", "!=", ">", "<"];
export let ConditionModel = (_dec = model("actions/EqualsConditionModel"), _dec(_class = class ConditionModel extends Model({
  conditionType: prop(ConditionType.TreeVariable).withSetter(),
  variableName: prop("").withSetter(),
  value: prop("").withSetter(),
  operator: prop(ConditionOperator.Equals).withSetter()
}) {
  displayString() {
    return conditionTypesTranslationKeys[this.conditionType];
  }

  isVariableSet() {
    return this.variableName.length > 0;
  }
  /**
   * Returns true if the data this condition is complete.
   * This depends on special cases depending on the condition type.
   */


  get isDataComplete() {
    if (this.conditionType == ConditionType.Reputation) {
      // special case: For some reason the default variable name of this condition type is "Awareness"
      return this.variableName != "Awareness" && this.isVariableSet() && !isBlank(this.value);
    }

    if (this.conditionType == ConditionType.TreeVariable || this.conditionType == ConditionType.GlobalVariable) {
      // default case: Check for variable name and value.
      return this.isVariableSet() && !isBlank(this.value);
    }

    if (this.conditionType == ConditionType.Awareness || this.conditionType == ConditionType.PlayerHealth) {
      // special case Awareness: Variable name is not needed and is always empty. -> Only check for value.
      // special case PlayerHealth: variable name is "Player" by default and can be ignored here. -> Only check for value.
      return !isBlank(this.value);
    } // default case: The value is either blank or 1. Both values are allowed. -> No check for value.


    return this.isVariableSet();
  }

}) || _class);