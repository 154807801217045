import _defineProperty from "@babel/runtime/helpers/defineProperty";
const blue = "#5896ed";
const green = "#84db7b";
const pink = "#ffcfd3";
const lightGreen = "#a6d9a5";
const orange = "#f7de6d";
const purple = "#ac82ba";
const veryLightGreen = "#e4ffe0";
const grey = "#acacac";
const colorOrder = [blue, pink, purple, lightGreen, orange, veryLightGreen, green, grey];
export class ActionTreeColors {}

_defineProperty(ActionTreeColors, "LOCATION_TRIGGER", blue);

_defineProperty(ActionTreeColors, "INTERACTION_TRIGGER", blue);

_defineProperty(ActionTreeColors, "CONDITION_TRIGGER", blue);

_defineProperty(ActionTreeColors, "USE_ITEM_TRIGGER", blue);

_defineProperty(ActionTreeColors, "TREE_ENTER", green);

_defineProperty(ActionTreeColors, "TREE_EXIT", green);

_defineProperty(ActionTreeColors, "SET_VARIABLE", pink);

_defineProperty(ActionTreeColors, "CALCULATE_VARIABLE", pink);

_defineProperty(ActionTreeColors, "TOSS_COIN", pink);

_defineProperty(ActionTreeColors, "SET_TAG", pink);

_defineProperty(ActionTreeColors, "CONDITION", pink);

_defineProperty(ActionTreeColors, "CONDITION_PLAYSTYLE", pink);

_defineProperty(ActionTreeColors, "SET_PLAYSTYLE", lightGreen);

_defineProperty(ActionTreeColors, "RECEIVE_REPUTATION", lightGreen);

_defineProperty(ActionTreeColors, "RECEIVE_AWARENESS", lightGreen);

_defineProperty(ActionTreeColors, "RECEIVE_ITEM", lightGreen);

_defineProperty(ActionTreeColors, "LOOSE_ITEM", lightGreen);

_defineProperty(ActionTreeColors, "MODIFY_PLAYER_HEALTH", lightGreen);

_defineProperty(ActionTreeColors, "START_ACT", lightGreen);

_defineProperty(ActionTreeColors, "SET_REPUTATION_STATUS", lightGreen);

_defineProperty(ActionTreeColors, "RECEIVE_QUEST_TASK", orange);

_defineProperty(ActionTreeColors, "QUEST_TASK_FINISHED", orange);

_defineProperty(ActionTreeColors, "ABORT_QUEST", orange);

_defineProperty(ActionTreeColors, "RECEIVE_TASK_LOCATION", orange);

_defineProperty(ActionTreeColors, "RECEIVE_TASK_DEFAULT", orange);

_defineProperty(ActionTreeColors, "FINISH_TASK", orange);

_defineProperty(ActionTreeColors, "ABORT_TASK", orange);

_defineProperty(ActionTreeColors, "START_DIALOGUE", purple);

_defineProperty(ActionTreeColors, "START_FIGHT", purple);

_defineProperty(ActionTreeColors, "TRIGGER_DAMAGE_ON_TILE", purple);

_defineProperty(ActionTreeColors, "CUTSCENE", purple);

_defineProperty(ActionTreeColors, "SET_PLAYER_INPUT_ACTION", purple);

_defineProperty(ActionTreeColors, "LOAD_MAP", purple);

_defineProperty(ActionTreeColors, "SHOW_TEXT", purple);

_defineProperty(ActionTreeColors, "SHOW_IMAGE", purple);

_defineProperty(ActionTreeColors, "START_TIMER", purple);

_defineProperty(ActionTreeColors, "RESET_AREA", purple);

_defineProperty(ActionTreeColors, "PLAY_ANIMATION", purple);

_defineProperty(ActionTreeColors, "START_EMERGENCY_LIGHTING", purple);

_defineProperty(ActionTreeColors, "CAMERA_NODES", purple);

_defineProperty(ActionTreeColors, "MOVE_MAP_ELEMENT", purple);

_defineProperty(ActionTreeColors, "STOP_MAP_ELEMENT", purple);

_defineProperty(ActionTreeColors, "ACTION_TREE", veryLightGreen);

_defineProperty(ActionTreeColors, "TREE_PARAMETER", veryLightGreen);

_defineProperty(ActionTreeColors, "TREE_PROPERTIES", veryLightGreen);

_defineProperty(ActionTreeColors, "COMMENT", grey);

_defineProperty(ActionTreeColors, "DEBUG_START", grey);

_defineProperty(ActionTreeColors, "DEACTIVATE_NODE_GROUP", grey);

const colorIndexByColor = new Map(colorOrder.map((color, index) => [color, index]));
export function compareColorOrder(a, b) {
  const hasColorA = colorIndexByColor.has(a);
  const hasColorB = colorIndexByColor.has(b);

  if (!hasColorA && !hasColorB) {
    return a.localeCompare(b);
  }

  if (!hasColorA) return 1;
  if (!hasColorB) return -1;
  const indexA = colorIndexByColor.get(a);
  const indexB = colorIndexByColor.get(b);
  return Math.sign(indexA - indexB);
}