import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { getCurrentReactFlowInstanceTransform, getCurrentlySelectedHierarchyIds, actionTreeSetSelection } from "./actionEditorSupport";
import { actionEditorStore } from "../../ActionEditorStore";
export function undoableActionEditorSelectAction(action) {
  actionEditorStore.clearClickActions();
  if (actionEditorStore.currentAction == action) return;
  executeUndoableOperation(new ActionEditorSelectActionOp(action === null || action === void 0 ? void 0 : action.$modelId));
}
export function undoableActionEditorDeselectAction() {
  undoableActionEditorSelectAction(null);
}

class ActionEditorSelectActionOp extends UndoableOperation {
  constructor(actionModelId) {
    var _actionEditorStore$cu;

    super(actionModelId ? "actionEditorSelectAction" : "actionEditorDeselectAction");
    this.actionModelId = actionModelId;

    _defineProperty(this, "currentTransform", void 0);

    _defineProperty(this, "previousActionModelId", void 0);

    _defineProperty(this, "previousTransform", void 0);

    _defineProperty(this, "hierachyIds", void 0);

    _defineProperty(this, "previousActionTreeHierarchy", void 0);

    this.previousActionModelId = (_actionEditorStore$cu = actionEditorStore.currentAction) === null || _actionEditorStore$cu === void 0 ? void 0 : _actionEditorStore$cu.$modelId;
    this.currentTransform = getCurrentReactFlowInstanceTransform();
    this.previousTransform = actionEditorStore.currentActionSelectionTransformForUndo || getCurrentReactFlowInstanceTransform();
    this.previousActionTreeHierarchy = actionEditorStore.currentActionSelectionHierarchyIdsForUndo || getCurrentlySelectedHierarchyIds();
    this.hierachyIds = getCurrentlySelectedHierarchyIds();
  }

  async execute() {
    actionEditorStore.clearClickActions();
    actionTreeSetSelection(this.actionModelId, this.hierachyIds, this.currentTransform);
  }

  async reverse() {
    actionEditorStore.clearClickActions();
    actionTreeSetSelection(this.previousActionModelId, this.previousActionTreeHierarchy, this.previousTransform);
  }

}