import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { doesPlacementSelectionLooselyEqual, mapRelatedStoreGetFilteredTileAssets, mapRelatedStoreGetTags } from "./MapRelatedStore";
import { MapState } from "./MapState";
import { makeAutoObservable, observable } from "mobx";
import { getTileLayerType } from "../../shared/data/layerConstants";
export class TileAssetEditorStore {
  // Not used in AssetEditor
  // Not used in AssetEditor
  constructor() {
    _defineProperty(this, "placementSelection", {});

    _defineProperty(this, "selectedTool", void 0);

    _defineProperty(this, "selectedLayer", null);

    _defineProperty(this, "selectedPlane", 0);

    _defineProperty(this, "tileAssetTagFilter", null);

    _defineProperty(this, "searchFilter", observable.map());

    _defineProperty(this, "showGamePreview", false);

    _defineProperty(this, "mapState", new MapState());

    _defineProperty(this, "allowMultiSelection", true);

    _defineProperty(this, "allowToggleSelection", true);

    _defineProperty(this, "isHoveringConflictResolutionOrigin", void 0);

    _defineProperty(this, "editBlockedDirections", void 0);

    _defineProperty(this, "complexityShowLayerMinusOne", true);

    _defineProperty(this, "complexityShowHeightPlanes", true);

    _defineProperty(this, "onlyShowVisibility", null);

    _defineProperty(this, "selectedTilePosition", null);

    _defineProperty(this, "highlightedElements", null);

    _defineProperty(this, "highlightedTiles", null);

    _defineProperty(this, "showEmptyAreaTriggersAndNPCsEvenIfNotHighlighted", false);

    _defineProperty(this, "ignoreHeightPlanes", false);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
  }

  get isAssetSelectionEnabled() {
    return true;
  }

  setPlane(plane) {} // Not used in AssetEditor


  setTool(tool) {} // Not used in AssetEditor


  setPlacementSelection(selection) {
    if (!selection) selection = {};
    if (doesPlacementSelectionLooselyEqual(this.placementSelection, selection)) return;
    this.placementSelection = selection;
    this.editBlockedDirections = false;
  }

  clearTileSelectionIfMatches(id) {
    const {
      selectedTileAssetId
    } = this.placementSelection;

    if (selectedTileAssetId === id) {
      this.setPlacementSelection({});
    }
  }

  get currentLayerType() {
    return getTileLayerType(this.selectedLayer);
  }

  setTileAssetTagFilter(tag) {
    this.tileAssetTagFilter = tag;
  }

  setSearchFilter(category, filter) {
    this.searchFilter.set(category, filter);
  }

  getSearchFilter(category) {
    return this.searchFilter.get(category) || "";
  }

  setSelectedLayer(layer) {
    this.selectedLayer = layer;
  }

  toggleGamePreview() {
    this.showGamePreview = !this.showGamePreview;
  }

  setHoveringConflictResolutionOrigin(value) {
    this.isHoveringConflictResolutionOrigin = value;
  }

  toggleEditBlockedDirections() {
    this.editBlockedDirections = !this.editBlockedDirections;
  }

  setOnlyShowVisibility(value) {
    this.onlyShowVisibility = value;
  }

  doesComplexityAllowTileAsset(tileAsset) {
    return this.onlyShowVisibility == null || tileAsset.visibilityInEditor === this.onlyShowVisibility;
  }

  get filteredTileAssets() {
    return mapRelatedStoreGetFilteredTileAssets(this, true, [], []);
  }

  get filteredTileAssetsWithoutSearch() {
    return mapRelatedStoreGetFilteredTileAssets(this, false, [], []);
  }

  get tags() {
    return mapRelatedStoreGetTags(this, [], []);
  }

}
export const tileAssetEditorStore = new TileAssetEditorStore();