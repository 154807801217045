import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { managementClient } from "../../../communication/ManagementClient";
import { managementStore } from "../../ManagementStore";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableCreateWorkshop() {
  executeUndoableOperation(new WorkshopCreationOp());
}

class WorkshopCreationOp extends UndoableOperation {
  constructor() {
    super("workshopCreation");

    _defineProperty(this, "workshop", void 0);
  }

  async execute(redo) {
    if (redo) {
      await managementClient.unDeleteWorkshop(this.workshop.$modelId);
    } else {
      this.workshop = await managementClient.createWorkshop();
      managementStore.setWorkshop(this.workshop);
    }
  }

  async reverse() {
    await managementClient.deleteWorkshop(this.workshop.$modelId);
  }

}