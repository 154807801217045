import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { managementStore } from "../../ManagementStore";
export function undoableManagementToggleItemViewOpen(itemId) {
  executeUndoableOperation(new ManagementToggleItemViewOpenOp(itemId));
}

class ManagementToggleItemViewOpenOp extends UndoableOperation {
  constructor(itemId) {
    super("managementToggleItemViewOpen");
    this.itemId = itemId;
  }

  async execute() {
    managementStore.toggleItemDetailView(this.itemId);
  }

  async reverse() {
    managementStore.toggleItemDetailView(this.itemId);
  }

}