import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { autorun, makeAutoObservable } from "mobx";
import { AnimationSelectionStore } from "./AnimationSelectionStore";
import { sharedStore } from "./SharedStore";
import { errorStore } from "./ErrorStore";
import { editorClient } from "../communication/EditorClient";
export class CharacterEditorStore {
  constructor(isTracking) {
    this.isTracking = isTracking;

    _defineProperty(this, "animationSelectionStore", new AnimationSelectionStore());

    _defineProperty(this, "selectedCharacterConfiguration", void 0);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
    autorun(() => {
      this.update();
    });
  }

  update() {
    if (!this.selectedCharacterConfiguration) return;
    const animation = sharedStore.getAnimationByName(this.selectedCharacterConfiguration.animationAssetName);

    if (animation) {
      this.animationSelectionStore.setSelectedAnimation(animation).catch(errorStore.addErrorFromErrorObject);
      this.animationSelectionStore.setSkinSelection(this.selectedCharacterConfiguration.animationSkins.split(","));
      this.animationSelectionStore.setSkinTint(this.selectedCharacterConfiguration.tintColorHex);
    }
  }

  setSelectedCharacter(char) {
    if (this.selectedCharacterConfiguration == char) return;

    if (this.isTracking) {
      editorClient.stopTrackingCharacterConfiguration();
    }

    this.selectedCharacterConfiguration = char;

    if (this.isTracking && char) {
      editorClient.startTrackingCharacterConfiguration(char);
    }
  }

  get hasMissingAnimationReference() {
    return this.selectedCharacterConfiguration && sharedStore.getCharacterAnimation(this.selectedCharacterConfiguration, true) == null;
  }

}
export const charEditorStore = new CharacterEditorStore(true);