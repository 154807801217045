var _document$querySelect;

import axios from "axios";
import { fromSnapshot } from "mobx-keystone";
import { LogoutReason, userStore } from "../stores/UserStore";
import { clientId } from "../data/clientId";
import { editorStore } from "../stores/EditorStore";
const axiosToServer = axios.create({
  headers: {
    'x-csrf-token': (_document$querySelect = document.querySelector("meta[name=\"csrf-token\"]")) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.getAttribute("content")
  }
});
/* ----------------- */

/* -- Auth Routes -- */

/* ----------------- */

export async function authLogin(username, password) {
  return processAnswer(axiosToServer.post("/api/auth/login", {
    username,
    password
  }));
}
export function authLogout() {
  return processAnswer(axiosToServer.post("/api/auth/logout"));
}
export async function authStatus() {
  return processAnswer(axiosToServer.get("/api/auth/status"));
}
/* --------------------- */

/* -- Resource routes -- */

/* --------------------- */

export async function resourceGetTileAssetImage(tileAssetId, usage, version, abortSignal) {
  return await processAnswer(axiosToServer.get(`/api/resources/tileAssetImage/${encodeURIComponent(tileAssetId)}/${usage}/${version}`, {
    responseType: "blob",
    signal: abortSignal
  }));
}
export async function resourceGetTileAssetAtlasImage(atlasImageName, abortSignal) {
  return await processAnswer(axiosToServer.get(`/tileAssetAtlasImage/${encodeURIComponent(atlasImageName)}`, {
    responseType: "blob",
    signal: abortSignal
  }));
}
export async function resourceGetImage(id, abortSignal) {
  return await processAnswer(axiosToServer.get(`/api/resources/image/${id}`, {
    responseType: "blob",
    signal: abortSignal
  }));
}
export async function resourceAnimationSkeletonBuffer(animationId) {
  return await processAnswer(axiosToServer.get(`/api/resources/animationSkeletonBuffer/${animationId}`, {
    responseType: "arraybuffer"
  }));
}
export async function resourceAnimationImageBuffer(animationId) {
  return await processAnswer(axiosToServer.get(`/api/resources/animationImageBuffer/${animationId}`, {
    responseType: "arraybuffer"
  }));
}
export async function resourceAnimationAtlasBuffer(animationId) {
  return await processAnswer(axiosToServer.get(`/api/resources/animationAtlasBuffer/${animationId}`, {
    responseType: "arraybuffer"
  }));
}
export async function resourcePublicModuleInMenu() {
  return await processAnswer(axiosToServer.get(`/api/resources/publicModulesInMenu`));
}
/* ----------------- */

/* -- Game routes -- */

/* ----------------- */

export async function gameGetMap(id, abortSignal) {
  const result = await processAnswer(axiosToServer.get(`/api/game/maps/${id}`, {
    signal: abortSignal
  }));
  return fromSnapshot(result.mapDataSnapshot);
}
/* ------------------- */

/* -- Report routes -- */

/* ------------------- */

function catchReportingProblem(promise) {
  promise.catch(e => {
    const axiosError = e;
    console.log(`Couldn't report to ${axiosError === null || axiosError === void 0 ? void 0 : axiosError.config.url} due to ${e}.`);
  });
}

export function reportSocketConnectionProblem(reason) {
  // Don't report if the server told us that it will shut down
  if (editorStore.serverWasShutDown) return;
  catchReportingProblem(processAnswer(axiosToServer.post(`/api/report/socketConnectionProblem`, {
    clientId,
    reason
  })));
}
export function reportSocketUnexpectedDisconnect(reason) {
  // Don't report if the server told us that it will shut down
  if (editorStore.serverWasShutDown) return;
  catchReportingProblem(processAnswer(axiosToServer.post(`/api/report/socketUnexpectedDisconnect`, {
    clientId,
    reason
  })));
}
/* ---------------------- */

/* -- Helper functions -- */

/* ---------------------- */

async function processAnswer(axiosPromise) {
  try {
    const result = await axiosPromise;
    return result.data;
  } catch (err) {
    if (err.response) {
      const {
        status
      } = err.response;

      if (status === 401) {
        userStore.setLoggedOut(LogoutReason.ByServer);
      }
    }

    throw err;
  }
}