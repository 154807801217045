import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2, _class3, _temp;

import { fromSnapshot, getSnapshot, model, Model, modelAction, prop } from "mobx-keystone";
import { computed } from "mobx";
import { Size3DModel } from "./Size3DModel";
import { adjustTileOffsetAndSizePrecision } from "../data/mapElementSorting";
import { TranslatedString } from "../game/TranslatedString";
export let AnimationType;

(function (AnimationType) {
  AnimationType[AnimationType["None"] = 0] = "None";
  AnimationType[AnimationType["BodyType"] = 1] = "BodyType";
  AnimationType[AnimationType["NPC"] = 3] = "NPC";
  AnimationType[AnimationType["Map"] = 2] = "Map";
  AnimationType[AnimationType["Cutscene"] = 4] = "Cutscene";
})(AnimationType || (AnimationType = {}));

export let AnimationAssetModel = (_dec = model("resources/AnimationAssetModel"), _dec(_class = (_class2 = (_temp = _class3 = class AnimationAssetModel extends Model({
  id: prop(0),
  name: prop(""),
  localizedName: prop(() => new TranslatedString({})),
  metaData: prop().withSetter(),
  createdAt: prop(),
  animationNames: prop(null),
  scale: prop(1).withSetter(),
  offsetX: prop(0),
  offsetY: prop(0),
  internalOffsetZ: prop(0),
  size: prop(() => new Size3DModel({})).withSetter()
}) {
  /**
   * Creates a new empty {@link AnimationAssetSnapshot} with the assigned name.
   * @param name The unique name of the animation.
   * @return The snapshot.
   */
  static newSnapshot(name) {
    return getSnapshot(new AnimationAssetModel({
      name: name,
      metaData: {
        type: AnimationType.None
      }
    }));
  }

  static fromSnapshot(snapshot) {
    return fromSnapshot(snapshot);
  }

  isType(animationType) {
    return this.metaData.type == animationType;
  }

  get typeName() {
    return AnimationType[this.metaData.type];
  }

  setType(type) {
    this.setMetaData({
      type: type
    });
  }

  setOffsetX(value) {
    this.offsetX = adjustTileOffsetAndSizePrecision(value);
  }

  setOffsetY(value) {
    this.offsetY = adjustTileOffsetAndSizePrecision(value);
  }

  setInternalOffsetZ(value) {
    this.internalOffsetZ = adjustTileOffsetAndSizePrecision(value);
  }

  setSizeX(value) {
    this.size.x = adjustTileOffsetAndSizePrecision(value);
  }

  setSizeY(value) {
    this.size.y = adjustTileOffsetAndSizePrecision(value);
  }

  setSizeZ(value) {
    this.size.z = adjustTileOffsetAndSizePrecision(value);
  }

  translatableEntityData() {
    return {
      entity: this,
      label: "Animation",
      translateableStrings: [{
        label: "Name",
        translatedString: this.localizedName
      }]
    };
  }

}, _defineProperty(_class3, "allTypeNames", Object.keys(AnimationType).filter(item => isNaN(Number(item)))), _temp), (_applyDecoratedDescriptor(_class2.prototype, "typeName", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "typeName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setOffsetX", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "setOffsetX"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setOffsetY", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "setOffsetY"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setInternalOffsetZ", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "setInternalOffsetZ"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setSizeX", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "setSizeX"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setSizeY", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "setSizeY"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setSizeZ", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "setSizeZ"), _class2.prototype)), _class2)) || _class);