import styled, { keyframes } from "styled-components";
import { UiConstants } from "../../../data/UiConstants";
import { Heading1Base } from "../../shared/Heading";
import { Button } from "./NineSlices";
export const BackgroundColorPrimary = "#CCCCCC";
export const BackgroundColorSecondary = "#FFFFFF";
export const BackgroundColorSecondaryFlash = "#4dc1cb";
export const BackgroundColorElement = "#EEEEEE";
export const BackgroundColorElementActive = "#FFFFFF";
export const CharacterEditorColorPrimary = "#333333";
export const CharacterEditorColorSecondary = "#555555";
export const CharacterEditorColorButton = "#CCCCCC";
export const BorderColor = "#0f0e12";
export const TabBorderColor = "#FFFFFF";
export const BorderPrimary = "border: 1px solid " + BorderColor + ";";
export const FontColorMain = "#000000";
export const FontColorModerate = "#000000";
export const FontColorHighlight = "#000000";
export const FontColorLowlight = "#444444";
export const FontColorLight = "#EEEEEE";
export const FontColorWarn = "#b16969";
export const FontFamily = "";
export const FontFamilyDebug = "font-family: monospace";
export const BoxShadowInset = "box-shadow: inset 0px -2px 8px 1px rgba(0,0,0,0.4);";
export const MenuDefaultWidth = "300px";
export const GUIBox = styled.div.withConfig({
  displayName: "GameUIElements__GUIBox",
  componentId: "sc-1cw97dm-0"
})(["background-color:", ";color:", ";margin:0px;padding:4px;border-radius:", ";", " white-space:pre-line;border:1px solid transparent;"], BackgroundColorSecondary, FontColorMain, UiConstants.BORDER_RADIUS, FontFamily);
export const GUISubBox = styled.div.withConfig({
  displayName: "GameUIElements__GUISubBox",
  componentId: "sc-1cw97dm-1"
})(["background-color:", ";max-height:180px;overflow:auto;", " border-radius:", ";margin:0px;padding:4px;", " &::-webkit-scrollbar{border:1px solid ", ";width:24px;}&::-webkit-scrollbar-track{background:", ";}&::-webkit-scrollbar-thumb{background:", ";border:1px solid ", ";border-radius:", ";}"], BackgroundColorPrimary, BorderPrimary, UiConstants.BORDER_RADIUS, FontFamily, BorderColor, BorderColor, BackgroundColorElement, BackgroundColorPrimary, UiConstants.BORDER_RADIUS);
export const GUIHeadline = styled(Heading1Base).withConfig({
  displayName: "GameUIElements__GUIHeadline",
  componentId: "sc-1cw97dm-2"
})(["display:flex;flex-direction:row;font-size:large;color:", ";padding-bottom:0.3em;padding-right:30px;", ""], FontColorHighlight, FontFamily);
export const GUIHeadlineLight = styled(GUIHeadline).withConfig({
  displayName: "GameUIElements__GUIHeadlineLight",
  componentId: "sc-1cw97dm-3"
})(["color:", ";"], FontColorLight);
export const GUIHeadlineSuffix = styled.div.withConfig({
  displayName: "GameUIElements__GUIHeadlineSuffix",
  componentId: "sc-1cw97dm-4"
})(["font-size:small;margin-left:4px;font-weight:normal;", ""], FontFamily);
export const GUITextBold = styled.div.withConfig({
  displayName: "GameUIElements__GUITextBold",
  componentId: "sc-1cw97dm-5"
})(["font-weight:bold;margin-bottom:3px;color:", ";", ""], props => props.color ? props.color : FontColorModerate, FontFamily);
export const GUITextNormal = styled.div.withConfig({
  displayName: "GameUIElements__GUITextNormal",
  componentId: "sc-1cw97dm-6"
})(["font-weight:normal;margin-bottom:3px;color:", ";", ""], props => props.color ? props.color : FontColorModerate, FontFamily);
export const GUITextNormalDebug = styled.div.withConfig({
  displayName: "GameUIElements__GUITextNormalDebug",
  componentId: "sc-1cw97dm-7"
})(["font-weight:normal;margin-bottom:3px;color:", ";", ""], props => props.color ? props.color : FontColorModerate, FontFamily);
export const GUITextDebug = styled.div.withConfig({
  displayName: "GameUIElements__GUITextDebug",
  componentId: "sc-1cw97dm-8"
})(["display:flex;flex-direction:row;color:", ";", ""], FontColorLowlight, FontFamilyDebug);
export const GUISelectableText = styled.div.withConfig({
  displayName: "GameUIElements__GUISelectableText",
  componentId: "sc-1cw97dm-9"
})(["cursor:text;-webkit-user-select:all;-moz-user-select:all;-ms-user-select:all;user-select:all;"]);
export const GUIMargin = styled.div.withConfig({
  displayName: "GameUIElements__GUIMargin",
  componentId: "sc-1cw97dm-10"
})(["margin-left:", ";margin-right:", ";margin-top:", ";margin-bottom:", ";"], props => props.left ? props.left : "0", props => props.right ? props.right : "0", props => props.top ? props.top : "0", props => props.bottom ? props.bottom : "0");
export const GUIKeyFramesFlash = keyframes(["from{background-color:", ";}to{background-color:", ";}"], BackgroundColorSecondaryFlash, BackgroundColorSecondary);
export const GUIButton = styled(Button).withConfig({
  displayName: "GameUIElements__GUIButton",
  componentId: "sc-1cw97dm-11"
})(["color:black;cursor:pointer;", " margin-left:-10px;"], FontFamily);
export const GUIRow = styled.div.withConfig({
  displayName: "GameUIElements__GUIRow",
  componentId: "sc-1cw97dm-12"
})(["display:flex;flex-direction:row;flex-grow:1;flex-shrink:0;"]);