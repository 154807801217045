import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable } from "mobx";
import { LocalStorageObjectString } from "../integration/localStorage";
import { ErrorType } from "./editor/ErrorNotification";
import { sharedStore } from "./SharedStore";
import { errorStore } from "./ErrorStore";
import { imageStore } from "./ImageStore";
import { userStore } from "./UserStore";
const localStorageUsername = new LocalStorageObjectString("Username", "");
export let ConnectionStatus;

(function (ConnectionStatus) {
  ConnectionStatus[ConnectionStatus["Connecting"] = 0] = "Connecting";
  ConnectionStatus[ConnectionStatus["Connected"] = 1] = "Connected";
  ConnectionStatus[ConnectionStatus["Disconnected"] = 2] = "Disconnected";
})(ConnectionStatus || (ConnectionStatus = {}));

export class EditorStore {
  constructor() {
    _defineProperty(this, "serverWasShutDown", void 0);

    _defineProperty(this, "reloadNecessaryReasonTranslationKey", void 0);

    _defineProperty(this, "connectionStatus", ConnectionStatus.Disconnected);

    _defineProperty(this, "userId", void 0);

    _defineProperty(this, "username", localStorageUsername.get());

    _defineProperty(this, "isInitialized", false);

    _defineProperty(this, "startedInitialization", false);

    _defineProperty(this, "sessionModule", void 0);

    _defineProperty(this, "tileImageLoadingPercentage", 0);

    _defineProperty(this, "initializationPercentage", 0);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
  }

  get isConnectedAndReady() {
    return this.isConnected && this.isInitialized && sharedStore.isInitialized && imageStore.completelyLoaded;
  }

  get isConnected() {
    return this.connectionStatus === ConnectionStatus.Connected;
  }

  setSessionModule(module) {
    this.sessionModule = module;
  }

  get sessionModuleId() {
    var _this$sessionModule;

    return (_this$sessionModule = this.sessionModule) === null || _this$sessionModule === void 0 ? void 0 : _this$sessionModule.id;
  }

  setServerWasUpdatedReloadNecessary() {
    this.reloadNecessaryReasonTranslationKey =
    /*t*/
    "editor.reload_necessary_server_was_updated";
  }

  setServerWasShutDown() {
    this.serverWasShutDown = true;
  }

  setInconsistentStateReloadNecessary() {
    this.reloadNecessaryReasonTranslationKey =
    /*t*/
    "editor.reload_necessary_inconsistent_state";
  }

  get tileImageLoadingPercentageInt100() {
    return Math.floor(this.tileImageLoadingPercentage * 100);
  }

  setTileImageLoadingPercentage(value) {
    this.tileImageLoadingPercentage = value;
  }

  setInitializationPercentage(value) {
    this.initializationPercentage = value;
  }

  setStartedInitialization() {
    this.startedInitialization = true;
  }

  setInitialized() {
    this.initializationPercentage = 1;
    this.isInitialized = true;
  }

  setUserId(userId) {
    this.userId = userId;
  }

  setConnectionStatus(status) {
    this.connectionStatus = status;

    if (status === ConnectionStatus.Connected) {
      errorStore.clearErrorsOfType(ErrorType.SocketIOConnection);
    }
  }

  setDisconnected() {
    this.setConnectionStatus(ConnectionStatus.Disconnected);
    this.userId = null;
    this.startedInitialization = false;
    this.isInitialized = false;
    this.initializationPercentage = 0;
    this.sessionModule = null;
  }

  setUsername(username) {
    this.username = username;
    localStorageUsername.set(username);
  }

  get isMainGameEditor() {
    return !this.sessionModule && userStore.mayOpenMainGameEditor;
  }

  get isModuleEditor() {
    return !!this.sessionModule;
  }

}
export const editorStore = new EditorStore();