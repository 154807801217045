import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { wait } from "../../shared/helper/generalHelpers";
export function spawnAsyncWorkers(workerCount, worker) {
  const loadingWorkers = new Array();

  for (let i = 0; i < workerCount; i++) {
    loadingWorkers.push(worker());
  }

  return Promise.all(loadingWorkers);
}
export async function repeatCallUntilSuccess(call, cancelled, onError) {
  while (true) {
    if (cancelled()) return undefined;

    try {
      const result = await call();
      return result;
    } catch (e) {
      onError(e);
      await wait(1000);
    }
  }
}
export class TokenGate {
  constructor(tokenCount) {
    this.tokenCount = tokenCount;

    _defineProperty(this, "waitingTicketResolve", new Array());
  }

  async executeWhenTokenIsFree(executor) {
    try {
      await this.getToken();
      return await executor();
    } finally {
      this.freeToken();
    }
  }

  getToken() {
    if (this.tokenCount > 0) {
      this.tokenCount--;
      return Promise.resolve();
    }

    return new Promise(resolve => {
      this.waitingTicketResolve.push(resolve);
    });
  }

  freeToken() {
    if (this.waitingTicketResolve.length > 0) {
      const resolve = this.waitingTicketResolve.shift();
      resolve();
    } else {
      this.tokenCount++;
    }
  }

}