import { ReadonlyDynamicMapElementAreaTrigger } from "../../shared/game/dynamicMapElements/DynamicMapElementAreaTriggerModel";
export class LoadedMap {
  constructor(mapData, npcs, mapMarkers, animationElements, interactionTriggers, areaTriggers) {
    this.mapData = mapData;
    this.npcs = npcs;
    this.mapMarkers = mapMarkers;
    this.animationElements = animationElements;
    this.interactionTriggers = interactionTriggers;
    this.areaTriggers = areaTriggers;
  }

  findExtendedMapMarkerPosition(id) {
    for (let i = 0; i < this.mapData.dynamicMapElements.length; i++) {
      const element = this.mapData.dynamicMapElements[i];

      if (element) {
        if (element instanceof ReadonlyDynamicMapElementAreaTrigger) {
          // For area triggers, we are not looking for the $modelId, but for the first one matching the user-set id that might be shared by multiple area triggers.
          if (element.id == id) return element.position;
        }

        if (element.$modelId == id) return element.position;
      }
    }

    return null;
  }

}