import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Character } from "../../game/character/Character";
import { ViewAreaController } from "./ViewAreaController";
import { gameStore } from "../../../stores/GameStore";
import { sharedStore } from "../../../stores/SharedStore";
import { errorStore } from "../../../stores/ErrorStore";
import { applyIdleAnimation } from "../../game/character/characterAnimationHelper";
import { DirectionHelper } from "../../../../shared/resources/DirectionHelper";
export class NpcViewBase extends Character {
  get viewAreaControllerEvents() {
    return this.viewAreaGraphicController.events;
  }

  constructor(data, overlayContainer, repeatLoadingUntilSuccess, repeatLoadingUntilSuccessCancelled) {
    super(overlayContainer, repeatLoadingUntilSuccess, repeatLoadingUntilSuccessCancelled);

    _defineProperty(this, "npcData", void 0);

    _defineProperty(this, "npcName", void 0);

    _defineProperty(this, "viewAreaGraphicController", new ViewAreaController());

    _defineProperty(this, "viewAreasVisible", false);

    _defineProperty(this, "baseRefreshMethods", [this.refreshName, this.refreshPosition, this.refreshSpineAnimation, this.refreshViewAreas, this.refreshInitialFacingDirection]);

    _defineProperty(this, "configurationRelatedRefreshMethods", [this.updateBox, this.refreshScale]);

    this.viewAreaGraphicController.setOverlayContainer(overlayContainer);
    this.npcData = data;
  }

  refreshViewAreas() {
    this.viewAreaGraphicController.init(this.npcData.viewAreaTriggers);
    this.viewAreaGraphicController.updateTransform(this.x, this.y, this.facingRotationRad);
  }

  refreshInitialFacingDirection() {
    const direction = this.npcData.initialFacingDirection;
    this.applyFacingRotation(DirectionHelper.getAngleRad(direction));
    if (this.animation) applyIdleAnimation(this.animation, direction);
  } // override


  setPosition(worldX, worldY) {
    var _this$viewAreaGraphic;

    super.setPosition(worldX, worldY);
    (_this$viewAreaGraphic = this.viewAreaGraphicController) === null || _this$viewAreaGraphic === void 0 ? void 0 : _this$viewAreaGraphic.updateTransform(this.x, this.y, this.facingRotationRad);
  } // override


  applyFacingRotation(rotationRad) {
    var _this$viewAreaGraphic2;

    super.applyFacingRotation(rotationRad);
    (_this$viewAreaGraphic2 = this.viewAreaGraphicController) === null || _this$viewAreaGraphic2 === void 0 ? void 0 : _this$viewAreaGraphic2.updateTransform(this.x, this.y, this.facingRotationRad);
  } // override


  destroy(options) {
    var _this$viewAreaGraphic3;

    super.destroy(options);
    (_this$viewAreaGraphic3 = this.viewAreaGraphicController) === null || _this$viewAreaGraphic3 === void 0 ? void 0 : _this$viewAreaGraphic3.destroy();
  }

  showViewAreas() {
    this.viewAreasVisible = true;
    this.refreshViewAreaVisibility();
  }

  hideViewAreas() {
    this.viewAreasVisible = false;
    this.refreshViewAreaVisibility();
  }

  show() {
    super.show();
    this.refreshViewAreaVisibility();
  }

  hide() {
    super.hide();
    this.refreshViewAreaVisibility();
  }

  refreshViewAreaVisibility() {
    if (this.viewAreasVisible && !this.isHidden) {
      var _this$viewAreaGraphic4;

      (_this$viewAreaGraphic4 = this.viewAreaGraphicController) === null || _this$viewAreaGraphic4 === void 0 ? void 0 : _this$viewAreaGraphic4.showGraphics();
    } else {
      var _this$viewAreaGraphic5;

      (_this$viewAreaGraphic5 = this.viewAreaGraphicController) === null || _this$viewAreaGraphic5 === void 0 ? void 0 : _this$viewAreaGraphic5.hideGraphics();
    }
  }

  checkViewIntersections(worldX, worldY) {
    var _this$viewAreaGraphic6;

    (_this$viewAreaGraphic6 = this.viewAreaGraphicController) === null || _this$viewAreaGraphic6 === void 0 ? void 0 : _this$viewAreaGraphic6.checkViewIntersections(worldX, worldY);
  }

  get $modelId() {
    return this.npcData.$modelId;
  }

  get characterId() {
    return this.npcData.characterId;
  }

  setNPCDataPosition(position) {
    this.npcData.position = position;
  }

  refreshName() {
    const character = sharedStore.characterConfigurations.get(this.npcData.characterId);

    if (character) {
      this.npcName = character.localizedName.get(gameStore.languageKey);
    } else {
      this.npcName = `[Deleted Character #${this.npcData.characterId}]`;
    }
  }

  refreshPosition() {
    this.spawnAt(this.npcData.position);
  }

  refreshSpineAnimation() {
    const character = sharedStore.characterConfigurations.get(this.npcData.characterId);

    if (character) {
      this.applyConfiguration(character).catch(errorStore.addErrorFromErrorObject);
    }
  }

}