import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { editorClient } from "../../../communication/EditorClient";
import { getChangeableTileDataSnapshot, tileDataEqualsChangeableTileDataSnapshot } from "../../../../shared/game/TileDataModel";
export function undoableMapEditorSetTileConflictResolutionOriginOverride(currentMapStore, tile, value) {
  const newSnapshot = getChangeableTileDataSnapshot(tile);
  newSnapshot.conflictResolutionOriginOverride = value;
  setSnapshot("mapEditorSetTileConflictResolutionOriginOverride", currentMapStore, tile, newSnapshot);
}
export function undoableMapEditorResetTileConflictResolutionOriginOverride(currentMapStore, tile) {
  undoableMapEditorSetTileConflictResolutionOriginOverride(currentMapStore, tile, null);
}
export function undoableMapEditorSetTileConflictResolutionFlatZIndex(currentMapStore, tile, value) {
  const newSnapshot = getChangeableTileDataSnapshot(tile);
  newSnapshot.conflictResolutionFlatZIndex = value;
  setSnapshot("mapEditorSetTileConflictResolutionFlatZIndex", currentMapStore, tile, newSnapshot);
}
export function undoableMapEditorSetAdditionalTileOffsetX(currentMapStore, tile, value) {
  const newSnapshot = getChangeableTileDataSnapshot(tile);
  newSnapshot.additionalOffsetX = value;
  setSnapshot("mapEditorSetAdditionalTileOffset", currentMapStore, tile, newSnapshot);
}
export function undoableMapEditorSetAdditionalTileOffsetY(currentMapStore, tile, value) {
  const newSnapshot = getChangeableTileDataSnapshot(tile);
  newSnapshot.additionalOffsetY = value;
  setSnapshot("mapEditorSetAdditionalTileOffset", currentMapStore, tile, newSnapshot);
}
export function undoableMapEditorSetAdditionalTileOffsetZ(currentMapStore, tile, value) {
  const newSnapshot = getChangeableTileDataSnapshot(tile);
  newSnapshot.additionalOffsetZ = value;
  setSnapshot("mapEditorSetAdditionalTileOffset", currentMapStore, tile, newSnapshot);
}
export function undoableMapEditorResetTileOffsetOverride(currentMapStore, tile) {
  const newSnapshot = getChangeableTileDataSnapshot(tile);
  newSnapshot.additionalOffsetX = 0;
  newSnapshot.additionalOffsetY = 0;
  newSnapshot.additionalOffsetZ = 0;
  setSnapshot("mapEditorSetAdditionalTileOffset", currentMapStore, tile, newSnapshot);
}
export function setSnapshot(name, currentMapStore, tile, newSnapshot) {
  if (tileDataEqualsChangeableTileDataSnapshot(tile, newSnapshot)) return;
  const currentSnapshot = getChangeableTileDataSnapshot(tile);
  const {
    x,
    y,
    layer,
    plane
  } = tile.position;
  const tileChangeForward = {
    newData: newSnapshot,
    previousData: currentSnapshot,
    position: {
      x,
      y,
      layer
    }
  };
  executeUndoableOperation(new MapEditorSetTileConflictResolutionOriginOverride(name, currentMapStore, plane, tileChangeForward));
}

class MapEditorSetTileConflictResolutionOriginOverride extends UndoableOperation {
  constructor(name, currentMapStore, plane, tileChangeForward) {
    super(name);
    this.currentMapStore = currentMapStore;
    this.plane = plane;
    this.tileChangeForward = tileChangeForward;

    _defineProperty(this, "tileChangeReverse", void 0);

    this.tileChangeReverse = {
      newData: tileChangeForward.previousData,
      previousData: tileChangeForward.newData,
      position: tileChangeForward.position
    };
  }

  async execute() {
    // ========= Set Tiles
    const {
      plane,
      tileChangeForward
    } = this;
    await editorClient.setCurrentMapTiles(this.currentMapStore, [tileChangeForward], plane);
  }

  async reverse() {
    // ========= Set Tiles
    const {
      plane,
      tileChangeReverse
    } = this;
    await editorClient.setCurrentMapTiles(this.currentMapStore, [tileChangeReverse], plane);
  }

}