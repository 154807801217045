import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { CharacterConfigurationModel } from "../../../../shared/resources/CharacterConfigurationModel";
import { AnimationType } from "../../../../shared/resources/AnimationAssetModel";
import { editorClient } from "../../../communication/EditorClient";
import { fromSnapshot, getSnapshot } from "mobx-keystone";
import { executeUndoableOperation } from "../UndoStore";
import { sharedStore } from "../../SharedStore";
import { animationLoader } from "../../../helper/AnimationLoader";
import { createRandomBodyTypeCharacter } from "../../../helper/characterHelpers";
import { mainMapEditorStore } from "../../MapEditorStore";
export function undoableCharacterEditorCreateCharacter(store) {
  executeUndoableOperation(new CharacterEditorCreationOp(store));
}

class CharacterEditorCreationOp extends UndoableOperation {
  constructor(store) {
    super("characterEditorCreateCharacter");
    this.store = store;

    _defineProperty(this, "createdConfiguration", void 0);

    _defineProperty(this, "previousPlacementSelection", void 0);

    this.previousPlacementSelection = mainMapEditorStore.placementSelection;
  }

  async execute(isRedo) {
    if (isRedo) {
      const snapshot = await editorClient.unDeleteCharacterConfiguration(this.createdConfiguration.id);
      this.createdConfiguration = fromSnapshot(snapshot);
    } else {
      const defaultAnimation = sharedStore.getAnimationsByType(AnimationType.BodyType)[0];
      const {
        skins
      } = await animationLoader.loadAnimationDataCached(defaultAnimation.id);
      const tempSnapshot = getSnapshot(await createRandomBodyTypeCharacter());
      const createdSnapshot = await editorClient.createCharacterConfiguration(tempSnapshot);
      this.createdConfiguration = CharacterConfigurationModel.fromSnapshot(createdSnapshot);
    }

    sharedStore.putCharacter(this.createdConfiguration);
    this.store.setSelectedCharacter(this.createdConfiguration);
    mainMapEditorStore.setPlacementSelection({
      selectedCharacterId: this.createdConfiguration.id
    });
  }

  async reverse() {
    await editorClient.deleteCharacterConfiguration(this.createdConfiguration.id);
    sharedStore.deleteCharacter(this.createdConfiguration.id);
    mainMapEditorStore.setPlacementSelection(this.previousPlacementSelection);
  }

}