import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { gameStore } from "../../GameStore";
import { actionEditorStore } from "../../ActionEditorStore";
export function undoableSetDebugStartNodeOp(debugStartNode) {
  executeUndoableOperation(new ActionEditorDebugStartNodeChangeOp(debugStartNode.$modelId));
}

class ActionEditorDebugStartNodeChangeOp extends UndoableOperation {
  constructor(debugStartNodeModelId) {
    super("changeDebugStartNode");
    this.debugStartNodeModelId = debugStartNodeModelId;

    _defineProperty(this, "previousDebugStartNodeModelId", void 0);

    this.previousDebugStartNodeModelId = gameStore.debugStartNodeModelId;
  }

  async execute() {
    actionEditorStore.clearClickActions();
    gameStore.setDebugStartNodeModelId(this.debugStartNodeModelId);
  }

  async reverse() {
    actionEditorStore.clearClickActions();
    gameStore.setDebugStartNodeModelId(this.previousDebugStartNodeModelId);
  }

}