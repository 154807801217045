import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _dec2, _class2, _class3;

import { computed } from "mobx";
import { getParent, model, Model, modelAction, prop } from "mobx-keystone";
import { parseFormattedTreeParameter } from "../helper/actionTreeHelper";
import { isBlank } from "../helper/generalHelpers";
import { getTreeParentOfClosestActionModel } from "./ActionTreeModel";
import { AnimationElementValueModel } from "./ValueModel";
export let MapElementReferenceModel = (_dec = model("actions/MapElementReferenceModel"), _dec(_class = class MapElementReferenceModel extends Model({
  mapId: prop(0).withSetter(),
  elementId: prop("").withSetter()
}) {
  isMapSet() {
    return this.mapId > 0;
  }

  isElementIdSet() {
    return this.elementId.length > 0;
  }

  isTreeParameter() {
    return Boolean(parseFormattedTreeParameter(this.elementId));
  }

  isComplete() {
    // This reference is complete if:
    // - It has an element which is NOT a tree parameter and HAS a map set or
    // - It has an element which IS a tree parameter WITHOUT a map set
    return this.isElementIdSet() && this.isMapSet() !== this.isTreeParameter();
  }

}) || _class);
export let AnimationElementReferenceModel = (_dec2 = model("actions/AnimationElementReferenceModel"), _dec2(_class2 = (_class3 = class AnimationElementReferenceModel extends Model({
  mapId: prop(0).withSetter(),
  elementId: prop("").withSetter(),
  elementLabel: prop("").withSetter(),
  animationName: prop("").withSetter(),
  loop: prop(false)
}) {
  isMapSet() {
    return this.mapId > 0;
  }

  isElementIdSet() {
    return this.elementId.length > 0;
  }

  isTreeParameter() {
    return Boolean(parseFormattedTreeParameter(this.elementId));
  }

  toggleLoop() {
    this.loop = !this.loop;
  }

  isComplete() {
    // This reference is complete if:
    // - It has an element which is NOT a tree parameter and HAS a map set or
    // - It has an element which IS a tree parameter WITHOUT a map set
    // ...and on top of that the animationName must be complete
    return this.isElementIdSet() && this.isMapSet() !== this.isTreeParameter() && this.isAnimationNameComplete;
  }

  get isAnimationNameComplete() {
    const parentAnimationElementValueModel = getParent(this);

    if (parentAnimationElementValueModel instanceof AnimationElementValueModel) {
      // If we are inside an AnimationElementValueModel that has required animation names, we don't need
      // to fill in an animationName here (so it's always considered complete).
      if (parentAnimationElementValueModel.hasRequiredAnimationNames) return true;
    }

    const treeParameterName = parseFormattedTreeParameter(this.elementId);

    if (treeParameterName) {
      const parentTree = getTreeParentOfClosestActionModel(this, true);
      const animationElementTreeParameters = parentTree.treeParameterActions("actions/AnimationElementValueModel");
      const treeParameter = animationElementTreeParameters.find(p => p.name === treeParameterName); // If we can't find the tree parameter (or it's for whatever reason not a AnimationElementValueModel), we
      // cannot check the animationName properly.

      if (!treeParameter || !(treeParameter.value instanceof AnimationElementValueModel)) return false; // If the tree parameter doesn't have required animation names, it's already filled in and we don't need
      // to fill in an animationName here (so it's always considered complete).

      if (!treeParameter.value.hasRequiredAnimationNames) return true; // Tree parameter with required names: Check if the animationName is not empty and in the list of required names

      return !isBlank(this.animationName) && treeParameter.value.requiredAnimationNames.indexOf(this.animationName) !== -1;
    } else {
      // Not a tree parameter: Make sure animationName is not blank
      return !isBlank(this.animationName);
    }
  }

}, (_applyDecoratedDescriptor(_class3.prototype, "toggleLoop", [modelAction], Object.getOwnPropertyDescriptor(_class3.prototype, "toggleLoop"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "isAnimationNameComplete", [computed], Object.getOwnPropertyDescriptor(_class3.prototype, "isAnimationNameComplete"), _class3.prototype)), _class3)) || _class2);