export const defaultLanguage = "en";
export const fallbackLanguages = ["en", "de"];
const languageData = {
  en: {
    languageName: "English"
  },
  de: {
    languageName: "Deutsch"
  },
  ku: {
    languageName: "Kurmancî"
  },
  ru: {
    languageName: "Русский язык"
  },
  uk: {
    languageName: "українська мова"
  },
  tr: {
    languageName: "Türkçe"
  }
};
export const validLanguages = Object.keys(languageData);
export function getValidLanguage(language) {
  return languageData[language] ? language : defaultLanguage;
}
export function getLanguageData(languageString) {
  languageString = getValidLanguage(languageString);
  return languageData[languageString];
}