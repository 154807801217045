import { editorClient } from "../../../communication/EditorClient";
import { imageStore } from "../../ImageStore";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableDeleteImage(imageId) {
  executeUndoableOperation(new ImageDeleteOp(imageId));
}

class ImageDeleteOp extends UndoableOperation {
  constructor(imageId) {
    super("deleteImage");
    this.imageId = imageId;
  }

  async execute() {
    await imageStore.deleteImage(this.imageId);
  }

  async reverse() {
    await editorClient.undeleteImage(this.imageId);
  }

}