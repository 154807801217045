import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { calcOriginToCenterOffset, calcScaleToFit } from "../../../helper/pixiHelpers";
import { DebugSpineInfoView } from "../../editor/map/debug/DebugSpineInfoView";
import { AnimationSelectionStore } from "../../../stores/AnimationSelectionStore";
import { LogEntry } from "../../../stores/LogEntry";
import { gameStore } from "../../../stores/GameStore";
import { localSettingsStore } from "../../../stores/LocalSettingsStore";
import { sharedStore } from "../../../stores/SharedStore";
/**
 * Properties of a cut scene.
 */

/**
 * A controller for cut scenes. Holds cut scene related information.
 * Can be used to observe {@see observeCutSceneAnimation} game store properties to add / remove cut scene elements.
 */
export class CutSceneController {
  /**
   * Creates a new instance.
   * @param animationCenterX The x center for the cut scene animation in screen coordinates
   * @param animationCenterY The y center for the cut scene animation in screen coordinates
   * @param maxAnimationWidth The maximum width for an animation in pixel count.
   * @param maxAnimationHeight The maximum height for an animation in pixel count.
   * @param animationContainer The container to put the animation in.
   */
  constructor(animationCenterX, animationCenterY, maxAnimationWidth, maxAnimationHeight, animationContainer) {
    this.animationCenterX = animationCenterX;
    this.animationCenterY = animationCenterY;
    this.maxAnimationWidth = maxAnimationWidth;
    this.maxAnimationHeight = maxAnimationHeight;
    this.animationContainer = animationContainer;

    _defineProperty(this, "currentCutSceneAnimation", void 0);

    _defineProperty(this, "debugCutSceneAnimationInfoView", void 0);
  }
  /**
   * Can be used as an 'autorun' method to react to cut scene related game store properties.
   */


  observeCutSceneAnimation() {
    var _gameStore$cutSceneAn, _gameStore$cutSceneAn2, _gameStore$cutSceneAn3, _gameStore$cutSceneAn4, _gameStore$cutSceneAn5;

    if (!this.animationContainer) return;

    if (!((_gameStore$cutSceneAn = gameStore.cutSceneAnimationStore) !== null && _gameStore$cutSceneAn !== void 0 && (_gameStore$cutSceneAn2 = _gameStore$cutSceneAn.selectedAnimation) !== null && _gameStore$cutSceneAn2 !== void 0 && _gameStore$cutSceneAn2.spine)) {
      this.clearAnimation();
    }

    if ((_gameStore$cutSceneAn3 = gameStore.cutSceneAnimationStore) !== null && _gameStore$cutSceneAn3 !== void 0 && (_gameStore$cutSceneAn4 = _gameStore$cutSceneAn3.selectedAnimation) !== null && _gameStore$cutSceneAn4 !== void 0 && _gameStore$cutSceneAn4.spine && !this.currentCutSceneAnimation && !((_gameStore$cutSceneAn5 = gameStore.cutSceneAnimationStore) !== null && _gameStore$cutSceneAn5 !== void 0 && _gameStore$cutSceneAn5.currentlyLoadingAnimation)) {
      this.addAnimation();
    }
  }
  /**
   * Clears the animation.
   * @private
   */


  clearAnimation() {
    if (this.currentCutSceneAnimation) {
      this.animationContainer.removeChild(this.currentCutSceneAnimation);
      this.currentCutSceneAnimation.destroy();
    }

    if (this.debugCutSceneAnimationInfoView) this.animationContainer.removeChild(this.debugCutSceneAnimationInfoView);
    this.currentCutSceneAnimation = null;
  }
  /**
   * Adds the animation.
   * @private
   */


  addAnimation() {
    this.currentCutSceneAnimation = gameStore.cutSceneAnimationStore.selectedAnimation.spine;

    if (this.currentCutSceneAnimation.transform) {
      // check for transform to avoid a race condition exception
      const animationBoundsRectangle = this.currentCutSceneAnimation.getBounds();
      const centerOffset = calcOriginToCenterOffset(animationBoundsRectangle);
      const scaleFactor = calcScaleToFit(animationBoundsRectangle, this.maxAnimationWidth, this.maxAnimationHeight);
      this.currentCutSceneAnimation.setTransform(this.animationCenterX + centerOffset.x * scaleFactor, this.animationCenterY + centerOffset.y * scaleFactor, scaleFactor, scaleFactor);
      this.animationContainer.addChild(this.currentCutSceneAnimation);

      if (localSettingsStore.showDebugInfo) {
        this.debugCutSceneAnimationInfoView = new DebugSpineInfoView(this.currentCutSceneAnimation, false, false, localSettingsStore.showDebugInfo);
        this.animationContainer.addChild(this.debugCutSceneAnimationInfoView);
      }
    }
  }
  /**
   * Starts the cut scene.
   * @param action The cut scene to start.
   */


  static startCutScene(action) {
    if (gameStore.gameEngine.gameState.actionPropertyCurrentCutScene) {
      gameStore.addLog(LogEntry.warnSameActionAlreadyRunning(action));
      return;
    } // action data needs to be converted to a non mobx Model (https://github.com/xaviergonz/mobx-keystone/issues/170)


    const props = CutSceneController.toProperties(action, gameStore.languageKey); // ui

    gameStore.gameEngine.gameState.setActionPropertyCurrentCutScene(props);
    gameStore.gameEngine.gameState.setActionPropertyCurrentCutSceneTextIndex(0); // animation

    if (props.animationAssetName) {
      gameStore.setCutSceneAnimationStore(new AnimationSelectionStore());
      const animationAsset = sharedStore.getAnimationByName(props.animationAssetName);
      gameStore.cutSceneAnimationStore.setSelectedAnimation(animationAsset).then(_ => {
        gameStore.cutSceneAnimationStore.setSelectedState(props.animationSequenceName, props.animationLoop);
      }).catch(e => {
        console.warn("Error loading cut scene animation with name " + props.animationAssetName, e);
      });
    }

    gameStore.gameEngine.executeActions(action.onStartExit, action);
  }
  /**
   * Ends the current cut scene.
   * @param sourceActionId The id of the action that started the cut scene.
   */


  static endCutScene(sourceActionId) {
    if (!sourceActionId) {
      console.warn("Can not end a cut scene with no source action id");
      return;
    } // ui


    gameStore.gameEngine.gameState.setActionPropertyCurrentCutScene(null);
    gameStore.gameEngine.gameState.setActionPropertyCurrentCutSceneTextIndex(0); // animation

    gameStore.setCutSceneAnimationStore(null); // continue in action tree

    const sourceAction = gameStore.gameEngine.getCachedActionNode(sourceActionId);

    if (!sourceAction) {
      console.error("Can not continue after cut scene. Action with the id " + sourceActionId + " not found.");
      return;
    }

    if (sourceAction.exits() && sourceAction.exits().length > 0) {
      gameStore.gameEngine.executeActions(sourceAction.onEndExit, sourceAction);
    }
  }
  /**
   * Creates {@link SimpleCutSceneProperties} out of the assigned action.
   * @param action The action to create the properties from.
   * @param languageKey The lange to translate cut scene text.
   */


  static toProperties(action, languageKey) {
    var _action$animation, _action$animation2, _action$animation3;

    if (!action) return null;
    return {
      sourceActionModelId: action.$modelId,
      text: action.textItems ? action.textItems.map(item => item.textToString(languageKey, gameStore.playerGender)) : [],
      textContainerAlignmentDirection: action.textItems ? action.textItems.map(item => item.textContainerAlignmentDirection) : [],
      textContainerWidthPercent: action.textItems ? action.textItems.map(item => item.textContainerWidthPercent) : [],
      textStyle: action.textItems ? action.textItems.map(item => item.textStyle) : [],
      enabledTypeAnimation: action.textItems ? action.textItems.map(item => item.enabledTypeAnimation) : [],
      animationAssetName: (_action$animation = action.animation) === null || _action$animation === void 0 ? void 0 : _action$animation.value,
      animationSequenceName: (_action$animation2 = action.animation) === null || _action$animation2 === void 0 ? void 0 : _action$animation2.sequence,
      animationLoop: (_action$animation3 = action.animation) === null || _action$animation3 === void 0 ? void 0 : _action$animation3.loop
    };
  }

}