import { BLEND_MODES, Graphics } from "pixi.js";
import { gameCanvasSize } from "../../../data/gameConstants";
import { MathE } from "../../../../shared/helper/MathExtension";
/**
 * A simple {@link Graphics} to draw a rectangle in the assigned color and opacity.
 */

export class FadeGraphics extends Graphics {
  /**
   * Creates a new instance.
   * @param fadeWith The width of this graphic in pixel.
   * @param fadeHeight The height of this graphic in pixel.
   * @param color The color to draw.
   */
  constructor(color = 0x000000, fadeWith = gameCanvasSize.width, fadeHeight = gameCanvasSize.height) {
    super();
    this.color = color;
    this.fadeWith = fadeWith;
    this.fadeHeight = fadeHeight;
    this.blendMode = BLEND_MODES.OVERLAY;
    this.draw();
  }

  setOpacity(opacity) {
    this.alpha = MathE.limit(0, 1, opacity);
  }
  /**
   * Draws the graphics.
   */


  draw() {
    this.clear();
    this.beginFill(this.color);
    this.drawRect(0, 0, this.fadeWith, this.fadeHeight);
    this.endFill();
  }

}