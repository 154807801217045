import { editorClient } from "../../../communication/EditorClient";
import { itemStore } from "../../ItemStore";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableDeleteItem(item) {
  executeUndoableOperation(new ItemEditorDeleteItemOp(item));
}

class ItemEditorDeleteItemOp extends UndoableOperation {
  constructor(item) {
    super("itemEditorDeleteItem");
    this.item = item;
  }

  async execute() {
    await editorClient.deleteItem(this.item.id);
    itemStore.deleteItem(this.item);
    itemStore.setSelectedItem(null);
  }

  async reverse() {
    await editorClient.unDeleteItem(this.item.id);
    itemStore.setSelectedItem(itemStore.getItem(this.item.id));
  }

}