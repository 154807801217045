import { EditorOnlyElementView } from "./EditorOnlyElementView";
import { MapMarkerVisual, MapMarkerVisualColorBorder } from "./MapMarkerVisual";
export function adjustMapMarkerViewText(text) {
  text.style.stroke = MapMarkerVisualColorBorder; //text.style.fill = "white";

  text.anchor.y = 1;
  text.position.y = 30;
}
export class MapMarkerView extends EditorOnlyElementView {
  constructor(mapRelatedStore, mapMarkerData, textGroup) {
    super(mapRelatedStore, textGroup, false);
    this.mapMarkerData = mapMarkerData;
    adjustMapMarkerViewText(this.text);
    this.addChild(new MapMarkerVisual());
  }

  getPosition() {
    return this.mapMarkerData.position;
  }

  getLabelString() {
    return this.mapMarkerData.label;
  }

  refreshVisibility() {
    if (!this.mapRelatedStore.highlightedElements) return super.refreshVisibility();
    this.isHidden = !this.mapRelatedStore.highlightedElements.has(this.mapMarkerData);
  }

}