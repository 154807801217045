import styled from "styled-components";
import { UiConstants } from "../../data/UiConstants";
export const Overlay = styled.div.withConfig({
  displayName: "PopupComponents__Overlay",
  componentId: "sc-vbawgn-0"
})(["z-index:", ";position:fixed;left:0;right:0;top:0;bottom:0;background-color:rgba(0,0,0,0.5);"], UiConstants.Z_INDEX_EDITOR_NOTIFICATIONS_OVERLAY);
export const CenterContainer = styled.div.withConfig({
  displayName: "PopupComponents__CenterContainer",
  componentId: "sc-vbawgn-1"
})(["position:absolute;width:100%;height:100%;display:flex;align-items:center;justify-content:center;flex-direction:row;"]);
export const PopupWindow = styled.div.withConfig({
  displayName: "PopupComponents__PopupWindow",
  componentId: "sc-vbawgn-2"
})(["background:white;padding:15px;margin:10px;"]);