var _dec, _class;

import { model, Model, prop } from "mobx-keystone";
import { GesturePatternModel } from "./gestures/GesturePatternModel";
export let PlayerAttackModel = (_dec = model("combat/PlayerAttackModel"), _dec(_class = class PlayerAttackModel extends Model({
  name: prop("").withSetter(),
  damage: prop(0).withSetter(),
  cooldown: prop(0).withSetter(),
  hitAnimationDuration: prop(0.4).withSetter(),
  pattern: prop(() => new GesturePatternModel({}))
}) {}) || _class);