export let EditorComplexity;

(function (EditorComplexity) {
  EditorComplexity[EditorComplexity["Workshop1"] = 1] = "Workshop1";
  EditorComplexity[EditorComplexity["Workshop2"] = 2] = "Workshop2";
  EditorComplexity[EditorComplexity["Workshop3"] = 3] = "Workshop3";
  EditorComplexity[EditorComplexity["Workshop4"] = 4] = "Workshop4";
  EditorComplexity[EditorComplexity["Production"] = 5] = "Production";
})(EditorComplexity || (EditorComplexity = {}));

export const defaultComplexitySetting = EditorComplexity.Production;
export function passComplexityGate(nodeComplexity, editorSettingComplexity) {
  return editorSettingComplexity >= nodeComplexity;
}