export function doesTilePositionEqual(a, b) {
  if (!a && !b) return true;
  if (!a) return false;
  return a.x === b.x && a.y === b.y && a.plane === b.plane;
}
export function copyTilePosition(tilePosition) {
  const {
    x,
    y,
    plane
  } = tilePosition;
  return {
    x,
    y,
    plane
  };
}