import { managementClient } from "../../../communication/ManagementClient";
import { managementStore } from "../../ManagementStore";
import { mergeSinglePatchOp, UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableWorkshopSubmitChanges(workshopId, patch, inversePatch) {
  executeUndoableOperation(new WorkshopSubmitChangesOp(workshopId, patch, inversePatch));
}

class WorkshopSubmitChangesOp extends UndoableOperation {
  constructor(workshopId, patch, inversePatch) {
    super("updateWorkshop");
    this.workshopId = workshopId;
    this.patch = patch;
    this.inversePatch = inversePatch;
  }

  async execute(isRedo) {
    await managementClient.submitWorkshopChanges(this.workshopId, this.patch, this.inversePatch);

    if (isRedo) {
      managementClient.patch(managementStore.getWorkshop(this.workshopId), this.patch);
    }
  }

  async reverse() {
    await managementClient.submitWorkshopChanges(this.workshopId, this.inversePatch, this.patch);
    managementClient.patch(managementStore.getWorkshop(this.workshopId), this.inversePatch);
  }

  merge(previousOperation) {
    return mergeSinglePatchOp(this, previousOperation);
  }

}