import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable } from "mobx";
import { fromSnapshot, getSnapshot } from "mobx-keystone";
import { Texture } from "pixi.js";
import { doesImageNeedTexture, ImageModel, throwIfImageSizeIsTooBig } from "../../shared/resources/ImageModel";
import { SizeModel } from "../../shared/resources/SizeModel";
import { editorClient } from "../communication/EditorClient";
import { wrapIterator } from "../../shared/helper/IterableIteratorWrapper";
import { resourceGetImage } from "../communication/api";
import { errorStore } from "./ErrorStore";
import { editorStore } from "./EditorStore";
export class ImageStore {
  constructor() {
    _defineProperty(this, "imageMetadata", new Map());

    _defineProperty(this, "imageTextures", new Map());

    _defineProperty(this, "imageUrls", new Map());

    _defineProperty(this, "preloadedUrlOnlyImages", new Array());

    _defineProperty(this, "loadingPercentage", 0);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
  }

  setLoadingPercentage(value) {
    this.loadingPercentage = value;
  }

  get completelyLoaded() {
    return this.loadingPercentage === 1;
  }

  getAllImageMetadataOfUsecase(usecase) {
    return wrapIterator(this.imageMetadata.values()).filter(image => image.usecase === usecase);
  }

  hasImage(id) {
    return this.imageMetadata.has(id);
  }

  getTexture(id) {
    return this.imageTextures.get(id);
  }

  getImageUrl(id) {
    return this.imageUrls.get(id);
  }

  setImage(src, texture, imageSnapshot) {
    const {
      id
    } = imageSnapshot;
    this.imageUrls.set(id, src);
    this.imageTextures.set(id, texture);
    this.imageMetadata.set(id, fromSnapshot(imageSnapshot));
  }

  async setImageFromBlob(imageSnapshot, blob) {
    const src = URL.createObjectURL(blob);
    const texture = await Texture.fromURL(src);
    this.setImage(src, texture, imageSnapshot);
  }

  static getImageServerUrl(id) {
    return `/api/resources/image/${id}`;
  }

  setImageJustWithUrl(imageSnapshot) {
    if (this.hasImage(imageSnapshot.id)) return;
    const url = ImageStore.getImageServerUrl(imageSnapshot.id);
    this.setImage(url, null, imageSnapshot);
  }
  /**
   * - For images that need textures: Load image from the server. Return false.
   * - For images that don't need textures (and are just used in HTML image elements):
   *   Just set the texture URL. Return true. Preload later with `preloadHTMLImage` if needed.
   * @returns true if the image is new and wasn't preloaded yet.
   */


  async addImage(imageSnapshot, abortSignal) {
    if (this.hasImage(imageSnapshot.id)) return false;
    if (this.imageUrls.has(imageSnapshot.id)) return false;

    if (doesImageNeedTexture(imageSnapshot)) {
      try {
        const imageBlob = await resourceGetImage(imageSnapshot.id, abortSignal);
        await this.setImageFromBlob(imageSnapshot, imageBlob);
        return false;
      } catch (error) {
        errorStore.addErrorFromErrorObject(error);
        return false;
      }
    } else {
      this.setImageJustWithUrl(imageSnapshot);
      return true;
    }
  }

  preloadHTMLImage(imageSnapshot) {
    return new Promise((resolve, reject) => {
      try {
        // Preload the image, but there is no need to wait until it is actually loaded.
        // Until it is actually shown it probably *will* be loaded, and if not, the image element
        // will work just fine without it for a moment.
        const htmlImage = new Image();
        htmlImage.src = ImageStore.getImageServerUrl(imageSnapshot.id);
        this.preloadedUrlOnlyImages.push(htmlImage);

        htmlImage.onload = () => {
          resolve();
        };

        htmlImage.onerror = e => {
          reject(new Error("Unknown error while preloading " + htmlImage.src));
        };
      } catch (e) {
        reject(e);
      }
    });
  }

  async provideImageFromLocalFilesystem(file, usecase) {
    throwIfImageSizeIsTooBig(usecase, file.size);
    const src = URL.createObjectURL(file);
    return Texture.fromURL(src).then(async texture => {
      const clientImageMetadata = new ImageModel({
        size: new SizeModel({
          width: texture.width,
          height: texture.height
        }),
        usecase: usecase,
        filename: file.name,
        moduleOwner: editorStore.sessionModuleId
      });
      const imageDataBuffer = await file.arrayBuffer();
      const imageSnapshot = await editorClient.createImage(getSnapshot(clientImageMetadata), imageDataBuffer);

      if (!doesImageNeedTexture(imageSnapshot)) {
        texture.destroy(true);
        texture = null;
      }

      this.setImage(src, texture, imageSnapshot);
      return imageSnapshot.id;
    });
  }

  async deleteImage(imageId) {
    await editorClient.deleteImage(imageId);
    this.imageDeleted(imageId);
  }

  imageDeleted(imageId) {
    var _this$imageMetadata;

    (_this$imageMetadata = this.imageMetadata) === null || _this$imageMetadata === void 0 ? void 0 : _this$imageMetadata.delete(imageId);
    this.imageTextures.delete(imageId);
    this.imageUrls.delete(imageId);
  }

  prune(keepImageIds) {
    for (const existingImageId of this.imageMetadata.keys()) {
      if (!keepImageIds.has(existingImageId)) {
        this.imageDeleted(existingImageId);
      }
    }
  }

}
export const imageStore = new ImageStore();