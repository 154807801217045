import { managementClient } from "../../../communication/ManagementClient";
import { managementStore } from "../../ManagementStore";
import { mergeSinglePatchOp, UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableModuleSubmitChanges(moduleId, patch, inversePatch) {
  executeUndoableOperation(new ModuleSubmitChangesOp(moduleId, patch, inversePatch));
}

class ModuleSubmitChangesOp extends UndoableOperation {
  constructor(moduleId, patch, inversePatch) {
    super("updateModule");
    this.moduleId = moduleId;
    this.patch = patch;
    this.inversePatch = inversePatch;
  }

  async execute(isRedo) {
    await managementClient.submitModuleChanges(this.moduleId, this.patch, this.inversePatch);

    if (isRedo) {
      managementClient.patch(managementStore.getModule(this.moduleId), this.patch);
    }
  }

  async reverse() {
    await managementClient.submitModuleChanges(this.moduleId, this.inversePatch, this.patch);
    managementClient.patch(managementStore.getModule(this.moduleId), this.inversePatch);
  }

  merge(previousOperation) {
    return mergeSinglePatchOp(this, previousOperation);
  }

}