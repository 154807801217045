import { editorClient } from "../../../communication/EditorClient";
import { translationStore } from "../../TranslationStore";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableMakeshiftTranslationSystemDataSubmitChanges(patch, inversePatch) {
  // Don't create undo entries while translations are importing
  if (translationStore.isImporting) return;
  executeUndoableOperation(new MakeshiftTranslationSystemDataSubmitChangesOp(patch, inversePatch));
}

class MakeshiftTranslationSystemDataSubmitChangesOp extends UndoableOperation {
  constructor(patch, inversePatch) {
    super("updateMakeshiftTranslationSystemData");
    this.patch = patch;
    this.inversePatch = inversePatch;
  }

  async execute(isRedo) {
    await editorClient.submitMakeshiftTranslationSystemDataChanges(this.patch, this.inversePatch);

    if (isRedo) {
      editorClient.patch(translationStore.makeshiftTranslationSystemData, this.patch);
    }
  }

  async reverse() {
    await editorClient.submitMakeshiftTranslationSystemDataChanges(this.inversePatch, this.patch);
    editorClient.patch(translationStore.makeshiftTranslationSystemData, this.inversePatch);
  }

}