import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable } from "mobx";
export class TimerStore {
  constructor() {
    _defineProperty(this, "currentTimePrecision1000", Date.now());

    makeAutoObservable(this, {}, {
      autoBind: true
    });
    setInterval(this.refreshCurrentTimePrecision1000, 1000);
  }

  refreshCurrentTimePrecision1000() {
    this.currentTimePrecision1000 = Date.now();
  }

}
export const timerStore = new TimerStore();