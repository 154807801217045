import _defineProperty from "@babel/runtime/helpers/defineProperty";
export class UiSounds {}

_defineProperty(UiSounds, "DIALOG_STARTS", ["ui_dialog_beginn"]);

_defineProperty(UiSounds, "DIALOG_ANSWER", ["ui_dialog_antwort"]);

_defineProperty(UiSounds, "OPEN_INVENTORY", ["ui_inventar_oeffnen", "ui_inventar_oeffnen 02", "ui_inventar_oeffnen 03"]);

_defineProperty(UiSounds, "CLOSE_INVENTORY", ["ui_inventar_schliessen", "ui_inventar_schliessen 02", "ui_inventar_schliessen 03"]);

_defineProperty(UiSounds, "RECEIVE_ITEM", ["ui_item_erhalten"]);

_defineProperty(UiSounds, "START_QUEST", ["ui_quest_starten"]);

_defineProperty(UiSounds, "FINISH_QUEST", ["ui_quest_abgeschlossen"]);

_defineProperty(UiSounds, "START_QUEST_TASK", ["ui_quest_aufgabe_erhalten"]);

_defineProperty(UiSounds, "FINISH_QUEST_TASK", ["ui_quest_aufgabe_abgeschlossen"]);

_defineProperty(UiSounds, "SET_WAYPOINT", ["ui_wegpunkt"]);

_defineProperty(UiSounds, "START_GAME", ["ui_editor_spiel_starten"]);

_defineProperty(UiSounds, "CHAR_EDITOR_MUSIC", ["ui_editor_musik"]);