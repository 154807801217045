import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { editorClient } from "../../../communication/EditorClient";
import { executeUndoableOperation } from "../UndoStore";
import { actionTreeSetSelection, getCurrentReactFlowInstanceTransform, selectHierarchyFromIds } from "./actionEditorSupport";
import { actionEditorStore } from "../../ActionEditorStore";
import { collectAllSubtreeSnapshots } from "./ActionEditorDeleteSubtreeOp";
export function undoableActionEditorDeleteTemplate(template) {
  const deletedSnapshots = new Array();
  collectAllSubtreeSnapshots(template, deletedSnapshots);
  executeUndoableOperation(new ActionEditorDeleteTemplateOp(deletedSnapshots));
}

class ActionEditorDeleteTemplateOp extends UndoableOperation {
  constructor(templateAndSubTrees) {
    var _actionEditorStore$cu;

    super("actionEditorDeleteTemplate");
    this.templateAndSubTrees = templateAndSubTrees;

    _defineProperty(this, "previousActionModelId", void 0);

    _defineProperty(this, "previousTransform", void 0);

    this.previousActionModelId = (_actionEditorStore$cu = actionEditorStore.currentAction) === null || _actionEditorStore$cu === void 0 ? void 0 : _actionEditorStore$cu.$modelId;
    this.previousTransform = actionEditorStore.currentActionSelectionTransformForUndo || getCurrentReactFlowInstanceTransform();
  }

  async execute() {
    actionEditorStore.clearClickActions();
    actionEditorStore.deselectSelectedAction();
    await editorClient.deleteActionTrees(this.templateAndSubTrees);
    selectHierarchyFromIds(null);
  }

  async reverse() {
    actionEditorStore.clearClickActions();
    await editorClient.unDeleteActionTrees(this.templateAndSubTrees.map(t => t.$modelId));
    actionTreeSetSelection(this.previousActionModelId, [this.templateAndSubTrees[0].$modelId], this.previousTransform);
  }

}