import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable } from "mobx";
import { UserPrivileges } from "../../shared/definitions/apiResults/AuthCheckLoginStatusResult";
import { MapEditorComplexity, mainMapEditorStore } from "./MapEditorStore";
import { editorStore } from "./EditorStore";
export let LogoutReason;

(function (LogoutReason) {
  LogoutReason[LogoutReason["UserRequested"] = 0] = "UserRequested";
  LogoutReason[LogoutReason["ByServer"] = 1] = "ByServer";
})(LogoutReason || (LogoutReason = {}));

export class UserStore {
  constructor() {
    _defineProperty(this, "privilegeLevel", void 0);

    _defineProperty(this, "isLoggedIn", false);

    _defineProperty(this, "restartingAfterLoggingOut", false);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
  }

  setLoggedIn(loginPrivilegeLevel) {
    this.isLoggedIn = true;
    this.privilegeLevel = loginPrivilegeLevel;
    this.setMapEditorComplexityAccordingToPrivilegeLevel();
  }

  setLoggedOut(reason) {
    this.isLoggedIn = false;
    this.privilegeLevel = null;
  }

  setRestartingAfterLoggingOut() {
    this.restartingAfterLoggingOut = true;
  }

  setMapEditorComplexityAccordingToPrivilegeLevel() {
    mainMapEditorStore.setMapEditorComplexity(this.isWorkshopParticipant ? MapEditorComplexity.Simple : MapEditorComplexity.Complex);
  }

  get isAdmin() {
    return this.privilegeLevel == UserPrivileges.Admin;
  }

  get isSingleWorkshopAdmin() {
    return this.privilegeLevel == UserPrivileges.SingleWorkshopAdmin;
  }

  get isWorkshopParticipant() {
    return this.privilegeLevel == UserPrivileges.WorkshopParticipant;
  }

  get isWorkshopPlayer() {
    return this.privilegeLevel == UserPrivileges.WorkshopPlayer;
  }

  get shouldUseProductionEditorComplexity() {
    return this.isAdmin || this.isSingleWorkshopAdmin;
  }

  get mayEditAllWorkshops() {
    return this.isAdmin;
  }

  get mayOpenMainGameEditor() {
    return this.isAdmin;
  }

  get mayUseWorkshopManagementView() {
    return this.isAdmin || this.isSingleWorkshopAdmin;
  }

  get mayAccessEditor() {
    return this.isLoggedIn && (this.isAdmin || this.isSingleWorkshopAdmin || this.isWorkshopParticipant);
  }

  get shouldShowAdvancedOptions() {
    return this.isAdmin || this.isSingleWorkshopAdmin;
  }

  get showMapComplexitySelector() {
    return this.isWorkshopParticipant;
  }

  get mayAccessProductionEditorComplexityFeatures() {
    return this.isAdmin || this.isSingleWorkshopAdmin;
  }

  mayEditCharacter(character) {
    return editorStore.isMainGameEditor || character.moduleOwner === editorStore.sessionModuleId;
  }

}
export const userStore = new UserStore();