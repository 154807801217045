import { gameStore } from "./GameStore";
import { sharedStore } from "./SharedStore";
export let OtherPlacementElement;

(function (OtherPlacementElement) {
  OtherPlacementElement[OtherPlacementElement["AreaTrigger"] = 1] = "AreaTrigger";
  OtherPlacementElement[OtherPlacementElement["MapMarker"] = 2] = "MapMarker";
  OtherPlacementElement[OtherPlacementElement["StartMarker"] = 3] = "StartMarker";
  OtherPlacementElement[OtherPlacementElement["DebugStartMarker"] = 4] = "DebugStartMarker";
})(OtherPlacementElement || (OtherPlacementElement = {}));

export function doesPlacementSelectionLooselyEqual(a, b) {
  if (!a || !b) throw new Error("PlacementSelection should never be null");
  return a.selectedTileAssetId == b.selectedTileAssetId && a.selectedCharacterId == b.selectedCharacterId && a.selectedAnimationName == b.selectedAnimationName && a.selectedOtherElement == b.selectedOtherElement && a.areaTriggerId == b.areaTriggerId;
}
export function isPlacementSelectionEmpty(placementSelection) {
  return !placementSelection.selectedTileAssetId && !placementSelection.selectedCharacterId && !placementSelection.selectedAnimationName && !placementSelection.selectedOtherElement; // areaTriggerId is not needed here (because they are only active when selectedOtherElement is not empty)
}
export let SearchFilterCategory;

(function (SearchFilterCategory) {
  SearchFilterCategory[SearchFilterCategory["TileAsset"] = 0] = "TileAsset";
  SearchFilterCategory[SearchFilterCategory["NPC"] = 1] = "NPC";
  SearchFilterCategory[SearchFilterCategory["Animation"] = 2] = "Animation";
})(SearchFilterCategory || (SearchFilterCategory = {}));

export let TagType;

(function (TagType) {
  TagType["Tag"] = "Tag";
  TagType["All"] = "All";
  TagType["NoTag"] = "NoTag";
})(TagType || (TagType = {}));

export function doTagSelectionsMatch(a, b) {
  if (a === b) return true;
  if (a === null || b === null) return false;
  if (a.type !== b.type) return false;
  if (a.type !== TagType.Tag || b.type !== TagType.Tag) return true;
  return a.tag === b.tag;
}
export function doesAnyTagMatchTagSelection(tagSelection, tags) {
  if (!tagSelection) return true;

  switch (tagSelection.type) {
    case TagType.All:
      return true;

    case TagType.NoTag:
      return tags.length === 0;

    case TagType.Tag:
      return tags.includes(tagSelection.tag);

    default:
      throw new Error("Not implemented");
  }
}
export function getTags(tileAssets) {
  const tagInfoByTag = new Map();
  const all = {
    type: TagType.All,
    count: 0
  };
  const noTags = {
    type: TagType.NoTag,
    count: 0
  };

  for (const asset of tileAssets) {
    all.count++;
    const tags = asset.tags.filter(tag => tag.length > 0);

    if (tags.length > 0) {
      for (const tag of tags) {
        let tagInfo = tagInfoByTag.get(tag);

        if (!tagInfo) {
          tagInfo = {
            type: TagType.Tag,
            tag,
            count: 0
          };
          tagInfoByTag.set(tag, tagInfo);
        }

        tagInfo.count++;
      }
    } else {
      noTags.count++;
    }
  }

  const tags = [...tagInfoByTag.values()];

  if (noTags.count > 0) {
    tags.push(noTags);
  }

  tags.sort((a, b) => {
    if (a.count !== b.count) return b.count - a.count;
    if (!a.tag && !b.tag) return 0;
    if (!a.tag) return 1;
    if (!b.tag) return -1;
    return a.tag.localeCompare(b.tag, gameStore.languageKey);
  });
  tags.unshift(all);
  return tags;
}
export function mapRelatedStoreGetTags(mapRelatedStore, includeMainTags, excludeMainTags) {
  const {
    currentLayerType,
    selectedPlane,
    complexityShowHeightPlanes,
    doesComplexityAllowTileAsset
  } = mapRelatedStore;
  const tileAssets = sharedStore.getFilteredTileAssets(currentLayerType, selectedPlane, complexityShowHeightPlanes, includeMainTags, excludeMainTags, null, doesComplexityAllowTileAsset);
  return getTags(tileAssets);
}
export function mapRelatedStoreGetFilteredTileAssets(mapRelatedStore, includeSearch, includeMainTags, excludeMainTags) {
  const {
    currentLayerType,
    selectedPlane,
    tileAssetTagFilter,
    complexityShowHeightPlanes,
    doesComplexityAllowTileAsset
  } = mapRelatedStore;
  const tileAssetSearchFilter = mapRelatedStore.getSearchFilter(SearchFilterCategory.TileAsset);
  const searchTags = [...(includeMainTags || []), tileAssetTagFilter].filter(tag => tag !== null);
  return sharedStore.getFilteredTileAssets(currentLayerType, selectedPlane, complexityShowHeightPlanes, searchTags, excludeMainTags, includeSearch ? tileAssetSearchFilter : undefined, doesComplexityAllowTileAsset).sort((a, b) => a.localizedName.get(gameStore.languageKey, true).localeCompare(b.localizedName.get(gameStore.languageKey, true), gameStore.languageKey));
}