import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2;

import { computed } from "mobx";
import { Model, model, modelAction, objectMap, prop } from "mobx-keystone";
import { TranslatedString } from "../game/TranslatedString";
import { wrapIterator } from "../helper/IterableIteratorWrapper";
export let MakeshiftTranslationSystemCategoryDataModel = (_dec = model('translation/MakeshiftTranslationSystemCategoryDataModel'), _dec(_class = (_class2 = class MakeshiftTranslationSystemCategoryDataModel extends Model({
  translations: prop(() => objectMap())
}) {
  get count() {
    return this.translations.size;
  }

  add(languageKey, keyAndInitialValue) {
    const translatedString = new TranslatedString({});
    translatedString.set(languageKey, keyAndInitialValue);
    this.translations.set(keyAndInitialValue, translatedString);
  }

  delete(key) {
    this.translations.delete(key);
  }

  has(key) {
    return this.translations.has(key);
  }

  getTranslation(languageKey, tileTagName) {
    var _this$translations$ge, _this$translations$ge2;

    return (_this$translations$ge = (_this$translations$ge2 = this.translations.get(tileTagName)) === null || _this$translations$ge2 === void 0 ? void 0 : _this$translations$ge2.get(languageKey, true)) !== null && _this$translations$ge !== void 0 ? _this$translations$ge : tileTagName;
  }

  translatableEntityData(label) {
    return {
      entity: this,
      label,
      translateableStrings: wrapIterator(this.translations.values()).map(translatedString => ({
        label: "Label",
        translatedString
      }))
    };
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "count", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "count"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "add", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "add"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "delete", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "delete"), _class2.prototype)), _class2)) || _class);