import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2;

import { Model, model, modelAction, prop } from "mobx-keystone";
export let WorkshopModel = (_dec = model("workshop/WorkshopModel"), _dec(_class = (_class2 = class WorkshopModel extends Model({
  accesscode: prop("").withSetter(),
  playcode: prop("").withSetter(),
  name: prop("").withSetter(),
  modules: prop(() => []).withSetter(),
  modulesToPlay: prop(() => []),
  serverUrl: prop("").withSetter()
}) {
  addModule(moduleId) {
    this.modules.push(moduleId);
  }

  removeModule(moduleId) {
    this.modules = this.modules.filter(m => m !== moduleId);
  }

  addModuleToPlay(moduleId) {
    this.modulesToPlay.push(moduleId);
  }

  removeModuleToPlay(moduleId) {
    this.modulesToPlay = this.modulesToPlay.filter(m => m !== moduleId);
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "addModule", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "addModule"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeModule", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "removeModule"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addModuleToPlay", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "addModuleToPlay"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeModuleToPlay", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "removeModuleToPlay"), _class2.prototype)), _class2)) || _class);