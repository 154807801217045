import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2;

import { model, Model, modelAction, prop } from "mobx-keystone";
import { TranslatedString } from "../game/TranslatedString";
import { TreeEnterActionModel } from "./ActionModel";
import { getTreeParent } from "./ActionTreeModel";
import { ConditionModel } from "./ConditionModel";
export let SelectableExitModel = (_dec = model("resources/SelectableExitModel"), _dec(_class = (_class2 = class SelectableExitModel extends Model({
  value: prop(() => new TranslatedString({})),
  nextActions: prop(() => []).withSetter(),
  hideCondition: prop(null)
}) {
  removeNextAction(actionId) {
    this.nextActions.splice(this.nextActions.indexOf(actionId), 1);
  }

  addNextAction(actionId) {
    if (!this.nextActions.includes(actionId)) {
      this.nextActions.push(actionId);
    }
  }

  toggleHideConditionActive() {
    if (this.hideCondition) {
      this.hideCondition = null;
    } else {
      this.hideCondition = new ConditionModel({});
    }
  }

  yPositionInThisTree(action) {
    var _getTreeParent, _getTreeParent$positi, _action$position;

    if (action instanceof TreeEnterActionModel) // For entry nodes, we need to use the position of the containing subtree
      return ((_getTreeParent = getTreeParent(action)) === null || _getTreeParent === void 0 ? void 0 : (_getTreeParent$positi = _getTreeParent.position) === null || _getTreeParent$positi === void 0 ? void 0 : _getTreeParent$positi.y) || 0;
    return (action === null || action === void 0 ? void 0 : (_action$position = action.position) === null || _action$position === void 0 ? void 0 : _action$position.y) || 0;
  }

  hasNextActions() {
    return this.nextActions.length > 0;
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "removeNextAction", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "removeNextAction"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addNextAction", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "addNextAction"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleHideConditionActive", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleHideConditionActive"), _class2.prototype)), _class2)) || _class);