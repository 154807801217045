import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UiConstants } from "../../../data/UiConstants";
import { autoDisposeOnDisplayObjectRemoved, autoDisposeOnDisplayObjectRemovedArray } from "../../../helper/ReactionDisposerGroup";
import { AnimationElementViewBase } from "../../shared/map/AnimationElementViewBase";
export class EditorAnimationElementView extends AnimationElementViewBase {
  constructor(mapRelatedStore, data) {
    super(data, false, null);
    this.mapRelatedStore = mapRelatedStore;

    _defineProperty(this, "remakeAnimationRelatedRefreshMethods", void 0);

    autoDisposeOnDisplayObjectRemovedArray(this, this.baseRefreshMethods, true);
    this.remakeAnimationRelatedRefreshMethods = autoDisposeOnDisplayObjectRemoved(this, autoDisposingAutorun => {
      autoDisposingAutorun(this.refreshTransparency.bind(this));
      autoDisposingAutorun(this.refreshTint.bind(this));
    });
  }

  refreshTransparency() {
    if (this.mapRelatedStore.showGamePreview || this.mapRelatedStore.ignoreHeightPlanes) {
      this.alpha = 1.0;
    } else {
      const sameHeightPlane = this.mapRelatedStore.selectedPlane === this.animationElementData.position.plane;
      this.alpha = sameHeightPlane ? 1.0 : UiConstants.ALPHA_WRONG_HEIGHT_PLANE;
    }
  }

  onAnimationLoaded() {
    this.remakeAnimationRelatedRefreshMethods();
  }

  refreshTint() {
    if (!this.spine) return;

    if (this.mapRelatedStore.highlightedElements) {
      if (!this.mapRelatedStore.highlightedElements.has(this.animationElementData)) {
        this.spine.tint = UiConstants.NON_HIGHLIGHT_TINT_0x;
        return;
      }
    }

    if (this.partOfLoop) {
      this.spine.tint = 0xFF0000;
      return;
    }

    this.spine.tint = 0xFFFFFF;
  }

  refreshPartOfLoop() {
    this.refreshTint();
  }

}