export class ErrorNotification {
  constructor(type, translationKey, interpolationOptions = {}) {
    this.type = type;
    this.translationKey = translationKey;
    this.interpolationOptions = interpolationOptions;
  }

  translate(t) {
    return t(this.translationKey, this.interpolationOptions);
  }

}
export let ErrorType;

(function (ErrorType) {
  ErrorType[ErrorType["General"] = 0] = "General";
  ErrorType[ErrorType["SocketIOConnection"] = 1] = "SocketIOConnection";
})(ErrorType || (ErrorType = {}));