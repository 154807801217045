import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Container, Point } from "pixi.js";
import { gameConstants } from "../../../data/gameConstants";
import { worldToTilePositionX, worldToTilePositionY, tileToWorldPositionX, tileToWorldPositionY, adjustViewMap, adjustMapElementContainerViewMap } from "../../../helper/pixiHelpers";
import { Culling } from "../optimization/Culling";
import { MapElementSorter } from "./sorting/MapElementSorter";
import { TileViewBundle } from "./TileViewBundle";
import { Water } from "./Water";
export class MapViewBase extends Container {
  constructor(appRef, isEditor) {
    super();

    _defineProperty(this, "_mapData", void 0);

    _defineProperty(this, "contentContainer", void 0);

    _defineProperty(this, "appRef", void 0);

    _defineProperty(this, "left", 0);

    _defineProperty(this, "right", 0);

    _defineProperty(this, "top", 0);

    _defineProperty(this, "bottom", 0);

    _defineProperty(this, "culling", void 0);

    _defineProperty(this, "mapElementSorter", void 0);

    _defineProperty(this, "water", void 0);

    _defineProperty(this, "tileViewBundles", new Map());

    _defineProperty(this, "npcViews", new Map());

    _defineProperty(this, "animationElementViews", new Map());

    _defineProperty(this, "baseMapChangeCallbacks", [this.refreshSize, this.refreshVisibility, this.refreshWater, this.refreshTiles, this.refreshNpcs, this.refreshAnimationElements]);

    this.appRef = appRef;
    this.createTileView = this.createTileView.bind(this);
    this.water = new Water(appRef);
    this.addChild(this.water);
    this.contentContainer = new Container(); //this.contentContainer.sortableChildren = true;

    this.addChild(this.contentContainer);
    this.culling = new Culling(appRef, this.contentContainer);
    this.mapElementSorter = new MapElementSorter(appRef, this.contentContainer, isEditor);
    this.addChildToContentContainer = this.addChildToContentContainer.bind(this);
    this.createNpcView = this.createNpcView.bind(this);
    this.createAnimationElementView = this.createAnimationElementView.bind(this);
    this.interactiveChildren = false;
  }

  destroy(options) {
    // First destroy all tileViewBundles (which will also destroy the contained tiles, even though
    // those are in the contentContainer)
    for (const tileViewBundle of this.tileViewBundles.values()) {
      tileViewBundle.destroy();
    }

    this.tileViewBundles.clear();
    this.culling.destroy();
    this.mapElementSorter.destroy(); // Then destroy everything else, including the contentContainer and its contents

    super.destroy(options);
  }

  initialize(mapData) {
    this.mapData = mapData;
  }

  addChildToContentContainer(child) {
    this.contentContainer.addChild(child);
  }

  get mapData() {
    return this._mapData;
  }

  set mapData(mapData) {
    this._mapData = mapData;
    this.onMapDataChanged();
  }

  refreshVisibility() {
    this.visible = !!this.mapData;
  }

  refreshWater() {
    if (!this.mapData) return;
    this.water.visible = this.mapData.properties.shouldShowWater;
  }

  refreshSize() {
    if (!this.mapData || this.mapData.tiles.length == 0) return;
    this.left = this.mapData.tiles.reduce((a, b) => a.position.x < b.position.x ? a : b).position.x;
    this.right = this.mapData.tiles.reduce((a, b) => a.position.x > b.position.x ? a : b).position.x;
    this.top = this.mapData.tiles.reduce((a, b) => a.position.y < b.position.y ? a : b).position.y;
    this.bottom = this.mapData.tiles.reduce((a, b) => a.position.y > b.position.y ? a : b).position.y;
  }

  refreshTiles() {
    if (!this.mapData) return;
    adjustViewMap(this.mapData.tiles, this.tileViewBundles, tileData => new TileViewBundle(tileData, this.mapData, this.createTileView), this.addChildToContentContainer, tileViewBundle => tileViewBundle.destroy());
  }

  refreshNpcs() {
    if (!this.mapData) return;
    adjustMapElementContainerViewMap(this.addChildToContentContainer, this.mapData.npcs, this.npcViews, this.createNpcView);
  }

  refreshAnimationElements() {
    if (!this.mapData) return;
    adjustMapElementContainerViewMap(this.addChildToContentContainer, this.mapData.animationElements, this.animationElementViews, this.createAnimationElementView);
  }
  /**
   * Returns true if the assigned {@link Point} is in the view bounds of the app.
   * @param worldPosition The point to check in world coordinate system.
   * @return True if the point is in the view bounds.
   */


  isPositionInViewBounds(worldPosition) {
    const {
      view
    } = this.appRef.required;
    const screenPosition = this.toGlobal(worldPosition);
    return screenPosition.x >= 0 && screenPosition.x <= view.width && screenPosition.y >= 0 && screenPosition.y <= view.height;
  }
  /**
   * Returns true if the tiles center position is in the view bounds of the app.
   * @param tileX The tile x position.
   * @param tileY The tile y position.
   * @return True if the tiles center position is in the view bounds.
   */


  isTileCenterInViewBounds(tileX, tileY) {
    const {
      tileWidth,
      tileHeight
    } = gameConstants;
    const tileCenterX = tileToWorldPositionX(tileX, tileY) + tileWidth / 2;
    const tileCenterY = tileToWorldPositionY(tileX, tileY) + tileHeight / 2;
    return this.isPositionInViewBounds(new Point(tileCenterX, tileCenterY));
  }
  /**
   * Gets the tile position to a global position, and also information about the highest tile found there.
   * @param global The global position (which is also the screen position)
   * @returns The x|y tile position clicked. "tileExists" to see whether an actual tile was found there.
   * "highestTilePlane" contains the plane of the highest tile that was found there, or undefined.
   */


  getTilePosition(global) {
    const local = this.toLocal(global);
    const x = worldToTilePositionX(local.x, local.y);
    const y = worldToTilePositionY(local.x, local.y);
    return {
      x,
      y
    };
  }

  findNPC(tileX, tileY) {
    for (const npc of this.npcViews.values()) {
      if (npc.baseTileX == tileX && npc.baseTileY == tileY) return npc;
    }

    return null;
  }

  getTileViewBundle(tileDataInterface) {
    return this.tileViewBundles.get(tileDataInterface);
  }

  get allNPCs() {
    return this.npcViews;
  }

}