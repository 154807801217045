import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Container, Text, TextStyle } from "pixi.js";
const fpsGreenUntil = 55;
const fpsYellowUntil = 30;
const fpsOrangeUntil = 20;
export class PerformanceInfoDisplay extends Container {
  constructor(appRef) {
    super();

    _defineProperty(this, "appRef", void 0);

    _defineProperty(this, "textFPS", void 0);

    _defineProperty(this, "textAvg", void 0);

    _defineProperty(this, "textMin", void 0);

    _defineProperty(this, "textMax", void 0);

    _defineProperty(this, "previousSecondMin", Number.MAX_VALUE);

    _defineProperty(this, "previousSecondMax", Number.MIN_VALUE);

    _defineProperty(this, "previousSecondAvgSum", 0);

    _defineProperty(this, "previousSecondAvgCount", 0);

    _defineProperty(this, "intervalTimeouts", new Array());

    this.appRef = appRef;
    const textX = 35;
    let nextTextPositionY = 35;
    this.textFPS = this.createText(textX, nextTextPositionY);
    nextTextPositionY += this.textFPS.height;
    this.textMin = this.createText(textX, nextTextPositionY);
    nextTextPositionY += this.textFPS.height;
    this.textAvg = this.createText(textX, nextTextPositionY);
    nextTextPositionY += this.textFPS.height;
    this.textMax = this.createText(textX, nextTextPositionY);
    nextTextPositionY += this.textFPS.height;
    appRef.required.ticker.add(this.update, this);
    this.on("added", this.onAdded, this);
    this.on("removed", this.onRemoved, this);
  }

  createText(x, y) {
    const text = new Text("", new TextStyle({
      fontSize: 16,
      fill: "white",
      strokeThickness: 2,
      fontFamily: "Consolas"
    }));
    text.position.set(x, y);
    this.addChild(text);
    return text;
  }

  onAdded() {
    this.appRef.required.ticker.add(this.update, this);
    this.intervalTimeouts.push(setInterval(this.refreshLastSecondDataText.bind(this), 1000));
  }

  onRemoved() {
    var _this$appRef$required;

    (_this$appRef$required = this.appRef.required.ticker) === null || _this$appRef$required === void 0 ? void 0 : _this$appRef$required.remove(this.update, this);

    for (const timeout of this.intervalTimeouts) {
      clearTimeout(timeout);
    }

    this.intervalTimeouts.length = 0;
  }

  update() {
    const fps = this.appRef.required.ticker.FPS;
    this.previousSecondMin = Math.min(this.previousSecondMin, fps);
    this.previousSecondMax = Math.max(this.previousSecondMax, fps);
    this.previousSecondAvgSum += fps;
    this.previousSecondAvgCount++;
    this.setValue(this.textFPS, "FPS", fps);
  }

  refreshLastSecondDataText() {
    this.setValue(this.textMin, "Min", this.previousSecondMin);
    this.setValue(this.textMax, "Max", this.previousSecondMax);
    this.setValue(this.textAvg, "Avg", this.previousSecondAvgSum / this.previousSecondAvgCount);
    const fps = this.appRef.required.ticker.FPS;
    this.previousSecondMin = fps;
    this.previousSecondMax = fps;
    this.previousSecondAvgSum = fps;
    this.previousSecondAvgCount = 1;
  }

  setValue(text, label, value) {
    text.text = label + ": " + Math.floor(value).toString().padStart(2);

    if (value >= fpsGreenUntil) {
      text.style.fill = "lightgreen";
    } else if (value >= fpsYellowUntil) {
      text.style.fill = "yellow";
    } else if (value >= fpsOrangeUntil) {
      text.style.fill = "#ffb833"; // light orange
    } else {
      text.style.fill = "#ff6666"; // light red
    }
  }

}