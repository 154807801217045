import styled from 'styled-components';
import { UiConstants } from '../../../../data/UiConstants';
export const ElementGroup = styled.div.withConfig({
  displayName: "BaseElements__ElementGroup",
  componentId: "sc-y45i8p-0"
})(["padding:5px;"]);
export const ElementLabel = styled.label.withConfig({
  displayName: "BaseElements__ElementLabel",
  componentId: "sc-y45i8p-1"
})(["display:block;&:not(:last-child){margin-bottom:2px;}"]);
export const ElementGroupContainer = styled(ElementGroup).withConfig({
  displayName: "BaseElements__ElementGroupContainer",
  componentId: "sc-y45i8p-2"
})(["margin-top:4px;margin-bottom:8px;border-radius:", ";border:1px #444444 solid;padding:4px;"], UiConstants.BORDER_RADIUS);