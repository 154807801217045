import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { action, makeObservable, observable, runInAction } from "mobx";
import { tryMergePatchesWithSamePath } from "../../../shared/helper/mobXHelpers";
export class UndoableOperation {
  constructor(name) {
    this.name = name;

    _defineProperty(this, "mayTryMerge", true);

    _defineProperty(this, "isBusy", void 0);

    _defineProperty(this, "_executionPromise", void 0);

    makeObservable(this, {
      mayTryMerge: observable,
      isBusy: observable
    });
  }

  merge(previousOperation) {
    return false;
  }

  get nameTranslationKey() {
    return "undoOperation." + this.name;
  }

  get executionPromise() {
    return this._executionPromise;
  }

  setExecutionPromise(executionPromise) {
    this._executionPromise = executionPromise;
    this.isBusy = true;

    this._executionPromise.catch(() => {}) // eat the errors, we are taking care of them elsewhere
    .finally(action(() => {
      this.isBusy = false;
    }));
  }

}
export function createChangeGroupStack(noneGroup) {
  return [{
    currentChangeGroup: noneGroup,
    currentGroupId: null,
    queuedSideEffects: null,
    extraData: null
  }];
}
let nextGroupId = 1;
/**
 * Creates a `groupUndoableChanges` function with the following description:
 * 
 * This method groups all changes made inside `executer` and merges them into one undo/redo entry, and labels
 * it appropriately (according to the selected `group`) and executes side effects if necessary.
 * 
 * Generally, outside of a `groupUndoableChanges` executer, every change is made with `*ChangeGroup.None`
 * and automatically gets merged with the previous change if it is also `*ChangeGroup.None`, unless merging is broken
 * by
 * - a focus change (e.g. a switch to a different text field)
 * - a click (e.g. on a button or dropdown) - here, the onClick handler of the button is executed first, and then merging
 *   is broken for the changes made)
 * 
 * This hopefully leads to text field changes being merged automatically as expected, and all other changed not to be merged.
 * If this ever leads to either a) unwanted merges or b) desired merges not happening, please inform Tobias Wehrum.
 * 
 * The contents of separate calls `groupUndoableChanges` will never be merged with each other.
 * 
 * @param changeGroupStack The stack of change groups
 * @param noneGroup *ChangeGroup.None
 * @returns a groupUndoableChanges function with those parameters:
 * - group: A group denoting the purpose of the grouped changes in executer
 * - executer: The callback that contains all changes that should be grouped
 * - sideEffects: Side effects to be executed after the first patch (initial run) or after all patches (undo/redo)
 */

export function createGroupUndoableChangesFunction(changeGroupStack, noneGroup, initializeExtraData = () => null, executeFinally) {
  return (group, executer, sideEffects) => {
    if (group === noneGroup) throw new Error("Don't call groupUndoableChanges with 'None' group.");
    changeGroupStack.push({
      currentChangeGroup: group,
      currentGroupId: nextGroupId++,
      queuedSideEffects: sideEffects,
      extraData: initializeExtraData()
    });

    try {
      runInAction(() => {
        executer();
      });
    } finally {
      try {
        executeFinally === null || executeFinally === void 0 ? void 0 : executeFinally();
      } finally {
        changeGroupStack.pop();
      }
    }
  };
}
export function mergeGroupedPatchOp(thisOperation, previousOperation, autoMergableGroups, noneGroup) {
  // Only may group if same group
  if (thisOperation.groupId !== previousOperation.groupId) return false;

  if (thisOperation.group === noneGroup) {
    var _thisOperation$sideEf, _previousOperation$si;

    if (((_thisOperation$sideEf = thisOperation.sideEffects) === null || _thisOperation$sideEf === void 0 ? void 0 : _thisOperation$sideEf.length) > 0 || ((_previousOperation$si = previousOperation.sideEffects) === null || _previousOperation$si === void 0 ? void 0 : _previousOperation$si.length) > 0) {
      throw new Error("'None' groups can never have side effects.");
    } // If the previous patch is empty (due to a collision of add -> remove earlier), just let it be removed doing anything


    if (previousOperation.patches.length === 0 && previousOperation.inversePatches.length === 0) return true; // Changed properties? Possibly merge.
    // There's lot of things I'm expecting to be implicitly always true if this is not another group.

    const onlyOnePatch = thisOperation.patches.length === 1 && thisOperation.inversePatches.length === 1 && previousOperation.patches.length === 1 && previousOperation.inversePatches.length === 1;

    if (onlyOnePatch) {
      const mergeResult = tryMergePatchesWithSamePath(previousOperation.patches[0], previousOperation.inversePatches[0], thisOperation.patches[0], thisOperation.inversePatches[0]);

      if (mergeResult) {
        if (mergeResult.patch === null && mergeResult.inversePatch === null) {
          thisOperation.patches = [];
          thisOperation.inversePatches = [];
        } else {
          thisOperation.patches = [mergeResult.patch];
          thisOperation.inversePatches = [mergeResult.inversePatch];
        }

        return true;
      }

      throw new Error("tryMergePatchesWithSamePath: Paths didn't match.");
    }

    console.error("Would've expected to be able to merge two grouped patch operations with 'None' group, but it failed. Please tell Tobias how to reproduce this.", {
      onlyOnePatch,
      this: this,
      previousOperation
    });
    throw new Error("Would've expected to be able to merge two grouped patch operations with 'None' group, but it failed. Please tell Tobias how to reproduce this.");
  } else {
    var _thisOperation$sideEf2;

    if (autoMergableGroups.indexOf(thisOperation.group) === -1) {
      console.error(`Unexpected group merging: ${thisOperation.group}. This might be fine, but it definitely is unexpected. Please tell Tobias how to reproduce this.`, {
        this: this,
        previousOperation
      });
      throw new Error(`Unexpected group merging: ${thisOperation.group}. This might be fine, but it definitely is unexpected. Please tell Tobias how to reproduce this.`);
    }

    if (((_thisOperation$sideEf2 = thisOperation.sideEffects) === null || _thisOperation$sideEf2 === void 0 ? void 0 : _thisOperation$sideEf2.length) > 0) {
      console.error("Only the first operation in a merge group should have side effects.", {
        this: this,
        previousOperation
      });
      throw new Error("Only the first operation in a merge group should have side effects.");
    }

    thisOperation.patches.splice(0, 0, ...previousOperation.patches);
    thisOperation.inversePatches.splice(0, 0, ...previousOperation.inversePatches);
    thisOperation.sideEffects = previousOperation.sideEffects;
    return true;
  }
}
export function mergeSinglePatchOp(thisOperation, previousOperation) {
  const mergeResult = tryMergePatchesWithSamePath(previousOperation.patch, previousOperation.inversePatch, thisOperation.patch, thisOperation.inversePatch);
  if (!mergeResult || !mergeResult.patch || !mergeResult.inversePatch) return false;
  thisOperation.patch = mergeResult.patch;
  thisOperation.inversePatch = mergeResult.inversePatch;
  return true;
}