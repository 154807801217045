import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2;

import { computed } from "mobx";
import { Model, model, prop } from "mobx-keystone";
import { MakeshiftTranslationSystemCategoryDataModel } from "./MakeshiftTranslationSystemCategoryDataModel";
export let MakeshiftTranslationSystemDataModel = (_dec = model('translation/MakeshiftTranslationSystemData'), _dec(_class = (_class2 = class MakeshiftTranslationSystemDataModel extends Model({
  tileTags: prop(() => new MakeshiftTranslationSystemCategoryDataModel({})),
  actionEditorTemplateTags: prop(() => new MakeshiftTranslationSystemCategoryDataModel({})),
  characterSkinVariantClasses: prop(() => new MakeshiftTranslationSystemCategoryDataModel({})),
  characterSkinVariantOptions: prop(() => new MakeshiftTranslationSystemCategoryDataModel({}))
}) {
  get totalCount() {
    return this.tileTags.count + this.actionEditorTemplateTags.count + this.characterSkinVariantClasses.count + this.characterSkinVariantOptions.count;
  }

  translatableEntityDataArray() {
    return [this.tileTags.translatableEntityData("Tile Category"), this.actionEditorTemplateTags.translatableEntityData("Action Editor Template Category"), this.characterSkinVariantClasses.translatableEntityData("Character Configuration Class"), this.characterSkinVariantOptions.translatableEntityData("Character Configuration Option")];
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "totalCount", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "totalCount"), _class2.prototype)), _class2)) || _class);