import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Container } from "pixi.js";
import { staticAssetLoader } from "../../loader/StaticAssetLoader";
export class HealthBarView extends Container {
  constructor(barScale, withFrame) {
    super();

    _defineProperty(this, "background", void 0);

    _defineProperty(this, "bar", void 0);

    _defineProperty(this, "frame", void 0);

    this.scale.set(barScale, barScale);
    this.background = staticAssetLoader.createStaticAssetView("Healthbar_Background");
    this.bar = staticAssetLoader.createStaticAssetView("Healthbar_Bar");
    this.bar.texture = this.bar.texture.clone();
    this.addChild(this.background, this.bar);

    if (withFrame) {
      this.frame = staticAssetLoader.createStaticAssetView("Healthbar_Player_Frame_Foreground");
      this.addChild(this.frame);
    }
  }

  setHealth(amount) {
    const sprite = this.bar;
    const newWidth = sprite.texture.baseTexture.width * amount;
    const oldWidth = sprite.width;
    sprite.texture.frame.width = newWidth;
    sprite.width = sprite.texture.frame.width;
    sprite.texture.updateUvs();
    return newWidth < oldWidth;
  }

  isDefeated() {
    return this.bar.width <= 0;
  }

}