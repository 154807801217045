var _dec, _class;

import { model, Model, prop } from "mobx-keystone";
export let NPCReferenceModel = (_dec = model("actions/NPCReferenceModel"), _dec(_class = class NPCReferenceModel extends Model({
  npcId: prop("-1").withSetter(),
  npcName: prop("").withSetter()
}) {
  isComplete() {
    return this.npcId != "-1";
  }

}) || _class);