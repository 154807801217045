import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { editorClient } from "../../../communication/EditorClient";
import { tileDataEqualsChangeableTileDataSnapshot, getChangeableTileDataSnapshot, getEmptyChangeableTileDataSnapshot } from "../../../../shared/game/TileDataModel";
import { mainMapEditorStore } from "../../MapEditorStore";
import { sharedStore } from "../../SharedStore";
export function getChangesToClearTilesToMakeWayForGroundTile(mapStore, tileAssetId, x, y, plane, layer) {
  const tileAsset = sharedStore.getTileAsset(tileAssetId);
  if (!tileAsset) return [];
  const {
    tilesX,
    tilesY
  } = tileAsset; // Delete all tiles that...
  // - are on the same layer and plane, but not exactly on x|y AND
  // - are intersecting with any position of the new tile we are setting

  const tilesToBeDeleted = mapStore.currentMap.tiles.filter(tile => tile.position.plane === plane && tile.position.layer === layer && (tile.position.x !== x || tile.position.y !== y)).filter(tile => tile.isIntersectingXY(x, y, tilesX, tilesY, sharedStore.getTileAsset));
  const emptyData = getEmptyChangeableTileDataSnapshot();
  return tilesToBeDeleted.map(tile => ({
    position: tile.position,
    newData: emptyData
  }));
}
export function undoableMapEditorRemoveTiles(currentMapStore, plane, tileAssetsToChange) {
  executeUndoableMapEditorSetAndSelectTilesWithCheck(currentMapStore, plane, tileAssetsToChange, null, "mapEditorClearTile");
}
export function undoableMapEditorSwapTileLayers(currentMapStore, plane, tileAssetsToChange) {
  executeUndoableMapEditorSetAndSelectTilesWithCheck(currentMapStore, plane, tileAssetsToChange, null, "mapEditorLayerOrderChanged");
}
export function undoableMapEditorSetAndSelectTiles(currentMapStore, plane, tileAssetsToChange, selectionPosition) {
  executeUndoableMapEditorSetAndSelectTilesWithCheck(currentMapStore, plane, tileAssetsToChange, selectionPosition, "mapEditorSetTile");
}

function executeUndoableMapEditorSetAndSelectTilesWithCheck(currentMapStore, plane, tileAssetsToChange, selectionPosition, operationTypeTranslationKey) {
  const tileChangeForward = new Array();

  for (const changeData of tileAssetsToChange) {
    const {
      newData,
      position
    } = changeData;
    const {
      x,
      y,
      layer
    } = position;
    const tile = currentMapStore.currentMap.getTile(x, y, layer, plane);
    if (tileDataEqualsChangeableTileDataSnapshot(tile, newData)) continue;
    const currentSnapshot = getChangeableTileDataSnapshot(tile);
    tileChangeForward.push({
      newData,
      previousData: currentSnapshot,
      position: {
        x,
        y,
        layer
      }
    });
  } // Skip if no actual changes are necessary


  if (tileChangeForward.length === 0) return;
  executeUndoableOperation(new MapEditorSetTilesOp(currentMapStore, plane, tileChangeForward, selectionPosition, operationTypeTranslationKey));
}

class MapEditorSetTilesOp extends UndoableOperation {
  constructor(currentMapStore, plane, tileChangeForward, selectionPosition, operationTypeTranslationKey) {
    super(operationTypeTranslationKey); // Set Reverse Changes

    this.currentMapStore = currentMapStore;
    this.plane = plane;
    this.tileChangeForward = tileChangeForward;
    this.selectionPosition = selectionPosition;

    _defineProperty(this, "tileChangeReverse", new Array());

    _defineProperty(this, "previousSelectedTilePosition", void 0);

    for (const tileChange of tileChangeForward) {
      this.tileChangeReverse.push({
        newData: tileChange.previousData,
        previousData: tileChange.newData,
        position: tileChange.position
      });
    }

    this.previousSelectedTilePosition = mainMapEditorStore.selectedTilePosition;
  }

  async execute() {
    await editorClient.setCurrentMapTiles(this.currentMapStore, this.tileChangeForward, this.plane);

    if (this.selectionPosition) {
      mainMapEditorStore.setSelectedTilePosition(this.selectionPosition);
    }
  }

  async reverse() {
    await editorClient.setCurrentMapTiles(this.currentMapStore, this.tileChangeReverse, this.plane);

    if (this.selectionPosition) {
      mainMapEditorStore.setSelectedTilePosition(this.previousSelectedTilePosition);
    }
  }

}