import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { TranslatedError } from "../../../../shared/definitions/errors/TranslatedError";
import { combatEditorStore } from "../../CombatEditorStore";
import { combatStore } from "../../CombatStore";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableSelectGesturePattern(id) {
  executeUndoableOperation(new CombatGesturePatternSelectionOp(id));
}
export function undoableDeselectGesturePattern() {
  executeUndoableOperation(new CombatGesturePatternSelectionOp(null));
}
export function selectGesturePattern(id) {
  if (id && !combatStore.config.findGesturePattern(id)) throw new TranslatedError("editor.error_gesture_pattern_does_not_exist");
  combatEditorStore.setSelectedGesturePattern(id);
}

class CombatGesturePatternSelectionOp extends UndoableOperation {
  constructor(id) {
    super("combatGesturePatternSelection" + (!id ? "Clear" : ""));
    this.id = id;

    _defineProperty(this, "previousId", void 0);

    this.previousId = combatEditorStore.selectedGesturePatternId;
  }

  async execute() {
    selectGesturePattern(this.id);
  }

  async reverse() {
    selectGesturePattern(this.previousId);
  }

}