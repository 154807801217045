import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable, observable } from "mobx";
import { MapState } from "./MapState";
import { doesPlacementSelectionLooselyEqual, mapRelatedStoreGetFilteredTileAssets, mapRelatedStoreGetTags } from "./MapRelatedStore";
import { doesTilePositionEqual } from "../../shared/definitions/other/TilePosition";
import { getTileOffsetX, getTileOffsetY, getTileSizeZOffset } from "../helper/mapElementSortingHelper";
import { getTileLayerType, groundLayerIndex, groundMinus1LayerIndex, layerConstants } from "../../shared/data/layerConstants";
import { TileLayerType } from "../../shared/resources/TileLayerType";
import { gameStore } from "./GameStore";
import { CurrentMapStore } from "./CurrentMapStore";
import { sharedStore } from "./SharedStore";
import { arrayEquals, getAllStringEnumValues } from "../../shared/helper/generalHelpers";
import { TileVisibility } from "../../shared/resources/TileAssetModel";
import { editorStore } from "./EditorStore";
import { userStore } from "./UserStore";
import { DynamicMapElementAreaTriggerModel } from "../../shared/game/dynamicMapElements/DynamicMapElementAreaTriggerModel";
import { DynamicMapElementNPCModel } from "../../shared/game/dynamicMapElements/DynamicMapElementNPCModel";
import { gameConstants } from "../data/gameConstants";
export let EditorToolType;

(function (EditorToolType) {
  EditorToolType[EditorToolType["PlaceAsset"] = 0] = "PlaceAsset";
  EditorToolType[EditorToolType["SingleSelect"] = 1] = "SingleSelect";
})(EditorToolType || (EditorToolType = {}));

export let MapEditorComplexity;

(function (MapEditorComplexity) {
  MapEditorComplexity["Simple"] = "Simple";
  MapEditorComplexity["Complex"] = "Complex";
})(MapEditorComplexity || (MapEditorComplexity = {}));

export const mapEditorComplexities = getAllStringEnumValues(MapEditorComplexity);
export class MapEditorStore {
  // note this must match the i18n entries
  // Scope: PlaceAsset
  // Scope: SingleSelect
  constructor(ignoreHeightPlanes, mapUsedForGame) {
    this.ignoreHeightPlanes = ignoreHeightPlanes;

    _defineProperty(this, "currentMapStore", void 0);

    _defineProperty(this, "selectedTool", EditorToolType.SingleSelect);

    _defineProperty(this, "selectedCategory", void 0);

    _defineProperty(this, "selectedLayer", null);

    _defineProperty(this, "selectedPlane", 0);

    _defineProperty(this, "placementSelection", {});

    _defineProperty(this, "tileAssetTagFilter", null);

    _defineProperty(this, "searchFilter", observable.map());

    _defineProperty(this, "selectedTilePosition", void 0);

    _defineProperty(this, "showGamePreview", false);

    _defineProperty(this, "onlyShowEnemyCharacters", false);

    _defineProperty(this, "mapState", new MapState());

    _defineProperty(this, "allowMultiSelection", false);

    _defineProperty(this, "allowToggleSelection", false);

    _defineProperty(this, "selectableDynamicMapElements", []);

    _defineProperty(this, "showEmptyAreaTriggersAndNPCsEvenIfNotHighlighted", false);

    _defineProperty(this, "cutDynamicMapElementModelId", void 0);

    _defineProperty(this, "hoveredConflictResolutionOriginTileData", void 0);

    _defineProperty(this, "mapEditorComplexity", MapEditorComplexity.Complex);

    _defineProperty(this, "highlightedElements", void 0);

    _defineProperty(this, "highlightedTiles", void 0);

    _defineProperty(this, "canvasWidth", void 0);

    _defineProperty(this, "canvasHeight", void 0);

    _defineProperty(this, "selectorMapEditorOpenCounter", 0);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
    this.currentMapStore = new CurrentMapStore(mapUsedForGame);
  }

  get effectiveSelectedLayer() {
    return this.complexityUseLayers ? this.selectedLayer : null;
  }

  get currentLayerType() {
    return getTileLayerType(this.effectiveSelectedLayer);
  }

  get selectedAsset() {
    if (this.placementSelection.selectedTileAssetId === null) return null;
    return sharedStore.getTileAsset(this.placementSelection.selectedTileAssetId);
  }

  get hasSelectedTile() {
    return !!this.selectedTilePosition;
  }

  get selectedTileDataWithXYOverlap() {
    const {
      x,
      y,
      plane
    } = this.selectedTilePosition;
    if (this.ignoreHeightPlanes) return this.currentMapStore.currentMap.getTilesWithXYOverlap(x, y, sharedStore.getTileAsset);
    return this.currentMapStore.currentMap.getTilesOnPlaneWithXYOverlap(x, y, plane, sharedStore.getTileAsset);
  }

  get selectedOverlappingGroundTilesFromDifferentLayer() {
    return this.currentMapStore.currentMap.tiles.filter(tileData => isOverlappingGroundTileOnDifferentLayer(tileData, this.selectedTilePosition));
  }

  get selectedTileDataForOriginLineDisplay() {
    const {
      x,
      y,
      plane
    } = this.selectedTilePosition;
    return this.currentMapStore.currentMap.tiles.filter(tileData => {
      var _sharedStore$getTileA;

      return (getTileLayerType(tileData.position.layer) == TileLayerType.Decoration && tileData.isOnPlaneAndOverlappingXY(x, y, plane, sharedStore.getTileAsset) || isOverlappingGroundTileOnDifferentLayer(tileData, this.selectedTilePosition)) && !((_sharedStore$getTileA = sharedStore.getTileAsset(tileData.tileAssetId)) !== null && _sharedStore$getTileA !== void 0 && _sharedStore$getTileA.size.isFlat);
    });
  }

  get selectedGroundTileDataWithGapsDirectOnly() {
    const groundTileData = [];

    for (let i = 0; i < layerConstants.numberOfGroundLayers; i++) {
      groundTileData.push(null);
    }

    this.selectedTileDataWithXYOverlap.forEach(tileData => {
      const {
        layer
      } = tileData.position;

      if (getTileLayerType(layer) === TileLayerType.Ground) {
        groundTileData[layer * -1] = tileData;
      }
    });
    return groundTileData;
  }

  get selectedDecorationTileData() {
    return this.selectedTileDataWithXYOverlap.filter(tileData => getTileLayerType(tileData.position.layer) === TileLayerType.Decoration);
  }

  get selectedDecorationTileDataNonFlat() {
    return this.selectedDecorationTileData.filter(tileData => {
      var _sharedStore$getTileA2;

      return !((_sharedStore$getTileA2 = sharedStore.getTileAsset(tileData.tileAssetId)) !== null && _sharedStore$getTileA2 !== void 0 && _sharedStore$getTileA2.size.isFlat);
    });
  }

  get selectedDecorationTileDataFlatX() {
    return this.selectedDecorationTileData.filter(tileData => {
      var _sharedStore$getTileA3;

      return (_sharedStore$getTileA3 = sharedStore.getTileAsset(tileData.tileAssetId)) === null || _sharedStore$getTileA3 === void 0 ? void 0 : _sharedStore$getTileA3.size.isFlatX;
    });
  }

  get selectedDecorationTileDataFlatY() {
    return this.selectedDecorationTileData.filter(tileData => {
      var _sharedStore$getTileA4;

      return (_sharedStore$getTileA4 = sharedStore.getTileAsset(tileData.tileAssetId)) === null || _sharedStore$getTileA4 === void 0 ? void 0 : _sharedStore$getTileA4.size.isFlatY;
    });
  }

  get selectedDecorationTileDataFlatZ() {
    return this.selectedDecorationTileData.filter(tileData => {
      var _sharedStore$getTileA5;

      return (_sharedStore$getTileA5 = sharedStore.getTileAsset(tileData.tileAssetId)) === null || _sharedStore$getTileA5 === void 0 ? void 0 : _sharedStore$getTileA5.size.isFlatZ;
    });
  }

  get selectedDynamicMapElements() {
    if (!this.hasSelectedTile) return [];
    const {
      x,
      y,
      plane
    } = this.selectedTilePosition;
    let elements = [];
    if (this.ignoreHeightPlanes) elements = this.currentMapStore.currentMap.getAllDynamicMapElementsAtPositionXY(x, y);else elements = this.currentMapStore.currentMap.getAllDynamicMapElementsAtPositionXYPlane(x, y, plane);

    if (this.highlightedElements) {
      elements = elements.filter(element => this.highlightedElements.has(element) || this.showEmptyAreaTriggersAndNPCsEvenIfNotHighlighted && (element instanceof DynamicMapElementAreaTriggerModel || element instanceof DynamicMapElementNPCModel // might have (or get) a sensor trigger
      ));
    }

    return elements;
  }

  isDynamicMapElementSelected(modelId) {
    return this.selectedDynamicMapElements.some(element => element.$modelId == modelId);
  }

  get selectedInteractionTriggerTiles() {
    if (!this.hasSelectedTile) return [];
    let tiles = this.selectedTileDataWithXYOverlap.filter(tileData => tileData.isInteractionTrigger);

    if (this.highlightedTiles) {
      tiles = tiles.filter(tile => this.highlightedTiles.has(tile));
    }

    return tiles;
  }

  get selectedDebugStartMarker() {
    if (this.highlightedElements) return null;
    if (!gameStore.debugStartMarker) return null;
    const {
      x,
      y,
      plane
    } = gameStore.debugStartMarker.position;
    const {
      x: tileX,
      y: tileY,
      plane: tilePlane
    } = this.selectedTilePosition;
    if (x !== tileX || y !== tileY || plane !== tilePlane) return null;
    return gameStore.debugStartMarker;
  }

  prepareActionMapViewer(newSelectableDynamicMapElements, showEmptyAreaTriggersAndNPCsEvenIfNotHighlighted) {
    if (!arrayEquals(this.selectableDynamicMapElements, newSelectableDynamicMapElements)) {
      this.selectableDynamicMapElements = newSelectableDynamicMapElements;
    }

    this.showEmptyAreaTriggersAndNPCsEvenIfNotHighlighted = showEmptyAreaTriggersAndNPCsEvenIfNotHighlighted;
  }

  clearTileSelectionIfMatches(id) {
    if (this.placementSelection.selectedTileAssetId === id) {
      this.clearPlacementSelection();
    }
  }

  setPlacementSelection(selection) {
    if (!selection) selection = {};
    if (doesPlacementSelectionLooselyEqual(this.placementSelection, selection)) return;
    this.placementSelection = selection;
  }

  clearPlacementSelection() {
    this.setPlacementSelection(null);
  }

  setTool(tool) {
    this.selectedTool = tool;
  }

  setPlacementSelectorCategory(category) {
    this.selectedCategory = category;
  }

  setPlane(plane) {
    this.selectedPlane = plane;

    if (this.selectedAsset && !this.selectedAsset.isMadeForPlane(plane)) {
      this.clearPlacementSelection();
    }
  }

  getHeightPlaneForNewAsset(x, y) {
    if (!this.ignoreHeightPlanes) return this.selectedPlane;
    const tiles = this.currentMapStore.currentMap.getTilesWithXYOverlap(x, y, sharedStore.getTileAsset); // Are there any tiles on this position already? If not, use lowest plane.

    if (tiles.length == 0) return gameConstants.minPlane; // There are tiles. Find the tile with the heightest plane and use that plane to place the new asset on.

    const tilePlanes = tiles.map(tile => tile.position.plane);
    return Math.max(...tilePlanes);
  }

  setTileAssetTagFilter(tag) {
    this.tileAssetTagFilter = tag;
  }

  setSearchFilter(category, filter) {
    this.searchFilter.set(category, filter);
  }

  getSearchFilter(category) {
    return this.searchFilter.get(category) || "";
  }

  setSelectedTilePosition(tilePosition) {
    this.selectedTilePosition = tilePosition;
  }

  isSelectedTilePosition(tilePosition) {
    if (!this.selectedTilePosition && !tilePosition) return true;
    if (!this.selectedTilePosition) return false;
    return doesTilePositionEqual(this.selectedTilePosition, tilePosition);
  }

  get isAssetSelectionEnabled() {
    return this.selectedTool == EditorToolType.PlaceAsset;
  }

  setSelectedLayer(value) {
    this.selectedLayer = value;
  }

  toggleGamePreview() {
    this.showGamePreview = !this.showGamePreview;
  }

  setCutDynamicMapElementModelId(modelId) {
    this.cutDynamicMapElementModelId = modelId;
  }

  get cutDynamicMapElement() {
    var _this$currentMapStore;

    if (!this.setCutDynamicMapElementModelId) return null;
    return (_this$currentMapStore = this.currentMapStore.currentMap) === null || _this$currentMapStore === void 0 ? void 0 : _this$currentMapStore.getDynamicMapElementByModelId(this.cutDynamicMapElementModelId);
  }

  get hasCutDynamicMapElement() {
    return !!this.cutDynamicMapElement;
  }

  get showConflictResolutionOriginLines() {
    return this.hoveredConflictResolutionOriginTileData;
  }

  setHoveredConflictResolutionOriginTileData(value) {
    this.hoveredConflictResolutionOriginTileData = value;
  }

  toggleOnlyShowEnemyCharacters() {
    this.onlyShowEnemyCharacters = !this.onlyShowEnemyCharacters;
  }

  setMapEditorComplexity(value) {
    this.mapEditorComplexity = value;

    if (!this.complexityShowHeightPlanes && this.selectedPlane > 0) {
      this.setPlane(0);
    }

    if (!this.complexityShowLayerMinusOne && this.selectedLayer === groundMinus1LayerIndex) {
      this.setSelectedLayer(groundLayerIndex);
    }
  }

  get isComplex() {
    return this.mapEditorComplexity === MapEditorComplexity.Complex;
  }

  get complexityShowHeightPlanes() {
    return this.isComplex;
  }

  get complexityShowLayerMinusOne() {
    return userStore.mayAccessProductionEditorComplexityFeatures;
  }

  get complexityShowDebugStartMarker() {
    return this.isComplex;
  }

  get complexityUseLayers() {
    return userStore.mayAccessProductionEditorComplexityFeatures;
  }

  get complexityUseTileConflictResolution() {
    return this.isComplex;
  }

  doesComplexityAllowTileAsset(tileAsset) {
    if (editorStore.isMainGameEditor) return true;
    const {
      visibilityInEditor
    } = tileAsset;

    switch (visibilityInEditor) {
      case TileVisibility.ProductionOnly:
        return false;

      case TileVisibility.ComplexOnly:
        return this.isComplex;

      case TileVisibility.ShowAlways:
        return true;

      default:
        throw new Error("Not implemented: " + visibilityInEditor);
    }
  }

  get filteredTileAssets() {
    var _this$selectedCategor, _this$selectedCategor2;

    return mapRelatedStoreGetFilteredTileAssets(this, true, (_this$selectedCategor = this.selectedCategory) === null || _this$selectedCategor === void 0 ? void 0 : _this$selectedCategor.includeTileCategories, (_this$selectedCategor2 = this.selectedCategory) === null || _this$selectedCategor2 === void 0 ? void 0 : _this$selectedCategor2.excludeTileCategories);
  }

  get filteredTileAssetsWithoutSearch() {
    var _this$selectedCategor3, _this$selectedCategor4;

    return mapRelatedStoreGetFilteredTileAssets(this, false, (_this$selectedCategor3 = this.selectedCategory) === null || _this$selectedCategor3 === void 0 ? void 0 : _this$selectedCategor3.includeTileCategories, (_this$selectedCategor4 = this.selectedCategory) === null || _this$selectedCategor4 === void 0 ? void 0 : _this$selectedCategor4.excludeTileCategories);
  }

  get tags() {
    var _this$selectedCategor5, _this$selectedCategor6;

    return mapRelatedStoreGetTags(this, (_this$selectedCategor5 = this.selectedCategory) === null || _this$selectedCategor5 === void 0 ? void 0 : _this$selectedCategor5.includeTileCategories, (_this$selectedCategor6 = this.selectedCategory) === null || _this$selectedCategor6 === void 0 ? void 0 : _this$selectedCategor6.excludeTileCategories);
  }

  setHighlightedElements(elements) {
    this.highlightedElements = elements;
  }

  setHighlightedTiles(tiles) {
    this.highlightedTiles = tiles;
  }

  setCanvasSize(width, height) {
    this.canvasWidth = width;
    this.canvasHeight = height;
  }

  increaseActionMapEditorOpenCounter() {
    this.selectorMapEditorOpenCounter++;
  }

}

_defineProperty(MapEditorStore, "ToolTypeToName", new Map([[EditorToolType.PlaceAsset, "PlaceAsset"], [EditorToolType.SingleSelect, "SingleSelection"]]));

function isOverlappingGroundTileOnDifferentLayer(tileData, position) {
  if (tileData.position.plane === position.plane) return false;
  if (getTileLayerType(tileData.position.layer) !== TileLayerType.Ground) return false;
  const tileAsset = sharedStore.getTileAsset(tileData.tileAssetId);
  if (!tileAsset) return false;
  const {
    offsetZComputed
  } = tileAsset;
  const {
    x,
    y,
    plane
  } = tileData.position;
  const offsetX = getTileOffsetX(tileData, tileAsset);
  const offsetY = getTileOffsetY(tileData, tileAsset);
  const sizeZOffset = getTileSizeZOffset(tileData); // Normalize title box

  const {
    x: sizeX,
    y: sizeY
  } = tileAsset.size;
  const sizeZ = tileAsset.size.z + sizeZOffset;
  const tileXMin = x + plane;
  const tileXMax = Math.ceil(tileXMin + sizeX + offsetX);
  const tileYMin = y + plane;
  const tileYMax = Math.ceil(tileYMin + sizeY + offsetY);
  const tileZMin = plane + offsetZComputed;
  const tileZMax = Math.ceil(tileZMin + sizeZ); // Normalize search position

  const positionX = position.x + position.plane;
  const positionY = position.y + position.plane;
  const positionZ = position.plane;
  return tileXMin <= positionX && positionX < tileXMax && tileYMin <= positionY && positionY < tileYMax && tileZMin <= positionZ && positionZ <= tileZMax;
}

export const mainMapEditorStore = new MapEditorStore(false, true);
export const selectorMapEditorStore = new MapEditorStore(true, false);
export const mapEditorStores = [mainMapEditorStore, selectorMapEditorStore];