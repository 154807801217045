import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2;

import { model, Model, modelAction, objectMap, prop } from "mobx-keystone";
import { getSharedCurrentLanguageKey } from "../data/dataAccess";
import { fallbackLanguages } from "../data/languages";
import { isBlank } from "../helper/generalHelpers";
export let TranslatedString = (_dec = model("game/TranslatedString"), _dec(_class = (_class2 = class TranslatedString extends Model({
  text: prop(() => objectMap()),
  comment: prop("").withSetter()
}) {
  /**
   * Gets the translation for the supplied language key.
   * @param languageKey
   * @param useFallbackLanguages
   * If false: returns the translation, or an empty string if it doesn't exist.
   * If true, returns the first version that exists:
   * - languageKey + languageKeyPostfix
   * - fallbackLanguageKey + languageKeyPostfix, returned in the format "[en: Fallback value]"
   * @param languageKeyPostfix
   * @returns
   */
  get(languageKey, useFallbackLanguages = true, languageKeyPostfix = "") {
    const value = this.text.get(languageKey + languageKeyPostfix);

    if (!value && useFallbackLanguages) {
      for (const fallbackLanguage of fallbackLanguages) {
        if (languageKey === fallbackLanguage) continue;
        const fallbackValue = this.text.get(fallbackLanguage + languageKeyPostfix);

        if (fallbackValue) {
          return `[${fallbackLanguage}: ${fallbackValue}]`;
        }
      }
    }

    return value || "";
  }
  /**
   * Gets a gendered translation for the languageKey.
   * @param languageKey
   * @param gender
   * @param useFallbackGenderAndLanguages
   * If false: returns the gendered version, or an empty string if it doesn't exist.
   * If true, returns the first version that exists:
   * - languageKey + gender postfix
   * - languageKey (without gender postfix)
   * - fallbackLanguageKey (without gender postfix), returned in the format "[en: Fallback value]"
   * - an empty string
   * @returns
   */


  getForGender(languageKey, gender, useFallbackGenderAndLanguages) {
    const exactMatchValue = this.text.get(languageKey + gender);
    if (exactMatchValue || !useFallbackGenderAndLanguages) return exactMatchValue || "";
    return this.get(languageKey, useFallbackGenderAndLanguages);
  }

  isCurrentLanguageEmpty() {
    const currentLanguageKey = getSharedCurrentLanguageKey();
    return this.isLanguageEmpty(currentLanguageKey);
  }

  isLanguageEmpty(languageKey) {
    return isBlank(this.text.get(languageKey));
  }

  set(languageKey, value) {
    this.text.set(languageKey, value);
  }

  setForGender(languageKey, gender, value) {
    this.text.set(languageKey + gender, value);
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "set", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "set"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setForGender", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "setForGender"), _class2.prototype)), _class2)) || _class);