import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2;

import { computed } from "mobx";
import { getParent, model, Model, prop } from "mobx-keystone";
export let TileDataInteractionTriggerModel = (_dec = model("game/TileDataInteractionTriggerModel"), _dec(_class = (_class2 = class TileDataInteractionTriggerModel extends Model({
  isInteractionTrigger: prop(true).withSetter(),
  isModuleGate: prop(false).withSetter(),
  label: prop("").withSetter(),
  tileOffsetX: prop(0).withSetter(),
  tileOffsetY: prop(0).withSetter()
}) {
  get tileData() {
    return getParent(this);
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "tileData", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "tileData"), _class2.prototype)), _class2)) || _class);
export class ReadonlyTileDataInteractionTrigger {
  constructor(data) {
    _defineProperty(this, "$modelId", void 0);

    _defineProperty(this, "label", void 0);

    _defineProperty(this, "isInteractionTrigger", void 0);

    _defineProperty(this, "isModuleGate", void 0);

    _defineProperty(this, "tileOffsetX", void 0);

    _defineProperty(this, "tileOffsetY", void 0);

    this.$modelId = data.$modelId;
    this.label = data.label;
    this.isInteractionTrigger = data.isInteractionTrigger;
    this.isModuleGate = data.isModuleGate;
    this.tileOffsetX = data.tileOffsetX;
    this.tileOffsetY = data.tileOffsetY;
  }

}