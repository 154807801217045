import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { editorClient } from "../../../communication/EditorClient";
import { mainMapEditorStore } from "../../MapEditorStore";
export function undoableMapEditorDeleteMap(mapId) {
  executeUndoableOperation(new MapEditorDeleteMapOp(mapId));
}

class MapEditorDeleteMapOp extends UndoableOperation {
  constructor(mapId) {
    super("mapEditorDeleteMap");
    this.mapId = mapId;

    _defineProperty(this, "mapWasOpen", void 0);

    this.mapWasOpen = mainMapEditorStore.currentMapStore.currentMapId === mapId;
  }

  async execute() {
    await editorClient.deleteMap(mainMapEditorStore.currentMapStore, this.mapId);
  }

  async reverse() {
    await editorClient.undeleteMap(mainMapEditorStore.currentMapStore, this.mapId);

    if (this.mapWasOpen) {
      await editorClient.openMapInMapEditor(mainMapEditorStore.currentMapStore, this.mapId);
    }
  }

}