import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable } from "mobx";
import { editorMapStore, MapStatus } from "./EditorMapStore";
import { editorStore } from "./EditorStore";
import { gameStore } from "./GameStore";
export class CurrentMapStore {
  constructor(mapUsedForGame) {
    this.mapUsedForGame = mapUsedForGame;

    _defineProperty(this, "runningMapOperation", void 0);

    _defineProperty(this, "currentMapId", null);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
  }

  get hasCurrentMap() {
    return this.currentMapId !== null;
  }

  get currentMap() {
    if (!this.hasCurrentMap) return null;
    return editorMapStore.maps.get(this.currentMapId);
  }

  setRunningMapOperation(value) {
    this.runningMapOperation = value;
  }

  setCurrentMap(mapId) {
    if (this.currentMapId === mapId) return;
    if (mapId !== null && editorMapStore.getMapStatus(mapId) !== MapStatus.Loaded) throw new Error("Cannot call setCurrentMap with a map that has not been loaded yet.");
    this.currentMapId = mapId;

    if (this.mapUsedForGame) {
      gameStore.setSelectedStartMap(mapId);
    }
  }

  clearCurrentMap() {
    this.setCurrentMap(null);
  }

  get isUserAllowedToEditCurrentMap() {
    return editorStore.isMainGameEditor || this.isCurrentMapPartOfTheModule;
  }

  get isCurrentMapPartOfTheModule() {
    var _this$currentMap;

    return editorStore.sessionModuleId === ((_this$currentMap = this.currentMap) === null || _this$currentMap === void 0 ? void 0 : _this$currentMap.moduleOwner);
  }

}