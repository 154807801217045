import _defineProperty from "@babel/runtime/helpers/defineProperty";
import EventEmitter from "eventemitter3";
import { AnimatedSprite, Container, Texture } from "pixi.js";
import { TileImageUsage } from "../../../../shared/resources/TileAssetModel";
import { tileToWorldPositionX, tileToWorldPositionY } from "../../../helper/pixiHelpers";
import { tileImageStore } from "../../../stores/TileImageStore";
import { WaterOverlayAnimatedSprite } from "./WaterOverlayAnimatedSprite";
export class TileViewBase extends Container {
  constructor(tileData, tileAssetData, tileImageUsage, water) {
    super();
    this.tileData = tileData;
    this.tileAssetData = tileAssetData;
    this.tileImageUsage = tileImageUsage;
    this.water = water;

    _defineProperty(this, "events", new EventEmitter());

    _defineProperty(this, "sprite", void 0);

    _defineProperty(this, "_partOfLoop", false);

    this.emitTileVisualsUpdated = this.emitTileVisualsUpdated.bind(this);
  }

  refreshTileVisuals() {
    if (this.tileAssetData) {
      const isWaterOverlay = this.tileImageUsage === TileImageUsage.WaterMask || this.tileImageUsage === TileImageUsage.WaterMaskForeground;
      this.createSpriteIfNeeded(isWaterOverlay);
      let mainTileImageUsage = this.tileImageUsage;

      if (this.tileImageUsage === TileImageUsage.WaterMask) {
        mainTileImageUsage = TileImageUsage.Background;
      } else if (this.tileImageUsage === TileImageUsage.WaterMaskForeground) {
        mainTileImageUsage = TileImageUsage.Foreground;
      }

      const mainAssetData = this.tileAssetData.imageProperties(mainTileImageUsage);
      tileImageStore.adjustTileView(this.sprite, mainAssetData, this.tileAssetId, mainTileImageUsage);

      if (isWaterOverlay && this.sprite.visible) {
        const waterMaskTexture = tileImageStore.getTexture(this.tileAssetId, this.tileImageUsage);
        this.sprite.visible = !!waterMaskTexture;

        if (waterMaskTexture) {
          const waterOverlaySprite = this.sprite;
          waterOverlaySprite.waterMaskTexture = waterMaskTexture;
          const mainTexture = this.sprite.texture;

          if (waterMaskTexture.frame.width != mainTexture.frame.width || waterMaskTexture.frame.height != mainTexture.frame.height) {
            waterMaskTexture.frame.width = mainTexture.frame.width;
            waterMaskTexture.frame.height = mainTexture.frame.height;
            waterMaskTexture.updateUvs();
          }
        }
      }

      const offsetX = this.tileData.additionalOffsetX - this.tileData.additionalOffsetZ;
      const offsetY = this.tileData.additionalOffsetY - this.tileData.additionalOffsetZ;

      if (offsetX || offsetY) {
        this.sprite.position.x += Math.round(tileToWorldPositionX(offsetX, offsetY));
        this.sprite.position.y += Math.round(tileToWorldPositionY(offsetX, offsetY));
      }
    } else {
      // Create placeholder for asset with deleted tileAssetData
      this.createSpriteIfNeeded(false);
      this.sprite.visible = false;
    }

    this.emitTileVisualsUpdated();
  }

  createSpriteIfNeeded(shouldBeWaterOverlay) {
    const spriteExists = !!this.sprite;
    const spriteIsWaterOverlay = this.sprite instanceof WaterOverlayAnimatedSprite;
    if (spriteExists && shouldBeWaterOverlay === spriteIsWaterOverlay) return;
    let newSprite = null;

    if (shouldBeWaterOverlay) {
      newSprite = new WaterOverlayAnimatedSprite(this.water, [Texture.EMPTY], false);
    } else {
      newSprite = new AnimatedSprite([Texture.EMPTY], false);
    }

    if (newSprite) {
      if (this.sprite) {
        this.removeChild(this.sprite);
        this.sprite.destroy();
      }

      this.sprite = newSprite;
      this.addChildAt(newSprite, 0);
      this.refreshPartOfLoop();
    }
  }

  get tilePosition() {
    return this.tileData.position;
  }

  get tileAssetId() {
    return this.tileData.tileAssetId;
  }

  get hasVisibleInvalidTexture() {
    if (!this.sprite || !this.sprite.visible) return false;

    if (this.sprite instanceof WaterOverlayAnimatedSprite) {
      var _this$sprite$texture, _this$sprite$waterMas;

      return !((_this$sprite$texture = this.sprite.texture) !== null && _this$sprite$texture !== void 0 && _this$sprite$texture.valid) || !((_this$sprite$waterMas = this.sprite.waterMaskTexture) !== null && _this$sprite$waterMas !== void 0 && _this$sprite$waterMas.valid);
    } else {
      var _this$sprite$texture2;

      return !((_this$sprite$texture2 = this.sprite.texture) !== null && _this$sprite$texture2 !== void 0 && _this$sprite$texture2.valid);
    }
  }

  get partOfLoop() {
    return this._partOfLoop;
  }

  set partOfLoop(value) {
    if (this._partOfLoop == value) return;
    this._partOfLoop = value;
    this.refreshPartOfLoop();
  }

  refreshPartOfLoop() {}

  emitTileVisualsUpdated() {
    this.events.emit("tileVisualsUpdated");
  }

}