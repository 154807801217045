import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { editorClient } from "../../../communication/EditorClient";
import { imageStore } from "../../ImageStore";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableCreateImage(imageFile, usecase) {
  executeUndoableOperation(new ImageCreateOp(imageFile, usecase));
}

class ImageCreateOp extends UndoableOperation {
  constructor(imageFile, usecase) {
    super("createImage");
    this.imageFile = imageFile;
    this.usecase = usecase;

    _defineProperty(this, "imageId", void 0);
  }

  async execute() {
    if (this.imageId) await editorClient.undeleteImage(this.imageId);else this.imageId = await imageStore.provideImageFromLocalFilesystem(this.imageFile, this.usecase);
  }

  async reverse() {
    await imageStore.deleteImage(this.imageId);
  }

}