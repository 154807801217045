import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _dec, _class;

import { model, Model, prop } from "mobx-keystone";
export let MapDataPropertiesModel = (_dec = model("game/MapDataPropertiesModel"), _dec(_class = class MapDataPropertiesModel extends Model({
  name: prop("").withSetter(),
  sortingPriority: prop(0).withSetter(),
  shouldShowWater: prop(true).withSetter(),
  backgroundSoundFilePath: prop(null).withSetter()
}) {}) || _class);
export class ReadonlyMapDataProperties {
  constructor(data) {
    _defineProperty(this, "name", void 0);

    _defineProperty(this, "sortingPriority", void 0);

    _defineProperty(this, "shouldShowWater", void 0);

    _defineProperty(this, "backgroundSoundFilePath", void 0);

    this.name = data.name;
    this.sortingPriority = data.sortingPriority;
    this.shouldShowWater = data.shouldShowWater;
    this.backgroundSoundFilePath = data.backgroundSoundFilePath;
  }

}