import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _dec, _class;

import { model, Model, prop } from "mobx-keystone";
import { ReadonlyPosition } from "../PositionModel";
export let DynamicMapElementAreaTriggerModel = (_dec = model("game/DynamicMapElementAreaTriggerModel"), _dec(_class = class DynamicMapElementAreaTriggerModel extends Model({
  position: prop(),
  id: prop("").withSetter(),
  isModuleGate: prop(false).withSetter()
}) {
  get isInteractionTrigger() {
    return false;
  }

  createReadOnlyVersion() {
    return new ReadonlyDynamicMapElementAreaTrigger(this);
  }

}) || _class);
export class ReadonlyDynamicMapElementAreaTrigger {
  constructor(data) {
    _defineProperty(this, "position", void 0);

    _defineProperty(this, "id", void 0);

    _defineProperty(this, "$modelId", void 0);

    this.position = new ReadonlyPosition(data.position);
    this.id = data.id;
    this.$modelId = data.$modelId;
  }

  createReadOnlyVersion() {
    return this;
  }

}