var _dec, _class, _dec2, _class2;

import { Model, model, prop } from "mobx-keystone";
export let ReputationDeltaValueModel = (_dec = model("game/GameDesignVariablesModel/ReputationDeltaValueModel"), _dec(_class = class ReputationDeltaValueModel extends Model({
  Small: prop(5).withSetter(),
  Reasonable: prop(10).withSetter(),
  Large: prop(20).withSetter()
}) {}) || _class);
export let GameDesignVariablesModel = (_dec2 = model("game/GameDesignVariablesModel"), _dec2(_class2 = class GameDesignVariablesModel extends Model({
  reputationAmountBalance: prop(() => new ReputationDeltaValueModel({})),
  reputationActBalance: prop(() => [1, 1, 1, 1, 1]).withSetter(),
  reputationBalanceFactor: prop(1).withSetter(),
  gameStartingMapId: prop(null).withSetter()
}) {}) || _class2);