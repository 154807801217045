export class Arrays {
  /**
   * A filter method for arrays to filter for unique entries.
   * myArray = myArray.filter(Arrays.uniqueEntries);
   */
  static uniqueEntries(value, index, self) {
    return self.indexOf(value) === index;
  }
  /**
   * Returns entries that are in both assigned arrays.
   */


  static elementsInBoth(array1, array2) {
    if (!array1 || !array2) return [];
    return array1.filter(e => array2.indexOf(e) > -1);
  }

  static randomItem(array) {
    if (!array || !array.length) return null;
    return array[Math.floor(Math.random() * array.length)];
  }

}