import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { executeUndoableOperation } from "../UndoStore";
import { UndoableOperation } from "../UndoableOperation";
export function undoableAnimationEditorSelectAnimationState(nextAnimationState, store) {
  var _store$selectedAnimat;

  if (store.selectedAnimationState == nextAnimationState) return;
  executeUndoableOperation(new AnimationEditorSelectionOp("animationEditorSelectAnimationState", (_store$selectedAnimat = store.selectedAnimation) === null || _store$selectedAnimat === void 0 ? void 0 : _store$selectedAnimat.animation, nextAnimationState, store));
}
export function undoableAnimationEditorSelectAnimation(nextAnimation, store) {
  var _store$selectedAnimat2;

  if (((_store$selectedAnimat2 = store.selectedAnimation) === null || _store$selectedAnimat2 === void 0 ? void 0 : _store$selectedAnimat2.animation) == nextAnimation) return;
  executeUndoableOperation(new AnimationEditorSelectionOp("animationEditorSelectAnimation", nextAnimation, store.selectedAnimationState, store));
}

class AnimationEditorSelectionOp extends UndoableOperation {
  constructor(operationNameTranslationKey, nextSelectedAnimation, nextSelectedAnimationState, store) {
    var _store$selectedAnimat3;

    super(operationNameTranslationKey);
    this.nextSelectedAnimation = nextSelectedAnimation;
    this.nextSelectedAnimationState = nextSelectedAnimationState;
    this.store = store;

    _defineProperty(this, "previousSelectedAnimation", void 0);

    _defineProperty(this, "previousSelectedAnimationState", void 0);

    this.previousSelectedAnimationState = store.selectedAnimationState;
    this.previousSelectedAnimation = (_store$selectedAnimat3 = store.selectedAnimation) === null || _store$selectedAnimat3 === void 0 ? void 0 : _store$selectedAnimat3.animation;
  }

  static async apply(previousAnimation, nextAnimation, nextAnimationState, store) {
    try {
      if (previousAnimation != nextAnimation) await store.setSelectedAnimation(nextAnimation);
      store.setSelectedState(nextAnimationState);
    } catch (e) {
      await store.setSelectedAnimation(previousAnimation); // reset selection in case of an error

      throw e;
    }
  }

  async execute() {
    await AnimationEditorSelectionOp.apply(this.previousSelectedAnimation, this.nextSelectedAnimation, this.nextSelectedAnimationState, this.store);
  }

  async reverse() {
    await AnimationEditorSelectionOp.apply(this.nextSelectedAnimation, this.previousSelectedAnimation, this.previousSelectedAnimationState, this.store);
  }

}