import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { gameConstants } from "../../../data/gameConstants";
import { AnimationElementViewBase } from "../../shared/map/AnimationElementViewBase";
import { GameInteractionTrigger } from "./GameInteractionTrigger";
export class GameAnimationElementView extends AnimationElementViewBase {
  constructor(data, repeatLoadingUntilSuccessCancelled, interactionTriggerOverlay) {
    super(data, true, repeatLoadingUntilSuccessCancelled);

    _defineProperty(this, "interactionTrigger", void 0);

    this.baseRefreshMethods.forEach(refresh => refresh.call(this));

    if (data.isInteractionTrigger) {
      this.interactionTrigger = new GameInteractionTrigger(data, () => this.tilePosition, interactionTriggerOverlay, this, gameConstants.tileWidth / 2, gameConstants.tileHeight / 2);
    }
  }

  updateBox() {
    var _this$interactionTrig;

    super.updateBox();
    (_this$interactionTrig = this.interactionTrigger) === null || _this$interactionTrig === void 0 ? void 0 : _this$interactionTrig.updatePosition();
  }

  destroy(options) {
    var _this$interactionTrig2;

    super.destroy(options);
    (_this$interactionTrig2 = this.interactionTrigger) === null || _this$interactionTrig2 === void 0 ? void 0 : _this$interactionTrig2.destroy(options);
  }

}