import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2, _class3, _temp;

import { fromSnapshot, getSnapshot, model, Model, modelAction, prop } from "mobx-keystone";
import { TranslatedString } from "../game/TranslatedString";
export let CharacterConfigurationModel = (_dec = model("resources/CharacterConfigurationModel"), _dec(_class = (_class2 = (_temp = _class3 = class CharacterConfigurationModel extends Model({
  id: prop(0).withSetter(),
  localizedName: prop(() => new TranslatedString({})).withSetter(),
  textReferenceId: prop("").withSetter(),
  animationSkins: prop().withSetter(),
  animationAssetName: prop().withSetter(),
  tintColorHex: prop().withSetter(),
  isEnemy: prop(false).withSetter(),
  enemyHealth: prop(10).withSetter(),
  enemyDamage: prop(10).withSetter(),
  enemyCombatPresetModelId: prop("").withSetter(),
  moduleOwner: prop("").withSetter()
}) {
  // North and West
  // South and East

  /**
   * Toggles the skins with the assigned class and variant.
   * Considers the direction prefixes of the skins.
   * @param variantClass The class of the skin.
   * @param variantName The name of the skin.
   */
  toggleSkins(variantClass, variantName) {
    if (!variantClass || !variantName) return;
    this.toggleSingleSkin(variantClass + "/" + variantName);
    this.toggleSingleSkin(variantClass + "/" + CharacterConfigurationModel.CharacterSkinVariantDirectionPrefix1 + variantName);
    this.toggleSingleSkin(variantClass + "/" + CharacterConfigurationModel.CharacterSkinVariantDirectionPrefix2 + variantName);
  }
  /**
   * Returns true if this {@link CharacterConfigurationModel} contains
   * the skins of the assigned class and name.
   * Considers the direction prefixes of the skins.
   * @param variantClass The class of the skin.
   * @param variantName The name of the skin.
   * @param ignoreDirections (optional) Ignores directional skins if 'true'.
   */


  isSkinActive(variantClass, variantName, ignoreDirections = true) {
    const skins = this.animationSkins.split(",");
    const containsBase = skins.some(s => s == variantClass + "/" + variantName);
    if (ignoreDirections) return containsBase;
    const containsDirection1 = skins.some(skinPath => skinPath == variantClass + "/" + CharacterConfigurationModel.CharacterSkinVariantDirectionPrefix1 + variantName);
    const containsDirection2 = skins.some(skinPath => skinPath == variantClass + "/" + CharacterConfigurationModel.CharacterSkinVariantDirectionPrefix2 + variantName);
    return containsBase && containsDirection1 && containsDirection2;
  }

  toggleSingleSkin(skinPath) {
    const skins = this.animationSkinPathsAsArray();

    if (skins.some(s => s == skinPath)) {
      this.removeSingleSkin(skinPath);
    } else {
      this.addSkin(skinPath);
    }
  }

  animationSkinPathsAsArray() {
    return this.animationSkins.split(",");
  }

  removeAllSkins(skinClassName) {
    const skins = this.animationSkinPathsAsArray().filter(item => item === null || item === void 0 ? void 0 : item.startsWith(skinClassName + "/"));
    skins.forEach(this.removeSingleSkin.bind(this));
  }

  removeSingleSkin(skinPath) {
    this.setAnimationSkins(this.animationSkins.split(",").filter(e => e != skinPath).join(","));
  }

  addSkin(skinPath) {
    const array = this.animationSkins.split(",");
    array.push(skinPath);
    this.setAnimationSkins(array.join(","));
  }

  resetSkins(applyDefaultSkin = true) {
    this.setAnimationSkins("");
    if (applyDefaultSkin) this.addSkin(CharacterConfigurationModel.DefaultSkinName);
  }

  static newSnapshot(animationAssetName, moduleOwner) {
    return getSnapshot(new CharacterConfigurationModel({
      animationAssetName: animationAssetName,
      animationSkins: CharacterConfigurationModel.DefaultSkinName,
      moduleOwner: moduleOwner
    }));
  }

  static fromSnapshot(snapshot) {
    return fromSnapshot(snapshot);
  }

  translatableEntityData() {
    return {
      entity: this,
      label: "Character",
      translateableStrings: [{
        label: "Name",
        translatedString: this.localizedName
      }]
    };
  }

}, _defineProperty(_class3, "DefaultSkinName", "skin-base"), _defineProperty(_class3, "CharacterSkinVariantDirectionPrefix1", "3quart_"), _defineProperty(_class3, "CharacterSkinVariantDirectionPrefix2", "quart_"), _temp), (_applyDecoratedDescriptor(_class2.prototype, "toggleSkins", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleSkins"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addSkin", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "addSkin"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "resetSkins", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "resetSkins"), _class2.prototype)), _class2)) || _class);