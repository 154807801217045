import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Point } from "pixi.js";
import { GameInteractionTriggerIcon } from "./GameInteractionTriggerIcon";
import { TypedEmitter } from 'tiny-typed-emitter';
const tempPosition = new Point();
export class GameInteractionTrigger extends TypedEmitter {
  constructor(interactionTriggerData, getTilePosition, interactionTriggerOverlay, parent, iconOffsetX, iconOffsetY) {
    super();
    this.interactionTriggerData = interactionTriggerData;
    this.getTilePosition = getTilePosition;
    this.interactionTriggerOverlay = interactionTriggerOverlay;
    this.parent = parent;

    _defineProperty(this, "icon", void 0);

    _defineProperty(this, "offsetPosition", void 0);

    this.icon = new GameInteractionTriggerIcon(this);
    interactionTriggerOverlay.addChild(this.icon);
    this.offsetPosition = new Point(iconOffsetX + 50, iconOffsetY - 40);
    this.hide();
  }

  destroy(options) {
    this.icon.destroy(options);
    this.removeAllListeners();
  }

  updatePosition() {
    if (!this.icon.visible) return; // Move icon on this.interactionTriggerOverlay on the offset position relative to this.parent

    this.interactionTriggerOverlay.toLocal(this.offsetPosition, this.parent, tempPosition);
    this.icon.position.set(tempPosition.x, tempPosition.y);
  }

  get $modelId() {
    return this.interactionTriggerData.$modelId;
  }

  get tilePosition() {
    return this.getTilePosition();
  }

  show(iconType) {
    this.icon.visible = true;
    this.icon.setType(iconType);
    this.emit("show");
    this.updatePosition();
  }

  hide() {
    this.icon.visible = false;
    this.emit("hide");
  }

}