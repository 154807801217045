import * as Sentry from "@sentry/react";
export function addSentryDebugBreadcrumb(message, data) {
  Sentry.addBreadcrumb({
    category: "Debug",
    level: "debug",
    message,
    data
  });
}
export function addSentryGameLogBreadcrumb(entry) {
  Sentry.addBreadcrumb({
    category: "Game Log",
    level: "debug",
    message: entry.executionType,
    data: entry
  });
}