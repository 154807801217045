import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { EnemyCombatPresetModel } from "../../../../shared/combat/EnemyCombatPresetModel";
import { GesturePatternModel } from "../../../../shared/combat/gestures/GesturePatternModel";
import { editorClient } from "../../../communication/EditorClient";
import { combatEditorStore } from "../../CombatEditorStore";
import { combatStore } from "../../CombatStore";
import { translationStore } from "../../TranslationStore";
import { createChangeGroupStack, createGroupUndoableChangesFunction, mergeGroupedPatchOp, UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { selectEnemyCombatPreset } from "./CombatEnemyCombatPresetSelectionOp";
import { selectGesturePattern } from "./CombatGesturePatternSelectionOp";
export let CombatConfigurationUpdateChangeGroup;

(function (CombatConfigurationUpdateChangeGroup) {
  CombatConfigurationUpdateChangeGroup[CombatConfigurationUpdateChangeGroup["None"] = 0] = "None";
  CombatConfigurationUpdateChangeGroup[CombatConfigurationUpdateChangeGroup["CreateEnemyCombatPreset"] = 1] = "CreateEnemyCombatPreset";
  CombatConfigurationUpdateChangeGroup[CombatConfigurationUpdateChangeGroup["DeleteEnemyCombatPreset"] = 2] = "DeleteEnemyCombatPreset";
  CombatConfigurationUpdateChangeGroup[CombatConfigurationUpdateChangeGroup["CreateGesturePattern"] = 3] = "CreateGesturePattern";
  CombatConfigurationUpdateChangeGroup[CombatConfigurationUpdateChangeGroup["DeleteGesturePattern"] = 4] = "DeleteGesturePattern";
})(CombatConfigurationUpdateChangeGroup || (CombatConfigurationUpdateChangeGroup = {}));

const autoMergableGroups = [//CombatConfigurationUpdateChangeGroup.UnspecificGroupedNodeChanges
];
const changeGroupStack = createChangeGroupStack(CombatConfigurationUpdateChangeGroup.None);
/**
 * This method groups all changes made inside `executer` and merges them into one undo/redo entry, and labels
 * it appropriately (according to the selected `group`) and executes side effects if necessary.
 * 
 * @see {@link createGroupUndoableChangesFunction} for more information.
 *
 * @param group A group denoting the purpose of the grouped changes in executer
 * @param executer The callback that contains all changes that should be grouped
 * @param sideEffects Side effects to be executed after the first patch (initial run) or after all patches (undo/redo)
 */

export const groupUndoableCombatConfigurationChanges = createGroupUndoableChangesFunction(changeGroupStack, CombatConfigurationUpdateChangeGroup.None);
export function undoableCombatConfigurationCreateAndSelectEnemyCombatPreset() {
  const newPreset = new EnemyCombatPresetModel({});
  groupUndoableCombatConfigurationChanges(CombatConfigurationUpdateChangeGroup.CreateEnemyCombatPreset, () => {
    combatStore.config.addEnemyCombatPreset(newPreset);
  }, [new SelectEnemyCombatPresetSideEffect(newPreset.$modelId)]);
}
export function undoableCombatConfigurationDeleteEnemyCombatPreset(preset) {
  groupUndoableCombatConfigurationChanges(CombatConfigurationUpdateChangeGroup.DeleteEnemyCombatPreset, () => {
    combatStore.config.removeEnemyCombatPreset(preset);
  }, [new SelectEnemyCombatPresetSideEffect(null)]);
}
export function undoableCombatConfigurationCreateAndSelectGesturePattern() {
  const newGesturePattern = new GesturePatternModel({});
  groupUndoableCombatConfigurationChanges(CombatConfigurationUpdateChangeGroup.CreateGesturePattern, () => {
    combatStore.config.addGesturePattern(newGesturePattern);
  }, [new SelectGesturePatternSideEffect(newGesturePattern.$modelId)]);
}
export function undoableCombatConfigurationDeleteGesturePattern(gesturepattern) {
  groupUndoableCombatConfigurationChanges(CombatConfigurationUpdateChangeGroup.DeleteGesturePattern, () => {
    combatStore.config.removeGesturePattern(gesturepattern);
  }, [new SelectGesturePatternSideEffect(null)]);
}
export function undoableCombatConfigurationSubmitChanges(patch, inversePatch) {
  // Don't create undo entries while translations are importing
  if (translationStore.isImporting) return;
  const currentStack = changeGroupStack[changeGroupStack.length - 1];
  const {
    currentChangeGroup,
    currentGroupId,
    queuedSideEffects
  } = currentStack;
  currentStack.queuedSideEffects = null;
  executeUndoableOperation(new CombatConfigurationSubmitChangesOp(currentChangeGroup, currentGroupId, queuedSideEffects, [patch], [inversePatch]));
}

class CombatConfigurationSubmitChangesOp extends UndoableOperation {
  constructor(group, groupId, sideEffects, patches, inversePatches) {
    super("combatConfiguratorUpdateConfiguration/" + CombatConfigurationUpdateChangeGroup[group]); //console.log(`[${groupId}:${DynamicMapElementChangeGroup[group]}]`, { patches, inversePatches });

    this.group = group;
    this.groupId = groupId;
    this.sideEffects = sideEffects;
    this.patches = patches;
    this.inversePatches = inversePatches;
  }

  async execute(isRedo) {
    var _this$sideEffects;

    await editorClient.submitCombatConfigurationChanges(this.patches, this.inversePatches, isRedo);

    if (isRedo) {
      editorClient.patch(combatStore.config, this.patches);
    }

    (_this$sideEffects = this.sideEffects) === null || _this$sideEffects === void 0 ? void 0 : _this$sideEffects.forEach(sideEffect => sideEffect.afterExecute(isRedo));
  }

  async reverse() {
    var _this$sideEffects2;

    const reversedInversePatches = this.inversePatches.slice().reverse();
    await editorClient.submitCombatConfigurationChanges(reversedInversePatches, this.patches.slice().reverse(), true);
    editorClient.patch(combatStore.config, reversedInversePatches);
    (_this$sideEffects2 = this.sideEffects) === null || _this$sideEffects2 === void 0 ? void 0 : _this$sideEffects2.forEach(sideEffect => sideEffect.afterReverse());
  }

  merge(previousOperation) {
    return mergeGroupedPatchOp(this, previousOperation, autoMergableGroups, CombatConfigurationUpdateChangeGroup.None);
  }

}

export class SelectEnemyCombatPresetSideEffect {
  constructor(id) {
    this.id = id;

    _defineProperty(this, "previousId", void 0);

    this.previousId = combatEditorStore.selectedEnemyCombatPresetId;
  }

  async afterExecute() {
    selectEnemyCombatPreset(this.id);
  }

  async afterReverse() {
    selectEnemyCombatPreset(this.previousId);
  }

}
export class SelectGesturePatternSideEffect {
  constructor(id) {
    this.id = id;

    _defineProperty(this, "previousId", void 0);

    this.previousId = combatEditorStore.selectedGesturePatternId;
  }

  async afterExecute() {
    selectGesturePattern(this.id);
  }

  async afterReverse() {
    selectGesturePattern(this.previousId);
  }

}