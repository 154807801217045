import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Container, Graphics } from "pixi.js";
import { UiConstants } from "../../../data/UiConstants";
import { tileAssetEditorStore } from "../../../stores/TileAssetEditorStore";
export class Generic2DHandle {
  constructor(cameraContainer, handleColor, isCurrentlyInitialized, getPositionX, getPositionY, setPosition) {
    this.cameraContainer = cameraContainer;
    this.isCurrentlyInitialized = isCurrentlyInitialized;
    this.getPositionX = getPositionX;
    this.getPositionY = getPositionY;
    this.setPosition = setPosition;

    _defineProperty(this, "handleView", void 0);

    _defineProperty(this, "handleGraphic", void 0);

    _defineProperty(this, "indicatorGraphic", void 0);

    _defineProperty(this, "dragging", void 0);

    _defineProperty(this, "dragData", void 0);

    _defineProperty(this, "handleSize", void 0);

    // Create Handle Graphic
    this.handleGraphic = new Graphics();
    this.handleGraphic.interactive = true;
    this.handleGraphic.cursor = 'move';
    this.handleGraphic.on('pointerdown', this.onDragStart, this).on('pointerup', this.onDragEnd, this).on('pointerupoutside', this.onDragEnd, this).on('pointermove', this.onDragMove, this);
    this.handleSize = 5;
    this.drawHandleGraphic(handleColor); // Create dragging indicator

    this.indicatorGraphic = new Graphics();
    this.indicatorGraphic.lineStyle(3, UiConstants.COLOR_DARK_BUTTON_0x, 1);
    this.indicatorGraphic.beginFill(UiConstants.COLOR_DARK_BUTTON_0x, 1);
    this.indicatorGraphic.drawCircle(0, 0, this.handleSize - 1);
    this.indicatorGraphic.endFill();
    this.indicatorGraphic.visible = false;
    this.handleView = new Container();
    this.handleView.addChild(this.handleGraphic);
    this.handleView.addChild(this.indicatorGraphic);
    this.dragging = false;
  }

  updatePosition() {
    this.handleGraphic.visible = this.isCurrentlyInitialized() && !tileAssetEditorStore.showGamePreview;

    if (this.isCurrentlyInitialized()) {
      const x = this.getPositionX();
      const y = this.getPositionY();
      this.handleGraphic.x = x;
      this.handleGraphic.y = y;
      this.indicatorGraphic.x = x;
      this.indicatorGraphic.y = y;
    }
  }

  isDragged() {
    return this.dragging;
  }

  drawHandleGraphic(color) {
    this.handleGraphic.lineStyle(3, color, 1);
    this.handleGraphic.beginFill(color, 1);
    this.handleGraphic.drawCircle(0, 0, this.handleSize);
    this.handleGraphic.endFill();
  }

  onDragStart(event) {
    this.dragging = true;
    this.dragData = event.data;
    this.indicatorGraphic.visible = true;
  }

  onDragEnd() {
    this.dragging = false;
    this.dragData = null;
    this.indicatorGraphic.visible = false;
    this.updatePosition();
  }

  get handleX() {
    return this.handleGraphic.x;
  }

  get handleY() {
    return this.handleGraphic.y;
  }

  onDragMove() {
    if (this.isCurrentlyInitialized() && this.dragging) {
      const newPosition = this.dragData.getLocalPosition(this.cameraContainer);
      this.setPosition(newPosition.x, newPosition.y);
    }
  }

}