import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { editorClient } from "../../../communication/EditorClient";
import { UiConstants } from "../../../data/UiConstants";
import { Generic2DHandle } from "./Generic2DHandle";
export class ImagePositionHandle extends Generic2DHandle {
  constructor(stage) {
    super(stage, UiConstants.COLOR_SELECTION_HIGHLIGHT_0x, () => {
      var _this$imageProperties;

      return !!((_this$imageProperties = this.imageProperties) !== null && _this$imageProperties !== void 0 && _this$imageProperties.positionOnTile) && !!this.tileAsset;
    }, () => {
      var _this$imageProperties2, _this$imageProperties3;

      return ((_this$imageProperties2 = this.imageProperties.positionOnTile) === null || _this$imageProperties2 === void 0 ? void 0 : _this$imageProperties2.x) + ((_this$imageProperties3 = this.imageProperties.size) === null || _this$imageProperties3 === void 0 ? void 0 : _this$imageProperties3.width) / 2;
    }, () => {
      var _this$imageProperties4, _this$imageProperties5;

      return ((_this$imageProperties4 = this.imageProperties.positionOnTile) === null || _this$imageProperties4 === void 0 ? void 0 : _this$imageProperties4.y) + ((_this$imageProperties5 = this.imageProperties.size) === null || _this$imageProperties5 === void 0 ? void 0 : _this$imageProperties5.height) / 2;
    }, (x, y) => {
      var _this$imageProperties6, _this$imageProperties7;

      const newX = Math.round(x - ((_this$imageProperties6 = this.imageProperties.size) === null || _this$imageProperties6 === void 0 ? void 0 : _this$imageProperties6.width) / 2);
      const newY = Math.round(y - ((_this$imageProperties7 = this.imageProperties.size) === null || _this$imageProperties7 === void 0 ? void 0 : _this$imageProperties7.height) / 2);
      this.imageProperties.positionOnTile.setX(newX);
      this.imageProperties.positionOnTile.setY(newY);
      editorClient.updateTileAsset(this.tileAsset, null, null, null, null);
    });

    _defineProperty(this, "tileAsset", void 0);

    _defineProperty(this, "imageProperties", void 0);

    this.handleSize = 5;
    this.indicatorGraphic.beginFill(UiConstants.COLOR_DARK_BUTTON_0x, 1);
    this.indicatorGraphic.drawCircle(0, 0, this.handleSize - 2);
    this.indicatorGraphic.endFill();
  }

  setImageAsset(imageProperties, tileAsset) {
    this.imageProperties = imageProperties;
    this.tileAsset = tileAsset;
    this.updatePosition();
  }

}