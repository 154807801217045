import { gameStore } from "../../../stores/GameStore";
import { DebugStartMarkerVisual, MapMarkerVisualColorBorder } from "./DebugStartMarkerVisual";
import { EditorOnlyElementView } from "./EditorOnlyElementView";
export class DebugStartMarkerView extends EditorOnlyElementView {
  constructor(mapRelatedStore, textGroup) {
    super(mapRelatedStore, textGroup, false);
    this.text.style.stroke = MapMarkerVisualColorBorder; //this.text.style.fill = "white";

    this.text.anchor.y = 1;
    this.text.position.y = 30;
    this.addChild(new DebugStartMarkerVisual());
  }

  getPosition() {
    return gameStore.debugStartMarker.position;
  }

  getLabelString() {
    return gameStore.debugStartMarker.label;
  }

  refreshVisibility() {
    if (!this.mapRelatedStore.highlightedElements) return super.refreshVisibility();
    this.isHidden = true;
  }

}