import { createChangeGroupStack, createGroupUndoableChangesFunction, mergeGroupedPatchOp, UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { editorClient } from "../../../communication/EditorClient";
export let AnimationEditorChangeGroup;

(function (AnimationEditorChangeGroup) {
  AnimationEditorChangeGroup[AnimationEditorChangeGroup["None"] = 0] = "None";
  AnimationEditorChangeGroup[AnimationEditorChangeGroup["UnspecificGroupedNodeChanges"] = 1] = "UnspecificGroupedNodeChanges";
})(AnimationEditorChangeGroup || (AnimationEditorChangeGroup = {}));

const autoMergableGroups = [AnimationEditorChangeGroup.UnspecificGroupedNodeChanges];
const changeGroupStack = createChangeGroupStack(AnimationEditorChangeGroup.None);
/**
 * This method groups all changes made inside `executer` and merges them into one undo/redo entry, and labels
 * it appropriately (according to the selected `group`) and executes side effects if necessary.
 * 
 * @see {@link createGroupUndoableChangesFunction} for more information.
 *
 * @param group A group denoting the purpose of the grouped changes in executer
 * @param executer The callback that contains all changes that should be grouped
 * @param sideEffects Side effects to be executed after the first patch (initial run) or after all patches (undo/redo)
 */

export const groupUndoableAnimationEditorChanges = createGroupUndoableChangesFunction(changeGroupStack, AnimationEditorChangeGroup.None);
export function undoableAnimationEditorSubmitChanges(animation, patch, inversePatch) {
  const currentStack = changeGroupStack[changeGroupStack.length - 1];
  const {
    currentChangeGroup,
    currentGroupId,
    queuedSideEffects
  } = currentStack;
  currentStack.queuedSideEffects = null;
  executeUndoableOperation(new AnimationEditorSubmitChangesOp(currentChangeGroup, currentGroupId, queuedSideEffects, animation, [patch], [inversePatch]));
}

class AnimationEditorSubmitChangesOp extends UndoableOperation {
  constructor(group, groupId, sideEffects, animation, patches, inversePatches) {
    super("animationEditorSubmitChanges/" + AnimationEditorChangeGroup[group]);
    this.group = group;
    this.groupId = groupId;
    this.sideEffects = sideEffects;
    this.animation = animation;
    this.patches = patches;
    this.inversePatches = inversePatches;
  }

  async execute(isRedo) {
    var _this$sideEffects;

    await editorClient.submitAnimationChanges(this.animation.id, this.patches, this.inversePatches, isRedo);

    if (isRedo) {
      editorClient.patch(this.animation, this.patches);
    }

    (_this$sideEffects = this.sideEffects) === null || _this$sideEffects === void 0 ? void 0 : _this$sideEffects.forEach(sideEffect => sideEffect.afterExecute(isRedo));
  }

  async reverse() {
    var _this$sideEffects2;

    const reversedInversePatches = this.inversePatches.slice().reverse();
    await editorClient.submitAnimationChanges(this.animation.id, reversedInversePatches, this.patches.slice().reverse(), true);
    editorClient.patch(this.animation, reversedInversePatches);
    (_this$sideEffects2 = this.sideEffects) === null || _this$sideEffects2 === void 0 ? void 0 : _this$sideEffects2.forEach(sideEffect => sideEffect.afterReverse());
  }

  merge(previousOperation) {
    return mergeGroupedPatchOp(this, previousOperation, autoMergableGroups, AnimationEditorChangeGroup.None);
  }

}