import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable, observable } from "mobx";
import { editorStore } from "./EditorStore";
export class ManagementStore {
  get isConnectedAndReady() {
    return editorStore.isConnected && this.isInitialized;
  }

  constructor() {
    _defineProperty(this, "workshops", void 0);

    _defineProperty(this, "sessionWorkshopId", void 0);

    _defineProperty(this, "openItemDetailViews", void 0);

    _defineProperty(this, "modules", void 0);

    _defineProperty(this, "moduleMapList", void 0);

    _defineProperty(this, "playableModules", void 0);

    _defineProperty(this, "isInitialized", false);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
    this.workshops = observable.map(new Map());
    this.modules = observable.map(new Map());
    this.openItemDetailViews = Array();
  }

  setAllWorkshops(newWorkshops) {
    this.workshops = observable.map(newWorkshops);
  }

  setWorkshop(newWorkshop) {
    this.workshops.set(newWorkshop.$modelId, newWorkshop);
  }

  deleteWorkshop(workshopId) {
    var _this$workshops;

    (_this$workshops = this.workshops) === null || _this$workshops === void 0 ? void 0 : _this$workshops.delete(workshopId);
  }

  getWorkshop(workshopId) {
    var _this$workshops2;

    return (_this$workshops2 = this.workshops) === null || _this$workshops2 === void 0 ? void 0 : _this$workshops2.get(workshopId);
  }

  setSessionWorkshop(workshopId) {
    this.sessionWorkshopId = workshopId;
  }

  get getAllWorkshops() {
    if (!this.workshops || this.workshops.size === 0) return [];
    return Array.from(this.workshops.values());
  }

  get sessionWorkshop() {
    var _this$workshops3;

    return (_this$workshops3 = this.workshops) === null || _this$workshops3 === void 0 ? void 0 : _this$workshops3.get(this.sessionWorkshopId);
  }

  setAllModules(newModules) {
    this.modules = observable.map(newModules);
  }

  setModuleMapList(moduleMapList) {
    this.moduleMapList = moduleMapList;
  }

  getModuleMapList(moduleId) {
    return this.moduleMapList.filter(map => map.moduleId === moduleId);
  }

  setModule(newModule) {
    this.modules.set(newModule.$modelId, newModule);
  }

  deleteModule(moduleId) {
    var _this$modules;

    (_this$modules = this.modules) === null || _this$modules === void 0 ? void 0 : _this$modules.delete(moduleId);
  }

  getModule(moduleId) {
    var _this$modules2;

    return (_this$modules2 = this.modules) === null || _this$modules2 === void 0 ? void 0 : _this$modules2.get(moduleId);
  }

  setAllPlayableModules(playableModules) {
    this.playableModules = observable.map(playableModules);
  }

  setPlayableModule(newPlayableModule) {
    this.playableModules.set(newPlayableModule.$modelId, newPlayableModule);
  }

  deletePlayableModule(moduleId) {
    var _this$playableModules;

    (_this$playableModules = this.playableModules) === null || _this$playableModules === void 0 ? void 0 : _this$playableModules.delete(moduleId);
  }

  getPlayableModule(moduleId) {
    return this.playableModules.get(moduleId);
  }

  get getAllSessionModules() {
    if (!this.workshops || !this.modules || !this.sessionWorkshop) return [];
    return this.getModulesForWorkshop(this.sessionWorkshop.$modelId);
  }

  getModulesForWorkshop(workshopId) {
    if (!this.workshops || !this.modules) return [];
    const workshop = this.workshops.get(workshopId);
    if (!workshop) return [];
    return workshop.modules.map(id => this.modules.get(id)).filter(module => !!module);
  }

  get getAllModules() {
    if (!this.modules || this.modules.size === 0) return [];
    return Array.from(this.modules.values());
  }

  get getAllPlayableModules() {
    if (!this.playableModules || this.playableModules.size === 0) return [];
    return Array.from(this.playableModules.values());
  }

  isDetailViewOpen(itemId) {
    return this.openItemDetailViews.includes(itemId);
  }

  toggleItemDetailView(itemId) {
    if (!this.isDetailViewOpen(itemId)) {
      this.openItemDetailViews.push(itemId);
    } else {
      this.openItemDetailViews = this.openItemDetailViews.filter(openViewItemId => openViewItemId !== itemId);
    }
  }

  setInitialized(status) {
    this.isInitialized = status;
  }

  clear() {
    this.workshops.clear();
    this.modules.clear();
    this.sessionWorkshopId = "";
    this.isInitialized = false;
  }

  accessCodeExists(accesscode) {
    if (accesscode.length === 0) return false;

    for (const workshop of this.workshops.values()) {
      if (workshop.accesscode === accesscode || workshop.playcode === accesscode) return true;
    }

    for (const module of this.modules.values()) {
      if (module.accesscode === accesscode || module.standalonePlayCode === accesscode) return true;
    }

    return false;
  }

}
export const managementStore = new ManagementStore();