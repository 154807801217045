import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableSetTileAssetSearchFilter(mapRelatedStore, category, value) {
  if (mapRelatedStore.getSearchFilter(category) === value) return;
  executeUndoableOperation(new SetTileAssetSearchFilterOp(mapRelatedStore, category, value));
}
export function undoableClearTileAssetSearchFilter(mapRelatedStore, category) {
  undoableSetTileAssetSearchFilter(mapRelatedStore, category, "");
}

class SetTileAssetSearchFilterOp extends UndoableOperation {
  constructor(mapRelatedStore, category, newValue) {
    super("setTileAssetSearchFilter");
    this.mapRelatedStore = mapRelatedStore;
    this.category = category;
    this.newValue = newValue;

    _defineProperty(this, "previousValue", void 0);

    this.previousValue = mapRelatedStore.getSearchFilter(category);
  }

  async execute() {
    this.mapRelatedStore.setSearchFilter(this.category, this.newValue);
  }

  async reverse() {
    this.mapRelatedStore.setSearchFilter(this.category, this.previousValue);
  }

  merge(previousOperation) {
    this.previousValue = previousOperation.previousValue;
    return true;
  }

}