var _dec, _class;

import { Model, model, prop } from "mobx-keystone";
import { dataConstants } from "../data/dataConstants";
import { TranslatedError } from "../definitions/errors/TranslatedError";
export let ImageUsecase;

(function (ImageUsecase) {
  ImageUsecase[ImageUsecase["None"] = 0] = "None";
  ImageUsecase[ImageUsecase["Item"] = 1] = "Item";
  ImageUsecase[ImageUsecase["DisplayImage"] = 2] = "DisplayImage";
})(ImageUsecase || (ImageUsecase = {}));

export let ImageModel = (_dec = model('game/ImageModel'), _dec(_class = class ImageModel extends Model({
  id: prop(0).withSetter(),
  filename: prop("").withSetter(),
  size: prop().withSetter(),
  usecase: prop(0).withSetter(),
  moduleOwner: prop("").withSetter()
}) {}) || _class);
export function doesImageNeedTexture(imageSnapshot) {
  switch (imageSnapshot.usecase) {
    case ImageUsecase.DisplayImage:
    case ImageUsecase.Item:
    case ImageUsecase.None:
      return false;

    default:
      throw new Error("Not implemented: " + imageSnapshot.usecase);
  }
}
export function throwIfImageSizeIsTooBig(usecase, imageSizeInBytes) {
  if (usecase === ImageUsecase.Item) {
    if (imageSizeInBytes > dataConstants.itemAssetMaxSizeBytes) {
      throw new TranslatedError("editor.error_image_size_too_large");
    }
  } else if (usecase === ImageUsecase.DisplayImage) {
    if (imageSizeInBytes > dataConstants.displayImageMaxSizeBytes) {
      throw new TranslatedError("editor.error_image_size_too_large");
    }
  } else {
    throw new Error("Not implemented: image usecase " + usecase);
  }
}