import _defineProperty from "@babel/runtime/helpers/defineProperty";
export class UiConstants {}

_defineProperty(UiConstants, "Z_INDEX_ACTION_EDITOR_NODE_UNFOCUSED", -100);

_defineProperty(UiConstants, "Z_INDEX_ACTION_EDITOR_EDGE", 4);

_defineProperty(UiConstants, "Z_INDEX_ACTION_EDITOR_EDGE_DELETE_BUTTON", 100);

_defineProperty(UiConstants, "Z_INDEX_SLIDE_MENU", 1500);

_defineProperty(UiConstants, "Z_INDEX_TASK_MARKERS", 1501);

_defineProperty(UiConstants, "Z_INDEX_DIALOG", 1502);

_defineProperty(UiConstants, "Z_INDEX_CHARACTER_EDITOR_POPUP", 1520);

_defineProperty(UiConstants, "Z_INDEX_ENEMY_COMBAT_PRESET_EDITOR_POPUP", 1550);

_defineProperty(UiConstants, "Z_INDEX_ENEMY_COMBAT_PRESET_EDITOR_GESTURE_SELECTION_POPUP", 1552);

_defineProperty(UiConstants, "Z_INDEX_COMBAT_GESTURE_PATTERN_EDITOR_POPUP", 1554);

_defineProperty(UiConstants, "Z_INDEX_POPUP_SUBMENU", 1700);

_defineProperty(UiConstants, "Z_INDEX_HEADER", 1800);

_defineProperty(UiConstants, "Z_INDEX_MODAL", 2001);

_defineProperty(UiConstants, "Z_INDEX_EDITOR_NOTIFICATIONS_OVERLAY", 10000);

_defineProperty(UiConstants, "SIZE_SLIDE_MENU_ICON_NUMBER", 35);

_defineProperty(UiConstants, "SIZE_SLIDE_MENU_ICON", "35px");

_defineProperty(UiConstants, "SIZE_SLIDE_MENU_ICON_FONT", "28px");

_defineProperty(UiConstants, "SIZE_SLIDE_MENU_CLOSE_ICON", "30px");

_defineProperty(UiConstants, "SIZE_SLIDE_MENU_CLOSE_ICON_FONT", "24px");

_defineProperty(UiConstants, "BORDER_RADIUS", "4px");

_defineProperty(UiConstants, "COLOR_MENU_BACKGROUND", "#CCCCDF");

_defineProperty(UiConstants, "COLOR_DARK_BUTTON", "#6D5D67");

_defineProperty(UiConstants, "COLOR_DARK_BUTTON_0x", 0x6D5D67);

_defineProperty(UiConstants, "COLOR_DARK_BUTTON_HOVER", "#7E6E78");

_defineProperty(UiConstants, "COLOR_SELECTION_HIGHLIGHT", "#D9C655");

_defineProperty(UiConstants, "COLOR_SELECTION_HIGHLIGHT_0x", 0xD9C655);

_defineProperty(UiConstants, "COLOR_VALUE_INVALID", "#FF0000");

_defineProperty(UiConstants, "COLOR_CLICK_CONNECT_SELECTION", "#D9C655");

_defineProperty(UiConstants, "COLOR_CLICK_CONNECT_TARGET", "blue");

_defineProperty(UiConstants, "COLOR_DISABLED_SELECTION_HIGHLIGHT", "#BBBBBB");

_defineProperty(UiConstants, "COLOR_DISABLED_SELECTION_HIGHLIGHT_0x", 0xAAAAAA);

_defineProperty(UiConstants, "COLOR_VIABLE_SELECTION_HIGHLIGHT_0x", 0xAAAAAA);

_defineProperty(UiConstants, "NON_HIGHLIGHT_TINT_0x", 0x444444);

_defineProperty(UiConstants, "COLOR_DISABLED", "#666666");

_defineProperty(UiConstants, "COLOR_HOVER", "#EFEFEF");

_defineProperty(UiConstants, "COLOR_ACTIVE", "#99ff99");

_defineProperty(UiConstants, "ALPHA_CORRECT_HEIGHT_PLANE_WRONG_LAYER", 0.7);

_defineProperty(UiConstants, "ALPHA_WRONG_HEIGHT_PLANE", 0.2);