import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { MapElementContainer } from "../../map/sorting/MapElementContainer";
export class HideableMapElementContainer extends MapElementContainer {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "_isHidden", false);
  }

  get isHidden() {
    return this._isHidden;
  }

  set isHidden(value) {
    // When setting that this element is not visible anymore, we are also explictely
    // storing that we hid it in _isHidden and skip the culling while that is the case.
    // If we would only set visible = false, the culling would turn visible back on.
    this._isHidden = value;
    this.visible = !value;
  }

  get skipCulling() {
    return this._isHidden;
  }

}