import { editorClient } from "../../../communication/EditorClient";
import { gameStore } from "../../GameStore";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableGameDesignVariablesSubmitChanges(patch, inversePatch) {
  executeUndoableOperation(new GameDesignVariablesSubmitChangesOp(patch, inversePatch));
}

class GameDesignVariablesSubmitChangesOp extends UndoableOperation {
  constructor(patch, inversePatch) {
    super("updateGameDesignVariables");
    this.patch = patch;
    this.inversePatch = inversePatch;
  }

  async execute(isRedo) {
    await editorClient.submitGameDesignVariablesChanges(this.patch, this.inversePatch, isRedo);

    if (isRedo) {
      editorClient.patch(gameStore.gameDesignVariables, this.patch);
    }
  }

  async reverse() {
    await editorClient.submitGameDesignVariablesChanges(this.inversePatch, this.patch, true);
    editorClient.patch(gameStore.gameDesignVariables, this.inversePatch);
  }

}