import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Container, Rectangle } from "pixi.js";
import { getBoundsFromBox } from "../../../../helper/mapElementSortingHelper";
import { rectanglesEqual, rectanglesIntersect } from "../../../../helper/pixiHelpers";
import { SparseArray } from "./SparseArray";
import { SpatialGridInfo } from "./SpatialGridInfo";
export let ElementOrder;

(function (ElementOrder) {
  ElementOrder[ElementOrder["IsInFront"] = 0] = "IsInFront";
  ElementOrder[ElementOrder["IsInBack"] = 1] = "IsInBack";
  ElementOrder[ElementOrder["CannotSort"] = 2] = "CannotSort";
})(ElementOrder || (ElementOrder = {}));

let runningIndex = 0;
const elementMap = new Map();
export let BoundsUpdateMode;

(function (BoundsUpdateMode) {
  BoundsUpdateMode[BoundsUpdateMode["UpdateFromGetLocalBoundsEveryFrame"] = 0] = "UpdateFromGetLocalBoundsEveryFrame";
  BoundsUpdateMode[BoundsUpdateMode["UpdateFromGetLocalBoundsWhenDirty"] = 1] = "UpdateFromGetLocalBoundsWhenDirty";
  BoundsUpdateMode[BoundsUpdateMode["UpdateFromBox"] = 2] = "UpdateFromBox";
})(BoundsUpdateMode || (BoundsUpdateMode = {}));

export class MapElementContainer extends Container {
  /*
  protected boundsGraphic = new Graphics();
  protected boundsGraphicName = new Text("");
  */
  constructor(boundsUpdateMode) {
    super();
    this.boundsUpdateMode = boundsUpdateMode;

    _defineProperty(this, "elementIndex", void 0);

    _defineProperty(this, "elementsBehind", new SparseArray());

    _defineProperty(this, "elementsInFront", new SparseArray());

    _defineProperty(this, "elementsBehindSaved", new SparseArray());

    _defineProperty(this, "elementsInFrontSaved", new SparseArray());

    _defineProperty(this, "boundsForComparison", new Rectangle());

    _defineProperty(this, "alreadyComparedTo", new Set());

    _defineProperty(this, "elementVisibleLastFrameAndUnchanged", false);

    _defineProperty(this, "spatialGrid", void 0);

    _defineProperty(this, "spatialGridInfo", new SpatialGridInfo());

    _defineProperty(this, "_partOfLoop", false);

    _defineProperty(this, "loopCheckIndex", void 0);

    _defineProperty(this, "loopCheckLowLink", void 0);

    _defineProperty(this, "loopCheckIsOnStack", void 0);

    _defineProperty(this, "box", void 0);

    _defineProperty(this, "boundsDirty", true);

    this.elementIndex = runningIndex++;
    elementMap.set(this.elementIndex, this);
    this.on("removed", this.onRemoved, this);
  }

  onRemoved() {
    this.clearSavedOrderData();
    this.detachFromSpatialGrid();
  }

  destroy(options) {
    super.destroy(options);
    elementMap.delete(this.elementIndex);
  }

  detachFromSpatialGrid() {
    if (this.spatialGrid) {
      this.spatialGrid.remove(this, this.spatialGridInfo);
      this.spatialGrid = null;
    }

    this.spatialGridInfo.reset();
  }

  get debugName() {
    if (this.box) {
      return `${this.box.conflictResolutionName} (${this.box.xMin}|${this.box.yMin}|${this.box.zMin} - ${this.box.xMax}|${this.box.yMax}|${this.box.zMax})`;
    } else {
      return "[unknown: no box]";
    }
  }

  setBox(box) {
    this.box = box;

    if (box && this.boundsUpdateMode === BoundsUpdateMode.UpdateFromBox) {
      getBoundsFromBox(this.box, this.boundsForComparison);
    }

    this.elementVisibleLastFrameAndUnchanged = false; // Position might be dirty

    this.setMapElementContainerBoundsDirty();
    this.setDirty();
  }

  setDirty() {
    this.spatialGridInfo.isDirty = true;
    this.clearSavedOrderData();
  }

  setMapElementContainerBoundsDirty() {
    this.boundsDirty = true;
  }

  clearSavedOrderData() {
    for (const element of this.elementsBehindSaved.sparseData) {
      if (element === undefined) continue;
      element.elementsInFrontSaved.delete(this);
    }

    this.elementsBehindSaved.clear();

    for (const element of this.elementsInFrontSaved.sparseData) {
      if (element === undefined) continue;
      element.elementsBehindSaved.delete(this);
    }

    this.elementsInFrontSaved.clear();

    for (const otherElementIndex of this.alreadyComparedTo) {
      const element = elementMap.get(otherElementIndex);
      element.alreadyComparedTo.delete(this.elementIndex);
    }

    this.alreadyComparedTo.clear();
  }

  initializeForComparison(spatialGrid) {
    if (this.elementsBehind.size !== 0) throw new Error("elementsBehind should have been cleared by the sorting algorithm.");
    if (this.elementsInFront.size !== 0) throw new Error("elementsInFront should have been cleared by the sorting algorithm."); //this.removeChild(this.boundsGraphic);

    if (this.boundsUpdateMode === BoundsUpdateMode.UpdateFromGetLocalBoundsEveryFrame || this.boundsUpdateMode === BoundsUpdateMode.UpdateFromGetLocalBoundsWhenDirty && this.boundsDirty) {
      this.boundsDirty = false;
      this.getLocalBounds(this.boundsForComparison);
      this.boundsForComparison.x += this.x;
      this.boundsForComparison.y += this.y;

      if (!this.spatialGridInfo.isDirty && this.spatialGridInfo.currentBounds) {
        if (!rectanglesEqual(this.boundsForComparison, this.spatialGridInfo.currentBounds)) {
          this.setDirty();
        }
      }
    }
    /*
    this.boundsGraphic.clear();
    this.boundsGraphic.lineStyle(5, 0xFFFFFF);
    this.boundsGraphic.drawRect(this.boundsForComparison.x, this.boundsForComparison.y, this.boundsForComparison.width, this.boundsForComparison.height);
    this.boundsGraphicName.text = this.debugName;
    this.boundsGraphicName.style.wordWrap = false;
    this.boundsGraphicName.style.fontSize = 32;
    this.boundsGraphicName.style.fill = 0xFFFFFF;
    this.boundsGraphicName.x = this.boundsForComparison.x;
    this.boundsGraphicName.y = this.boundsForComparison.y;
    this.boundsGraphic.addChild(this.boundsGraphicName);
    this.boundsGraphic.x = -this.x;
    this.boundsGraphic.y = -this.y;
    this.addChildAt(this.boundsGraphic, this.children.length);
    */


    if (this.spatialGrid && this.spatialGrid !== spatialGrid) {
      throw Error("MapElementContainer was already assigned to a different spatial grid: " + this.debugName);
    }

    this.spatialGrid = spatialGrid;

    try {
      return spatialGrid.insertOrUpdate(this, this.boundsForComparison, this.spatialGridInfo);
    } catch (e) {
      console.error(this.debugName, e);
      throw e;
    }
  }

  initializeForSorting() {
    for (const element of this.elementsBehindSaved.sparseData) {
      if (element === undefined) continue;

      if (element.visible) {
        this.elementsBehind.add(element);
      }
    }

    for (const element of this.elementsInFrontSaved.sparseData) {
      if (element === undefined) continue;

      if (element.visible) {
        this.elementsInFront.add(element);
      }
    }
  }

  compareOrderTo(other) {
    const box1 = this.box;
    const box2 = other.box;
    if (!box1 || !box2) return ElementOrder.CannotSort;
    if (!rectanglesIntersect(this.boundsForComparison, other.boundsForComparison)) return ElementOrder.CannotSort;
    let isInFront = 0;
    let isInBack = 0; // Are both elements flat and on the same layer?

    if (areBoxesFlatAndOnSameGeometricPlane(box1, box2)) {
      return MapElementContainer.compareConflictResolutionOrder(box1, box2, true);
    } // test for intersection x-axis
    // (higher x value is in front)


    if (box1.xMin >= box2.xMax) {
      isInFront++;
    }

    if (box2.xMin >= box1.xMax) {
      isInBack++;
    } // test for intersection y-axis
    // (higher y value is in front)


    if (box1.yMin >= box2.yMax) {
      isInFront++;
    }

    if (box2.yMin >= box1.yMax) {
      isInBack++;
    } // test for intersection z-axis
    // (higher z value is in front)


    if (box1.zMin >= box2.zMax) {
      isInFront++;
    }

    if (box2.zMin >= box1.zMax) {
      isInBack++;
    }
    /*
    console.log({
        a: this.debugName,
        b: other.debugName,
        isInFront,
        isInBack
    });
    */
    // If we cannot clearly decide whether an object is in the front or the back, we cannot sort or risk loops.


    if (isInFront && isInBack) return ElementOrder.CannotSort;
    if (isInFront) return ElementOrder.IsInFront;
    if (isInBack) return ElementOrder.IsInBack; // Overlap

    return MapElementContainer.compareConflictResolutionOrder(box1, box2, false);
  }

  compareConflictResolutionOrderTo(other) {
    const box1 = this.box;
    const box2 = other.box;
    const flatAndOnSameGeometricPlane = areBoxesFlatAndOnSameGeometricPlane(box1, box2);
    return MapElementContainer.compareConflictResolutionOrder(box1, box2, flatAndOnSameGeometricPlane);
  }

  static compareConflictResolutionOrder(box1, box2, flatAndOnSameGeometricPlane) {
    if (flatAndOnSameGeometricPlane) {
      if (box1.conflictResolutionFlatOrder < box2.conflictResolutionFlatOrder) {
        return ElementOrder.IsInBack;
      } else if (box1.conflictResolutionFlatOrder > box2.conflictResolutionFlatOrder) {
        return ElementOrder.IsInFront;
      }

      if (box1.conflictResolutionFlatZIndex < box2.conflictResolutionFlatZIndex) {
        return ElementOrder.IsInBack;
      } else if (box1.conflictResolutionFlatZIndex > box2.conflictResolutionFlatZIndex) {
        return ElementOrder.IsInFront;
      }
    }

    if (box1.zMin < box2.zMin) {
      return ElementOrder.IsInBack;
    } else if (box2.zMin < box1.zMin) {
      return ElementOrder.IsInFront;
    }

    if (box1.isTransit && box2.canTakeTransit) {
      return ElementOrder.IsInBack;
    } else if (box2.isTransit && box1.canTakeTransit) {
      return ElementOrder.IsInFront;
    }

    if (box1.isGroundMinus1 && box2.isGround) {
      return ElementOrder.IsInBack;
    } else if (box2.isGroundMinus1 && box1.isGround) {
      return ElementOrder.IsInFront;
    }

    if (box1.conflictResolutionOriginValue < box2.conflictResolutionOriginValue) {
      return ElementOrder.IsInBack;
    } else if (box1.conflictResolutionOriginValue > box2.conflictResolutionOriginValue) {
      return ElementOrder.IsInFront;
    }

    return box1.conflictResolutionName.localeCompare(box2.conflictResolutionName) > 0 ? ElementOrder.IsInFront : ElementOrder.IsInBack;
  }

  get partOfLoop() {
    return this._partOfLoop;
  }

  set partOfLoop(value) {
    if (this._partOfLoop == value) return;
    this._partOfLoop = value;
    this.refreshPartOfLoop();
  }

  refreshPartOfLoop() {}

  outputDebugData() {
    console.log(" - " + this.debugName, {
      inFrontSaved: [...this.elementsInFrontSaved.sparseData].filter(element => element !== undefined).map(element => element.debugName),
      behindSaved: [...this.elementsBehindSaved.sparseData].filter(element => element !== undefined).map(element => element.debugName),
      box: this.box
    });
  }

}
/**
 * Returns true if both boxes are flat and share a geometric plane (namely the XY, XZ or YZ planes)
 */

function areBoxesFlatAndOnSameGeometricPlane(box1, box2) {
  return box1.xMin === box1.xMax && box2.xMin === box2.xMax && box1.xMin === box2.xMin || box1.yMin === box1.yMax && box2.yMin === box2.yMax && box1.yMin === box2.yMin || box1.zMin === box1.zMax && box2.zMin === box2.zMax && box1.zMin === box2.zMin;
}