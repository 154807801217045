import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { editorClient } from "../../../communication/EditorClient";
import { editorStore } from "../../EditorStore";
export function undoableSetUsername(username) {
  executeUndoableOperation(new SetUsernameOp(username));
}

class SetUsernameOp extends UndoableOperation {
  constructor(username) {
    super("setUsername");
    this.username = username;

    _defineProperty(this, "previousUsername", void 0);

    this.previousUsername = editorStore.username;
  }

  async execute() {
    editorStore.setUsername(this.username);
    editorClient.setUsername(this.username);
  }

  async reverse() {
    editorStore.setUsername(this.previousUsername);
    editorClient.setUsername(this.previousUsername);
  }

  merge(previousOperation) {
    this.previousUsername = previousOperation.previousUsername;
    return true;
  }

}