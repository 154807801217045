import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { editorClient } from "../../../communication/EditorClient";
export function undoableMapEditorLoadMap(currentMapStore, mapId) {
  if (currentMapStore.currentMapId === mapId) return;
  executeUndoableOperation(new MapEditorLoadMapOp(currentMapStore, mapId));
}

class MapEditorLoadMapOp extends UndoableOperation {
  constructor(currentMapStore, mapId) {
    super("mapEditorLoadMap");
    this.currentMapStore = currentMapStore;
    this.mapId = mapId;

    _defineProperty(this, "previousMapId", void 0);

    this.previousMapId = currentMapStore.currentMapId;
  }

  async execute() {
    await editorClient.openMapInMapEditor(this.currentMapStore, this.mapId);
  }

  async reverse() {
    if (this.previousMapId === null) {
      this.currentMapStore.clearCurrentMap();
    } else {
      await editorClient.openMapInMapEditor(this.currentMapStore, this.previousMapId);
    }
  }

}