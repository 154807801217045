import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { halfTileHeight, halfTileWidth } from "../../../helper/pixiHelpers";
import { Generic2DHandle } from "./Generic2DHandle";
const keepOffsetXY = {
  removeOffsetXY: false,
  removeSizeXY: true,
  removeOffsetZ: true,
  removeSizeZ: true
};
const keepOffsetAndSizeXY = {
  removeOffsetXY: false,
  removeSizeXY: false,
  removeOffsetZ: true,
  removeSizeZ: true
};
const keepZ = {
  removeOffsetXY: true,
  removeSizeXY: true,
  removeOffsetZ: false,
  removeSizeZ: false
};
const centerHandle = {
  sX: 0.5,
  sY: 0.5,
  sZ: 0,
  reversal: keepOffsetXY
};
const backHandleX = {
  sX: 0,
  sY: 0.5,
  sZ: 0,
  reversal: keepOffsetAndSizeXY
};
const frontHandleX = {
  sX: 1,
  sY: 0.5,
  sZ: 0,
  reversal: keepOffsetAndSizeXY
};
const backHandleY = {
  sX: 0.5,
  sY: 0,
  sZ: 0,
  reversal: keepOffsetAndSizeXY
};
const frontHandleY = {
  sX: 0.5,
  sY: 1,
  sZ: 0,
  reversal: keepOffsetAndSizeXY
};
const bottomHandle = {
  sX: 1,
  sY: 0,
  sZ: 0,
  reversal: keepZ
};
const topHandle = {
  sX: 1,
  sY: 0,
  sZ: 1,
  reversal: keepZ
};
const handleColorCenter = 0x65A2E1;
const handleColorX = 0x0861BE;
const handleColorY = handleColorX;
const handleColorZ = 0x053A72;
export class SizeAndHandleManager {
  constructor(cameraContainer, addHandleCallback, updateCallback, isGroundCallback) {
    this.addHandleCallback = addHandleCallback;
    this.updateCallback = updateCallback;
    this.isGroundCallback = isGroundCallback;

    _defineProperty(this, "handles", new Array());

    _defineProperty(this, "backHandleX", void 0);

    _defineProperty(this, "frontHandleX", void 0);

    _defineProperty(this, "backHandleY", void 0);

    _defineProperty(this, "frontHandleY", void 0);

    _defineProperty(this, "bottomHandle", void 0);

    _defineProperty(this, "topHandle", void 0);

    _defineProperty(this, "_tileAsset", void 0);

    this.bottomHandle = this.addHandle(new Generic2DHandle(cameraContainer, handleColorZ, () => !!this.target && !this.isGround, () => this.interpolatedTileToWorldPositionX(bottomHandle), () => this.interpolatedTileToWorldPositionY(bottomHandle), (x, y) => this.updateZ(this.bottomHandle.handleX, y, this.topHandle.handleX, this.topHandle.handleY)));
    this.topHandle = this.addHandle(new Generic2DHandle(cameraContainer, handleColorZ, () => !!this.target, () => this.interpolatedTileToWorldPositionX(topHandle), () => this.interpolatedTileToWorldPositionY(topHandle), (x, y) => this.updateZ(this.bottomHandle.handleX, this.bottomHandle.handleY, this.topHandle.handleX, y)));
    this.addHandle(new Generic2DHandle(cameraContainer, handleColorCenter, () => !!this.target && !this.isGround, () => this.interpolatedTileToWorldPositionX(centerHandle), () => this.interpolatedTileToWorldPositionY(centerHandle), (x, y) => {
      const tileX = this.interpolatedWorldToTilePositionX(x, y, centerHandle);
      const tileY = this.interpolatedWorldToTilePositionY(x, y, centerHandle);
      this.updateTarget(() => {
        this.target.setOffsetX(tileX);
        this.target.setOffsetY(tileY);
      });
    }));
    this.backHandleX = this.addHandle(new Generic2DHandle(cameraContainer, handleColorX, () => !!this.target && !this.isGround, () => this.interpolatedTileToWorldPositionX(backHandleX), () => this.interpolatedTileToWorldPositionY(backHandleX), (x, y) => this.updateX(x, y, this.frontHandleX.handleX, this.frontHandleX.handleY)));
    this.frontHandleX = this.addHandle(new Generic2DHandle(cameraContainer, handleColorX, () => !!this.target, () => this.interpolatedTileToWorldPositionX(frontHandleX), () => this.interpolatedTileToWorldPositionY(frontHandleX), (x, y) => this.updateX(this.backHandleX.handleX, this.backHandleX.handleY, x, y)));
    this.backHandleY = this.addHandle(new Generic2DHandle(cameraContainer, handleColorY, () => !!this.target && !this.isGround, () => this.interpolatedTileToWorldPositionX(backHandleY), () => this.interpolatedTileToWorldPositionY(backHandleY), (x, y) => this.updateY(x, y, this.frontHandleY.handleX, this.frontHandleY.handleY)));
    this.frontHandleY = this.addHandle(new Generic2DHandle(cameraContainer, handleColorY, () => !!this.target, () => this.interpolatedTileToWorldPositionX(frontHandleY), () => this.interpolatedTileToWorldPositionY(frontHandleY), (x, y) => this.updateY(this.backHandleY.handleX, this.backHandleY.handleY, x, y)));
  }

  addHandle(handle) {
    this.handles.push(handle);
    this.addHandleCallback(handle);
    return handle;
  }

  get target() {
    return this._tileAsset;
  }

  set target(value) {
    this._tileAsset = value;
    this.handles.forEach(handle => handle.updatePosition());
  }

  updateX(backWorldX, backWorldY, frontWorldX, frontWorldY) {
    const bX = this.isGround ? 0 : this.interpolatedWorldToTilePositionX(backWorldX, backWorldY, backHandleX);
    const fX = this.interpolatedWorldToTilePositionX(frontWorldX, frontWorldY, frontHandleX);
    this.updateTarget(() => {
      this.target.setOffsetX(bX);
      this.target.setSizeX(fX - this.target.offsetX);
    });
  }

  updateY(backWorldX, backWorldY, frontWorldX, frontWorldY) {
    const bY = this.isGround ? 0 : this.interpolatedWorldToTilePositionY(backWorldX, backWorldY, backHandleX);
    const fY = this.interpolatedWorldToTilePositionY(frontWorldX, frontWorldY, frontHandleX);
    this.updateTarget(() => {
      this.target.setOffsetY(bY);
      this.target.setSizeY(fY - this.target.offsetY);
    });
  }

  updateZ(bottomWorldX, bottomWorldY, topWorldX, topWorldY) {
    const bZ = this.isGround ? 0 : this.interpolatedWorldToTilePositionY(bottomWorldX, bottomWorldY, bottomHandle);
    const tZ = this.interpolatedWorldToTilePositionY(topWorldX, topWorldY, topHandle);
    this.updateTarget(() => {
      this.target.setInternalOffsetZ(-bZ);
      this.target.setSizeZ(-tZ * this.sizeDirection - this.target.internalOffsetZ);
    });
  }

  getTileX(config) {
    const {
      sX,
      sZ
    } = config;
    const {
      offsetX,
      internalOffsetZ,
      size
    } = this.target;
    const tileX = offsetX + sX * size.x - internalOffsetZ - sZ * size.z * this.sizeDirection;
    return tileX;
  }

  getTileY(config) {
    const {
      sY,
      sZ
    } = config;
    const {
      offsetY,
      internalOffsetZ,
      size
    } = this.target;
    const tileY = offsetY + sY * size.y - internalOffsetZ - sZ * size.z * this.sizeDirection;
    return tileY;
  }

  interpolatedTileToWorldPositionX(config) {
    const tileX = this.getTileX(config);
    const tileY = this.getTileY(config);
    let worldX = (tileX - tileY) * halfTileWidth;
    worldX += halfTileWidth;
    return worldX;
  }

  interpolatedTileToWorldPositionY(config) {
    const tileX = this.getTileX(config);
    const tileY = this.getTileY(config);
    return (tileX + tileY) * halfTileHeight;
  }

  interpolatedWorldToTilePositionX(worldX, worldY, config) {
    worldX -= halfTileWidth;
    let tileX = (worldX / halfTileWidth + worldY / halfTileHeight) / 2;
    const {
      reversal
    } = config;

    if (reversal.removeOffsetXY) {
      tileX -= this.target.offsetX;
    }

    if (reversal.removeSizeXY) {
      tileX -= this.target.size.x * config.sX;
    }

    if (reversal.removeOffsetZ) {
      tileX += this.target.internalOffsetZ;
    }

    if (reversal.removeSizeZ) {
      tileX += this.target.size.z * config.sZ * this.sizeDirection;
    }

    return tileX;
  }

  interpolatedWorldToTilePositionY(worldX, worldY, config) {
    worldX -= halfTileWidth;
    let tileY = (worldY / halfTileHeight - worldX / halfTileWidth) / 2;
    const {
      reversal
    } = config;

    if (reversal.removeOffsetXY) {
      tileY -= this.target.offsetY;
    }

    if (reversal.removeSizeXY) {
      tileY -= this.target.size.y * config.sY;
    }

    if (reversal.removeOffsetZ) {
      tileY += this.target.internalOffsetZ;
    }

    if (reversal.removeSizeZ) {
      tileY += this.target.size.z * config.sZ * this.sizeDirection;
    }

    return tileY;
  }

  updateTarget(changeExecuter) {
    this.updateCallback(this.target, changeExecuter);
  }

  get isGround() {
    return this.isGroundCallback(this.target);
  }

  get sizeDirection() {
    return this.isGround ? -1 : 1;
  }

}