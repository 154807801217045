import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable } from "mobx";
import { CircleGestureModel } from "../../shared/combat/gestures/CircleGestureModel";
import { LineGestureModel } from "../../shared/combat/gestures/LineGestureModel";
import { StaticAssetLoader } from "../canvas/loader/StaticAssetLoader";
import { CombatPhaseLength } from "../../shared/combat/CombatPhaseLength";
import { editorClient } from "../communication/EditorClient";
import { gameStore } from "./GameStore";
import { CombatSounds } from "../canvas/game/combat/CombatSounds";
import { soundCache } from "./SoundCache";
const ticksPerSecond = 60;
const keyStrokesPerSecond = 1;
const patternYCoordinateFactor = 1000;
export const screenToPatternCoordinatesFactor = 9;
export const gesturePatternViewOffset = {
  x: 190,
  y: 100
};
export let CombatPhase;

(function (CombatPhase) {
  CombatPhase[CombatPhase["Attack"] = 0] = "Attack";
  CombatPhase[CombatPhase["Defense"] = 1] = "Defense";
  CombatPhase[CombatPhase["FirstAttack"] = 2] = "FirstAttack";
  CombatPhase[CombatPhase["WinCombat"] = 3] = "WinCombat";
  CombatPhase[CombatPhase["LooseCombat"] = 4] = "LooseCombat";
})(CombatPhase || (CombatPhase = {}));

export class CombatStore {
  constructor() {
    _defineProperty(this, "config", void 0);

    _defineProperty(this, "player", void 0);

    _defineProperty(this, "enemies", []);

    _defineProperty(this, "activeSkill", void 0);

    _defineProperty(this, "currentPhase", void 0);

    _defineProperty(this, "enemyIndex", void 0);

    _defineProperty(this, "inTransition", void 0);

    _defineProperty(this, "currentTimerStart", void 0);

    _defineProperty(this, "currentTimerTicks", void 0);

    _defineProperty(this, "currentHitAnimationTimerTicks", void 0);

    _defineProperty(this, "failedGestureCooldownTicks", void 0);

    _defineProperty(this, "patternSuccessCooldownTicks", void 0);

    _defineProperty(this, "expectedGestures", void 0);

    _defineProperty(this, "expectedKeySequence", void 0);

    _defineProperty(this, "finishedGesture", void 0);

    _defineProperty(this, "finishedPattern", void 0);

    _defineProperty(this, "currentGestureAnalysis", void 0);

    _defineProperty(this, "currentCooldown", void 0);

    _defineProperty(this, "currentHitAnimationDuration", void 0);

    _defineProperty(this, "currentPrecision", void 0);

    _defineProperty(this, "currentMissTollerance", void 0);

    _defineProperty(this, "misses", void 0);

    _defineProperty(this, "gesturePerformed", false);

    _defineProperty(this, "finishArenaFight", void 0);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
  }

  get hasConfig() {
    return !!this.config;
  }

  setConfig(config) {
    if (this.config === config) return;

    if (this.config) {
      editorClient.stopTrackingCombatConfiguration();
    }

    this.config = config;

    if (this.config) {
      editorClient.startTrackingCombatConfiguration();
    }
  }

  start(enemies, finishArenaFight) {
    this.player = new Player();
    this.enemies = enemies;
    this.enemyIndex = 0;
    this.player.activityTimerTicks = 0;
    this.currentCooldown = 0;
    this.currentHitAnimationTimerTicks = 0;
    this.patternSuccessCooldownTicks = 0;
    this.inTransition = true;
    this.setCurrentPhase(CombatPhase.FirstAttack);
    this.restartTimer(this.config.phaseTransitionDuration);
    this.currentGestureAnalysis = [];
    this.activeSkill = this.config.playerAttacks.find(a => a.name === "attack_simple");
    this.finishArenaFight = finishArenaFight;
    this.resetPattern();
  }

  setSkill(id) {
    this.activeSkill = this.config.playerAttacks.find(a => a.name === id);

    if (this.attacking && !this.inColldown()) {
      var _this$expectedGesture;

      if ((_this$expectedGesture = this.expectedGestures) !== null && _this$expectedGesture !== void 0 && _this$expectedGesture.some(g => g == null)) {
        this.gestureFailed();
      } else {
        this.resetPattern();
      }
    }
  }

  get active() {
    return this.enemies.length > 0;
  }

  get attacking() {
    return this.currentPhase === CombatPhase.Attack || this.currentPhase === CombatPhase.FirstAttack;
  }

  get currentPhaseLength() {
    const currentRound = this.currentRound;
    return this.attacking ? currentRound.playerAttackPhaseLength : currentRound.playerDefensePhaseLength;
  }

  get currentEnemy() {
    return this.enemies[this.enemyIndex];
  }

  get currentCombatPreset() {
    return this.config.enemyCombatPresets.find(preset => preset.$modelId === this.currentEnemy.combatPresetId);
  }

  get currentRound() {
    return this.currentCombatPreset.rounds[this.currentEnemy.round];
  }

  get currentPattern() {
    if (this.attacking) {
      return this.activeSkill.pattern;
    } else {
      if (!this.currentEnemy.defensePatternId) {
        // first defense phase in this fight
        this.currentEnemy.chooseNewRandomDefensePattern(this.currentRound.playerDefenseGesturePatternIds);
      }

      return this.config.findGesturePattern(this.currentEnemy.defensePatternId);
    }
  }

  finish() {
    const win = this.enemies.every(e => !e.alive);

    if (win) {
      this.enemies.forEach(e => gameStore.gameEngine.gameState.defeatedEnemies.add(e.elementId));
    } else {
      gameStore.gameEngine.gameState.setPlayerHealth(0);
    }

    this.enemies = [];
    this.finishArenaFight();
    this.finishArenaFight = null;
  }

  restartTimer(time) {
    this.currentTimerStart = time * ticksPerSecond;
    this.currentTimerTicks = this.currentTimerStart;
  }

  resetPattern() {
    this.misses = 0;
    this.currentGestureAnalysis = [];
    this.finishedGesture = null;
    this.finishedPattern = null;

    if (!this.inTransition) {
      const patternDefinition = this.currentPattern;
      this.setExpectedGestures(patternDefinition.gestures.slice());
      this.expectedKeySequence = patternDefinition.keySequence.split("");
      this.currentPrecision = patternDefinition.precision;
      this.currentMissTollerance = patternDefinition.missTolerance;

      if (this.attacking) {
        this.currentCooldown = this.activeSkill.cooldown;
        this.currentHitAnimationDuration = this.activeSkill.hitAnimationDuration;
      } else {
        this.currentCooldown = 1;
        this.currentHitAnimationDuration = 0.8;
      }
    } else {
      this.setExpectedGestures(null);
      this.expectedKeySequence = null;
    }
  }

  inColldown() {
    if (!this.enemies) return false; // Cooldown: pattern finished/failed but not yet ready to start new pattern

    return this.patternSuccessCooldownTicks > 0 || this.failedGestureCooldownTicks > 0 || this.currentTimerTicks < ticksPerSecond + 10;
  }

  reduceTimer(deltaTimeTicks) {
    this.player.activityTimerTicks -= deltaTimeTicks;
    this.failedGestureCooldownTicks -= deltaTimeTicks;
    this.currentHitAnimationTimerTicks -= deltaTimeTicks;
    this.patternSuccessCooldownTicks -= deltaTimeTicks;

    if (!this.expectedGestures && !this.inColldown()) {
      // last pattern was completed (or failed) and cooldown is over
      this.resetPattern();
    }

    if (this.checkFinished() && this.currentPhase !== CombatPhase.WinCombat && this.currentPhase !== CombatPhase.LooseCombat) {
      const gameOver = gameStore.gameEngine.gameState.playerHealth <= 1;
      this.setCurrentPhase(gameOver ? CombatPhase.LooseCombat : CombatPhase.WinCombat);
      this.inTransition = true;
      this.restartTimer(gameOver ? 3 : this.config.phaseTransitionDuration);
      this.resetPattern();
      return;
    }

    if (gameStore.accessibilityOptions && this.expectedGestures) {
      if (this.gesturePerformed) {
        this.currentTimerTicks -= ticksPerSecond / keyStrokesPerSecond;
      }
    } else {
      this.currentTimerTicks -= deltaTimeTicks;
    }

    if (this.currentTimerTicks <= 0) {
      var _this$expectedGesture2;

      if (this.attacking && (_this$expectedGesture2 = this.expectedGestures) !== null && _this$expectedGesture2 !== void 0 && _this$expectedGesture2.some(g => g == null)) {
        this.gestureFailed();
        return;
      }

      if (this.currentPhase === CombatPhase.WinCombat || this.currentPhase === CombatPhase.LooseCombat) {
        this.finish();
        return;
      }

      this.inTransition = !this.inTransition;

      if (this.inTransition) {
        this.transitionToNextPhase();
      } else {
        this.startCurrentPhase();
      }
    }

    this.enemies.forEach(e => this.enemyActivity(e, deltaTimeTicks));
    this.setGesturePerformed(false);
  }

  checkFinished() {
    return this.enemies.every(e => !e.alive) || gameStore.gameEngine.gameState.playerHealth <= 1;
  }

  transitionToNextPhase() {
    this.enemies.forEach(e => e.activityTimerTicks = 0);
    this.failedGestureCooldownTicks = 0;
    this.player.setLostHealth(false);
    this.restartTimer(this.config.phaseTransitionDuration);

    if (this.attacking) {
      this.setCurrentPhase(CombatPhase.Defense);
    } else {
      this.setCurrentPhase(CombatPhase.Attack);
    }

    this.resetPattern();
  }

  startCurrentPhase() {
    if (this.attacking) {
      this.startNextEnemyRoundAttack();
    } else {
      this.startNextEnemyRoundDefend();
    }

    this.resetPattern();
  }

  nextEnemyIndex() {
    if (this.enemyIndex + 1 === this.enemies.length) return 0;
    return this.enemyIndex + 1;
  }

  selectNextActiveEnemy() {
    // select the next enemy that is still alive
    this.enemyIndex = this.nextEnemyIndex();

    while (!this.enemies[this.enemyIndex].alive) {
      this.enemyIndex = this.nextEnemyIndex();
    } // select next round for chosen enemy


    this.currentEnemy.nextRound(this.currentCombatPreset.rounds.length);
    this.currentEnemy.chooseNewRandomDefensePattern(this.currentRound.playerDefenseGesturePatternIds);
  }

  startNextEnemyRoundAttack() {
    this.selectNextActiveEnemy();
    const phaseDuration = this.currentRound.playerAttackPhaseLength === CombatPhaseLength.Short ? this.config.shortPhaseDuration : this.config.longPhaseDuration;
    this.restartTimer(phaseDuration);
  }

  startNextEnemyRoundDefend() {
    const phaseDuration = this.currentRound.playerDefensePhaseLength === CombatPhaseLength.Short ? this.config.shortPhaseDuration : this.config.longPhaseDuration;
    this.restartTimer(phaseDuration);
    this.currentEnemy.activityTimerTicks = phaseDuration * ticksPerSecond;
  } // ==  Methods to handle pattern inputs from the 'Game'


  gestureStart() {
    if (!this.expectedGestures) return;
    this.currentGestureAnalysis = this.expectedGestures.map(gesture => {
      if (gesture instanceof LineGestureModel) {
        return this.pointsOnLine(gesture);
      }

      if (gesture instanceof CircleGestureModel) {
        return this.pointsOnCircle(gesture);
      }

      return null;
    });
  }

  gestureInput(p) {
    const variation = this.currentPrecision;
    let gestureIndex = 0;
    const xPattern = Math.round(p.x);
    const yPattern = Math.round(p.y);
    let hit = false;

    for (const gesture of this.currentGestureAnalysis) {
      if (gesture) {
        for (let xvar = -variation; xvar < variation; xvar++) {
          for (let yvar = -variation; yvar < variation; yvar++) {
            const pos = xPattern + xvar + patternYCoordinateFactor * (yPattern + yvar);
            hit = gesture.delete(pos) || hit;
          }
        }

        if (gesture.size === 0) {
          this.finishedGesture = this.expectedGestures[gestureIndex];
          hit = true;
          this.misses = 0;

          if (this.expectedGestures) {
            this.expectedGestures[gestureIndex] = null;
            this.onGestureSuccess();
          }

          this.currentGestureAnalysis = [];

          if (!this.patternCompleted()) {
            this.doDamageOrDefend(false);
          }

          this.setGesturePerformed(true);
          break;
        }
      }

      gestureIndex++;
    }

    if (this.patternCompleted()) {
      this.finishedPattern = this.currentPattern.gestures;
      this.doDamageOrDefend(true);
      this.setExpectedGestures(null);
    }

    if (this.currentGestureAnalysis.length > 0 && !hit) {
      this.misses++;

      if (this.misses > this.currentMissTollerance) {
        this.gestureFailed();
      }
    }
  }

  gestureEnd() {
    this.finishedGesture = null;

    if (this.currentGestureAnalysis.length > 0) {
      this.gestureFailed();
    }
  }

  keyInput(key) {
    if (!gameStore.accessibilityOptions) return;
    if (!this.expectedKeySequence || this.expectedKeySequence.length === 0) return;

    if (this.expectedKeySequence[0] === key) {
      this.expectedKeySequence.shift();

      if (!this.keySequenceCompleted()) {
        this.doDamageOrDefend(false);
      }

      this.onGestureSuccess();
    } else {
      this.gestureFailed();
      this.onGestureFailed();

      if (!this.attacking) {
        this.endDefensePhase(true);
      }
    }

    if (this.keySequenceCompleted()) {
      this.finishedPattern = this.currentPattern.gestures;
      this.doDamageOrDefend(true);
      this.setExpectedGestures(null);
      this.expectedKeySequence = null;
    }

    this.setGesturePerformed(true);
  }

  patternCompleted() {
    return this.expectedGestures && this.expectedGestures.every(g => g == null);
  }

  keySequenceCompleted() {
    return this.expectedKeySequence && this.expectedKeySequence.length === 0;
  }

  gestureFailed() {
    this.failedGestureCooldownTicks = 0.5 * ticksPerSecond;

    if (this.attacking || this.currentTimerTicks > 1.5 * ticksPerSecond) {
      // only reset, if there is enough time to actually redo the pattern before the pattern animation
      this.misses = 0;
      this.setExpectedGestures(null);
      this.expectedKeySequence = null;
      this.currentGestureAnalysis = [];
      this.onGestureFailed();
    }
  }

  pointsOnLine(gesture) {
    const result = new Set();
    const xDirection = gesture.to.x > gesture.from.x ? 1 : -1;
    const yDirection = gesture.to.y > gesture.from.y ? 1 : -1;
    const distX = (gesture.to.x - gesture.from.x) * xDirection;
    const distY = (gesture.to.y - gesture.from.y) * yDirection;

    if (distX > distY) {
      const skew = distY / distX;

      for (let xDelta = 0; xDelta < distX; xDelta++) {
        const x = gesture.from.x + xDelta * xDirection;
        const y = gesture.from.y + Math.round(xDelta * skew) * yDirection;
        result.add(x + patternYCoordinateFactor * y);
      }
    } else {
      const skew = distX / distY;

      for (let yDelta = 0; yDelta < distY; yDelta++) {
        const x = gesture.from.x + Math.round(yDelta * skew) * xDirection;
        const y = gesture.from.y + yDelta * yDirection;
        result.add(x + patternYCoordinateFactor * y);
      }
    }

    return result;
  }

  pointsOnCircle(gesture) {
    const result = new Set();
    const xc = gesture.center.x;
    const yc = gesture.center.y;
    let x = 0;
    let y = gesture.radius;
    let d = 3 - 2 * gesture.radius;

    while (y >= x) {
      result.add(xc + x + patternYCoordinateFactor * (yc + y));
      result.add(xc - x + patternYCoordinateFactor * (yc + y));
      result.add(xc + x + patternYCoordinateFactor * (yc - y));
      result.add(xc - x + patternYCoordinateFactor * (yc - y));
      result.add(xc + y + patternYCoordinateFactor * (yc + x));
      result.add(xc - y + patternYCoordinateFactor * (yc + x));
      result.add(xc + y + patternYCoordinateFactor * (yc - x));
      result.add(xc - y + patternYCoordinateFactor * (yc - x));
      x++;

      if (d > 0) {
        y--;
        d = d + 4 * (x - y) + 10;
      } else {
        d = d + 4 * x + 6;
      }
    }

    return result;
  } // ==  Methods to calculate the damage for the player or the enemies


  gestureDamage(attackConfig) {
    return Math.ceil(attackConfig.damage * (1 - this.config.bonusDamage) / attackConfig.pattern.gestures.length);
  }

  patternCompletionDamage(attackConfig) {
    // damage of the last gesture + completion bonus
    return attackConfig.damage + Math.ceil(attackConfig.damage * this.config.bonusDamage);
  }

  doDamageOrDefend(patternCompleted) {
    if (this.attacking) {
      const multiplier = this.currentRound.playerAttackPhaseLength === CombatPhaseLength.Short ? this.config.shortPlayerAttackPhaseDamageMultiplier : this.config.longPlayerAttackPhaseDamageMultiplier;
      const damage = multiplier * (patternCompleted ? this.patternCompletionDamage(this.activeSkill) : this.gestureDamage(this.activeSkill));
      const doAreaDamage = this.activeSkill.name === "attack_complex";
      const allEnemiesAlive = this.enemies.filter(enemy => enemy.alive);
      const enemiesToAttack = doAreaDamage ? allEnemiesAlive : [this.currentEnemy];
      const cooldown = patternCompleted ? this.currentCooldown : 0.2;
      this.patternSuccessCooldownTicks = patternCompleted ? cooldown * ticksPerSecond : 0;
      enemiesToAttack.forEach(enemy => {
        enemy.health -= patternCompleted ? damage : Math.min(damage, enemy.health - 1); // keep enemy at '1' health if this is not completion damage (you need to complete a pattern to defeat)

        enemy.activityTimerTicks = patternCompleted ? (this.currentCooldown + StaticAssetLoader.animationProperties.effect_slash.animationDuration) * ticksPerSecond : ticksPerSecond * 0.5;

        if (enemy.health <= 0 && enemy === this.currentEnemy) {
          this.selectNextActiveEnemy();
        }
      });

      if (patternCompleted) {
        // make sure enough time is left in phase to finish cooldown animation
        this.currentTimerTicks = Math.max(this.currentTimerTicks, cooldown * ticksPerSecond + ticksPerSecond);
      }
    } else if (patternCompleted) {
      // defense phase
      this.endDefensePhase(false);
    }

    if (patternCompleted) {
      this.currentHitAnimationTimerTicks = Math.min(this.currentHitAnimationDuration, this.currentCooldown) * ticksPerSecond;
    }
  }

  endDefensePhase(letEnemyAttack) {
    this.player.activityTimerTicks = (this.currentCooldown + StaticAssetLoader.animationProperties.effect_slash.animationDuration) * ticksPerSecond;
    this.currentTimerTicks = this.player.activityTimerTicks; // end the defense phase early as the player defended successfully already

    this.enemies.forEach(e => {
      if (letEnemyAttack && e.activityTimerTicks > 0) {
        e.activityTimerTicks = 1;
      } else {
        e.activityTimerTicks = 0;
      }
    });
    this.patternSuccessCooldownTicks = this.currentTimerTicks + 5;
  }

  enemyActivity(enemy, deltaTimeTicks) {
    if (!enemy) return;
    if (enemy.activityTimerTicks <= 0) return;
    enemy.activityTimerTicks -= deltaTimeTicks;
    if (this.attacking) return;

    if (enemy.activityTimerTicks <= 0) {
      // an enemy is attacking in a defense phase
      const round = this.currentRound;
      const factor = round.playerDefensePhaseLength === CombatPhaseLength.Short ? 1 : this.config.longDefensePhaseDamageFactor; // we keep the player at "1" health when the fight is lost.
      // She will loose the last health point after the attack animation played.

      gameStore.gameEngine.gameState.changePlayerHealth(-enemy.damage * factor, 1);
      this.player.setLostHealth(true);
      this.player.activityTimerTicks = (this.currentCooldown + StaticAssetLoader.animationProperties.effect_slash.animationDuration) * ticksPerSecond;
      this.currentTimerTicks = this.player.activityTimerTicks;
      this.currentHitAnimationTimerTicks = Math.min(this.currentHitAnimationDuration, this.currentCooldown) * ticksPerSecond;
    }
  }

  clear() {
    this.enemies = [];
  }

  setCurrentPhase(combatPhaseToSet) {
    const previousPhase = this.currentPhase;
    this.currentPhase = combatPhaseToSet; // apply it before calling handle change..

    if (combatPhaseToSet !== previousPhase) this.onCombatPhaseChange(combatPhaseToSet, previousPhase);
  }

  setExpectedGestures(gestures) {
    this.expectedGestures = gestures;
  }

  setGesturePerformed(performed) {
    this.gesturePerformed = performed;
  }

  allGesturesSucceeded() {
    return this.expectedGestures && !this.expectedGestures.some(item => item != null);
  }

  isActiveBombSkill() {
    var _this$activeSkill;

    return ((_this$activeSkill = this.activeSkill) === null || _this$activeSkill === void 0 ? void 0 : _this$activeSkill.name) == "attack_complex";
  }

  isActiveKnifeSkill() {
    var _this$activeSkill2;

    return ((_this$activeSkill2 = this.activeSkill) === null || _this$activeSkill2 === void 0 ? void 0 : _this$activeSkill2.name) == "attack_simple";
  }

  onGestureSuccess() {
    if (this.allGesturesSucceeded() || this.keySequenceCompleted()) {
      if (this.currentPhase === CombatPhase.Defense) {
        soundCache.playOneOf(CombatSounds.DEFENSE_SUCCESS);
      } else {
        if (this.isActiveBombSkill()) soundCache.playOneOf(CombatSounds.BOMB_ATTACK_SUCCESS);
        if (this.isActiveKnifeSkill()) soundCache.playOneOf(CombatSounds.KNIFE_ATTACK_SUCCESS);
      }

      return;
    }

    if (this.currentPhase === CombatPhase.Attack || this.currentPhase === CombatPhase.FirstAttack) {
      if (this.isActiveBombSkill()) soundCache.playOneOf(CombatSounds.BOMB_ATTACK_ATTEMPT);
      if (this.isActiveKnifeSkill()) soundCache.playOneOf(CombatSounds.KNIFE_ATTACK_ATTEMPT);
    }

    if (this.currentPhase === CombatPhase.Defense) soundCache.playOneOf(CombatSounds.DEFENSE_ATTEMPT);
  }

  onGestureFailed() {
    if (this.currentPhase === CombatPhase.Attack || this.currentPhase === CombatPhase.FirstAttack) {
      if (this.isActiveBombSkill()) soundCache.playOneOf(CombatSounds.BOMB_ATTACK_FAILED_ATTEMPT);
      if (this.isActiveKnifeSkill()) soundCache.playOneOf(CombatSounds.KNIFE_ATTACK_FAILED_ATTEMPT);
    }

    if (this.currentPhase === CombatPhase.Defense) soundCache.playOneOf(CombatSounds.DEFENSE_ATTEMPT_FAILED);
  }

  onCombatPhaseChange(current, previous) {
    const phaseLength = this.currentPhaseLength;
    if (current === CombatPhase.FirstAttack) soundCache.playOneOf(CombatSounds.START);
    if (current === CombatPhase.WinCombat) soundCache.playOneOf(CombatSounds.SUCCESS);

    if (current === CombatPhase.Defense) {
      if (phaseLength === CombatPhaseLength.Long) soundCache.playOneOf(CombatSounds.ROUND_DEFENSE_LONG);
      if (phaseLength === CombatPhaseLength.Short) soundCache.playOneOf(CombatSounds.ROUND_DEFENSE_SHORT);
    }

    if (current === CombatPhase.Attack) {
      if (phaseLength === CombatPhaseLength.Long) soundCache.playOneOf(CombatSounds.ROUND_ATTACK_LONG);
      if (phaseLength === CombatPhaseLength.Short) soundCache.playOneOf(CombatSounds.ROUND_ATTACK_SHORT);
    }
  }

} // == Data classes for additional state of the player/enemies that is only needed during combat

export class Enemy {
  constructor(elementId, x, y, hitPoints, combatPresetId, damage) {
    this.elementId = elementId;
    this.x = x;
    this.y = y;
    this.hitPoints = hitPoints;
    this.combatPresetId = combatPresetId;
    this.damage = damage;

    _defineProperty(this, "activityTimerTicks", 0);

    _defineProperty(this, "round", 0);

    _defineProperty(this, "defensePatternId", void 0);

    _defineProperty(this, "health", void 0);

    this.health = hitPoints;
    makeAutoObservable(this, {}, {
      autoBind: true
    });
  }

  get alive() {
    return this.health > 0 || this.activityTimerTicks > 0;
  }

  nextRound(roundsAvailable) {
    this.round++;

    if (this.round === roundsAvailable) {
      this.round = 0;
    }
  }

  chooseNewRandomDefensePattern(defensePatternIds) {
    // random defense pattern for round
    this.defensePatternId = defensePatternIds[Math.floor(Math.random() * defensePatternIds.length)];
  }

}
export class Player {
  constructor() {
    _defineProperty(this, "activityTimerTicks", 0);

    _defineProperty(this, "lostHealth", false);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
  }

  setLostHealth(value) {
    if (!this.lostHealth && value) {
      soundCache.playOneOf(CombatSounds.PLAYER_LOST_HEALTH);
    }

    this.lostHealth = value;
  }

}
export const combatStore = new CombatStore();