import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { gameConstants } from "../../../data/gameConstants";
import { animationLoader } from "../../../helper/AnimationLoader";
import { repeatCallUntilSuccess } from "../../../helper/asyncHelpers";
import { createOrUpdateBoxFromAnimationData } from "../../../helper/mapElementSortingHelper";
import { tileToWorldPositionX, tileToWorldPositionY } from "../../../helper/pixiHelpers";
import { errorStore } from "../../../stores/ErrorStore";
import { sharedStore } from "../../../stores/SharedStore";
import { SkipCullingUntilFirstRenderContainer } from "../optimization/SkipCullingUntilFirstRenderContainer";
import { BoundsUpdateMode } from "./sorting/MapElementContainer";
export class AnimationElementViewBase extends SkipCullingUntilFirstRenderContainer {
  constructor(data, repeatLoadingUntilSuccess, repeatLoadingUntilSuccessCancelled) {
    super(BoundsUpdateMode.UpdateFromBox);
    this.repeatLoadingUntilSuccess = repeatLoadingUntilSuccess;
    this.repeatLoadingUntilSuccessCancelled = repeatLoadingUntilSuccessCancelled;

    _defineProperty(this, "animationElementData", void 0);

    _defineProperty(this, "spine", void 0);

    _defineProperty(this, "_loadingPromise", void 0);

    _defineProperty(this, "baseRefreshMethods", [this.refreshPosition, this.refreshStartAnimation, this.refreshSpineAnimation, this.refreshScale, this.refreshTint, this.updateBox]);

    this.animationElementData = data;
  }

  get $modelId() {
    return this.animationElementData.$modelId;
  }

  get loadingPromise() {
    return this._loadingPromise;
  }

  refreshPosition() {
    const {
      x,
      y
    } = this.animationElementData.position;
    this.x = tileToWorldPositionX(x, y);
    this.y = tileToWorldPositionY(x, y);
    this.updateBox();
  }

  refreshScale() {
    const animation = sharedStore.getAnimationByName(this.animationElementData.animationName);
    if (!animation) return;
    const {
      scale
    } = animation;

    if (this.spine) {
      this.spine.scale.set(scale, scale);
    }
  }

  get tilePosition() {
    return this.animationElementData.position;
  }

  setPosition(newPosition) {
    const {
      x,
      y
    } = newPosition;
    this.x = tileToWorldPositionX(x, y);
    this.y = tileToWorldPositionY(x, y);
    this.animationElementData.position = newPosition;
    this.updateBox();
  }

  updateBox() {
    const animation = sharedStore.getAnimationByName(this.animationElementData.animationName);
    this.setBox(createOrUpdateBoxFromAnimationData(this.box, this.animationElementData.position, animation));
  }

  refreshSpineAnimation() {
    if (!sharedStore.animationsInitialized) return; // Destroy previous animation, if there is any

    if (this.spine) {
      this.spine.destroy({
        children: true,
        texture: false,
        baseTexture: false
      });
      this.spine = null;
    }

    let initialLoadingPromise;

    const load = () => animationLoader.getSpineFromAnimationName(this.animationElementData.animationName);

    if (this.repeatLoadingUntilSuccess) {
      initialLoadingPromise = repeatCallUntilSuccess(load, this.repeatLoadingUntilSuccessCancelled, errorStore.addErrorFromErrorObject);
    } else {
      initialLoadingPromise = load();
    }

    this._loadingPromise = initialLoadingPromise.then(spine => {
      spine.position.set(gameConstants.tileWidth / 2, gameConstants.tileHeight / 2); // to tile center

      this.addChildAt(spine, 0);
      this.spine = spine;
      this.refreshPartOfLoop(); // Since this is called inside asynchronous code, the contents of refreshStartAnimation() are NOT
      // called as a reaction. That's good, because we don't want to call refreshSpineAnimation() every
      // time the animationElementData.startAnimationName changes. refreshStartAnimation() has its own
      // reaction for later when the spine animation is loaded.

      this.refreshStartAnimation();
      this.refreshScale();
      this.onAnimationLoaded();
    }).catch(errorStore.addErrorFromErrorObject);
  }

  refreshStartAnimation() {
    const {
      startAnimationName,
      startAnimationLoops
    } = this.animationElementData;

    if (!startAnimationName) {
      var _this$spine;

      (_this$spine = this.spine) === null || _this$spine === void 0 ? void 0 : _this$spine.state.setEmptyAnimation(0);
      return;
    }

    this.playAnimation(startAnimationName, startAnimationLoops, false);
  }

  playAnimation(name, loop, restartIfAlreadyPlaying) {
    try {
      if (this.spine && (restartIfAlreadyPlaying || !this.isAlreadyAnimating(name, loop))) {
        this.spine.state.setAnimation(0, name, loop);
        const animation = this.spine.spineData.findAnimation(name);
        return animation ? animation.duration : 0;
      }
    } catch (e) {
      errorStore.addErrorFromErrorObject(e);
    }

    return 0;
  }

  isAlreadyAnimating(name, loop) {
    var _this$spine2;

    const track = (_this$spine2 = this.spine) === null || _this$spine2 === void 0 ? void 0 : _this$spine2.state.tracks[0];
    return track && track.animation.name === name && track.loop === loop;
  }

  onAnimationLoaded() {}

  refreshTint() {}

}