import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Container } from "pixi.js";
import { combatStore } from "../../../stores/CombatStore";
import { staticAssetLoader } from "../../loader/StaticAssetLoader";
export class SkillSelectorView extends Container {
  constructor(skillPattern, symbol) {
    super();
    this.skillPattern = skillPattern;

    _defineProperty(this, "active", void 0);

    _defineProperty(this, "inactive", void 0);

    const buttonSize = 160;
    this.inactive = staticAssetLoader.createStaticAssetView(symbol);
    this.inactive.width = buttonSize;
    this.inactive.height = buttonSize;
    this.active = staticAssetLoader.createStaticAssetView(symbol + "_selected");
    this.active.width = buttonSize;
    this.active.height = buttonSize;
    this.active.visible = false;
    this.addChild(this.inactive, this.active);
    this.inactive.interactive = true;
    this.inactive.on("pointerdown", this.setSkill.bind(this));
  }

  setSkill(e) {
    e.stopPropagation();
    if (this.active.visible) return;
    combatStore.setSkill(this.skillPattern);
  }

  activate() {
    this.active.visible = true;
  }

  deactivate() {
    this.active.visible = false;
  }

}