import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable } from "mobx";
import { combatStore } from "./CombatStore";
export class CombatEditorStore {
  constructor() {
    _defineProperty(this, "selectedEnemyCombatPresetId", void 0);

    _defineProperty(this, "selectedGesturePatternId", void 0);

    _defineProperty(this, "showEnemyCombatPresetGesturePatternSelectionRoundModelId", void 0);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
  }

  get selectedEnemyCombatPreset() {
    var _combatStore$config;

    return (_combatStore$config = combatStore.config) === null || _combatStore$config === void 0 ? void 0 : _combatStore$config.findEnemyCombatPreset(this.selectedEnemyCombatPresetId);
  }

  setSelectedEnemyCombatPreset(id) {
    if (this.selectedEnemyCombatPresetId == id) return;
    this.selectedEnemyCombatPresetId = id;
    this.showEnemyCombatPresetGesturePatternSelectionRoundModelId = null;
  }

  clearSelectedEnemyCombatPreset() {
    this.selectedEnemyCombatPresetId = null;
  }

  get selectedGesturePattern() {
    var _combatStore$config2;

    return (_combatStore$config2 = combatStore.config) === null || _combatStore$config2 === void 0 ? void 0 : _combatStore$config2.findGesturePattern(this.selectedGesturePatternId);
  }

  setSelectedGesturePattern(id) {
    this.selectedGesturePatternId = id;
  }

  clearSelectedGesturePattern() {
    this.selectedGesturePatternId = null;
  }

  setShowEnemyCombatPresetGesturePatternSelectionModelId(roundModelId) {
    this.showEnemyCombatPresetGesturePatternSelectionRoundModelId = roundModelId;
  }

  clear() {
    this.selectedEnemyCombatPresetId = null;
    this.selectedGesturePatternId = null;
  }

}
export const combatEditorStore = new CombatEditorStore();