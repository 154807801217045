import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { managementClient } from "../../../communication/ManagementClient";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableCreateModule(workshopId) {
  return executeUndoableOperation(new ModuleCreationOp(workshopId));
}

class ModuleCreationOp extends UndoableOperation {
  constructor(workshopId) {
    super("moduleCreation");
    this.workshopId = workshopId;

    _defineProperty(this, "module", void 0);
  }

  async execute(redo) {
    if (redo) {
      await managementClient.unDeleteModule(this.module.$modelId);
    } else {
      this.module = await managementClient.createModule(this.workshopId);
    }
  }

  async reverse() {
    await managementClient.deleteModule(this.module.$modelId);
  }

}