import _defineProperty from "@babel/runtime/helpers/defineProperty";

class IterableIteratorWrapper {
  constructor(iterator) {
    this.iterator = iterator;

    _defineProperty(this, "used", false);
  }

  markUsed() {
    if (this.used) throw new Error("IterableIteratorWrapper can only be used once.");
    this.used = true;
  }
  /**
   * Determines whether the specified callback function returns true for any element of an iterator.
   * @param predicate A function that accepts up to two arguments. The some method calls
   * the predicate function for each element in the iterator until the predicate returns a value
   * which is coercible to the Boolean value true, or until the end of the iterator.
   * @param thisArg An object to which the this keyword can refer in the predicate function.
   * If thisArg is omitted, undefined is used as the this value.
   */


  some(predicate, thisArg) {
    this.markUsed();

    if (thisArg) {
      predicate = predicate.bind(thisArg);
    }

    let i = 0;

    for (const element of this.iterator) {
      if (predicate(element, i)) return true;
      i++;
    }

    return false;
  }
  /**
   * Calls a defined callback function on each element of an iterator, and returns an array that contains the results.
   * @param callbackfn A function that accepts up to two arguments. The map method calls the callbackfn function one time for each element in the iterator.
   * @param thisArg An object to which the this keyword can refer in the callbackfn function. If thisArg is omitted, undefined is used as the this value.
   */


  map(callbackfn, thisArg) {
    this.markUsed();

    if (thisArg) {
      callbackfn = callbackfn.bind(thisArg);
    }

    const result = new Array();
    let i = 0;

    for (const element of this.iterator) {
      result.push(callbackfn(element, i));
      i++;
    }

    return result;
  }
  /**
   * Returns the elements of an iterator that meet the condition specified in a callback function.
   * @param predicate A function that accepts up to three arguments. The filter method calls the predicate function one time for each element in the iterator.
   * @param thisArg An object to which the this keyword can refer in the predicate function. If thisArg is omitted, undefined is used as the this value.
   */


  filter(predicate, thisArg) {
    this.markUsed();

    if (thisArg) {
      predicate = predicate.bind(thisArg);
    }

    const result = new Array();
    let i = 0;

    for (const element of this.iterator) {
      if (predicate(element, i)) {
        result.push(element);
      }

      i++;
    }

    return result;
  }
  /**
       * Returns the value of the first element in the iterator where predicate is true, and undefined
       * otherwise.
       * @param predicate find calls predicate once for each element of the iterator, in ascending
       * order, until it finds one where predicate returns true. If such an element is found, find
       * immediately returns that element value. Otherwise, find returns undefined.
       * @param thisArg If provided, it will be used as the this value for each invocation of
       * predicate. If it is not provided, undefined is used instead.
       */


  find(predicate, thisArg) {
    this.markUsed();

    if (thisArg) {
      predicate = predicate.bind(thisArg);
    }

    let i = 0;

    for (const element of this.iterator) {
      if (predicate(element, i)) return element;
      i++;
    }

    return undefined;
  }

  indexOf(value) {
    this.markUsed();
    let i = 0;

    for (const element of this.iterator) {
      if (element === value) return i;
      i++;
    }

    return -1;
  }

}

export function wrapIterator(iterator) {
  return new IterableIteratorWrapper(iterator);
}
export function wrapArraySet(arraySet) {
  return new IterableIteratorWrapper(arraySet.values());
}