import { ReadonlyEditorModule } from "../../../shared/workshop/ModuleModel";
import { navigateTo } from "../../helper/navigationHelpers";
import { editorStore } from "../../stores/EditorStore";
import { undoStore } from "../../stores/undo/UndoStore";
import { managementStore } from "../../stores/ManagementStore";
import { routes } from "../../data/routes";
import { editorClient } from "../../communication/EditorClient";
import { userStore } from "../../stores/UserStore";
export function openProductionEditor(history, moduleId = undefined) {
  if (moduleId) {
    const module = managementStore.getModule(moduleId);
    const workshop = managementStore.getWorkshop(module.workshopId);
    editorStore.setSessionModule(new ReadonlyEditorModule(module, workshop.name));
  } else {
    editorStore.setSessionModule(null);
  }

  userStore.setMapEditorComplexityAccordingToPrivilegeLevel();
  editorClient.clearReloadMapIdOnReconnection();
  undoStore.clear();
  navigateTo(history, routes.editorAction);
}