import { mergeSinglePatchOp, UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { editorClient } from "../../../communication/EditorClient";
import { editorMapStore } from "../../EditorMapStore";
export function undoableMapEditorSubmitCurrentMapPropertyChanges(mapId, patch, inversePatch) {
  executeUndoableOperation(new MapEditorSubmitCurrentMapPropertyChangesOp(mapId, patch, inversePatch));
}

class MapEditorSubmitCurrentMapPropertyChangesOp extends UndoableOperation {
  constructor(mapId, patch, inversePatch) {
    super("mapEditorSubmitCurrentMapPropertyChanges");
    this.mapId = mapId;
    this.patch = patch;
    this.inversePatch = inversePatch;
  }

  async execute(isRedo) {
    await editorClient.submitCurrentMapPropertyChanges(this.mapId, this.patch, this.inversePatch);

    if (isRedo) {
      editorClient.patch(editorMapStore.getOrLoadMapWithMetaData(this.mapId).map.properties, this.patch);
    }
  }

  async reverse() {
    await editorClient.submitCurrentMapPropertyChanges(this.mapId, this.inversePatch, this.patch);
    editorClient.patch(editorMapStore.getOrLoadMapWithMetaData(this.mapId).map.properties, this.inversePatch);
  }

  merge(previousOperation) {
    return mergeSinglePatchOp(this, previousOperation);
  }

}