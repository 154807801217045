import localforage from "localforage";
import { generatedImageCacheKeyPrefix } from "./sharedCacheData";
export let GeneratedImageType;

(function (GeneratedImageType) {
  GeneratedImageType[GeneratedImageType["AnimationAsset"] = 0] = "AnimationAsset";
  GeneratedImageType[GeneratedImageType["CharacterConfig"] = 1] = "CharacterConfig";
})(GeneratedImageType || (GeneratedImageType = {}));

function generatedImageCachePrefixForType(type) {
  return `${generatedImageCacheKeyPrefix}${type}_`;
}

export function generatedImageCacheKey(id, type) {
  return generatedImageCachePrefixForType(type) + id;
}
export function saveInGeneratedImageCache(id, type, version, blob) {
  return localforage.setItem(generatedImageCacheKey(id, type), {
    blob,
    version
  });
}
export async function loadFromGeneratedImageCacheIfVersionMatchesElsePrune(id, type, expectedVersion) {
  const cachedImageData = await localforage.getItem(generatedImageCacheKey(id, type));
  if (!cachedImageData) return null;

  if (cachedImageData.version !== expectedVersion) {
    await removeFromGeneratedImageCache(id, type);
    return null;
  }

  return cachedImageData.blob;
}
export function removeFromGeneratedImageCache(id, type) {
  return localforage.removeItem(generatedImageCacheKey(id, type));
}