import { getCharacterNameForCurrentLanguage } from "./displayHelpers";
import { DynamicMapElementNPCModel } from "../../shared/game/dynamicMapElements/DynamicMapElementNPCModel";
import { DynamicMapElementMapMarkerModel } from "../../shared/game/dynamicMapElements/DynamicMapElementMapMarkerModel";
import { DynamicMapElementAreaTriggerModel } from "../../shared/game/dynamicMapElements/DynamicMapElementAreaTriggerModel";
import { DynamicMapElementAnimationElementModel } from "../../shared/game/dynamicMapElements/DynamicMapElementAnimationElementModel";
import { sharedStore } from "../stores/SharedStore";
import { editorStore } from "../stores/EditorStore";
export class MapElementFilter {
  static filterNPCLabels(map) {
    const allNPCs = map.dynamicMapElements.filter(e => e instanceof DynamicMapElementNPCModel);
    return allNPCs.map(e => MapElementFilter.newIdAndLabel(e, e.$modelId, MapElementFilter.getCharacterLabel(e)));
  }

  static filterEnemiesLabels(map) {
    const allEnemies = map.dynamicMapElements.filter(e => {
      var _sharedStore$characte;

      return e instanceof DynamicMapElementNPCModel && ((_sharedStore$characte = sharedStore.characterConfigurations.get(e.characterId)) === null || _sharedStore$characte === void 0 ? void 0 : _sharedStore$characte.isEnemy);
    });
    return allEnemies.map(e => MapElementFilter.newIdAndLabel(e, e.$modelId, MapElementFilter.getCharacterLabel(e)));
  }

  static filterMapMarkerLabels(map) {
    const allMapMarker = map.dynamicMapElements.filter(e => e instanceof DynamicMapElementMapMarkerModel);
    return allMapMarker.map(e => MapElementFilter.newIdAndLabel(e, e.$modelId, e.label));
  }

  static filterAnimationLabels(map) {
    const allAnimationElements = map.dynamicMapElements.filter(e => e instanceof DynamicMapElementAnimationElementModel);
    return allAnimationElements.map(e => MapElementFilter.newIdAndLabel(e, e.$modelId, e.label));
  }

  static filterExtendedMapMarkerLabels(map) {
    const allExtendedMapMarker = map.dynamicMapElements.filter(e => e instanceof DynamicMapElementMapMarkerModel || e instanceof DynamicMapElementNPCModel || e instanceof DynamicMapElementAreaTriggerModel && e.id.length > 0 || e instanceof DynamicMapElementAnimationElementModel);
    const dynamicMapElements = allExtendedMapMarker.map(e => {
      let id = e.$modelId;
      let label = "";

      if (e instanceof DynamicMapElementMapMarkerModel) {
        label = e.label;
      } else if (e instanceof DynamicMapElementNPCModel) {
        label = MapElementFilter.getCharacterLabel(e);
      } else if (e instanceof DynamicMapElementAreaTriggerModel) {
        id = e.id;
        label = e.id;
      } else if (e instanceof DynamicMapElementAnimationElementModel) {
        label = e.label;
      }

      return MapElementFilter.newIdAndLabel(e, id, label);
    });
    const tileInteractionTriggers = map.interactionTriggerTiles.map(e => {
      return MapElementFilter.newIdAndLabel(e, e.interactionTriggerData.$modelId, e.interactionTriggerData.label);
    });
    return [...tileInteractionTriggers, ...dynamicMapElements];
  }

  static filterIndividualAreaTriggerLabels(map) {
    return MapElementFilter.filterAreaTriggerLabels(map, true);
  }

  static filterAreaTriggerLabels(map, individualTriggers = false) {
    const allAreaTrigger = map.dynamicMapElements.filter(e => e instanceof DynamicMapElementAreaTriggerModel && (editorStore.isMainGameEditor || map.moduleOwner === editorStore.sessionModuleId || e.isModuleGate));
    let allAreaTriggerNames = allAreaTrigger.map(e => {
      const trigger = e;
      if (individualTriggers) return MapElementFilter.newIdAndLabel(e, trigger.$modelId, trigger.id);else return MapElementFilter.newIdAndLabel(e, trigger.id, trigger.id);
    });
    allAreaTriggerNames = allAreaTriggerNames.filter(e => e.id); // remove items with empty ids

    return allAreaTriggerNames;
  }

  static filterTriggerLabels(map) {
    // collect standard triggers
    const allAreaTriggerNames = MapElementFilter.filterAreaTriggerLabels(map); // collect view area trigger of NPCs

    for (const npc of map.npcs) {
      for (const viewAreaTrigger of npc.viewAreaTriggers) {
        if (!viewAreaTrigger.name) continue;

        if (editorStore.isMainGameEditor || map.moduleOwner === editorStore.sessionModuleId || viewAreaTrigger.isModuleGate) {
          allAreaTriggerNames.push(MapElementFilter.newIdAndLabel(viewAreaTrigger, viewAreaTrigger.name, viewAreaTrigger.name));
        }
      }
    }

    return [...allAreaTriggerNames];
  }

  static filterTriggerLabelsWithoutViewAreaTriggers(map) {
    return MapElementFilter.filterAreaTriggerLabels(map);
  }

  static getCharacterLabel(singleNPC) {
    return singleNPC.label === "" ? getCharacterNameForCurrentLanguage(singleNPC.characterId) : singleNPC.label;
  }

  static newIdAndLabel(element, id, label) {
    return {
      element,
      id,
      label
    };
  }

}