import { editorClient } from "../../../communication/EditorClient";
import { itemStore } from "../../ItemStore";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableCreateItem(item) {
  executeUndoableOperation(new ItemEditorCreateItemOp(item));
}

class ItemEditorCreateItemOp extends UndoableOperation {
  constructor(item) {
    super("itemEditorCreateItem");
    this.item = item;
  }

  async execute(redo) {
    if (redo) {
      await editorClient.unDeleteItem(this.item.id);
    } else {
      await editorClient.createItem(this.item);
      itemStore.setItem(this.item);
    }

    itemStore.setSelectedItem(itemStore.getItem(this.item.id));
  }

  async reverse() {
    await editorClient.deleteItem(this.item.id);
    itemStore.deleteItem(this.item);

    if (itemStore.selectedItem.id === this.item.id) {
      itemStore.setSelectedItem(null);
    }
  }

}