import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Graphics } from "pixi.js";
import { gameConstants } from "../../../data/gameConstants";
/**
 * A {@link Graphics} that can be used as a highlight for a tile.
 */

export class TileHighlight extends Graphics {
  /**
   * Creates a new instance.
   * @param borderSize The border size. Can be negative for a outline.
   * @param colorBorder The color of the border.
   * @param colorFill The fill color.
   */
  constructor(borderSize, colorBorder, colorFill = -1, lineAlpha = 1, heightPlane = 0, heightLineStyle = {
    width: 4,
    color: 0xFFFFFF,
    alpha: 0.3
  }, heightLineBackAlpha = 0, fillAlpha = 1) {
    super();
    this.borderSize = borderSize;
    this.colorBorder = colorBorder;
    this.colorFill = colorFill;
    this.lineAlpha = lineAlpha;
    this.heightLineStyle = heightLineStyle;
    this.heightLineBackAlpha = heightLineBackAlpha;
    this.fillAlpha = fillAlpha;

    _defineProperty(this, "offset", 0);

    _defineProperty(this, "_heightPlaneEnd", void 0);

    _defineProperty(this, "heightPlaneStart", 0);

    _defineProperty(this, "tilesX", 1);

    _defineProperty(this, "tilesY", 1);

    _defineProperty(this, "upwards", false);

    if (this.borderSize < 0) {
      this.offset = Math.abs(this.borderSize);
      this.borderSize = this.offset;
    }

    this._heightPlaneEnd = heightPlane;
    this.refresh();
  }

  hide() {
    this.visible = false;
  }

  show() {
    this.visible = true;
  }

  get heightPlane() {
    return this._heightPlaneEnd;
  }

  set heightPlane(value) {
    if (this._heightPlaneEnd == value) return;
    this._heightPlaneEnd = value;
    this.refresh();
  }

  setAll(tilesX, tilesY, heightPlaneEnd, heightPlaneStart, upwards) {
    if (this.tilesX === tilesX && this.tilesY === tilesY && this._heightPlaneEnd === heightPlaneEnd && this.heightPlaneStart === heightPlaneStart && this.upwards === upwards) return;
    this.tilesX = tilesX;
    this.tilesY = tilesY;
    this._heightPlaneEnd = heightPlaneEnd;
    this.heightPlaneStart = heightPlaneStart;
    this.upwards = upwards;
    this.refresh();
  }

  refresh() {
    this.clear();
    const {
      tileWidth,
      tileHeight
    } = gameConstants;
    const {
      tilesX,
      tilesY,
      heightPlane,
      heightPlaneStart,
      upwards
    } = this;
    this.lineStyle(this.borderSize, this.colorBorder, this.lineAlpha);

    if (this.colorFill > -1) {
      this.beginFill(this.colorFill, this.fillAlpha);
    }

    const offsetY = (upwards ? -heightPlane - heightPlaneStart : heightPlaneStart) * tileHeight;
    const topPointX = tileWidth / 2;
    const topPointY = offsetY;
    const rightPointX = (tilesX + 1) * tileWidth / 2;
    const rightPointY = tilesX * tileHeight / 2 + offsetY;
    const bottomPointX = (tilesX - tilesY + 1) * tileWidth / 2;
    const bottomPointY = (tilesX + tilesY) * tileHeight / 2 + offsetY;
    const leftPointX = -(tilesY - 1) * tileWidth / 2;
    const leftPointY = tilesY * tileHeight / 2 + offsetY;
    this.moveTo(topPointX, topPointY - this.offset);
    this.lineTo(rightPointX + this.offset, rightPointY);
    this.lineTo(bottomPointX, bottomPointY + this.offset);
    this.lineTo(leftPointX - this.offset, leftPointY);
    this.closePath();
    this.endFill();

    if (heightPlane > 0) {
      const heightOffset = tileHeight * heightPlane;
      this.lineStyle(this.heightLineStyle);
      this.moveTo(bottomPointX, bottomPointY);
      this.lineTo(bottomPointX, bottomPointY + heightOffset);
      this.moveTo(leftPointX, leftPointY);
      this.lineTo(leftPointX, leftPointY + heightOffset);
      this.moveTo(rightPointX, rightPointY);
      this.lineTo(rightPointX, rightPointY + heightOffset);

      for (let i = 1 + Math.floor(heightPlaneStart); i < heightPlane + heightPlaneStart; i++) {
        const currentHeightOffset = tileHeight * i * (upwards ? -1 : 1) - offsetY;
        this.moveTo(leftPointX, leftPointY + currentHeightOffset);
        this.lineTo(bottomPointX, bottomPointY + currentHeightOffset);
        this.lineTo(rightPointX, rightPointY + currentHeightOffset);
      }

      this.moveTo(leftPointX, leftPointY + heightOffset);
      this.lineTo(bottomPointX, bottomPointY + heightOffset);
      this.lineTo(rightPointX, rightPointY + heightOffset);

      if (this.heightLineBackAlpha > 0) {
        this.lineStyle(_objectSpread(_objectSpread({}, this.heightLineStyle), {}, {
          alpha: this.heightLineBackAlpha
        }));
        this.moveTo(leftPointX, leftPointY + heightOffset);
        this.lineTo(topPointX, topPointY + heightOffset);
        this.lineTo(rightPointX, rightPointY + heightOffset);
      }
    }
  }

}