import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable } from "mobx";
export class MapState {
  constructor() {
    _defineProperty(this, "currentMapCenterX", 0);

    _defineProperty(this, "currentMapCenterY", 0);

    _defineProperty(this, "currentMapZoom", 1);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
  }

  setMapCenter(x, y, zoom) {
    if (zoom === undefined) zoom = this.currentMapZoom;
    if (this.currentMapCenterX === x && this.currentMapCenterY === y && this.currentMapZoom === zoom) return;
    this.currentMapCenterX = x;
    this.currentMapCenterY = y;
    this.currentMapZoom = zoom;
  }

}