import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Camera } from "./Camera";

/**
 * A basic camera that uses a {@link MapState} for its properties.
 */
export class MapStateCamera extends Camera {
  /**
   * Creates a new instance.
   * @param mapState The map state to use.
   */
  constructor(mapState) {
    super(mapState.currentMapCenterX, mapState.currentMapCenterY, mapState.currentMapZoom);
    this.mapState = mapState;

    _defineProperty(this, "getY", () => this.mapState.currentMapCenterY);

    _defineProperty(this, "getZoom", () => this.mapState.currentMapZoom);

    _defineProperty(this, "setX", x => {
      this.mapState.setMapCenter(x, this.getX(), this.getZoom());
    });

    _defineProperty(this, "setY", y => {
      this.mapState.setMapCenter(this.getX(), y, this.getZoom());
    });

    _defineProperty(this, "setZoom", zoom => {
      this.mapState.setMapCenter(this.getX(), this.getY(), zoom);
    });

    _defineProperty(this, "onTick", () => {});
  }

  getX() {
    return this.mapState.currentMapCenterX;
  }

}