import Encoding from 'encoding-japanese';
export function readUnicodeText(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);

      try {
        let rawResult = '';
        const bytes = new Uint8Array(data);
        const len = bytes.byteLength;

        for (let i = 0; i < len; i++) {
          rawResult += String.fromCharCode(bytes[i]);
        }

        resolve(rawResult);
      } catch (e) {
        reject(e);
      }
    };

    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}
export function readTextUsingEncodingLibrary(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const detectedEncoding = Encoding.detect(data);
      if (!detectedEncoding) throw new Error("Couldn't detect encoding");

      try {
        const unicodeString = Encoding.convert(data, {
          to: 'UNICODE',
          from: detectedEncoding,
          type: 'string'
        });
        resolve(unicodeString);
      } catch (e) {
        reject(e);
      }
    };

    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}