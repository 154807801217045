import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2, _dec2, _class3, _class4, _dec3, _class5, _class6, _dec4, _class7, _class8, _dec5, _class9, _class10, _dec6, _class11, _class12, _dec7, _class13, _class14, _dec8, _class15, _class16, _dec9, _class17, _class18, _dec10, _class19, _class20, _dec11, _class21, _class22, _dec12, _class23, _class24, _dec13, _class25, _class26, _dec14, _class27, _class28, _dec15, _class29, _class30, _dec16, _class31, _class32, _dec17, _class33, _class34, _dec18, _class35, _class36;

import { Model, model, modelAction, prop } from "mobx-keystone";
import { TranslatedString } from "../game/TranslatedString";
import { AnimationElementReferenceModel, MapElementReferenceModel } from "./MapElementReferenceModel";
import { NPCReferenceModel } from "./NPCReferenceModel";
import { isBlank } from "../helper/generalHelpers";
import { computed } from "mobx";
export let StringValueModel = (_dec = model("actions/StringValueModel"), _dec(_class = (_class2 = class StringValueModel extends Model({
  value: prop("").withSetter()
}) {
  title() {
    return "action_editor.property_string";
  }

  get() {
    return this.value;
  }

  set(value) {
    this.setValue(value);
  }

  clean() {
    this.value = "";
  }

  isSet() {
    return !isBlank(this.value);
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "set", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "set"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "clean"), _class2.prototype)), _class2)) || _class);
export let NumberValueModel = (_dec2 = model("actions/NumberValueModel"), _dec2(_class3 = (_class4 = class NumberValueModel extends Model({
  value: prop("0").withSetter() // string, because this can also be set to a tree parameter

}) {
  title() {
    return "action_editor.property_number";
  }

  get() {
    return "" + this.value;
  }

  set(value) {
    this.setValue(value);
  }

  clean() {
    this.value = "";
  }

  isSet() {
    return !isBlank(this.value);
  }

}, (_applyDecoratedDescriptor(_class4.prototype, "set", [modelAction], Object.getOwnPropertyDescriptor(_class4.prototype, "set"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class4.prototype, "clean"), _class4.prototype)), _class4)) || _class3);
export let ItemIdValueModel = (_dec3 = model("actions/ItemIdValueModel"), _dec3(_class5 = (_class6 = class ItemIdValueModel extends Model({
  value: prop("").withSetter()
}) {
  title() {
    return "action_editor.property_item";
  }

  get() {
    return this.value;
  }

  set(value) {
    this.setValue(value);
  }

  clean() {
    this.value = "";
  }

  isSet() {
    return !isBlank(this.value);
  }

}, (_applyDecoratedDescriptor(_class6.prototype, "set", [modelAction], Object.getOwnPropertyDescriptor(_class6.prototype, "set"), _class6.prototype), _applyDecoratedDescriptor(_class6.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class6.prototype, "clean"), _class6.prototype)), _class6)) || _class5);
export let ItemTagValueModel = (_dec4 = model("actions/ItemTagValueModel"), _dec4(_class7 = (_class8 = class ItemTagValueModel extends Model({
  value: prop("").withSetter()
}) {
  title() {
    return "action_editor.property_item_tag";
  }

  get() {
    return this.value;
  }

  set(value) {
    this.setValue(value);
  }

  clean() {
    this.value = "";
  }

  isSet() {
    return !isBlank(this.value);
  }

}, (_applyDecoratedDescriptor(_class8.prototype, "set", [modelAction], Object.getOwnPropertyDescriptor(_class8.prototype, "set"), _class8.prototype), _applyDecoratedDescriptor(_class8.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class8.prototype, "clean"), _class8.prototype)), _class8)) || _class7);
export let QuestIdValueModel = (_dec5 = model("actions/QuestIdValueModel"), _dec5(_class9 = (_class10 = class QuestIdValueModel extends Model({
  value: prop("").withSetter()
}) {
  title() {
    return "action_editor.property_quest";
  }

  get() {
    return this.value;
  }

  set(value) {
    this.setValue(value);
  }

  clean() {
    this.value = "";
  }

  isSet() {
    return !isBlank(this.value);
  }

}, (_applyDecoratedDescriptor(_class10.prototype, "set", [modelAction], Object.getOwnPropertyDescriptor(_class10.prototype, "set"), _class10.prototype), _applyDecoratedDescriptor(_class10.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class10.prototype, "clean"), _class10.prototype)), _class10)) || _class9);
export let PlayStyleValueModel = (_dec6 = model("actions/PlayStyleValueModel"), _dec6(_class11 = (_class12 = class PlayStyleValueModel extends Model({
  value: prop("").withSetter()
}) {
  title() {
    return "action_editor.property_play_style";
  }

  get() {
    return this.value;
  }

  set(value) {
    this.setValue(value);
  }

  clean() {
    this.value = "";
  }

  isSet() {
    return !isBlank(this.value);
  }

}, (_applyDecoratedDescriptor(_class12.prototype, "set", [modelAction], Object.getOwnPropertyDescriptor(_class12.prototype, "set"), _class12.prototype), _applyDecoratedDescriptor(_class12.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class12.prototype, "clean"), _class12.prototype)), _class12)) || _class11);
export let FactionValueModel = (_dec7 = model("actions/FractionValueModel"), _dec7(_class13 = (_class14 = class FactionValueModel extends Model({
  value: prop("").withSetter()
}) {
  title() {
    return "action_editor.property_faction";
  }

  get() {
    return this.value;
  }

  set(value) {
    this.setValue(value);
  }

  clean() {
    this.value = "";
  }

  isSet() {
    return !isBlank(this.value);
  }

}, (_applyDecoratedDescriptor(_class14.prototype, "set", [modelAction], Object.getOwnPropertyDescriptor(_class14.prototype, "set"), _class14.prototype), _applyDecoratedDescriptor(_class14.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class14.prototype, "clean"), _class14.prototype)), _class14)) || _class13);
export let TranslatedStringValueModel = (_dec8 = model("actions/TranslatedStringValueModel"), _dec8(_class15 = (_class16 = class TranslatedStringValueModel extends Model({
  value: prop(() => new TranslatedString({})).withSetter()
}) {
  title() {
    return "action_editor.property_text";
  }

  get(languageKey, gender, useFallback = true) {
    return this.value.getForGender(languageKey, gender, useFallback);
  }
  /*
  @modelAction
  public set(value: string, languageKey: string) {
      this.value.set(languageKey, value);
  }
  */


  clean() {
    this.value = new TranslatedString({});
  }

  isSet() {
    return !this.value.isCurrentLanguageEmpty();
  }

}, (_applyDecoratedDescriptor(_class16.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class16.prototype, "clean"), _class16.prototype)), _class16)) || _class15);
export let MapMarkerValueModel = (_dec9 = model("actions/MapMarkerValueModel"), _dec9(_class17 = (_class18 = class MapMarkerValueModel extends Model({
  value: prop(() => new MapElementReferenceModel({})).withSetter()
}) {
  title() {
    return "action_editor.property_map_marker";
  }

  get() {
    return this.value.$modelId;
  }

  clean() {
    this.value = new MapElementReferenceModel({});
  }

  isSet() {
    return this.value.isComplete();
  }

}, (_applyDecoratedDescriptor(_class18.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class18.prototype, "clean"), _class18.prototype)), _class18)) || _class17);
export const extendedMapMarkerValueModelTypesArray = ["actions/MapMarkerValueModel", "actions/NPCOnMapValueModel", "actions/EnemyOnMapValueModel", "actions/AreaTriggerValueModel", "actions/AnimationElementValueModel", "actions/ExtendedMapMarkerValueModel"];
export let ExtendedMapMarkerValueModel = (_dec10 = model("actions/ExtendedMapMarkerValueModel"), _dec10(_class19 = (_class20 = class ExtendedMapMarkerValueModel extends Model({
  value: prop(() => new MapElementReferenceModel({})).withSetter()
}) {
  title() {
    return "action_editor.property_extended_map_marker";
  }

  get() {
    return this.value.$modelId;
  }

  clean() {
    this.value = new MapElementReferenceModel({});
  }

  isSet() {
    return this.value.isComplete();
  }

}, (_applyDecoratedDescriptor(_class20.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class20.prototype, "clean"), _class20.prototype)), _class20)) || _class19);
export let AreaTriggerValueModel = (_dec11 = model("actions/AreaTriggerValueModel"), _dec11(_class21 = (_class22 = class AreaTriggerValueModel extends Model({
  value: prop(() => new MapElementReferenceModel({})).withSetter()
}) {
  title() {
    return "action_editor.property_area_trigger";
  }

  get() {
    return this.value.$modelId;
  }

  clean() {
    this.value = new MapElementReferenceModel({});
  }

  isSet() {
    return this.value.isComplete();
  }

}, (_applyDecoratedDescriptor(_class22.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class22.prototype, "clean"), _class22.prototype)), _class22)) || _class21);
export let InteractionTriggerValueModel = (_dec12 = model("actions/InteractionTriggerValueModel"), _dec12(_class23 = (_class24 = class InteractionTriggerValueModel extends Model({
  value: prop(() => new MapElementReferenceModel({})).withSetter()
}) {
  title() {
    return "action_editor.property_interaction_trigger";
  }

  get() {
    return this.value.$modelId;
  }

  clean() {
    this.value = new MapElementReferenceModel({});
  }

  isSet() {
    return this.value.isComplete();
  }

}, (_applyDecoratedDescriptor(_class24.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class24.prototype, "clean"), _class24.prototype)), _class24)) || _class23);
export let NPCValueModel = (_dec13 = model("actions/NPCValueModel"), _dec13(_class25 = (_class26 = class NPCValueModel extends Model({
  value: prop(() => new NPCReferenceModel({})).withSetter()
}) {
  title() {
    return "action_editor.property_npc";
  }

  get() {
    return this.value.$modelId;
  }

  clean() {
    this.value = new NPCReferenceModel({});
  }

  isSet() {
    return this.value.isComplete();
  }

}, (_applyDecoratedDescriptor(_class26.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class26.prototype, "clean"), _class26.prototype)), _class26)) || _class25);
export let NPCOnMapValueModel = (_dec14 = model("actions/NPCOnMapValueModel"), _dec14(_class27 = (_class28 = class NPCOnMapValueModel extends Model({
  value: prop(() => new MapElementReferenceModel({})).withSetter()
}) {
  title() {
    return "action_editor.property_npc_on_map";
  }

  get() {
    return this.value.$modelId;
  }

  clean() {
    this.value = new MapElementReferenceModel({});
  }

  isSet() {
    return this.value.isComplete();
  }

}, (_applyDecoratedDescriptor(_class28.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class28.prototype, "clean"), _class28.prototype)), _class28)) || _class27);
export let EnemyOnMapValueModel = (_dec15 = model("actions/EnemyOnMapValueModel"), _dec15(_class29 = (_class30 = class EnemyOnMapValueModel extends Model({
  value: prop(() => new MapElementReferenceModel({})).withSetter()
}) {
  title() {
    return "action_editor.property_enemy_on_map";
  }

  get() {
    return this.value.$modelId;
  }

  clean() {
    this.value = new MapElementReferenceModel({});
  }

  isSet() {
    return this.value.isComplete();
  }

}, (_applyDecoratedDescriptor(_class30.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class30.prototype, "clean"), _class30.prototype)), _class30)) || _class29);
export let AnimationElementValueModel = (_dec16 = model("actions/AnimationElementValueModel"), _dec16(_class31 = (_class32 = class AnimationElementValueModel extends Model({
  value: prop(() => new AnimationElementReferenceModel({})).withSetter(),
  requiredAnimationNamesString: prop(null).withSetter()
}) {
  title() {
    return "action_editor.property_animation_element";
  }

  get() {
    return this.value.$modelId;
  }

  clean() {
    this.value = new AnimationElementReferenceModel({});
  }

  isSet() {
    return this.value.isComplete();
  }

  get shouldSelectAnimationInTemplate() {
    return this.requiredAnimationNamesString === null;
  }

  get hasRequiredAnimationNames() {
    return this.requiredAnimationNamesString !== null;
  }

  get requiredAnimationNames() {
    return this.requiredAnimationNamesString.split(",").map(value => value.trim());
  }

  setShouldSelectAnimationInTemplate() {
    this.requiredAnimationNamesString = null;
  }

  setHasRequiredAnimationNames() {
    if (!this.hasRequiredAnimationNames) {
      this.requiredAnimationNamesString = "";
    }
  }

}, (_applyDecoratedDescriptor(_class32.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class32.prototype, "clean"), _class32.prototype), _applyDecoratedDescriptor(_class32.prototype, "shouldSelectAnimationInTemplate", [computed], Object.getOwnPropertyDescriptor(_class32.prototype, "shouldSelectAnimationInTemplate"), _class32.prototype), _applyDecoratedDescriptor(_class32.prototype, "hasRequiredAnimationNames", [computed], Object.getOwnPropertyDescriptor(_class32.prototype, "hasRequiredAnimationNames"), _class32.prototype), _applyDecoratedDescriptor(_class32.prototype, "requiredAnimationNames", [computed], Object.getOwnPropertyDescriptor(_class32.prototype, "requiredAnimationNames"), _class32.prototype), _applyDecoratedDescriptor(_class32.prototype, "setShouldSelectAnimationInTemplate", [modelAction], Object.getOwnPropertyDescriptor(_class32.prototype, "setShouldSelectAnimationInTemplate"), _class32.prototype), _applyDecoratedDescriptor(_class32.prototype, "setHasRequiredAnimationNames", [modelAction], Object.getOwnPropertyDescriptor(_class32.prototype, "setHasRequiredAnimationNames"), _class32.prototype)), _class32)) || _class31);
export let AnimationPropertiesValueModel = (_dec17 = model("actions/AnimationIdAndStateValueModel"), _dec17(_class33 = (_class34 = class AnimationPropertiesValueModel extends Model({
  value: prop(null).withSetter(),
  // value in this case is the animation asset name
  sequence: prop(null).withSetter(),
  loop: prop(true).withSetter()
}) {
  title() {
    return "action_editor.property_animation";
  }

  get() {
    return this.value;
  }

  clean() {
    this.value = null;
    this.sequence = null;
    this.loop = true;
  }

  isSet() {
    return !isBlank(this.value) && !isBlank(this.sequence);
  }

}, (_applyDecoratedDescriptor(_class34.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class34.prototype, "clean"), _class34.prototype)), _class34)) || _class33);
export let SoundValueModel = (_dec18 = model("actions/SoundValueModel"), _dec18(_class35 = (_class36 = class SoundValueModel extends Model({
  value: prop(null).withSetter(),
  treeParameter: prop(null).withSetter()
}) {
  title() {
    return "action_editor.property_sound";
  }

  get() {
    return this.value != null ? this.value.base : null;
  }

  clean() {
    this.value = null;
  }

  isSet() {
    return this.value != null || this.treeParameter != null;
  }

}, (_applyDecoratedDescriptor(_class36.prototype, "clean", [modelAction], Object.getOwnPropertyDescriptor(_class36.prototype, "clean"), _class36.prototype)), _class36)) || _class35);