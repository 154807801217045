import { actionEditorStore } from '../../../stores/ActionEditorStore';
export function handleClickConnect(handleId, nodeId, onConnect, isTarget, isValidConnection) {
  const {
    clickConnectionData
  } = actionEditorStore;

  if (clickConnectionData) {
    if (isTarget !== clickConnectionData.isTarget) {
      const connection = isTarget ? {
        source: clickConnectionData.nodeId,
        sourceHandle: clickConnectionData.handleId,
        target: nodeId,
        targetHandle: handleId
      } : {
        source: nodeId,
        sourceHandle: handleId,
        target: clickConnectionData.nodeId,
        targetHandle: clickConnectionData.handleId
      };

      if (isValidConnection) {
        onConnect(connection);
        return;
      }
    } else if (nodeId === clickConnectionData.nodeId && handleId === clickConnectionData.handleId) {
      actionEditorStore.clearClickActions();
      return;
    }
  }

  actionEditorStore.setClickConnectionData({
    nodeId,
    handleId,
    isTarget
  });
}