import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { editorClient } from "../../../communication/EditorClient";
import { executeUndoableOperation } from "../UndoStore";
import { actionEditorStore } from "../../ActionEditorStore";
import { sharedStore } from "../../SharedStore";
import { getCurrentReactFlowInstanceTransform, getCurrentlySelectedHierarchyIds, actionTreeSetSelection } from "./actionEditorSupport";
import { getSnapshot } from "mobx-keystone";
export function undoableActionEditorCreateSubtrees(newTrees) {
  for (const newTree of newTrees) {
    sharedStore.addActionTree(newTree);
  }

  executeUndoableOperation(new ActionEditorCreateSubtreeOp(newTrees.map(newTree => getSnapshot(newTree))));
}

class ActionEditorCreateSubtreeOp extends UndoableOperation {
  constructor(subtrees) {
    var _actionEditorStore$cu;

    super("actionEditorCreateSubtree");
    this.subtrees = subtrees;

    _defineProperty(this, "currentTransform", void 0);

    _defineProperty(this, "hierachyIds", void 0);

    _defineProperty(this, "previousSelectedActionModelId", void 0);

    this.currentTransform = getCurrentReactFlowInstanceTransform();
    this.hierachyIds = getCurrentlySelectedHierarchyIds();
    this.previousSelectedActionModelId = (_actionEditorStore$cu = actionEditorStore.currentAction) === null || _actionEditorStore$cu === void 0 ? void 0 : _actionEditorStore$cu.$modelId;
  }

  async execute(isRedo) {
    actionEditorStore.clearClickActions();

    if (isRedo) {
      await editorClient.unDeleteActionTrees(this.subtrees.map(subtree => subtree.$modelId));
    } else {
      await editorClient.createActionTrees(this.subtrees);
    }

    actionTreeSetSelection(this.subtrees[0].$modelId, this.hierachyIds, this.currentTransform);
  }

  async reverse() {
    actionEditorStore.clearClickActions();
    await editorClient.deleteActionTrees(this.subtrees);
    actionTreeSetSelection(this.previousSelectedActionModelId, this.hierachyIds, this.currentTransform);
  }

}