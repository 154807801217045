import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2;

import { computed } from "mobx";
import { model, Model, modelAction, objectMap, prop } from "mobx-keystone";
import { CombatPhaseLength } from "./CombatPhaseLength";
export let EnemyCombatPresetRoundModel = (_dec = model("combat/EnemyCombatPresetRoundModel"), _dec(_class = (_class2 = class EnemyCombatPresetRoundModel extends Model({
  playerAttackPhaseLength: prop(() => CombatPhaseLength.Short).withSetter(),
  playerDefensePhaseLength: prop(() => CombatPhaseLength.Short).withSetter(),
  // We're simulating a set here by never setting any value to false.
  // Using ArraySet/ asSet is not a good option here, as it doesn't work well with undo/redo.
  playerDefenseGesturePatternIdsAsMap: prop(() => objectMap())
}) {
  togglePlayerDefensePatternId(id) {
    if (this.playerDefenseGesturePatternIdsAsMap.has(id)) {
      this.playerDefenseGesturePatternIdsAsMap.delete(id);
    } else {
      this.playerDefenseGesturePatternIdsAsMap.set(id, true);
    }
  }

  hasPlayerDefensePatternId(id) {
    return this.playerDefenseGesturePatternIdsAsMap.has(id);
  }

  get playerDefenseGesturePatternIds() {
    return [...this.playerDefenseGesturePatternIdsAsMap.keys()];
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "togglePlayerDefensePatternId", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "togglePlayerDefensePatternId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "playerDefenseGesturePatternIds", [computed], Object.getOwnPropertyDescriptor(_class2.prototype, "playerDefenseGesturePatternIds"), _class2.prototype)), _class2)) || _class);