import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UiConstants } from "../../../data/UiConstants";
import { autoDisposeOnDisplayObjectRemoved, autoDisposeOnDisplayObjectRemovedArray } from "../../../helper/ReactionDisposerGroup";
import { NpcViewBase } from "../../shared/map/NpcViewBase";
import { doesTilePositionEqual } from "../../../../shared/definitions/other/TilePosition";
import { localSettingsStore } from "../../../stores/LocalSettingsStore";
export class EditorNpcView extends NpcViewBase {
  constructor(mapRelatedStore, data, overlayContainer = null) {
    super(data, overlayContainer, false, null);
    this.mapRelatedStore = mapRelatedStore;

    _defineProperty(this, "remakeConfigurationRelatedRefreshMethods", void 0);

    _defineProperty(this, "remakeConfigurationRelatedRefreshMethods2", void 0);

    autoDisposeOnDisplayObjectRemovedArray(this, this.baseRefreshMethods, true);
    this.remakeConfigurationRelatedRefreshMethods = autoDisposeOnDisplayObjectRemovedArray(this, this.configurationRelatedRefreshMethods, true);
    this.remakeConfigurationRelatedRefreshMethods2 = autoDisposeOnDisplayObjectRemoved(this, autoDisposingAutorun => {
      autoDisposingAutorun(this.refreshTransparency.bind(this));
      autoDisposingAutorun(this.refreshTint.bind(this));
      autoDisposingAutorun(this.updateViewAreaGraphics.bind(this));
    });
  }

  onConfigurationApplied() {
    this.remakeConfigurationRelatedRefreshMethods();
    this.remakeConfigurationRelatedRefreshMethods2();
  }

  refreshTransparency() {
    if (this.mapRelatedStore.showGamePreview || this.mapRelatedStore.ignoreHeightPlanes) {
      this.alpha = 1;
    } else {
      const sameHeightPlane = this.mapRelatedStore.selectedPlane === this.npcData.position.plane;
      this.alpha = sameHeightPlane ? 1.0 : UiConstants.ALPHA_WRONG_HEIGHT_PLANE;
    }
  }

  refreshTint() {
    if (!this.animation) return;

    if (this.mapRelatedStore.highlightedElements) {
      if (!this.mapRelatedStore.highlightedElements.has(this.npcData)) {
        this.animation.tint = UiConstants.NON_HIGHLIGHT_TINT_0x;
        return;
      }
    }

    if (this.partOfLoop) {
      this.animation.tint = 0xFF0000;
      return;
    }

    this.animation.tint = 0xFFFFFF;
  }

  updateViewAreaGraphics() {
    if (localSettingsStore.showDebugInfo || doesTilePositionEqual(this.mapRelatedStore.selectedTilePosition, this.baseTilePosition)) {
      this.showViewAreas();
    } else {
      this.hideViewAreas();
    }
  }

  refreshPartOfLoop() {
    this.refreshTint();
  }

}