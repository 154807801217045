import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2;

import { model, Model, modelAction, prop } from "mobx-keystone";
export let PositionModel = (_dec = model("game/PositionModel"), _dec(_class = (_class2 = class PositionModel extends Model({
  x: prop(0).withSetter(),
  y: prop(0).withSetter(),
  layer: prop(0).withSetter(),
  plane: prop(0).withSetter()
}) {
  set(x, y, layer, plane) {
    this.x = x;
    this.y = y;
    this.layer = layer;
    this.plane = plane;
    return this;
  }

  setXY(x, y) {
    this.x = x;
    this.y = y;
  }

  setXYPlane(x, y, plane) {
    this.x = x;
    this.y = y;
    this.plane = plane;
  }

  clone() {
    return new PositionModel({
      x: this.x,
      y: this.y,
      plane: this.plane,
      layer: this.layer
    });
  }

  equals(x, y, layer, plane) {
    return this.x === x && this.y === y && this.layer === layer && this.plane === plane;
  }

  equalsOther(position) {
    return position && this.equals(position.x, position.y, position.layer, position.plane);
  }

  copyWithAppliedTransition(planeTransit) {
    return new PositionModel({
      x: this.x + planeTransit.targetXOffset,
      y: this.y + planeTransit.targetYOffset,
      plane: this.plane + planeTransit.heightDifference,
      layer: this.layer
    });
  }

  toHash() {
    return this.x + "," + this.y + "," + this.layer + "," + this.plane;
  }

  copyXY() {
    return [this.x, this.y];
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "set", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "set"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setXY", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "setXY"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setXYPlane", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "setXYPlane"), _class2.prototype)), _class2)) || _class);
export class ReadonlyPosition {
  constructor(data) {
    _defineProperty(this, "x", void 0);

    _defineProperty(this, "y", void 0);

    _defineProperty(this, "layer", void 0);

    _defineProperty(this, "plane", void 0);

    this.x = data.x || 0;
    this.y = data.y || 0;
    this.layer = data.layer || 0;
    this.plane = data.plane || 0;
  }

  clone() {
    return new ReadonlyPosition({
      x: this.x,
      y: this.y,
      plane: this.plane,
      layer: this.layer
    });
  }

  equals(x, y, layer, plane) {
    return PositionModel.prototype.equals.call(this, x, y, layer, plane);
  }

  equalsOther(position) {
    return PositionModel.prototype.equalsOther.call(this, position);
  }

  copyWithAppliedTransition(planeTransit) {
    return new ReadonlyPosition({
      x: this.x + planeTransit.targetXOffset,
      y: this.y + planeTransit.targetYOffset,
      plane: this.plane + planeTransit.heightDifference,
      layer: this.layer
    });
  }

  toHash() {
    return PositionModel.prototype.toHash.call(this);
  }

  copyXY() {
    return [this.x, this.y];
  }

}