import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { routes } from "../../../data/routes";
import { navigateTo } from "../../../helper/navigationHelpers";
import { charEditorStore } from "../../CharacterEditorStore";
import { sharedStore } from "../../SharedStore";
export function undoableCharacterEditorOpenSelection(characterId, history) {
  executeUndoableOperation(new CharacterEditorOpenSelectionOp(characterId, history));
}

class CharacterEditorOpenSelectionOp extends UndoableOperation {
  constructor(characterId, history) {
    var _charEditorStore$sele;

    super("characterEditorOpenSelection");

    _defineProperty(this, "previousUrl", void 0);

    _defineProperty(this, "nextUrl", void 0);

    _defineProperty(this, "history", void 0);

    _defineProperty(this, "characterIdToSelect", void 0);

    _defineProperty(this, "previousSelectedCharacterId", void 0);

    this.history = history;
    this.nextUrl = routes.editorMap;
    this.previousUrl = this.history.location.pathname;
    this.characterIdToSelect = characterId;
    this.previousSelectedCharacterId = (_charEditorStore$sele = charEditorStore.selectedCharacterConfiguration) === null || _charEditorStore$sele === void 0 ? void 0 : _charEditorStore$sele.id;
  }

  async execute() {
    navigateTo(this.history, this.nextUrl);
    charEditorStore.setSelectedCharacter(sharedStore.characterConfigurations.get(this.characterIdToSelect));
  }

  async reverse() {
    charEditorStore.setSelectedCharacter(sharedStore.characterConfigurations.get(this.previousSelectedCharacterId));
    navigateTo(this.history, this.previousUrl);
  }

}