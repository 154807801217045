export let sharedLogger = {
  error: console.log,
  warn: console.log,
  info: console.log,
  http: console.log,
  verbose: console.log,
  debug: console.log,
  silly: console.log
};
export function setSharedLogger(logger) {
  sharedLogger = logger;
}