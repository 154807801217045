import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { executeUndoableOperation } from "../UndoStore";
import { UndoableOperation } from "../UndoableOperation";
import { TranslatedError } from "../../../../shared/definitions/errors/TranslatedError";
import { sharedStore } from "../../SharedStore";
export function undoableCharacterEditorSelectCharacter(characterId, store) {
  executeUndoableOperation(new CharacterEditorSelectCharacterOp(characterId, store, "charEditorCharacterSelected"));
}
export function undoableCharacterEditorDeselectCharacter(store) {
  executeUndoableOperation(new CharacterEditorSelectCharacterOp(null, store, "charEditorCharacterSelected"));
}

class CharacterEditorSelectCharacterOp extends UndoableOperation {
  constructor(charIdToSelect, store, operationNameI18nKey) {
    var _store$selectedCharac;

    super(operationNameI18nKey);
    this.charIdToSelect = charIdToSelect;
    this.store = store;

    _defineProperty(this, "previousCharId", void 0);

    this.previousCharId = (_store$selectedCharac = store.selectedCharacterConfiguration) === null || _store$selectedCharac === void 0 ? void 0 : _store$selectedCharac.id;
  }

  async execute() {
    selectCharacterOrThrow(this.store, this.charIdToSelect);
  }

  async reverse() {
    selectCharacterOrThrow(this.store, this.previousCharId);
  }

}

export function selectCharacterOrThrow(store, charId) {
  var _store$selectedCharac2;

  if (((_store$selectedCharac2 = store.selectedCharacterConfiguration) === null || _store$selectedCharac2 === void 0 ? void 0 : _store$selectedCharac2.id) == charId) return;

  if (charId != null) {
    const char = sharedStore.getCharacter(charId);
    if (!char) throw new TranslatedError("editor.error_character_does_not_exists");
    store.setSelectedCharacter(char);
  } else {
    store.setSelectedCharacter(null);
  }
}