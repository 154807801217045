import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { editorClient } from "../../../communication/EditorClient";
import { getChangeableTileDataSnapshot } from "../../../../shared/game/TileDataModel";
import * as uuid from "uuid";
export function executableUndoableMapEditorSetTileInteractionTriggerStatus(currentMapStore, tile, isInteractionTrigger) {
  var _tile$interactionTrig;

  executableUndoableMapEditorSetTileInteractionTriggerData(currentMapStore, tile, isInteractionTrigger, tile.isModuleGate, (_tile$interactionTrig = tile.interactionTriggerData) === null || _tile$interactionTrig === void 0 ? void 0 : _tile$interactionTrig.label);
}
export function executableUndoableMapEditorSetTileModuleGateStatus(currentMapStore, tile, isModuleGate) {
  var _tile$interactionTrig2;

  executableUndoableMapEditorSetTileInteractionTriggerData(currentMapStore, tile, true, isModuleGate, (_tile$interactionTrig2 = tile.interactionTriggerData) === null || _tile$interactionTrig2 === void 0 ? void 0 : _tile$interactionTrig2.label);
}
export function executableUndoableMapEditorSetTileInteractionTriggerLabel(currentMapStore, tile, label) {
  executableUndoableMapEditorSetTileInteractionTriggerData(currentMapStore, tile, true, tile.isModuleGate, label);
}

function executableUndoableMapEditorSetTileInteractionTriggerData(currentMapStore, tile, isInteractionTrigger, isModuleGate, label) {
  const newSnapshot = getChangeableTileDataSnapshot(tile);
  newSnapshot.isInteractionTrigger = isInteractionTrigger;
  newSnapshot.isModuleGate = isModuleGate;

  if (isInteractionTrigger) {
    newSnapshot.interactionTriggerModelId = newSnapshot.interactionTriggerModelId || uuid.v4();
    newSnapshot.interactionTriggerLabel = label || "";
    newSnapshot.interactionTriggerTileOffsetX |= 0;
    newSnapshot.interactionTriggerTileOffsetY |= 0;
  }

  const currentSnapshot = getChangeableTileDataSnapshot(tile);
  if (newSnapshot.isInteractionTrigger == currentSnapshot.isInteractionTrigger && newSnapshot.isModuleGate == currentSnapshot.isModuleGate && newSnapshot.interactionTriggerModelId == currentSnapshot.interactionTriggerModelId && newSnapshot.interactionTriggerLabel == currentSnapshot.interactionTriggerLabel) return;
  const {
    x,
    y,
    layer,
    plane
  } = tile.position;
  const tileChangeForward = {
    newData: newSnapshot,
    previousData: currentSnapshot,
    position: {
      x,
      y,
      layer
    }
  };
  executeUndoableOperation(new MapEditorSetTileInteractionTriggerData(currentMapStore, plane, tileChangeForward));
}

class MapEditorSetTileInteractionTriggerData extends UndoableOperation {
  constructor(currentMapStore, plane, tileChangeForward) {
    super("mapEditorSetTileInteractionTriggerData");
    this.currentMapStore = currentMapStore;
    this.plane = plane;
    this.tileChangeForward = tileChangeForward;

    _defineProperty(this, "tileChangeReverse", void 0);

    this.tileChangeReverse = {
      newData: tileChangeForward.previousData,
      previousData: tileChangeForward.newData,
      position: tileChangeForward.position
    };
  }

  async execute() {
    // ========= Set Tiles
    const {
      plane,
      tileChangeForward
    } = this;
    await editorClient.setCurrentMapTiles(this.currentMapStore, [tileChangeForward], plane);
  }

  async reverse() {
    // ========= Set Tiles
    const {
      plane,
      tileChangeReverse
    } = this;
    await editorClient.setCurrentMapTiles(this.currentMapStore, [tileChangeReverse], plane);
  }

}