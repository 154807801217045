import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Container, Geometry, Mesh, Shader, Buffer, MIPMAP_MODES } from "pixi.js";

/* babel-plugin-inline-import '../shader/water.vert' */
const waterVertexCode = "precision highp float;\n\nattribute vec2 aVertexPosition;\nattribute vec2 aTextureCoord;\n\nuniform mat3 projectionMatrix;\n\nvarying vec2 vTextureCoord;\n\nvoid main() {\n   gl_Position = vec4((projectionMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);\n\n   vTextureCoord = aTextureCoord;\n}";

/* babel-plugin-inline-import '../shader/water.frag' */
const waterFragmentCode = "precision highp float;\n\nuniform float timeS;\n\n//[SHARED]\nuniform sampler2D uSamplerWaves;\nuniform float waveTextureScale;\n\nvec4 colorLayer1 = vec4(88.0 / 255.0, 137.0 / 255.0, 126.0 / 255.0, 1.0);\nvec4 colorLayer2 = vec4(21.0 / 255.0, 51.0 / 255.0, 54.0 / 255.0, 1.0);\nvec4 colorLayer3 = vec4(33.0 / 255.0, 69.0 / 255.0, 68.0 / 255.0, 1.0);\nvec4 colorBackground = vec4(45.0 / 255.0, 87.0 / 255.0, 84.0 / 255.0, 1.0);\n\nvoid calculateWaves(mat3 matrix, vec2 position, out float scale, out vec2 wavesUv, out float wavesDisplacement, out vec4 wavesColor) {\n   vec2 offset = vec2(matrix[2][0], matrix[2][1]);\n   scale = matrix[0][0];\n\n   wavesUv = (position - offset) / scale;\n\n   wavesDisplacement = cos(wavesUv.x * 0.01 + timeS * 1.0) * 10.0 * scale;\n\n   wavesColor = colorBackground;\n\n#ifndef SHALLOW_WATER\n   float layer3 = texture2D(uSamplerWaves, fract(wavesUv * waveTextureScale)).b;\n   wavesColor = mix(wavesColor, colorLayer3, layer3 * 0.5);\n\n   float layer2 = texture2D(uSamplerWaves, fract((wavesUv + wavesDisplacement / 3.0) * waveTextureScale)).g;\n   wavesColor = mix(wavesColor, colorLayer2, layer2 * 0.35);\n#endif\n\n   float layer1 = texture2D(uSamplerWaves, fract((wavesUv + wavesDisplacement) * waveTextureScale)).r;\n   wavesColor = mix(wavesColor, colorLayer1, layer1 * 0.4);\n}\n//[/SHARED]\n\nvarying vec2 vTextureCoord;\n\nuniform mat3 translationMatrix;\n\nvoid main() {\n   float scale;\n   vec2 wavesUv;\n   float displacement;\n   vec4 wavesColor;\n   calculateWaves(translationMatrix, vTextureCoord, scale, wavesUv, displacement, wavesColor);\n\n   gl_FragColor = wavesColor;\n   //gl_FragColor = vec4(wavesUv.x, 0, wavesUv.y, 1.0);\n}\n";
import { staticAssetLoader } from "../../loader/StaticAssetLoader";
export class Water extends Container {
  constructor(appRef) {
    super();

    _defineProperty(this, "appRef", void 0);

    _defineProperty(this, "mesh", void 0);

    _defineProperty(this, "shader", void 0);

    _defineProperty(this, "vertexPositionBuffer", void 0);

    _defineProperty(this, "textureCoordBuffer", void 0);

    _defineProperty(this, "startTime", void 0);

    _defineProperty(this, "uniforms", void 0);

    this.appRef = appRef;
    this.startTime = Date.now();
    const wavesTexture = staticAssetLoader.getTexture("Water_Waves");
    wavesTexture.baseTexture.mipmap = MIPMAP_MODES.OFF;
    this.uniforms = {
      uSamplerWaves: wavesTexture,
      waveTextureScale: 1 / 512,
      timeS: 0
    };
    this.shader = Shader.from(waterVertexCode, waterFragmentCode, this.uniforms);
    this.vertexPositionBuffer = new Buffer();
    this.textureCoordBuffer = new Buffer();
    const geometry = new Geometry().addAttribute("aVertexPosition", this.vertexPositionBuffer, 2).addAttribute("aTextureCoord", this.textureCoordBuffer, 2).addIndex([0, 1, 2, 0, 3, 2]);
    const {
      width,
      height
    } = appRef.required.renderer;
    this.resize(width, height);
    this.mesh = new Mesh(geometry, this.shader);
    this.addChild(this.mesh);
    this.on("added", this.onAdded, this);
    this.on("removed", this.onRemoved, this);
  }

  onAdded() {
    const app = this.appRef.required;
    app.ticker.add(this.update, this);
    app.renderer.on("resize", this.resize, this);
  }

  onRemoved() {
    var _app$ticker;

    const app = this.appRef.required;
    (_app$ticker = app.ticker) === null || _app$ticker === void 0 ? void 0 : _app$ticker.remove(this.update, this);
    app.renderer.off("resize", this.resize, this);
  }

  resize(width, height) {
    this.vertexPositionBuffer.update(new Float32Array([0, 0, width, 0, width, height, 0, height]));
    const uMax = width;
    const vMax = height;
    this.textureCoordBuffer.update(new Float32Array([0, 0, uMax, 0, uMax, vMax, 0, vMax]));
  }

  update() {
    this.uniforms.timeS = (Date.now() - this.startTime) / 1000;
  }

  destroy(options) {
    this.mesh.destroy({
      children: true
    });
    super.destroy(options);
    this.shader.destroy();
    this.vertexPositionBuffer.destroy();
    this.textureCoordBuffer.destroy();
  }

}