import { TranslatedError } from "../errors/TranslatedError";

/* ------------- */

/* -- HELPERS -- */

/* ------------- */
export function errorToSocketIOError(error) {
  return {
    type: error.constructor.name,
    message: error.message,
    translationKey: error.translationKey,
    interpolationOptions: error.interpolationOptions
  };
}
export function createErrorFromSocketError(error) {
  if (isErrorTranslated(error)) return new TranslatedError(error.translationKey, error.interpolationOptions);
  return new TranslatedError("editor.untranslated_server_error", {
    error: `${error.type}: ${error.message}`
  });
}
export function isErrorTranslated(error) {
  return !!error.translationKey;
}
/* -------------------- */

/* -- CLIENT HELPERS -- */

/* -------------------- */

export function throwIfErrorSet(socketIOError) {
  if (!socketIOError) return;
  throw createErrorFromSocketError(socketIOError);
}
export function autoResolveRejectCallback(resolve, reject) {
  return error => resolveRejectCallback(error, resolve, reject);
}
export function autoResolveRejectWithResultCallback(resolve, reject, error, result) {
  if (error) reject(createErrorFromSocketError(error));else resolve(result);
}
export function resolveRejectCallback(error, resolve, reject) {
  try {
    throwIfErrorSet(error);
    resolve();
  } catch (e) {
    reject(e);
  }
}