var _dec, _class;

import { model, Model, prop } from "mobx-keystone";
import { DirectionHelper } from "./DirectionHelper";
export let PlaneTransitModel = (_dec = model("resources/PlaneTransitModel"), _dec(_class = class PlaneTransitModel extends Model({
  direction: prop().withSetter(),
  heightDifference: prop(0).withSetter(),
  targetXOffset: prop(0).withSetter(),
  targetYOffset: prop(0).withSetter()
}) {
  /**
   * Returns true if this {@link PlaneTransitModel} is initialized.
   * @return True if this is initialized.
   */
  isInitialized() {
    return this.heightDifference > 0;
  }
  /**
   * Creates a 'counter part' of this {@link PlaneTransitModel}.
   * It is located at the target position, pointing to the opposite direction
   * and to the opposite height difference.
   * @return The transit model.
   */


  createCounterPart() {
    return new PlaneTransitModel({
      heightDifference: this.heightDifference * -1,
      targetXOffset: this.targetXOffset * -1,
      targetYOffset: this.targetYOffset * -1,
      direction: DirectionHelper.getOpposite(this.direction)
    });
  }

}) || _class);