import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";

var _dec, _class, _class2;

import { model, Model, modelAction, prop } from "mobx-keystone";
import { TranslatedString } from "../game/TranslatedString";
import { EnemyCombatPresetRoundModel } from "./EnemyCombatPresetRoundModel";
export let EnemyCombatPresetModel = (_dec = model("combat/EnemyCombatPresetModel"), _dec(_class = (_class2 = class EnemyCombatPresetModel extends Model({
  name: prop(() => new TranslatedString({})),
  rounds: prop(() => [])
}) {
  addRound() {
    this.rounds.push(new EnemyCombatPresetRoundModel({}));
  }

  removeRound(index) {
    this.rounds.splice(index, 1);
  }

  translatableEntityData() {
    return {
      entity: this,
      label: "Enemy Combat Preset",
      translateableStrings: [{
        label: "Name",
        translatedString: this.name
      }]
    };
  }

}, (_applyDecoratedDescriptor(_class2.prototype, "addRound", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "addRound"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeRound", [modelAction], Object.getOwnPropertyDescriptor(_class2.prototype, "removeRound"), _class2.prototype)), _class2)) || _class);