import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { TranslatedError } from "../../../../shared/definitions/errors/TranslatedError";
import { combatEditorStore } from "../../CombatEditorStore";
import { combatStore } from "../../CombatStore";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableSelectEnemyCombatPreset(id) {
  executeUndoableOperation(new CombatEnemyCombatPresetSelectionOp(id));
}
export function undoableDeselectEnemyCombatPreset() {
  executeUndoableOperation(new CombatEnemyCombatPresetSelectionOp(null));
}
export function selectEnemyCombatPreset(id) {
  if (id && !combatStore.config.findEnemyCombatPreset(id)) throw new TranslatedError("editor.error_enemy_combat_preset_does_not_exist");
  combatEditorStore.setSelectedEnemyCombatPreset(id);
}

class CombatEnemyCombatPresetSelectionOp extends UndoableOperation {
  constructor(id) {
    super("combatEnemyCombatPresetSelection" + (!id ? "Clear" : ""));
    this.id = id;

    _defineProperty(this, "previousId", void 0);

    this.previousId = combatEditorStore.selectedEnemyCombatPresetId;
  }

  async execute() {
    selectEnemyCombatPreset(this.id);
  }

  async reverse() {
    selectEnemyCombatPreset(this.previousId);
  }

}