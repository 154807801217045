import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable, runInAction } from "mobx";
import { animationLoader } from "../helper/AnimationLoader";
import { AnimationSkinCombinator } from "../canvas/shared/animation/AnimationSkinCombinator";
import { setSkinTint } from "../canvas/game/character/characterAnimationHelper";
import { hexToColor } from "../helper/pixiHelpers";
import { errorStore } from "./ErrorStore";
export class AnimationSelectionStore {
  constructor() {
    _defineProperty(this, "selectedAnimation", null);

    _defineProperty(this, "currentlyLoadingAnimation", null);

    _defineProperty(this, "selectedAnimationState", null);

    _defineProperty(this, "selectedSkinClass", void 0);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
  }

  async setSelectedAnimation(animationAssetModel) {
    var _this$selectedAnimati;

    if (!animationAssetModel) {
      this.setLoadingState(null);
      this.applySelectableAnimation(null, null);
      return;
    }

    if (this.currentlyLoadingAnimation == animationAssetModel.name || animationAssetModel == ((_this$selectedAnimati = this.selectedAnimation) === null || _this$selectedAnimati === void 0 ? void 0 : _this$selectedAnimati.animation)) return;

    try {
      this.setLoadingState(animationAssetModel.name);
      this.applySelectableAnimation(null, null);
      const spineAnimation = await animationLoader.loadSpine(animationAssetModel.id); // avoid race conditions..

      if (this.currentlyLoadingAnimation == animationAssetModel.name) {
        // ... apply the latest call.
        runInAction(() => {
          this.setLoadingState(null);
          this.applySelectableAnimation(spineAnimation, animationAssetModel);
          this.setSelectedState(AnimationSelectionStore.DefaultAnimationState);
        });
      } else {
        // ... destroy outdated calls.
        spineAnimation.destroy();
      }
    } catch (e) {
      errorStore.addErrorFromErrorObject(e);
      this.applySelectableAnimation(animationLoader.createEmptySpine(), animationAssetModel);
      this.setLoadingState(null);
    }
  }

  setLoadingState(animationName) {
    runInAction(() => {
      this.currentlyLoadingAnimation = animationName;
    });
  }

  applySelectableAnimation(spine, animation) {
    runInAction(() => {
      if (spine && animation) {
        this.selectedAnimation = {
          spine: spine,
          animation: animation
        };
      } else {
        this.selectedAnimation = null;
      }
    });
  }

  setSelectedState(animationStateName, loop = true) {
    var _this$selectedAnimati2;

    if (this.hasAnimationSelected && animationStateName && (_this$selectedAnimati2 = this.selectedAnimation.spine.state) !== null && _this$selectedAnimati2 !== void 0 && _this$selectedAnimati2.hasAnimation(animationStateName)) {
      this.selectedAnimationState = animationStateName;
      this.selectedAnimation.spine.state.setAnimation(0, animationStateName, loop);
      this.selectedAnimation.spine.state.timeScale = 1;
    } else {
      this.selectedAnimationState = null;
    }
  }

  setSelectedSkinClass(className) {
    this.selectedSkinClass = className;
  }

  setSkinTint(colorHex) {
    var _this$selectedAnimati3;

    if ((_this$selectedAnimati3 = this.selectedAnimation) !== null && _this$selectedAnimati3 !== void 0 && _this$selectedAnimati3.spine) {
      setSkinTint(this.selectedAnimation.spine, hexToColor(colorHex));
    }
  }

  get hasAnimationSelected() {
    return this.selectedAnimation && this.selectedAnimation.spine && this.selectedAnimation.spine.spineData;
  }

  setSkinSelection(skinNames) {
    var _this$selectedAnimati4, _this$selectedAnimati5, _this$selectedAnimati6, _this$selectedAnimati7, _this$selectedAnimati8;

    const skinCombinator = new AnimationSkinCombinator((_this$selectedAnimati4 = this.selectedAnimation) === null || _this$selectedAnimati4 === void 0 ? void 0 : (_this$selectedAnimati5 = _this$selectedAnimati4.spine) === null || _this$selectedAnimati5 === void 0 ? void 0 : (_this$selectedAnimati6 = _this$selectedAnimati5.spineData) === null || _this$selectedAnimati6 === void 0 ? void 0 : _this$selectedAnimati6.skins);
    skinCombinator.addAll(skinNames);
    skinCombinator.applyTo((_this$selectedAnimati7 = this.selectedAnimation) === null || _this$selectedAnimati7 === void 0 ? void 0 : (_this$selectedAnimati8 = _this$selectedAnimati7.spine) === null || _this$selectedAnimati8 === void 0 ? void 0 : _this$selectedAnimati8.skeleton);
  }

}

_defineProperty(AnimationSelectionStore, "DefaultAnimationState", "idleEast");

_defineProperty(AnimationSelectionStore, "DefaultAnimationSkin", "skin-base");

export const animationEditorStore = new AnimationSelectionStore();