import { editorClient } from "../../../communication/EditorClient";
import { itemStore } from "../../ItemStore";
import { translationStore } from "../../TranslationStore";
import { mergeSinglePatchOp, UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableItemEditorSubmitItemChanges(itemId, patch, inversePatch) {
  // Don't create undo entries while translations are importing
  if (translationStore.isImporting) return;
  executeUndoableOperation(new ItemEditorSubmitItemPropertyChangesOp(itemId, patch, inversePatch));
}

class ItemEditorSubmitItemPropertyChangesOp extends UndoableOperation {
  constructor(itemId, patch, inversePatch) {
    super("itemEditorSubmitItemPropertyChanges");
    this.itemId = itemId;
    this.patch = patch;
    this.inversePatch = inversePatch;
  }

  async execute(isRedo) {
    await editorClient.submitItemChanges(this.itemId, this.patch, this.inversePatch);

    if (isRedo) {
      editorClient.patchItem(this.itemId, this.patch);
    }

    itemStore.setSelectedItem(itemStore.getItem(this.itemId));
  }

  async reverse() {
    await editorClient.submitItemChanges(this.itemId, this.inversePatch, this.patch);
    editorClient.patchItem(this.itemId, this.inversePatch);
    itemStore.setSelectedItem(itemStore.getItem(this.itemId));
  }

  merge(previousOperation) {
    return mergeSinglePatchOp(this, previousOperation);
  }

}