import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { editorClient } from "../../../communication/EditorClient";
import { fromSnapshot } from "mobx-keystone";
import { animationEditorStore } from "../../AnimationSelectionStore";
import { sharedStore } from "../../SharedStore";
export function undoableAnimationEditorCreateAnimation(animationAsset) {
  executeUndoableOperation(new AnimationEditorCreationOp(animationAsset));
}

class AnimationEditorCreationOp extends UndoableOperation {
  constructor(initialAnimationAsset) {
    var _animationEditorStore;

    super("animationEditorCreateAnimation");
    this.initialAnimationAsset = initialAnimationAsset;

    _defineProperty(this, "previousSelectedAnimationState", void 0);

    _defineProperty(this, "previousSelectedAnimation", void 0);

    _defineProperty(this, "animationId", void 0);

    this.animationId = this.initialAnimationAsset.id;
    this.previousSelectedAnimationState = animationEditorStore.selectedAnimationState;
    this.previousSelectedAnimation = (_animationEditorStore = animationEditorStore.selectedAnimation) === null || _animationEditorStore === void 0 ? void 0 : _animationEditorStore.animation;
  }

  async execute(isRedo) {
    let animationAsset;

    if (isRedo) {
      const snapshot = await editorClient.unDeleteAnimationAsset(this.animationId);
      animationAsset = fromSnapshot(snapshot);
    } else {
      animationAsset = this.initialAnimationAsset;
    }

    sharedStore.setAnimation(animationAsset);
    await animationEditorStore.setSelectedAnimation(animationAsset);
  }

  async reverse() {
    await editorClient.deleteAnimationAsset(this.animationId);
    sharedStore.deleteAnimation(this.animationId);
    await animationEditorStore.setSelectedAnimation(this.previousSelectedAnimation);
    animationEditorStore.setSelectedState(this.previousSelectedAnimationState);
  }

}