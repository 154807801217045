import { editorClient } from "../../../communication/EditorClient";
import { charEditorStore } from "../../CharacterEditorStore";
import { sharedStore } from "../../SharedStore";
import { translationStore } from "../../TranslationStore";
import { createChangeGroupStack, createGroupUndoableChangesFunction, mergeGroupedPatchOp, UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { selectCharacterOrThrow } from "./CharacterEditorSelectCharacterOp";
export let CharacterEditorChangeGroup;

(function (CharacterEditorChangeGroup) {
  CharacterEditorChangeGroup[CharacterEditorChangeGroup["None"] = 0] = "None";
  CharacterEditorChangeGroup[CharacterEditorChangeGroup["UnspecificGroupedChanges"] = 1] = "UnspecificGroupedChanges";
})(CharacterEditorChangeGroup || (CharacterEditorChangeGroup = {}));

const autoMergableGroups = [CharacterEditorChangeGroup.UnspecificGroupedChanges];
const changeGroupStack = createChangeGroupStack(CharacterEditorChangeGroup.None);
/**
 * This method groups all changes made inside `executer` and merges them into one undo/redo entry, and labels
 * it appropriately (according to the selected `group`) and executes side effects if necessary.
 * 
 * @see {@link createGroupUndoableChangesFunction} for more information.
 *
 * @param group A group denoting the purpose of the grouped changes in executer
 * @param executer The callback that contains all changes that should be grouped
 * @param sideEffects Side effects to be executed after the first patch (initial run) or after all patches (undo/redo)
 */

export const groupUndoableCharacterEditorChanges = createGroupUndoableChangesFunction(changeGroupStack, CharacterEditorChangeGroup.None, () => ({
  characterConfigurationId: null,
  patches: [],
  inversePatches: []
}), () => {
  const currentStack = changeGroupStack[changeGroupStack.length - 1];
  const {
    currentChangeGroup,
    currentGroupId,
    queuedSideEffects,
    extraData
  } = currentStack;
  const {
    characterConfigurationId,
    patches,
    inversePatches
  } = extraData;

  if (characterConfigurationId === null && patches.length === 0 && inversePatches.length === 0) {
    // Nothing happened. While this might be an error, it's also possible that despite starting the
    // group no changes were made at all.
    //console.log(`groupUndoableCharacterEditorChanges: No changes made in a CharacterEditorChangeGroup.${CharacterEditorChangeGroup[currentChangeGroup]} group.`);
    return;
  }

  executeUndoableOperation(new CharacterEditorSubmitCharacterConfigurationChangesOp(currentChangeGroup, currentGroupId, queuedSideEffects, characterConfigurationId, patches, inversePatches));
});
export function undoableCharacterEditorSubmitCharacterConfigurationsChanges(characterConfigurationId, patch, inversePatch) {
  // Don't create undo entries while translations are importing
  if (translationStore.isImporting) return;
  const currentStack = changeGroupStack[changeGroupStack.length - 1];

  if (currentStack.currentChangeGroup !== CharacterEditorChangeGroup.None) {
    currentStack.extraData.patches.push(patch);
    currentStack.extraData.inversePatches.push(inversePatch);
    if (currentStack.extraData.characterConfigurationId !== null && currentStack.extraData.characterConfigurationId !== characterConfigurationId) throw new Error("Groups must have the same actionTree");
    currentStack.extraData.characterConfigurationId = characterConfigurationId;
    return;
  }

  const {
    currentChangeGroup,
    currentGroupId,
    queuedSideEffects
  } = currentStack;
  currentStack.queuedSideEffects = null;
  executeUndoableOperation(new CharacterEditorSubmitCharacterConfigurationChangesOp(currentChangeGroup, currentGroupId, queuedSideEffects, characterConfigurationId, [patch], [inversePatch]));
}

class CharacterEditorSubmitCharacterConfigurationChangesOp extends UndoableOperation {
  constructor(group, groupId, sideEffects, characterConfigurationId, patches, inversePatches) {
    super("characterEditorSubmitChanges/changed"); //super("characterEditorSubmitChanges/" + CharacterEditorChangeGroup[group]);

    this.group = group;
    this.groupId = groupId;
    this.sideEffects = sideEffects;
    this.characterConfigurationId = characterConfigurationId;
    this.patches = patches;
    this.inversePatches = inversePatches;
  }

  async execute(isRedo) {
    var _this$sideEffects;

    await editorClient.submitCharacterConfigurationChanges(this.characterConfigurationId, this.patches, this.inversePatches);

    if (isRedo) {
      editorClient.patch(sharedStore.getCharacter(this.characterConfigurationId), this.patches);
    }

    selectCharacterOrThrow(charEditorStore, this.characterConfigurationId);
    (_this$sideEffects = this.sideEffects) === null || _this$sideEffects === void 0 ? void 0 : _this$sideEffects.forEach(sideEffect => sideEffect.afterExecute(isRedo));
  }

  async reverse() {
    var _this$sideEffects2;

    const reversedInversePatches = this.inversePatches.slice().reverse();
    await editorClient.submitCharacterConfigurationChanges(this.characterConfigurationId, reversedInversePatches, this.patches.slice().reverse());
    editorClient.patch(sharedStore.getCharacter(this.characterConfigurationId), reversedInversePatches);
    selectCharacterOrThrow(charEditorStore, this.characterConfigurationId);
    (_this$sideEffects2 = this.sideEffects) === null || _this$sideEffects2 === void 0 ? void 0 : _this$sideEffects2.forEach(sideEffect => sideEffect.afterReverse());
  }

  merge(previousOperation) {
    return mergeGroupedPatchOp(this, previousOperation, autoMergableGroups, CharacterEditorChangeGroup.None);
  }

}