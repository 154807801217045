import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { MapViewBase } from "../../shared/map/MapViewBase";
import { EditorTileView } from "./EditorTileView";
import { EditorNpcView } from "./EditorNpcView";
import { EditorAnimationElementView } from "./EditorAnimationElementView";
import { MapMarkerView } from "./MapMarkerView";
import { AreaTriggerView } from "./AreaTriggerView";
import { adjustMapElementContainerViewMap } from "../../../helper/pixiHelpers";
import { autoDisposeOnDisplayObjectRemovedArray } from "../../../helper/ReactionDisposerGroup";
import { MapWalker } from "../../../interaction/path/MapWalker";
import { DebugStartMarkerView } from "./DebugStartMarkerView";
import { gameStore } from "../../../stores/GameStore";
export class EditorMapView extends MapViewBase {
  constructor(appRef, mapData, textGroup, mapRelatedStore, currentMapStore, overlayContainer = null) {
    super(appRef, true);
    this.textGroup = textGroup;
    this.mapRelatedStore = mapRelatedStore;
    this.currentMapStore = currentMapStore;
    this.overlayContainer = overlayContainer;

    _defineProperty(this, "recreateOnMapChangeReactions", void 0);

    _defineProperty(this, "areaTriggerViews", new Map());

    _defineProperty(this, "mapMarkerViews", new Map());

    _defineProperty(this, "debugStartMarkerView", new Map());

    _defineProperty(this, "_mapWalker", void 0);

    this.recreateOnMapChangeReactions = autoDisposeOnDisplayObjectRemovedArray(this, [...this.baseMapChangeCallbacks, this.refreshAreaTriggers, this.refreshMapMarkers, this.refreshMapWalker, this.refreshDebugStartMarker], true);
    this.initialize(mapData);
  }

  refreshAreaTriggers() {
    if (!this.mapData) return;
    adjustMapElementContainerViewMap(this.addChildToContentContainer, this.mapData.areaTriggers, this.areaTriggerViews, data => new AreaTriggerView(this.mapRelatedStore, data, this.textGroup));
  }

  refreshMapMarkers() {
    if (!this.mapData) return;
    adjustMapElementContainerViewMap(this.addChildToContentContainer, this.mapData.mapMarkers, this.mapMarkerViews, data => new MapMarkerView(this.mapRelatedStore, data, this.textGroup));
  }

  refreshDebugStartMarker() {
    var _this$currentMapStore;

    if (!this.mapData) return;
    const debugStartMarker = [];
    if (gameStore.debugStartMarker && gameStore.debugStartMarkerMapId === ((_this$currentMapStore = this.currentMapStore) === null || _this$currentMapStore === void 0 ? void 0 : _this$currentMapStore.currentMapId)) debugStartMarker.push(gameStore.debugStartMarker);
    adjustMapElementContainerViewMap(this.addChildToContentContainer, debugStartMarker, this.debugStartMarkerView, _ => new DebugStartMarkerView(this.mapRelatedStore, this.textGroup));
  }

  refreshMapWalker() {
    if (!this.mapData) {
      this._mapWalker = null;
      return;
    }

    this._mapWalker = new MapWalker(this);
  }

  onMapDataChanged() {
    this.recreateOnMapChangeReactions();
  }

  get mapWalker() {
    return this._mapWalker;
  }

  createTileView(tileData, tileAssetData, tileImageUsage) {
    return new EditorTileView(tileData, tileAssetData, tileImageUsage, this.water, this.mapRelatedStore);
  }

  createNpcView(data) {
    return new EditorNpcView(this.mapRelatedStore, data, this.overlayContainer);
  }

  createAnimationElementView(data) {
    return new EditorAnimationElementView(this.mapRelatedStore, data);
  }

}