import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable } from "mobx";
import { gameCanvasSize } from "../../../data/gameConstants";
import { factions } from "../../../../shared/action/ActionModel";
const NOTIFICATION_AREA_HEIGHT = gameCanvasSize.height; // sizes in pixel

export const NOTIFICATION_HEIGHT = 100;
export const NOTIFICATION_WIDTH = 350;
export const NOTIFICATION_BORDER_WIDTH = 1;
const NOTIFICATION_HEIGHT_AND_MARGIN_TOP = NOTIFICATION_HEIGHT + 5;
export const NOTIFICATION_LIFETIME_MS = 20000;
const NOTIFICATION_FALL_SPEED_FACTOR = 1;
const INTERVAL_TIME_MS = 200;
const MAX_PAST_NOTIFICATIONS = 50;
const ACTION_TYPES_COLOR_1 = ["actions/ReceiveQuestTaskActionModel", "actions/QuestTaskFinishedActionModel", "actions/AbortQuestActionModel", "actions/ReceiveTaskActionModel", "actions/FinishTaskActionModel", "actions/AbortTaskActionModel"];
const ACTION_TYPES_COLOR_2 = ["actions/ReceiveItemActionModel", "actions/LooseItemActionModel"];
let runningId = 0;

class NotificationController {
  constructor() {
    _defineProperty(this, "pendingNotifications", new Array());

    _defineProperty(this, "visibleNotifications", new Array());

    _defineProperty(this, "pastNotifications", new Array());

    _defineProperty(this, "automaticRemovalIntervalId", -1);

    _defineProperty(this, "updateAnimationTimeout", -1);

    makeAutoObservable(this, {}, {
      autoBind: true
    });
  }

  addNotificationFromAction(action, extraInformation) {
    this.addNotification(this.createNotificationInfo(action, extraInformation));
  }

  createNotificationInfo(action, extraInformation) {
    return {
      id: runningId++,
      action,
      extraInformation,
      topDistance: -NOTIFICATION_HEIGHT_AND_MARGIN_TOP,
      lastIndex: -1,
      backgroundColor: this.backgroundColorOf(action),
      borderColor: this.borderColorOf(action)
    };
  }

  backgroundColorOf(action) {
    if (ACTION_TYPES_COLOR_1.includes(action.$modelType)) return "#ffe6cc";
    if (ACTION_TYPES_COLOR_2.includes(action.$modelType)) return "#fff1cc";

    if (action.$modelType === "actions/ReceiveReputationActionModel") {
      const reputationAction = action;
      if (reputationAction.fraction == factions[0]) return "#dbe7fc";
      if (reputationAction.fraction == factions[1]) return "#d5e7d3";
    }

    return "#ffffff";
  }

  borderColorOf(action) {
    if (ACTION_TYPES_COLOR_1.includes(action.$modelType)) return "#d79b02";
    if (ACTION_TYPES_COLOR_2.includes(action.$modelType)) return "#d5b556";

    if (action.$modelType === "actions/ReceiveReputationActionModel") {
      const reputationAction = action;
      if (reputationAction.fraction == factions[0]) return "#6b8ebf";
      if (reputationAction.fraction == factions[1]) return "#81b366";
    }

    return "#000000";
  }

  addNotification(notificationInfo) {
    if (this.automaticRemovalIntervalId == -1) {
      this.automaticRemovalIntervalId = window.setInterval(this.onAutomaticRemovalIntervalTick.bind(this), INTERVAL_TIME_MS);
    }

    if (this.isDisplayAreaFull()) {
      this.pendingNotifications.push(notificationInfo);
    } else {
      notificationInfo.displayTime = Date.now();
      this.visibleNotifications.push(notificationInfo);
      this.triggerUpdateAnimation();
    }
  }

  removeNotification(id) {
    const notificationToRemove = this.visibleNotifications.find(n => n.id == id);

    if (!notificationToRemove) {
      console.warn("Can not remove a notification by the id", id);
      return;
    }

    this.visibleNotifications = this.visibleNotifications.filter(n => n.id != id);
    this.pastNotifications.unshift(notificationToRemove);

    if (this.pastNotifications.length > MAX_PAST_NOTIFICATIONS) {
      this.pastNotifications.length = MAX_PAST_NOTIFICATIONS;
    }

    this.pastNotifications.sort((a, b) => b.id - a.id);

    if (this.pendingNotifications.length > 0) {
      this.addNotification(this.pendingNotifications.shift());
    }

    this.triggerUpdateAnimation();
  }

  triggerUpdateAnimation() {
    this.updateAnimationTimeout = window.setTimeout(this.updateAnimation.bind(this), 100);
  }

  updateAnimation() {
    this.updateAnimationTimeout = -1;
    this.visibleNotifications.forEach((ni, index) => {
      ni.topDistance = this.calcTopDistanceByIndex(index);
      ni.animationDurationMs = (ni.topDistance - this.calcTopDistanceByIndex(ni.lastIndex)) * NOTIFICATION_FALL_SPEED_FACTOR;
      ni.lastIndex = index;
    });
  }

  calcTopDistanceByIndex(index) {
    if (index < 0) return -NOTIFICATION_HEIGHT_AND_MARGIN_TOP;
    return NOTIFICATION_AREA_HEIGHT - (index + 1) * NOTIFICATION_HEIGHT_AND_MARGIN_TOP;
  }

  isDisplayAreaFull() {
    return NOTIFICATION_AREA_HEIGHT - this.visibleNotifications.length * NOTIFICATION_HEIGHT_AND_MARGIN_TOP < NOTIFICATION_HEIGHT_AND_MARGIN_TOP;
  }

  onAutomaticRemovalIntervalTick() {
    const now = Date.now();
    this.visibleNotifications.forEach(ni => {
      if (now - ni.displayTime > NOTIFICATION_LIFETIME_MS) {
        this.removeNotification(ni.id);
      }
    });
  }

  disposeCurrentData() {
    this.pendingNotifications.length = 0;
    this.visibleNotifications.length = 0;
    this.pastNotifications.length = 0;

    if (this.automaticRemovalIntervalId !== -1) {
      clearInterval(this.automaticRemovalIntervalId);
      this.automaticRemovalIntervalId = -1;
    }

    if (this.updateAnimationTimeout !== -1) {
      window.clearTimeout(this.updateAnimationTimeout);
      this.updateAnimationTimeout = -1;
    }
  }

}

export const notificationController = new NotificationController();