import { managementClient } from "../../../communication/ManagementClient";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableDeleteModule(module) {
  executeUndoableOperation(new ModuleDeletionOp(module));
}

class ModuleDeletionOp extends UndoableOperation {
  constructor(module) {
    super("moduleDeletion");
    this.module = module;
  }

  async execute() {
    await managementClient.deleteModule(this.module.$modelId);
  }

  async reverse() {
    await managementClient.unDeleteModule(this.module.$modelId);
  }

}