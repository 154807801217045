import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { MapElementContainer } from "../map/sorting/MapElementContainer";
export class SkipCullingUntilFirstRenderContainer extends MapElementContainer {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "skipCullingBeforeFirstRender", true);
  }

  render(renderer) {
    super.render(renderer);
    this.skipCullingBeforeFirstRender = false;
  }

}