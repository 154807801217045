import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable, observable } from "mobx";
import { ActionTreeModel, ActionTreeType } from "../../shared/action/ActionTreeModel";
import { allTileImageUsages, mainCategoryTags, tileAssetFloorTag } from "../../shared/resources/TileAssetModel";
import { AnimationType } from "../../shared/resources/AnimationAssetModel";
import { wrapIterator } from "../../shared/helper/IterableIteratorWrapper";
import { applySnapshot, fromSnapshot } from "mobx-keystone";
import { dataConstants } from "../../shared/data/dataConstants";
import { editorStore } from "./EditorStore";
import { removeFromTileImageCache } from "../cache/TileImageCache";
import { errorStore } from "./ErrorStore";
import { selectorMapEditorStore, mainMapEditorStore } from "./MapEditorStore";
import { tileAssetEditorStore } from "./TileAssetEditorStore";
import { animationEditorStore } from "./AnimationSelectionStore";
import { registerActionTree } from "../../shared/helper/actionTreeHelper";
import { editorClient } from "../communication/EditorClient";
import { actionEditorStore } from "./ActionEditorStore";
import { routes } from "../data/routes";
import { EventEmitter } from "eventemitter3";
import { doesAnyTagMatchTagSelection } from "./MapRelatedStore";
import { gameStore } from "./GameStore";
export const sharedStoreEventEmitter = new EventEmitter();
/**
 * Everything that is shared between any editor *and* the game is put here.
 */

export class SharedStore {
  constructor() {
    _defineProperty(this, "tileAssetsInitialized", void 0);

    _defineProperty(this, "allTileAssetsLoaded", void 0);

    _defineProperty(this, "tileAssetDataLoadingPercentage", 0);

    _defineProperty(this, "tileAssets", void 0);

    _defineProperty(this, "animationAssetsLoadingPercentage", 0);

    _defineProperty(this, "animationsInitialized", void 0);

    _defineProperty(this, "animationAssets", observable.map());

    _defineProperty(this, "characterConfigurations", observable.map());

    _defineProperty(this, "actionTreeLoadingPercentage", 0);

    _defineProperty(this, "actionTreesInitialized", void 0);

    _defineProperty(this, "subTrees", void 0);

    _defineProperty(this, "modulesRootActionTrees", void 0);

    _defineProperty(this, "actionTreeTemplates", void 0);

    _defineProperty(this, "mainGameRootActionTree", void 0);

    _defineProperty(this, "actionTreesById", observable.map());

    _defineProperty(this, "subTreesByParentId", observable.map());

    _defineProperty(this, "gameSoundsLoadingPercentage", 0);

    _defineProperty(this, "previousHistoryLocationPathname", routes.editorAction);

    _defineProperty(this, "treeAccess", {
      getSubTreesForActionTree: actionTree => this.subTreesByParentId.get(actionTree.$modelId) || [],
      getTreeById: modelId => this.actionTreesById.get(modelId)
    });

    makeAutoObservable(this, {}, {
      autoBind: true
    });
    this.clearActionTrees();
  }

  get isInitialized() {
    return this.tileAssetsInitialized && this.actionTreesInitialized && this.animationsInitialized;
  }

  get mayOpenMap() {
    // Asset loading slows down rapidly when a map is open, so for now, only allow
    // opening maps before loading finishes in development mode
    return this.allTileAssetsLoaded || dataConstants.isDevelopment;
  }

  get mayOpenGame() {
    // See comment in mayOpenMap()
    return editorStore.isConnectedAndReady && this.allGameSoundsLoaded && this.mayOpenMap;
  }

  get allGameSoundsLoaded() {
    return this.gameSoundsLoadingPercentage === 1;
  }

  clear() {
    this.clearActionTrees();
    this.clearTileAssets();
    this.clearAnimationAssets();
    this.clearCharacterConfigurations();
    this.setGameSoundsLoadingPercentage(0);
  }

  clearTileAssets() {
    this.tileAssets = null;
    this.tileAssetsInitialized = false;
    this.allTileAssetsLoaded = false;
    this.tileAssetDataLoadingPercentage = 0;
  }

  setTileAssetsInitialized() {
    this.tileAssetsInitialized = true;
  }

  setAllTileAssetsLoaded() {
    this.allTileAssetsLoaded = true;
  }

  setGameSoundsLoadingPercentage(value) {
    this.gameSoundsLoadingPercentage = value;
  }

  setResources(tileAssets) {
    this.tileAssets = observable.map(tileAssets);
  }

  addTileAsset(tileAsset) {
    this.tileAssets.set(tileAsset.id, tileAsset);
  }

  updateTileAssetFromSnapshot(snapshot) {
    const existingTileAsset = this.tileAssets.get(snapshot.id);

    if (existingTileAsset && existingTileAsset.$modelId === snapshot.$modelId) {
      applySnapshot(existingTileAsset, snapshot);
    } else {
      this.addTileAsset(fromSnapshot(snapshot));
    }
  }

  clearAnimationAssets() {
    this.animationAssets.clear();
    this.animationAssetsLoadingPercentage = 0;
    this.animationsInitialized = false;
  }

  setAnimationAssetLoadingPercentage(value) {
    this.animationAssetsLoadingPercentage = value;
  }

  setAnimationsInitialized() {
    this.animationAssetsLoadingPercentage = 1;
    this.animationsInitialized = true;
  }

  setAnimations(animations) {
    this.animationAssets = observable.map(animations);
  }

  setAnimation(animation) {
    this.animationAssets = observable.map(new Map(this.animationAssets.set(animation.id, animation)));
  }

  deleteAnimation(animationId) {
    var _this$animationAssets, _animationEditorStore;

    (_this$animationAssets = this.animationAssets) === null || _this$animationAssets === void 0 ? void 0 : _this$animationAssets.delete(animationId);
    this.animationAssets = observable.map(new Map(this.animationAssets));

    if (((_animationEditorStore = animationEditorStore.selectedAnimation) === null || _animationEditorStore === void 0 ? void 0 : _animationEditorStore.animation.id) === animationId) {
      animationEditorStore.setSelectedAnimation(null).catch(errorStore.addErrorFromErrorObject);
    }
  }

  getAnimation(animationId) {
    var _this$animationAssets2;

    return (_this$animationAssets2 = this.animationAssets) === null || _this$animationAssets2 === void 0 ? void 0 : _this$animationAssets2.get(animationId);
  }

  getAnimationsByType(animationType) {
    if (this.animationAssets) return wrapIterator(this.animationAssets.values()).filter(a => a.isType(animationType));
    return [];
  }

  getAnimationByName(name) {
    return wrapIterator(this.animationAssets.values()).find(a => a.name === name);
  }

  clearCharacterConfigurations() {
    this.characterConfigurations.forEach(characterConfiguration => sharedStoreEventEmitter.emit("removedCharacterConfiguration", characterConfiguration.id));
    this.characterConfigurations.clear();
  }
  /**
   * Returns an {@link AnimationAssetModel} of the type {@link AnimationType.BodyType}
   * that is used by the assigned {@link CharacterConfigurationModel} or null.
   * If includingNPC = true is assigned it also returns animations of the type {@link AnimationType.NPC}.
   * @param characterConfiguration The config to search the animation.
   * @param includingNPC
   */


  getCharacterAnimation(characterConfiguration, includingNPC = false) {
    if (characterConfiguration) {
      const animation = this.getAnimationByName(characterConfiguration.animationAssetName);

      if (animation && (animation.isType(AnimationType.BodyType) || includingNPC && animation.isType(AnimationType.NPC))) {
        return animation;
      }
    }

    return null;
  }

  getCharactersWithMissingAnimationReference(includingNPC = false) {
    var _this$characterConfig;

    const charsWithMissingAnimationReference = [];
    (_this$characterConfig = this.characterConfigurations) === null || _this$characterConfig === void 0 ? void 0 : _this$characterConfig.forEach(c => {
      const animation = this.getCharacterAnimation(c, includingNPC);

      if (!animation) {
        charsWithMissingAnimationReference.push(c);
      }
    });
    return charsWithMissingAnimationReference;
  }

  setCharacters(characters) {
    this.characterConfigurations.forEach(characterConfiguration => sharedStoreEventEmitter.emit("removedCharacterConfiguration", characterConfiguration.id));
    this.characterConfigurations = observable.map(characters);
    this.characterConfigurations.forEach(characterConfiguration => sharedStoreEventEmitter.emit("addedCharacterConfiguration", characterConfiguration.id));
  }

  getCharacter(id) {
    return this.characterConfigurations.get(id);
  }

  getCharacters() {
    return Array.from(this.characterConfigurations.values());
  }

  getCharacterByReferenceId(textReferenceId) {
    return wrapIterator(this.characterConfigurations.values()).find(a => a.textReferenceId === textReferenceId);
  }

  putCharacter(character) {
    if (!this.characterConfigurations.get(character.id)) {
      this.characterConfigurations.set(character.id, character);
      sharedStoreEventEmitter.emit("addedCharacterConfiguration", character.id);
    }
  }

  deleteCharacter(characterId) {
    var _this$characterConfig2;

    (_this$characterConfig2 = this.characterConfigurations) === null || _this$characterConfig2 === void 0 ? void 0 : _this$characterConfig2.delete(characterId);
    sharedStoreEventEmitter.emit("removedCharacterConfiguration", characterId);
  }

  getCharactersThatReferencingAnimation(animationName) {
    return wrapIterator(this.characterConfigurations.values()).filter(c => c.animationAssetName == animationName);
  }

  setTileAssetDataLoadingPercentage(value) {
    this.tileAssetDataLoadingPercentage = value;
  }

  isTileAssetIdUsed(id) {
    return this.tileAssets.has(id);
  }

  getTileAsset(tileAssetId) {
    var _this$tileAssets;

    return (_this$tileAssets = this.tileAssets) === null || _this$tileAssets === void 0 ? void 0 : _this$tileAssets.get(tileAssetId);
  }

  deleteTileAsset(id) {
    mainMapEditorStore.clearTileSelectionIfMatches(id);
    selectorMapEditorStore.clearTileSelectionIfMatches(id);
    tileAssetEditorStore.clearTileSelectionIfMatches(id);
    this.tileAssets.delete(id);

    for (const tileImageUsage of allTileImageUsages) {
      removeFromTileImageCache(id, tileImageUsage).catch(errorStore.addErrorFromErrorObject);
    }
  }

  getFilteredTileAssets(layerType, plane, showPlaneTransit, includeTags, excludeTags, search, additionalFilter) {
    if (!this.tileAssets) return [];

    if (search) {
      search = search.toLowerCase();
    }

    return wrapIterator(this.tileAssets.values()).filter(tileAsset => (layerType == null || tileAsset.layerType == layerType) && (plane === null || tileAsset.isMadeForPlane(plane)) && (showPlaneTransit || !tileAsset.planeTransit) && (!includeTags || includeTags.every(tag => doesAnyTagMatchTagSelection(tag, tileAsset.tags))) && (!excludeTags || excludeTags.every(tag => !doesAnyTagMatchTagSelection(tag, tileAsset.tags))) && (!search || tileAsset.localizedName.get(gameStore.languageKey).toLowerCase().includes(search)) && (!additionalFilter || additionalFilter(tileAsset)));
  }

  get hasFloorCategoryTiles() {
    if (!this.tileAssets) return false;

    for (const tileAsset of this.tileAssets.values()) {
      if (tileAsset.tags.includes(tileAssetFloorTag)) return true;
    }

    return false;
  }

  get hasTilesWithoutMainCategory() {
    if (!this.tileAssets) return false;

    for (const tileAsset of this.tileAssets.values()) {
      if (!mainCategoryTags.some(tag => tileAsset.tags.includes(tag))) return true;
    }

    return false;
  }

  setActionTreeLoadingPercentage(value) {
    this.actionTreeLoadingPercentage = value;
  }

  clearActionTrees() {
    if (this.actionTreesInitialized) {
      editorClient.stopTrackingAllActionTrees();
    }

    this.actionTreesInitialized = false;
    this.actionTreeLoadingPercentage = 0;
    this.actionTreeTemplates = [];
    this.subTrees = [];
    this.subTreesByParentId.clear();
    this.actionTreesById.clear();
    const temporaryUnregisteredTree = ActionTreeModel.createEmptyPrototype();
    temporaryUnregisteredTree.setType(ActionTreeType.MainGameRoot);
    this.mainGameRootActionTree = temporaryUnregisteredTree;
  }

  setActionTrees(actionTrees) {
    actionTrees.forEach(actionTree => this.registerActionTree(actionTree));
    this.mainGameRootActionTree = actionTrees.find(t => t.type === ActionTreeType.MainGameRoot);
    this.actionTreeTemplates = actionTrees.filter(t => t.type === ActionTreeType.TemplateRoot);
    this.subTrees = actionTrees.filter(t => t.type === ActionTreeType.SubTree);
    this.modulesRootActionTrees = actionTrees.filter(t => t.type === ActionTreeType.ModuleRoot);
    this.actionTreesInitialized = true;
  }

  addActionTreesFromSnapshots(actionTreeSnapshots) {
    actionTreeSnapshots.forEach(snapshot => this.addActionTree(fromSnapshot(snapshot)));
  }

  addActionTree(actionTree) {
    this.registerActionTree(actionTree);

    switch (actionTree.type) {
      case ActionTreeType.TemplateRoot:
        this.actionTreeTemplates.push(actionTree);
        break;

      case ActionTreeType.SubTree:
        this.subTrees.push(actionTree);
        break;

      case ActionTreeType.ModuleRoot:
        this.modulesRootActionTrees.push(actionTree);
        break;

      default:
        throw Error("Not implemented: " + actionTree.type);
    }
  }

  removeActionTrees(actionTreeIds) {
    actionTreeIds.forEach(id => this.removeActionTree(id));
  }

  removeActionTree(actionTreeId) {
    const actionTree = this.actionTreesById.get(actionTreeId);
    this.actionTreesById.delete(actionTreeId);
    actionEditorStore.onActionTreeRemoved(actionTree);
    const templateIndex = this.actionTreeTemplates.findIndex(t => t.$modelId === actionTreeId);

    if (templateIndex !== -1) {
      editorClient.stopTrackingActionTree(this.actionTreeTemplates[templateIndex]);
      this.actionTreeTemplates.splice(templateIndex, 1);
      return;
    }

    const subTreeIndex = this.subTrees.findIndex(t => t.$modelId === actionTreeId);

    if (subTreeIndex !== -1) {
      const subTree = this.subTrees[subTreeIndex];
      editorClient.stopTrackingActionTree(subTree);
      const subTreesForThisParent = this.subTreesByParentId.get(subTree.activeParentModelId);
      subTreesForThisParent.splice(subTreesForThisParent.indexOf(subTree), 1);
      this.subTrees.splice(subTreeIndex, 1);
      return;
    }

    console.error("Tried to remove an action tree that didn't exist: " + actionTreeId);
  }

  registerActionTree(actionTree, startTracking = true) {
    this.actionTreesById.set(actionTree.$modelId, actionTree);
    editorClient.startTrackingActionTree(actionTree);
    return registerActionTree(actionTree, this.subTreesByParentId, this.treeAccess);
  }

  prepareMainGameRootActionTreesForGame() {
    if (!this.modulesRootActionTrees) // is null in tests
      return;

    for (const tree of this.modulesRootActionTrees) {
      tree.temporaryParentModelId = this.mainGameRootActionTree.$modelId;
    }
  }

  restoreMainGameRootActionTreesAfterGame() {
    if (!this.modulesRootActionTrees) // is null in tests
      return;

    for (const tree of this.modulesRootActionTrees) {
      tree.temporaryParentModelId = null;
    }
  }

  restoreTreeAccessAssignments() {
    for (const actionTree of this.actionTreesById.values()) {
      actionTree.treeAccess = this.treeAccess;
    }
  }

  setPreviousHistoryLocationPathname(pathname) {
    this.previousHistoryLocationPathname = pathname;
  }

}
export const sharedStore = new SharedStore();