import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
import { doesTilePositionEqual } from "../../../../shared/definitions/other/TilePosition";
import { tileToWorldPositionX, tileToWorldPositionY } from "../../../helper/pixiHelpers";
export function undoableMapEditorSelectTile(tilePosition, mapEditorStore, focusOnTile) {
  const {
    selectedTilePosition
  } = mapEditorStore; // Deselect without any selection? Skip.

  if (!selectedTilePosition && !tilePosition) return; // Selected again directly - unselect then.

  if (selectedTilePosition && tilePosition && doesTilePositionEqual(selectedTilePosition, tilePosition)) {
    tilePosition = null;
  } // execute method from 'UndoStore' ..


  executeUndoableOperation(new MapEditorEntitySelectionOp(tilePosition, mapEditorStore, focusOnTile));
}

class MapEditorEntitySelectionOp extends UndoableOperation {
  constructor(nextSelectedTileDataPosition, mapEditorStore, focusOnTile) {
    super("mapEditorEntitySelectionChanged");
    this.nextSelectedTileDataPosition = nextSelectedTileDataPosition;
    this.mapEditorStore = mapEditorStore;
    this.focusOnTile = focusOnTile;

    _defineProperty(this, "lastSelectedTileDataPosition", void 0);

    this.lastSelectedTileDataPosition = this.mapEditorStore.selectedTilePosition;
  }

  async execute() {
    this.select(this.nextSelectedTileDataPosition);
  }

  async reverse() {
    this.select(this.lastSelectedTileDataPosition);
  }

  select(targetPosition) {
    this.mapEditorStore.setSelectedTilePosition(targetPosition);

    if (targetPosition && this.focusOnTile) {
      const {
        mapState,
        canvasWidth,
        canvasHeight
      } = this.mapEditorStore;
      const zoom = mapState.currentMapZoom;
      const x = canvasWidth / 2 - tileToWorldPositionX(targetPosition.x, targetPosition.y, true) * zoom;
      const y = canvasHeight / 2 - tileToWorldPositionY(targetPosition.x, targetPosition.y, true) * zoom;
      mapState.setMapCenter(x, y, mapState.currentMapZoom);
    }
  }

}