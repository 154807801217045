import { CanceledError } from "axios";
export class ClientDisconnectedError extends Error {
  constructor() {
    super("socket.io Client Disconnected");
    this.name = "ClientDisconnectedError";
  }

}
export function isDisconnectedOrCancelled(e) {
  return e instanceof ClientDisconnectedError || e instanceof CanceledError;
}