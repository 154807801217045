import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { makeAutoObservable, ObservableMap, reaction, runInAction } from "mobx";
import { backgroundRendererManager } from "../canvas/backgroundRendering/BackgroundRendererManager";
import { editorStore } from "./EditorStore";
import { sharedStore, sharedStoreEventEmitter } from "./SharedStore";
import * as Sentry from "@sentry/react";
import { throttledDelayedCall } from "../helper/generalHelpers";
import { GeneratedImageType, saveInGeneratedImageCache } from "../cache/GeneratedImageCache";
import { errorStore } from "./ErrorStore";
import { loadingAnimationUrl } from "../canvas/loader/StaticAssetLoader";
export let PreviewImageStatus;

(function (PreviewImageStatus) {
  PreviewImageStatus[PreviewImageStatus["NotRequested"] = 0] = "NotRequested";
  PreviewImageStatus[PreviewImageStatus["Generating"] = 1] = "Generating";
  PreviewImageStatus[PreviewImageStatus["Available"] = 2] = "Available";
  PreviewImageStatus[PreviewImageStatus["DoesNotExist"] = 3] = "DoesNotExist";
})(PreviewImageStatus || (PreviewImageStatus = {}));

export class GeneratedThumbnailImageStore {
  constructor(getObject, renderObject, saveInCache) {
    this.getObject = getObject;
    this.renderObject = renderObject;
    this.saveInCache = saveInCache;

    _defineProperty(this, "imageUrlData", new ObservableMap());

    _defineProperty(this, "isLoading", new ObservableMap());

    makeAutoObservable(this, {
      getOrGenerateThumbnail: false
    }, {
      autoBind: true
    });
  }

  getStatus(id) {
    if (!this.getObject(id)) return PreviewImageStatus.DoesNotExist;
    if (this.imageUrlData.has(id)) return PreviewImageStatus.Available;
    if (this.isLoading.has(id)) return PreviewImageStatus.Generating;
    return PreviewImageStatus.NotRequested;
  }

  setGenerating(id, abortController) {
    if (this.getStatus(id) !== PreviewImageStatus.NotRequested) return;
    this.isLoading.set(id, abortController);
  }

  setFailed(id) {
    if (this.getStatus(id) !== PreviewImageStatus.Generating) return;
    this.isLoading.delete(id);
    this.imageUrlData.set(id, {
      url: loadingAnimationUrl,
      version: "failed"
    });
  }

  setAvailable(id, url, version) {
    if (this.getStatus(id) !== PreviewImageStatus.Generating) return;
    this.isLoading.delete(id);
    this.imageUrlData.set(id, {
      url,
      version
    });
  }

  abortIfLoading(id) {
    const abortSignal = this.isLoading.get(id);

    if (abortSignal) {
      abortSignal.abort();
      this.isLoading.delete(id);
    }
  }

  clear(id) {
    this.abortIfLoading(id);
    this.imageUrlData.delete(id);
  }

  isSet(id) {
    return this.imageUrlData.has(id);
  }

  isSetWithVersion(id, version) {
    const object = this.imageUrlData.get(id);
    if (!object) return false;
    return object.version === version;
  }

  setFromCache(id, url, version) {
    this.abortIfLoading(id);
    this.imageUrlData.set(id, {
      url,
      version
    });
  }

  getOrGenerateThumbnail(id) {
    var _this$imageUrlData$ge;

    const status = this.getStatus(id);

    if (status === PreviewImageStatus.NotRequested && editorStore.isConnected) {
      const object = this.getObject(id);

      if (object) {
        const abortController = new AbortController();
        runInAction(() => this.setGenerating(id, abortController));
        this.renderObject(object, abortController.signal).then(result => {
          if (result) {
            this.saveInCache(id, result);
            this.setAvailable(id, URL.createObjectURL(result.imageBlob), result.version);
          } else {
            this.setFailed(id);
          }
        }).catch(e => {
          //errorStore.addErrorFromErrorObject(e);
          // Failing preview image generation should be reported to Sentry, but not displayed to the user
          Sentry.captureException(e);
          console.log(e);
          this.setFailed(id);
        });
      }
    }

    return (_this$imageUrlData$ge = this.imageUrlData.get(id)) === null || _this$imageUrlData$ge === void 0 ? void 0 : _this$imageUrlData$ge.url;
  }

}
export const animationThumbnailStore = new GeneratedThumbnailImageStore(id => {
  var _sharedStore$getAnima;

  return (_sharedStore$getAnima = sharedStore.getAnimation(id)) === null || _sharedStore$getAnima === void 0 ? void 0 : _sharedStore$getAnima.name;
}, (animationName, abortSignal) => backgroundRendererManager.renderAnimation(animationName, abortSignal), (id, result) => saveInGeneratedImageCache(id.toString(), GeneratedImageType.AnimationAsset, result.version, result.imageBlob).catch(errorStore.addErrorFromErrorObject));
export const characterThumbnailStore = new GeneratedThumbnailImageStore(id => {
  var _sharedStore$getChara;

  return (_sharedStore$getChara = sharedStore.getCharacter(id)) === null || _sharedStore$getChara === void 0 ? void 0 : _sharedStore$getChara.id;
}, (id, abortSignal) => backgroundRendererManager.renderCharacter(id, abortSignal), (id, result) => saveInGeneratedImageCache(id.toString(), GeneratedImageType.CharacterConfig, result.version, result.imageBlob).catch(errorStore.addErrorFromErrorObject));
const characterChangeWatcherDisposers = new Map();

function startWatchingCharacterConfiguration(id) {
  // Refresh 2000ms after the *latest* change, and only if no other changes happen
  const throttledClearCharacterPreviewImageStore = throttledDelayedCall(() => characterThumbnailStore.clear(id), 2000);
  const disposer = reaction(() => {
    var _sharedStore$getAnima2;

    const character = sharedStore.characterConfigurations.get(id);
    if (!character) return null;
    return {
      animationId: (_sharedStore$getAnima2 = sharedStore.getAnimationByName(character.animationAssetName)) === null || _sharedStore$getAnima2 === void 0 ? void 0 : _sharedStore$getAnima2.id,
      animationSkins: character.animationSkins,
      tintColorHex: character.tintColorHex
    };
  }, () => {
    throttledClearCharacterPreviewImageStore();
  });
  characterChangeWatcherDisposers.set(id, disposer);
}

function stopWatchingCharacterConfiguration(id) {
  const disposer = characterChangeWatcherDisposers.get(id);

  if (disposer) {
    disposer();
    characterChangeWatcherDisposers.delete(id);
  }
}

sharedStore.characterConfigurations.forEach(character => startWatchingCharacterConfiguration(character.id));
sharedStoreEventEmitter.on("addedCharacterConfiguration", startWatchingCharacterConfiguration);
sharedStoreEventEmitter.on("removedCharacterConfiguration", stopWatchingCharacterConfiguration);