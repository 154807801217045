import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { MapViewBase } from "../../shared/map/MapViewBase";
import { GameTileView } from "./GameTileView";
import { GameNpcView } from "./GameNpcView";
import { GameAnimationElementView } from "./GameAnimationElementView";
import { wrapIterator } from "../../../../shared/helper/IterableIteratorWrapper";
import { autoDisposeOnDisplayObjectRemoved } from "../../../helper/ReactionDisposerGroup";
import { TaskMarkerWithColor } from "./TaskMarkerWithColor";
import { resolvePotentialMapElementTreeParameter } from "../../../helper/treeParameterHelpers";
import { ReadonlyDynamicMapElementAreaTrigger } from "../../../../shared/game/dynamicMapElements/DynamicMapElementAreaTriggerModel";
import { PathFinder } from "../../../interaction/path/PathFinder";
import { InteractionTriggerActionModel } from "../../../../shared/action/ActionModel";
import { gameStore } from "../../../stores/GameStore";
export class GameMapView extends MapViewBase {
  constructor(appRef, mapData, characterOverlayContainer, interactionTriggerOverlay, appDisposed) {
    super(appRef, false);
    this.characterOverlayContainer = characterOverlayContainer;
    this.interactionTriggerOverlay = interactionTriggerOverlay;
    this.appDisposed = appDisposed;

    _defineProperty(this, "recreateGameStoreReactions", void 0);

    _defineProperty(this, "_pathfinder", void 0);

    this.recreateGameStoreReactions = autoDisposeOnDisplayObjectRemoved(this, autoDisposingAutorun => {
      autoDisposingAutorun(this.refreshInteractionTriggers.bind(this)); // to refresh when the active tasks change in player state
    });
    this.initialize(mapData);
  }

  get npcViewsArray() {
    return Array.from(this.npcViews.values());
  }

  get animationElementViewsArray() {
    return Array.from(this.animationElementViews.values());
  }

  get interactionTriggersArray() {
    const array = new Array();

    for (const npcView of this.npcViews.values()) {
      if (npcView.interactionTrigger) array.push(npcView.interactionTrigger);
    }

    for (const animationElementView of this.animationElementViews.values()) {
      if (animationElementView.interactionTrigger) array.push(animationElementView.interactionTrigger);
    }

    for (const tileViewBundle of this.tileViewBundles.values()) {
      for (const tileView of tileViewBundle.tileViews) {
        if (tileView.interactionTrigger) array.push(tileView.interactionTrigger);
      }
    }

    return array;
  }

  get pathfinder() {
    return this._pathfinder;
  }

  onMapDataChanged() {
    for (const callback of this.baseMapChangeCallbacks) {
      callback.call(this);
    }

    this.refreshPathfinder();
    this.recreateGameStoreReactions();
  }

  createTileView(tileData, tileAssetData, tileImageUsage) {
    return new GameTileView(tileData, tileAssetData, tileImageUsage, this.water, this.interactionTriggerOverlay);
  }

  createNpcView(data) {
    return new GameNpcView(data, this.characterOverlayContainer, this.appDisposed, this.interactionTriggerOverlay);
  }

  createAnimationElementView(data) {
    return new GameAnimationElementView(data, this.appDisposed, this.interactionTriggerOverlay);
  }

  shakeX(value) {
    this.x = value;
  }

  refreshPathfinder() {
    this._pathfinder = new PathFinder(this);
  }

  refreshInteractionTriggers() {
    if (!gameStore.gameEngine) return;
    const {
      gameState
    } = gameStore.gameEngine;
    const shownInteractionTriggers = new Set();

    for (const triggerId of gameState.activeTriggerActions) {
      const triggerAction = gameStore.gameEngine.getCachedActionNode(triggerId);
      if (!(triggerAction instanceof InteractionTriggerActionModel)) continue;
      const triggerTarget = resolvePotentialMapElementTreeParameter(triggerAction.triggerElement, "actions/InteractionTriggerValueModel", triggerAction);
      if (!triggerTarget) continue;
      const triggerElement = this.interactionTriggersArray.find(t => t.$modelId === triggerTarget.elementId);
      triggerElement === null || triggerElement === void 0 ? void 0 : triggerElement.show(triggerAction.iconType);
      shownInteractionTriggers.add(triggerElement);
    }

    for (const triggerElement of this.interactionTriggersArray) {
      if (shownInteractionTriggers.has(triggerElement)) continue;
      triggerElement.hide();
    }
  }

  getTaskMarkerOnCurrentMap() {
    const {
      gameState,
      rootActionTree
    } = gameStore.gameEngine;
    const activeLocationTasks = wrapIterator(gameState.playerQuestLogTasks.keys()).map(taskId => rootActionTree.taskForId(taskId)).filter(task => task.isTaskWithLocation());
    return activeLocationTasks.map(task => {
      var _gameState$findTransi;

      const mapElementReference = resolvePotentialMapElementTreeParameter(task.location, undefined, task);
      const elementId = mapElementReference.mapId === gameState.currentMap ? mapElementReference.elementId : (_gameState$findTransi = gameState.findTransitionToMap(mapElementReference.mapId, rootActionTree)) === null || _gameState$findTransi === void 0 ? void 0 : _gameState$findTransi.elementId;
      let mapElement = this.mapData.dynamicMapElements.find(marker => marker instanceof ReadonlyDynamicMapElementAreaTrigger ? marker.id === elementId : marker.$modelId === elementId); // See if its a deco tile with an interaction trigger

      if (!mapElement && mapElementReference.mapId === gameState.currentMap) {
        const tilesWithInteractionTrigger = this.mapData.interactionTriggerTiles;
        const locationTaskTile = tilesWithInteractionTrigger.find(t => t.interactionTriggerData.$modelId === mapElementReference.elementId);

        if (locationTaskTile) {
          const locationTaskTileMapElement = {
            position: locationTaskTile.position,
            createReadOnlyVersion: () => locationTaskTileMapElement
          };
          mapElement = locationTaskTileMapElement;
        }
      }

      if (!mapElement) return null;
      return new TaskMarkerWithColor(mapElement, gameState.playerQuestLogTasks.get(task.$modelId));
    }).filter(task => !!task);
  }

}