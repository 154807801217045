var _dec, _class;

import { Model, model, prop } from "mobx-keystone";
export let ViewAreaTriggerModel = (_dec = model("game/ViewAreaTriggerModel"), _dec(_class = class ViewAreaTriggerModel extends Model({
  rangeOfSight: prop(1).withSetter(),
  directionForward: prop(false).withSetter(),
  directionBackward: prop(false).withSetter(),
  directionLeft: prop(false).withSetter(),
  directionRight: prop(false).withSetter(),
  name: prop("").withSetter(),
  isModuleGate: prop(false).withSetter()
}) {}) || _class);