import { Point } from "pixi.js";
/**
 * A basic camera.
 */

export class Camera {
  /**
   * Creates a new instance.
   * @param x The world x position of the camera.
   * @param y The world y position of the camera.
   * @param zoom The zoom of the camera.
   */
  constructor(x = 0, y = 0, zoom = 0) {
    this.x = x;
    this.y = y;
    this.zoom = zoom;
  }

  getX() {
    return this.x;
  }

  getY() {
    return this.y;
  }

  getZoom() {
    return this.zoom;
  }

  setX(x) {
    this.x = x;
  }

  setY(y) {
    this.y = y;
  }

  setZoom(zoom) {
    this.zoom = zoom;
  }

  onTick() {}

  set(x, y, zoom) {
    this.setX(x);
    this.setY(y);
    this.setZoom(zoom);
    return this;
  }

  setPosition(position) {
    this.setX(position.x);
    this.setY(position.y);
    return this;
  }

  copyPosition() {
    return new Point(this.x, this.y);
  }
  /**
   * Applies this camera to the assigned container.
   * @param container The container to use the camera for.
   */


  applyTo(container) {
    if (!container) {
      console.warn("Trying to apply a camera to an undefined container.");
      return;
    }

    container.position.set(this.getX(), this.getY());
    const zoom = this.getZoom();
    container.scale.set(zoom, zoom);
  }

}