import { managementClient } from "../../../communication/ManagementClient";
import { UndoableOperation } from "../UndoableOperation";
import { executeUndoableOperation } from "../UndoStore";
export function undoableDeleteWorkshop(workshop) {
  executeUndoableOperation(new WorkshopDeletionOp(workshop));
}

class WorkshopDeletionOp extends UndoableOperation {
  constructor(workshop) {
    super("workshopDeletion");
    this.workshop = workshop;
  }

  async execute() {
    await managementClient.deleteWorkshop(this.workshop.$modelId);
  }

  async reverse() {
    await managementClient.unDeleteWorkshop(this.workshop.$modelId);
  }

}